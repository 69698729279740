import '../../assets/fonts/clash-display/WEB/css/clash-display.css';
import React, { useEffect, useRef, useState } from 'react';
import { XAffix, XContainer } from '../../styles/GlobalStyle';
import { Row, Col, Space, Popover, Tooltip } from 'antd';
import Header from '../../components/Header/Header';
import HeaderFile from '../../components/Header/HeaderFile';
import {
	AipaMobileBody,
	AddInputIcon,
	ParaDeocdex,
	XDecodexTitle,
	XHeader,
	XLottieAipa,
	XLottieAipaLoader,
	XSelectIcons,
	XTitleHeader,
	RecordCard,
	XActionBtns,
	XActionPlay,
	XShazaamBtn,
	AipaDesktopBody,
	XRadio,
	XDivider,
	XCardDesktop,
	XInputTextArea,
	XUpload,
} from './NewAipaStyle';
import LoaderJson from '../../json/aipa_animation.json';
import {
	AudioOutlined,
	UserSwitchOutlined,
	EnvironmentOutlined,
	CheckOutlined,
	CloseOutlined,
	PauseOutlined,
	InboxOutlined,
	PlayCircleOutlined,
	RedoOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import {
	Button,
	Input,
	notification,
	Affix,
	List,
	Checkbox,
	Radio,
	Modal,
} from 'antd';
import { XLottie } from '../Login/LoginStyle';
import LoginAnimation from '../../json/Login.json';
import Thanks from '../../json/thanksNew1.json';
import {
	XForm,
	XModal,
	XSelect,
	XRoundButton,
	XModalTitle,
	XModalSubtitle,
	XList,
	XCircle,
	XLabel,
} from '../../styles/GlobalStyle';
import {
	XLottieMargin,
	Title,
	XPara1,
	XButtonCloseBar,
	XButtonReload,
	XNotificationBar,
	XButtonConfirm,
	XCheckboxGroup,
	XuploadAddCase,
	XLottieOverlay,
} from '../AnalyseCoverage/AnalyseCoverageStyle';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
// Actions
import {
	analyseCoverageApi,
	analyseCoverageReset,
} from '../../actions/analyseCoverageAction';
import {
	reviseCoverageApi,
	reviseCoverageReset,
} from '../../actions/reviseCoverageAction';
import {
	sendFeedbackApi,
	sendFeedbackReset,
} from '../../actions/sendFeedbackAction';
import {
	getStateDropdownApi,
	getStateDropdownReset,
} from '../../actions/getStateDropdownAction';
import { filterByLabel, handleInputChange } from '../../utils/formFunctions';
import {
	getAnalysisReset,
	getAnalysisApi,
} from '../../actions/getAnalysisAction';
import AnalysisLoader from '../../components/AnalysisLoader/AnalysisLoader';
import { checkModulePermissions, getUserToken } from '../../utils/helper';
import CaseOperations from '../../components/HorizontalTimeline/CaseOperations';
import config from '../../config';
import {
	getAppealLetterApi,
	getAppealLetterReset,
} from '../../actions/getAppealLetterAction';
import AppealLoading from '../../json/reloading.json';
import AppealLetter from '../../components/HorizontalTimeline/AppealLetter';
import { AipaAudioBtn } from '../Aipa/AipaStyle';
import WaveformVisualizer from '../../components/HorizontalTimeline/RecorderWave';
import axios from 'axios';
const { TextArea } = Input;
const { Dragger } = XUpload;

const NewAipa = (props) => {
	const {
		analyseCoverage,
		analyseCoverageReset,
		analyseCoverageState,
		reviseCoverage,
		reviseCoverageReset,
		reviseCoverageState,
		sendFeedback,
		sendFeedbackReset,
		sendFeedbackState,
		getStateDropdownState,
		getStateDropdown,
		getAnalysis,
		getAnalysisState,
		getAnalysisReset,
		getAppealLetter,
		getAppealLetterState,
	} = props;
	const missingFieldsCardRef = useRef(null);
	const shouldSaveBlobRef = useRef(false);
	const [form] = XForm.useForm();
	const [formUpload] = XForm.useForm();
	const [clinicalDocsForm] = XForm.useForm();
	const [clinicalAudioForm] = XForm.useForm();
	const [showClinicalTextArea, setShowClinicalTextArea] = useState(false);
	const [isClinicalUploadModalOpen, setIsClinicalUploadModalOpen] =
		useState(false);
	const [clinicalDocsLoading, setClinicalDocsLoading] = useState(false);
	const [clinicalDocumentFileList, setClinicalDocumentFileList] = useState(
		[],
	);
	const [submitClinicalDocs, setSubmitClinicalDocs] = useState(false); // Initial value can be 'textarea' or 'upload'
	const [formData, setFormData] = useState();
	const [clinicalSummaryTextLoading, setClinicalSummaryTextLoading] =
		useState(false);
	const [supportingDocsSummary, setSupportingDocsSummary] = useState('');
	const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const [previewTitle, setPreviewTitle] = useState('Upload Acknowledgement');
	const [newOptionForm] = XForm.useForm();
	const [summaryForm] = XForm.useForm();
	const [loading, setLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState([false, '', '', '', '']);
	const [thanksModal, setThanksModal] = useState([false, '']);
	const [summary, setSummary] = useState('');
	const [state, setState] = useState('');
	const [provider, setProvider] = useState('');
	const [modalTextArea, setModalTextArea] = useState('');
	const [feedbackId, setFeedbackId] = useState(null);
	const [pageContent, setPageContent] = useState({
		pastHistory: [],
		presentComplaint: [],
		recommendedProcedure: [],
		medicalCoverage: [],
		status: '',
		missing_points_for_coverage: [],
	});

	const [insuranceCompanies, setInsuranceCompanies] = useState([]);
	const [callGetAnalysisAPiBtn, setCallGetAnalysisAPiBtn] = useState(true);
	const [missingFieldsModal, setMissingFieldsModal] = useState(false);
	const [missingFields, setMissingFields] = useState([]);
	const [missingFieldInstructions, setMissingFieldInstructions] = useState(
		[],
	);
	const [missingFieldTitle, setMissingFieldTitle] = useState('');
	const [missingFieldsForm] = XForm.useForm();
	const [lastAnalysisData, setLastAnalysisData] = useState({});
	const [showAnalysisData, setShowAnalysisData] = useState(false);
	const [analysisCompleted, setAnalysisCompleted] = useState('');
	const [newOptionText, setNewOptionText] = useState('');
	const [newCheckBoxModal, setNewCheckBoxModal] = useState([false, null]);
	const [id, setId] = useState('');
	const [coverageloading, setCoverageLoading] = useState(false);
	const [LooksGoodLoading, setLooksGoodLoading] = useState(false);
	const [isMissingFieldsThere, setIsMissingFieldsThere] = useState(false);
	const [
		updatedClinicalDocumentFileList,
		setUpdatedClinicalDocumentFileList,
	] = useState([]);
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
	const [previewOpen, setPreviewOpen] = useState(false);
	const [clinicalDocumentsToDisplay, setClinicalDocumentsToDisplay] =
		useState([]);
	const [clinicalSelectedOption, setClinicalSelectedOption] =
		useState('clinical_text'); // Initial value can be 'textarea' or 'upload'
	const [supportingFiles, setSupportingFiles] = useState([]);
	const [isClinicalDocUploadFromCommon, setIsClinicalDocUploadFromCommon] =
		useState(false);
	const [isUploadClinicalDoc, setIsUploadClinicalDoc] = useState(false);
	const [appealLetterType, setAppealLetterType] = useState('');
	const [appealLetterContent, setAppealLetterContent] = useState('');
	const [showAppealLetterPreview, setShowAppealLetterPreview] =
		useState(false);
	const [appealLetterLoading, setAppealLetterLoading] = useState(false);

	//Recording start
	const [isRecording, setIsRecording] = useState(false);
	const [transcribedText, setTranscribedText] = useState('');
	const [recordingTime, setRecordingTime] = useState('00:00');
	const recognitionRef = useRef(null); // Store the recognition instance
	const [isPaused, setIsPaused] = useState(false); // Add this new state

	// Add these new states
	const [startTime, setStartTime] = useState(null);
	const [elapsedTime, setElapsedTime] = useState(0);
	const [interimTranscript, setInterimTranscript] = useState(''); // Add this new state
	const [audioBlob, setAudioBlob] = useState(null); // Add this state
	const [isPlaying, setIsPlaying] = useState(false); // Add this state
	const mediaRecorderRef = useRef(null); // Add this ref
	const audioChunksRef = useRef([]); // Add this ref
	const audioElementRef = useRef(null); // Add this ref
	const [totalDuration, setTotalDuration] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);
	const [audioNotes, setAudioNotes] = useState([]);
	const [updatedClinicalAudioFileList, setUpdatedClinicalAudioFileList] =
		useState([]);
	const [clinicalAudioToDisplay, setClinicalAudioToDisplay] = useState([]);
	const [isClinicalUploadAudioModalOpen, setIsClinicalUploadAudioModalOpen] =
		useState(false);
	const [audioPlayModal, setAudioPlayModal] = useState(false);
	const [fileToPlay, setFileToPlay] = useState(null);
	const [shouldSaveBlob, setShouldSaveBlob] = useState(false);

	useEffect(() => {
		let timer;
		if (isRecording && !isPaused) {
			// If there's no startTime (fresh start), set it
			if (!startTime) {
				setStartTime(Date.now() - elapsedTime);
			}

			timer = setInterval(() => {
				const currentElapsed = Date.now() - startTime;
				setElapsedTime(currentElapsed);
				const minutes = String(
					Math.floor(currentElapsed / 60000),
				).padStart(2, '0');
				const seconds = String(
					Math.floor((currentElapsed % 60000) / 1000),
				).padStart(2, '0');
				setRecordingTime(`${minutes}:${seconds}`);
			}, 1000);
		}

		return () => clearInterval(timer);
	}, [isRecording, isPaused, startTime, elapsedTime]);

	console.log('audioBlob--------------------', audioBlob);
	// Update toggleDictation function
	const toggleDictation = () => {
		if (!isRecording && !isPaused) {
			console.log(
				'toggleDictation called*******************************************',
			);
			navigator.mediaDevices
				.getUserMedia({ audio: true })
				.then((stream) => {
					const mediaRecorder = new MediaRecorder(stream);
					mediaRecorderRef.current = mediaRecorder;
					audioChunksRef.current = [];

					mediaRecorder.ondataavailable = (e) => {
						audioChunksRef.current.push(e.data);
					};

					mediaRecorder.onstop = () => {
						const audioBlob = new Blob(audioChunksRef.current, {
							type: 'audio/wav',
						});
						console.log(
							'setAudioBlobcalled--------------------------',
						);

						if (shouldSaveBlobRef.current) {
							console.log(
								'shouldSaveBlobRef##############################',
							);
							setAudioNotes((prevBlobs) => [
								...prevBlobs,
								audioBlob,
							]);
							setAudioBlob(audioBlob);
							handleStopMicrophones();
						}
						shouldSaveBlobRef.current = false;
					};

					mediaRecorder.start();
					setIsRecording(true);
					setStartTime(Date.now());
				})
				.catch((error) => {
					console.error('Error accessing microphone:', error);
					notification.error({
						message: 'Microphone Error',
						description:
							'Unable to access microphone. Please check permissions.',
					});
				});
		}
	};

	// Update handlePause function
	const handlePause = () => {
		if (mediaRecorderRef.current && isRecording) {
			mediaRecorderRef.current.pause();
			setIsPaused(true);
			setIsRecording(false);
		}
	};

	// Update handleResume function
	const handleResume = () => {
		if (mediaRecorderRef.current && isPaused) {
			mediaRecorderRef.current.resume();
			setIsPaused(false);
			setIsRecording(true);
			setStartTime(Date.now() - elapsedTime);
		}
	};

	// const handleStopMicrophones = () => {
	// 	console.log('handleStopMicrophones called!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
	// 	if (mediaRecorderRef.current) {
	// 		mediaRecorderRef.current.stop(); // Stop the MediaRecorder

	// 		// Stop all tracks in the MediaStream
	// 		mediaRecorderRef.current.stream
	// 			.getTracks()
	// 			.forEach((track) => track.stop());

	// 		mediaRecorderRef.current = null; // Clear the MediaRecorder reference
	// 	}
	// };

	const handleStopMicrophones = () => {
		console.log('handleStopMicrophones called!');

		if (mediaRecorderRef.current) {
			// Stop all tracks in the MediaStream
			const tracks = mediaRecorderRef.current.stream.getTracks();
			tracks.forEach((track) => {
				track.stop(); // Stop each track
			});

			// Clear the MediaRecorder reference
			// mediaRecorderRef.current = null;

			// Check if all tracks are stopped
			tracks.forEach((track) => {
				if (track.readyState !== 'ended') {
					console.warn(`Track ${track.kind} is not ended.`);
				}
			});

			console.log('All media tracks stopped.');
		} else {
			console.warn('No mediaRecorderRef to stop.');
		}

		// Optional: Check for active audio input devices
		navigator.mediaDevices.enumerateDevices().then((devices) => {
			const activeAudioInputs = devices.filter(
				(device) => device.kind === 'audioinput' && device.label !== '',
			);
			if (activeAudioInputs.length > 0) {
				console.warn(
					'There are still active audio input devices:',
					activeAudioInputs,
				);
			} else {
				console.log('All audio input devices are stopped.');
			}
		});
	};

	// Update handleStop function
	const handleStop = () => {
		console.log('handleStop function called');
		if (mediaRecorderRef.current) {
			setAudioBlob(null);
			setAudioNotes([]);
			// commented because when click on cancle it will run all the operations related with mediaRecorderRef and set blop value in audioBlob state
			// mediaRecorderRef.current.stop();
			setIsRecording(false);
			setIsPaused(false);
			setIsPlaying(false);
			setRecordingTime('00:00');
			setStartTime(null);
			setElapsedTime(0);
			if (audioElementRef.current) {
				audioElementRef.current.pause();
				audioElementRef.current = null;
			}
			handleStopMicrophones();
		}
	};

	// Update handleDone function
	const handleDone = () => {
		if (mediaRecorderRef.current) {
			shouldSaveBlobRef.current = true; // Allow saving the audioBlob
			mediaRecorderRef.current.stop();
			setIsRecording(false);
			setIsPaused(false);
		}
	};

	// Add new function to handle audio playback
	const handlePlayRecording = async () => {
		if (audioBlob && !isPlaying) {
			const audioUrl = URL.createObjectURL(audioBlob);

			if (!audioElementRef.current) {
				audioElementRef.current = new Audio(audioUrl);

				// audioElementRef.current.onended = () => {
				// 	setIsPlaying(false);
				// 	setRecordingTime('00:00');
				// };
				// audioElementRef.current.ontimeupdate = () => {
				// 	if (audioElementRef.current) {
				// 		const minutes = String(
				// 			Math.floor(
				// 				audioElementRef.current.currentTime / 60,
				// 			),
				// 		).padStart(2, '0');
				// 		const seconds = String(
				// 			Math.floor(
				// 				audioElementRef.current.currentTime % 60,
				// 			),
				// 		).padStart(2, '0');
				// 		setRecordingTime(`${minutes}:${seconds}`);
				// 	}
				// };

				// Using Web Audio API to decode the audio blob and get its duration
				const audioContext = new (window.AudioContext ||
					window.webkitAudioContext)();
				const arrayBuffer = await audioBlob.arrayBuffer();
				let totalDuration = 0;
				// Decode the audio data
				audioContext.decodeAudioData(arrayBuffer, (decodedData) => {
					totalDuration = decodedData.duration;

					// if (isFinite(totalDuration) && totalDuration > 0) {
					// 	const minutes = String(
					// 		Math.floor(totalDuration / 60),
					// 	).padStart(2, '0');
					// 	const seconds = String(
					// 		Math.floor(totalDuration % 60),
					// 	).padStart(2, '0');
					// 	setRecordingTime(`${minutes}:${seconds}`);
					// }
				});

				audioElementRef.current.onended = () => {
					setIsPlaying(false);
					const totalDuration = audioElementRef.current.duration;

					const minutes = String(
						Math.floor(totalDuration / 60),
					).padStart(2, '0');
					const seconds = String(
						Math.floor(totalDuration % 60),
					).padStart(2, '0');
					setRecordingTime(`${minutes}:${seconds}`); // Reset to total duration
				};
				audioElementRef.current.ontimeupdate = () => {
					if (audioElementRef.current) {
						const remainingTime =
							totalDuration - audioElementRef.current.currentTime;

						const minutes = String(
							Math.floor(remainingTime / 60),
						).padStart(2, '0');
						const seconds = String(
							Math.floor(remainingTime % 60),
						).padStart(2, '0');
						setRecordingTime(`${minutes}:${seconds}`);
					}
				};
			}

			audioElementRef.current.play();
			setIsPlaying(true);
		}
	};

	const handlePlayRecordedNote = async () => {
		if (fileToPlay && !isPlaying) {
			// const audioUrl = URL.createObjectURL(audioBlob);

			if (!audioElementRef.current) {
				audioElementRef.current = new Audio(fileToPlay);
				audioElementRef.current.crossOrigin = 'anonymous';

				const response = await fetch(fileToPlay);
				const arrayBuffer = await response.arrayBuffer();

				const audioContext = new (window.AudioContext ||
					window.webkitAudioContext)();
				const decodedData = await audioContext.decodeAudioData(
					arrayBuffer,
				);

				const totalDuration = decodedData.duration;

				audioElementRef.current.onended = () => {
					setIsPlaying(false);
					const totalDuration = audioElementRef.current.duration;

					const minutes = String(
						Math.floor(totalDuration / 60),
					).padStart(2, '0');
					const seconds = String(
						Math.floor(totalDuration % 60),
					).padStart(2, '0');
					setRecordingTime(`${minutes}:${seconds}`); // Reset to total duration
				};

				audioElementRef.current.ontimeupdate = () => {
					if (audioElementRef.current) {
						const remainingTime =
							totalDuration - audioElementRef.current.currentTime;

						const minutes = String(
							Math.floor(remainingTime / 60),
						).padStart(2, '0');
						const seconds = String(
							Math.floor(remainingTime % 60),
						).padStart(2, '0');
						setRecordingTime(`${minutes}:${seconds}`);
					}
				};
			}

			audioElementRef.current.play();
			// audioElementRef.current.play().catch((error) => {
			// 	console.error('Error playing audio:', error);
			// });
			setIsPlaying(true);
		}
	};

	// Add new function to handle pause playback
	const handlePausePlayback = () => {
		if (audioElementRef.current && isPlaying) {
			audioElementRef.current.pause();
			setIsPlaying(false);
		}
	};

	// const handlePause = () => {
	// 	if (recognitionRef.current) {
	// 		recognitionRef.current.stop();
	// 		setIsPaused(true);
	// 		setIsRecording(false);
	// 		// Don't reset startTime here to maintain the elapsed time
	// 	}
	// };

	// const handleResume = () => {
	// 	if (recognitionRef.current) {
	// 		recognitionRef.current.start();
	// 		setIsPaused(false);
	// 		setIsRecording(true);
	// 		// Update startTime to account for the pause duration
	// 		setStartTime(Date.now() - elapsedTime);
	// 	}
	// };

	// const handleStop = () => {
	// 	if (recognitionRef.current) {
	// 		recognitionRef.current.stop();
	// 		recognitionRef.current = null;
	// 		setIsRecording(false);
	// 		setIsPaused(false);
	// 		setRecordingTime('00:00');
	// 		setStartTime(null);
	// 		setElapsedTime(0);
	// 		setTranscribedText('');
	// 		setInterimTranscript(''); // Clear interim transcript
	// 		summaryForm.setFieldsValue({
	// 			dictate_summary: '',
	// 		});
	// 		setSummary('');
	// 	}
	// };

	// const handleDone = () => {
	// 	if (transcribedText.trim()) {
	// 		const finalText = transcribedText.trim();

	// 		// Stop the recognition
	// 		if (recognitionRef.current) {
	// 			recognitionRef.current.stop();
	// 			recognitionRef.current = null;
	// 		}

	// 		// Update all relevant states and form
	// 		setTranscribedText(finalText);
	// 		summaryForm.setFieldsValue({
	// 			dictate_summary: finalText,
	// 		});
	// 		setSummary(finalText);
	// 		setInterimTranscript(''); // Clear interim transcript

	// 		// Reset recording states
	// 		setIsRecording(false);
	// 		setIsPaused(false);
	// 		setRecordingTime('00:00');
	// 		setStartTime(null);
	// 		setElapsedTime(0);

	// 		notification.success({
	// 			message: 'Transcription Complete',
	// 			description: 'Your recording has been transcribed!',
	// 		});
	// 	} else {
	// 		notification.warning({
	// 			message: 'No Transcription',
	// 			description: 'Please record some text before saving.',
	// 		});
	// 	}
	// };

	// const toggleDictation = () => {
	// 	const SpeechRecognition =
	// 		window.SpeechRecognition || window.webkitSpeechRecognition;

	// 	if (!SpeechRecognition) {
	// 		notification.error({
	// 			message: 'Speech Recognition Not Supported',
	// 			description:
	// 				'Your browser does not support the Speech Recognition API.',
	// 		});
	// 		return;
	// 	}

	// 	if (!isRecording && !isPaused) {
	// 		const recognition = new SpeechRecognition();
	// 		recognitionRef.current = recognition;

	// 		recognition.lang = 'en-US';
	// 		recognition.continuous = true;
	// 		recognition.interimResults = true;
	// 		recognition.maxAlternatives = 1;

	// 		let finalTranscript = '';

	// 		recognition.onresult = (event) => {
	// 			let interimTranscript = '';

	// 			for (let i = event.resultIndex; i < event.results.length; i++) {
	// 				const transcript = event.results[i][0].transcript;

	// 				if (event.results[i].isFinal) {
	// 					finalTranscript += transcript + ' ';
	// 					// Update the main transcribed text
	// 					setTranscribedText(finalTranscript.trim());
	// 					// Update form and summary with final text
	// 					summaryForm.setFieldsValue({
	// 						dictate_summary: finalTranscript.trim(),
	// 					});
	// 					setSummary(finalTranscript.trim());
	// 				} else {
	// 					interimTranscript += transcript;
	// 				}
	// 			}

	// 			// Update interim transcript for popover display
	// 			setInterimTranscript(
	// 				finalTranscript + ' ' + interimTranscript.trim(),
	// 			);
	// 		};

	// 		recognition.onerror = (event) => {
	// 			console.error('Speech recognition error:', event.error);
	// 			notification.error({
	// 				message: 'Error',
	// 				description: 'Speech recognition encountered an error.',
	// 			});
	// 			handleStop();
	// 		};

	// 		recognition.onend = () => {
	// 			if (!isPaused) {
	// 				setIsRecording(false);
	// 			}
	// 		};

	// 		recognition.start();
	// 		setIsRecording(true);
	// 		setStartTime(Date.now());
	// 	}
	// };
	// //Recording end

	useEffect(() => {
		if (modalOpen[0] && modalOpen[4] !== 'feedbackSummary') {
			// Check if modal is open and it's not the feedbackSummary modal
			const [description, code] = modalTextArea.split(' - ');
			form.setFieldsValue({
				modal_text_area: description || '', // Prepopulate description
				code: code ? code.split(': ')[1] : '', // Extract and prepopulate code
			});
		}
	}, [modalOpen, modalTextArea, form]);

	// useEffect(() => {
	// 	if (
	// 		getStateDropdownState.apiState === 'success' &&
	// 		getStateDropdownState.data &&
	// 		getStateDropdownState.data.all_states?.length > 0
	// 	) {
	// 		// Check if the field is already populated
	// 		const currentState = summaryForm.getFieldValue('state');
	// 		if (!currentState) {
	// 			const preselectedState = 'Washington';
	// 			summaryForm.setFieldValue('state', preselectedState);

	// 			// Set insurance companies for the preselected state
	// 			const selectedState =
	// 				getStateDropdownState.data.all_states.find(
	// 					(itm) => itm.state === preselectedState,
	// 				);

	// 			if (selectedState) {
	// 				setState(preselectedState);
	// 				setInsuranceCompanies(selectedState.providers);
	// 			}
	// 		}
	// 	}
	// }, [getStateDropdownState]);

	useEffect(() => {
		if (getAppealLetterState.apiState === 'success') {
			setAppealLetterLoading(false);

			// getAnalysis({ id: id });
			setAppealLetterContent(getAppealLetterState.data.appeal_letter);
			setShowAppealLetterPreview(true);
			getAnalysis({ feedback_id: feedbackId });
			// navigate(`/case/filings/preview/${id}`, {
			// 	state: {
			// 		appealLetterType: appealLetterType,
			// 		// id: id,
			// 		patientDetails: patientData,
			// 		content: getAppealLetterState.data.appeal_letter,
			// 		header: getAppealLetterState.data.header,
			// 		footer: getAppealLetterState.data.footer,
			// 		isEdit: false,
			// 	},
			// });
		}
		if (getAppealLetterState.apiState === 'error') {
			setAppealLetterLoading(false);
			setShowAppealLetterPreview(false);
			notification.error({
				message: getAppealLetterState.message
					? getAppealLetterState.message
					: 'Unable to get Appeal Letter content',
			});
		}
		getAppealLetterReset();
	}, [getAppealLetterState.apiState]);

	const previewAppealLetter = (type, regenerate = false) => {
		getAppealLetter({
			id: id,
			type: type,
			appeal_letter_generated: regenerate,
			aipa: true,
			provider: provider,
			clinical_summary: summary,
			state: state,
			feedbackId: feedbackId,
		});
		setAppealLetterType(type);
	};

	const handleUploadCancel = () => {
		setIsClinicalUploadModalOpen(false);
		setIsUploadModalOpen(false);
		setClinicalDocumentFileList([]);
		setUpdatedClinicalDocumentFileList([]);
		setClinicalDocsLoading(false);
		setSubmitClinicalDocs(false);
		setFormData({
			...formData,
			clinical_summary: '',
		});

		clinicalDocsForm.setFieldsValue({ clinical_docs: '' });
	};

	const handleUploadAudioCancel = () => {
		setIsClinicalUploadAudioModalOpen(false);
		setIsUploadModalOpen(false);
		setAudioNotes([]);
		setUpdatedClinicalAudioFileList([]);
		setClinicalDocsLoading(false);
		setSubmitClinicalDocs(false);
		setAudioBlob(null);
		// setFormData({
		// 	...formData,
		// 	clinical_summary: '',
		// });
		handleStop();
		clinicalAudioForm.setFieldsValue({ dictate_audio_note: '' });
	};

	const handlePreviewClinicalSummary = async (file) => {
		// Check if the file is a PDF
		if (file && file.name && file.name.split('.').includes('pdf')) {
			// If there's a URL, use it directly; otherwise, generate a preview from the file
			const fileUrl = file.url ? `${config.s3_url}${file.url}` : null;
			setPreviewImage(fileUrl || file.preview);
			setPdfPreviewOpen(true); // Open PDF preview modal
		} else {
			// For non-PDF files, generate a preview if necessary
			if (!file.url && !file.preview && file.originFileObj) {
				file.preview = await getBase64(file.originFileObj);
			}
			setPreviewImage(file.url || file.preview);
			setPreviewOpen(true); // Open image preview modal
			setPreviewTitle(
				file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
			);
		}
	};

	const handleClinicalDocumentChange = ({ fileList: newFileList }) => {
		const updatedFileList = newFileList.map((file) => {
			if (file.status === 'error') {
				return { ...file, status: 'done' };
			}
			return file;
		});
		setClinicalDocumentFileList(updatedFileList);
		setUpdatedClinicalDocumentFileList(updatedFileList);
		summaryForm.setFieldsValue({
			clinical_summary: '',
		});
		setFormData({
			...formData,
			clinical_summary: '',
		});
		setSummary('');
		handleStop();
		summaryForm.validateFields(['clinical_document']);
	};

	const uploadButton = (
		<Button
			style={{
				border: 0,
				background: 'none',
				margin: '0',
				boxShadow: 'none',
			}}
		>
			<PlusOutlined style={{ color: '#000' }} />
			<div style={{ color: '#000' }}>Upload</div>
		</Button>
	);

	const handleRemoveClinicalDocuments = async (file) => {
		Modal.confirm({
			title: 'Confirm Deletion',
			content: 'Are you sure you want to delete this file?',
			okText: 'Yes',
			cancelText: 'No',
			cancelButtonProps: {
				// Add an icon to the Cancel button
				style: {
					backgroundColor: '#fff', // Red background for Cancel
					color: '#007299', // Text color
				},
			},
			okButtonProps: {
				style: {
					backgroundColor: '#007299',
					borderColor: '#007299',
					color: '#fff',
				},
			},
			onOk: async () => {
				const updatedFileList = clinicalDocumentFileList.filter(
					(item) => item.uid !== file.uid,
				);
				const updatedFileListToDisplay =
					clinicalDocumentsToDisplay.filter(
						(item) => item.uid !== file.uid,
					);
				setClinicalDocumentFileList(updatedFileList);
				setUpdatedClinicalDocumentFileList(updatedFileList);
				setClinicalDocumentsToDisplay(updatedFileListToDisplay);
				form.validateFields(['clinical_document']);
				if (!file.originFileObj) {
					// setDeletedClinicalDocuments((prevFiles) => [
					// 	...prevFiles,
					// 	file,
					// ]);
				}
			},
			onCancel: () => {
				form.validateFields(['clinical_document']);
				console.log('Deletion cancelled');
			},
		});
	};

	const handleRemoveClinicalAudios = async (file) => {
		Modal.confirm({
			title: 'Confirm Deletion',
			content: 'Are you sure you want to delete this file?',
			okText: 'Yes',
			cancelText: 'No',
			cancelButtonProps: {
				// Add an icon to the Cancel button
				style: {
					backgroundColor: '#fff', // Red background for Cancel
					color: '#007299', // Text color
				},
			},
			okButtonProps: {
				style: {
					backgroundColor: '#007299',
					borderColor: '#007299',
					color: '#fff',
				},
			},
			onOk: async () => {
				// const updatedFileList = audioNotes.filter(
				// 	(item) => item.uid !== file.uid,
				// );
				const updatedAudioFileListToDisplay =
					clinicalAudioToDisplay.filter(
						(item) => item.url !== file.url,
					);
				setAudioNotes([]);
				setUpdatedClinicalDocumentFileList(
					updatedAudioFileListToDisplay,
				);
				setClinicalAudioToDisplay(updatedAudioFileListToDisplay);
				// form.validateFields(['clinical_document']);
			},
			onCancel: () => {
				// form.validateFields(['clinical_document']);
				console.log('Deletion cancelled');
			},
		});
	};

	// Function to generate a random alphanumeric string of given length
	const generateRandomAlphaNumeric = (length) => {
		const characters =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let result = '';
		for (let i = 0; i < length; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * characters.length),
			);
		}
		return result;
	};

	// // Function to generate an ID with specified format
	// const generateCustomId = () => {
	// 	const randomPart = generateRandomAlphaNumeric(9); // First 9 characters alphanumeric
	// 	const timestampPart = moment().format('YYDDMMHHMMSSMS'); // Last 15 characters formatted as YYDDMMHHMMSSMS
	// 	const customId = randomPart + timestampPart;

	// 	return customId;
	// };

	// Generate a random 24-character hexadecimal ID
	const generateCustomId = () => {
		const characters = '0123456789abcdef';
		let result = '';
		for (let i = 0; i < 24; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * characters.length),
			);
		}
		return result;
	};

	const updatePageContent = (title, id, content) => {
		if (id === null) {
			if (title === 'originalQuery') {
				setPageContent({
					...pageContent,
					originalQuery: content,
				});
			} else {
				setPageContent({
					...pageContent,
					[title]: [...pageContent[[title]], content],
				});
			}
		} else {
			const updatedPageContent = { ...pageContent };
			updatedPageContent[[title]][id] = content;
			setPageContent(updatedPageContent);
		}
		// setSummary(formData.clinical_summary);
		setClinicalSummaryTextLoading(false);
		setShowClinicalTextArea(false);
		setModalOpen([false, '', ' ']);
		setModalTextArea('');
		form.resetFields();
		document.getElementById('notification').classList.remove('d-none');
	};

	const handleAddOption = (index) => {
		setNewCheckBoxModal([true, index]);
		setNewOptionText('');
		newOptionForm.setFieldsValue({});
		newOptionForm.resetFields();
	};

	const handleNewCheckBoxModal = () => {
		// Create a new option object
		newOptionForm
			.validateFields()
			.then(() => {
				const newOption = {
					label: newOptionText,
					value: newOptionText,
				};

				if (
					newCheckBoxModal[1] !== null &&
					newCheckBoxModal[1] >= 0 &&
					newCheckBoxModal[1] < missingFields.length
				) {
					const updatedFields = [...missingFields];
					updatedFields[newCheckBoxModal[1]].options.push(newOption);
					setMissingFields(updatedFields);

					// Get the current form values
					const currentValues = missingFieldsForm.getFieldsValue();

					// Add the new option to the appropriate field and mark it as checked
					if (
						!currentValues[updatedFields[newCheckBoxModal[1]].name]
					) {
						currentValues[updatedFields[newCheckBoxModal[1]].name] =
							[];
					}
					currentValues[updatedFields[newCheckBoxModal[1]].name].push(
						newOptionText,
					);

					// Update the form with the new values
					missingFieldsForm.setFieldsValue(currentValues);
				}

				// Close the modal and reset the newOptionText state
				setNewCheckBoxModal([false, null]);
				setNewOptionText('');
				newOptionForm.setFieldsValue({});
				newOptionForm.resetFields();
			})
			.catch((info) => {
				console.log('Validate Failed:', info);
			});
	};

	const handleClinicalSummaryDoc = async () => {
		let uploadPromises = [];
		let uploadedClinicalDocs = [];

		// Handle Clinical Documents Upload
		if (clinicalDocumentFileList.length > 0) {
			const clinicalDocPromises = clinicalDocumentFileList.map(
				async (fileData) => {
					let get_ext = fileData.originFileObj.name.split('.');
					let ext = get_ext[get_ext.length - 1];
					const res = await axios.get(
						`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=clinical_documents&file_name=${fileData.originFileObj.uid}&ext=${ext}&id=${id}`,
						{
							headers: {
								'Content-type':
									'application/json; charset=UTF-8',
								Authorization: 'Bearer ' + getUserToken(),
							},
						},
					);

					const result = await axios.put(
						res.data.data,
						fileData.originFileObj,
					);
					if (result.status === 200) {
						const documentPath = `/clinical_documents/${id}/${fileData.originFileObj.uid}.${ext}`;
						uploadedClinicalDocs.push({
							uid: fileData.originFileObj.uid,
							name: fileData.originFileObj.name,
							status: 'done',
							url: documentPath,
						});
					}
				},
			);
			uploadPromises = uploadPromises.concat(clinicalDocPromises);
		}
		// Wait for all uploads to complete
		await Promise.all(uploadPromises);
		// Update state with uploaded files
		setUpdatedClinicalDocumentFileList(uploadedClinicalDocs);

		// for audio note start

		// for audio note end

		// Make the API call with updated file information
		const payload = {
			analysis_id: id,
			state: state,
			provider: provider,
			aipa: true,
			clinical_docs: uploadedClinicalDocs,
			isExtractedTextReturn: true,
		};

		setIsUploadClinicalDoc(true);
		setIsClinicalDocUploadFromCommon(true);
		analyseCoverage(payload);
		setShowAppealLetterPreview(false);
		// const formData = new FormData();

		// // // Append supportingDocs files
		// // supportingFiles.forEach((file) => {
		// // 	formData.append('supporting_docs', file.url?.originFileObj); // 'supportingDocs' will be the backend key
		// // });

		// // Append clinicalDocs files
		// clinicalDocumentFileList.forEach((file) => {
		// 	formData.append('clinical_docs', file.originFileObj); // 'clinicalDocs' will be the backend key
		// });
		// formData.append('supportingDocsSummary', supportingDocsSummary);
		// // formData.append('state', state);
		// // formData.append('provider', provider);
		// // formData.append('analysis_id', id);
		// // Log to check FormData contents
		// // setLoading(true);
		// setIsClinicalDocUploadFromCommon(true);
		// analyseCoverage(formData);
	};

	const handleClinicalSummaryAudio = async () => {
		let uploadAudioPromises = [];
		let uploadedClinicalAudio = [];

		if (audioNotes.length > 0) {
			const audioDocPromises = audioNotes.map(async (audioBlob) => {
				let ext = '';
				const mimeType = audioBlob.type;

				if (mimeType === 'audio/wav') {
					ext = 'wav';
				} else if (mimeType === 'audio/mp3') {
					ext = 'mp3';
				} else if (mimeType === 'audio/mpeg') {
					// 'audio/mpeg' is commonly used for mp3
					ext = 'mp3';
				} else if (mimeType === 'audio/mp4') {
					ext = 'mp4';
				} else {
					throw new Error('Unsupported audio format');
				}
				const fileName = `audio_${Date.now()}`;

				const res = await axios.get(
					`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=clinical_documents&file_name=${fileName}&ext=${ext}&id=${id}`,
					{
						headers: {
							'Content-type': 'application/json; charset=UTF-8',
							Authorization: 'Bearer ' + getUserToken(),
						},
					},
				);

				const result = await axios.put(res.data.data, audioBlob);
				if (result.status === 200) {
					const documentPath = `/clinical_documents/${id}/${fileName}.${ext}`;
					uploadedClinicalAudio.push({
						url: documentPath,
					});
				}
			});
			uploadAudioPromises = uploadAudioPromises.concat(audioDocPromises);
		}
		// Wait for all uploads to complete
		await Promise.all(uploadAudioPromises);

		// Update state with uploaded files
		setUpdatedClinicalAudioFileList(uploadedClinicalAudio);

		// Make the API call with updated file information
		const payload = {
			analysis_id: id,
			state: state,
			provider: provider,
			aipa: true,
			audio_files: uploadedClinicalAudio,
			isExtractedTextReturn: true,
		};

		// setIsUploadClinicalDoc(true);
		// setIsClinicalDocUploadFromCommon(true);
		analyseCoverage(payload);
		setShowAppealLetterPreview(false);
	};

	const getBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const handleClinicalDocsChange = ({ fileList: newFileList }) => {
		const updatedFileList = newFileList.map((file) => {
			if (file.status === 'error') {
				return { ...file, status: 'done' };
			}
			// file.preview = await getBase64(file.originFileObj);
			return file;
		});
		setClinicalDocumentFileList(updatedFileList);
		setUpdatedClinicalDocumentFileList(updatedFileList);
		clinicalDocsForm.validateFields(['clinical_docs']);
	};

	// Function to handle form submission
	const handleShazamClick = async () => {
		try {
			if (clinicalSelectedOption === 'dictate_audio' && !audioBlob) {
				return;
			}
			setLoading(true); // Assuming you have setLoading function declared somewhere
			const newId = generateCustomId();
			setId(newId); // Update state with generated ID

			let uploadPromises = [];
			let uploadedClinicalDocs = [];
			let uploadedClinicalAudio = [];
			let uploadAudioPromises = [];

			// Handle Clinical Documents Upload
			if (clinicalDocumentFileList.length > 0) {
				const clinicalDocPromises = clinicalDocumentFileList.map(
					async (fileData) => {
						let get_ext = fileData.originFileObj.name.split('.');
						let ext = get_ext[get_ext.length - 1];
						const res = await axios.get(
							`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=clinical_documents&file_name=${fileData.originFileObj.uid}&ext=${ext}&id=${newId}`,
							{
								headers: {
									'Content-type':
										'application/json; charset=UTF-8',
									Authorization: 'Bearer ' + getUserToken(),
								},
							},
						);

						const result = await axios.put(
							res.data.data,
							fileData.originFileObj,
						);
						if (result.status === 200) {
							const documentPath = `/clinical_documents/${newId}/${fileData.originFileObj.uid}.${ext}`;
							uploadedClinicalDocs.push({
								uid: fileData.originFileObj.uid,
								name: fileData.originFileObj.name,
								status: 'done',
								url: documentPath,
							});
						}
					},
				);
				uploadPromises = uploadPromises.concat(clinicalDocPromises);
			}

			// Wait for all uploads to complete
			await Promise.all(uploadPromises);

			// Update state with uploaded files
			setUpdatedClinicalDocumentFileList(uploadedClinicalDocs);

			if (audioNotes.length > 0) {
				const audioDocPromises = audioNotes.map(async (audioBlob) => {
					let ext = '';
					const mimeType = audioBlob.type;

					if (mimeType === 'audio/wav') {
						ext = 'wav';
					} else if (mimeType === 'audio/mp3') {
						ext = 'mp3';
					} else if (mimeType === 'audio/mpeg') {
						// 'audio/mpeg' is commonly used for mp3
						ext = 'mp3';
					} else if (mimeType === 'audio/mp4') {
						ext = 'mp4';
					} else {
						throw new Error('Unsupported audio format');
					}
					const fileName = `audio_${Date.now()}`;

					const res = await axios.get(
						`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=clinical_documents&file_name=${fileName}&ext=${ext}&id=${newId}`,
						{
							headers: {
								'Content-type':
									'application/json; charset=UTF-8',
								Authorization: 'Bearer ' + getUserToken(),
							},
						},
					);

					const result = await axios.put(res.data.data, audioBlob);
					if (result.status === 200) {
						const documentPath = `/clinical_documents/${newId}/${fileName}.${ext}`;
						uploadedClinicalAudio.push({
							url: documentPath,
						});
					}
				});
				uploadAudioPromises =
					uploadAudioPromises.concat(audioDocPromises);
			}
			// Wait for all uploads to complete
			await Promise.all(uploadAudioPromises);

			// Update state with uploaded files
			setUpdatedClinicalAudioFileList(uploadedClinicalAudio);

			// if (audioBlob) {
			// 	// Determine the file extension based on the blob's type
			// 	let ext = '';
			// 	const mimeType = audioBlob.type;

			// 	if (mimeType === 'audio/wav') {
			// 		ext = 'wav';
			// 	} else if (mimeType === 'audio/mp3') {
			// 		ext = 'mp3';
			// 	} else if (mimeType === 'audio/mpeg') {
			// 		// 'audio/mpeg' is commonly used for mp3
			// 		ext = 'mp3';
			// 	} else if (mimeType === 'audio/mp4') {
			// 		ext = 'mp4';
			// 	} else {
			// 		throw new Error('Unsupported audio format');
			// 	}
			// 	const fileName = `audio_${Date.now()}`;
			// 	const res = await axios.get(
			// 		`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=clinical_documents&file_name=${fileName}&ext=${ext}&id=${newId}`,
			// 		{
			// 			headers: {
			// 				'Content-type': 'application/json; charset=UTF-8',
			// 				Authorization: 'Bearer ' + getUserToken(),
			// 			},
			// 		},
			// 	);

			// 	const result = await axios.put(res.data.data, audioBlob);

			// 	if (result.status === 200) {
			// 		const documentPath = `/clinical_documents/${newId}/${fileName}.${ext}`;
			// 		uploadedClinicalAudio.push({
			// 			url: documentPath,
			// 		});
			// 	}
			// }

			// Make the API call with updated file information
			const payload = {
				analysis_id: newId,
				clinicalSummary: summary,
				state: state,
				provider: provider,
				aipa: true,
				clinical_docs: uploadedClinicalDocs,
				audio_files: uploadedClinicalAudio,
			};
			// const formData = new FormData();
			// // Append supportingDocs files
			// supportingFiles.forEach((file) => {
			// 	formData.append('supporting_docs', file.url?.originFileObj); // 'supportingDocs' will be the backend key
			// });

			// // Append clinicalDocs files
			// clinicalDocumentFileList.forEach((file) => {
			// 	formData.append('clinical_docs', file.originFileObj); // 'clinicalDocs' will be the backend key
			// });
			// formData.append('clinicalSummary', summary);
			// formData.append('state', state);
			// formData.append('provider', provider);
			// formData.append('analysis_id', newId);
			// formData.append('aipa', true);
			// Log to check FormData contents
			setIsUploadClinicalDoc(true);
			setIsClinicalDocUploadFromCommon(true);
			setLoading(true);
			analyseCoverage(payload);
			setShowAppealLetterPreview(false);
		} catch (error) {
			setLoading(false);
			notification.error({
				message: 'Upload Error',
				description:
					'An error occurred while uploading files. Please try again.',
			});
			console.error('Error in handleShazamClick:', error);
		}
	};

	useEffect(() => {
		if (showAnalysisData) {
			document.getElementById('getQust').classList.add('bottom-100');
			document.getElementById('getAns').classList.add('top-0');
			document.getElementById('getAns').classList.remove('top-100');
			document.getElementById('getAns')?.classList.remove('d-none');
		}
	}, [showAnalysisData]);

	useEffect(() => {
		if (missingFieldsModal && missingFieldsCardRef.current) {
			// ADD
			document.getElementById('getQust').classList.add('bottom-100');
			document.getElementById('getAns').classList.add('top-0');
			document.getElementById('getAns').classList.remove('top-100');
			document.getElementById('getAns')?.classList.remove('d-none');
			// ADD
			missingFieldsCardRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [missingFieldsModal]);

	useEffect(() => {
		if (analyseCoverageState.apiState === 'success') {
			if (analyseCoverageState.data.isClinicalText) {
				if (analyseCoverageState.data?.is_clinical_summary === 0) {
					// setLoading(false);
					setAppealLetterLoading(false);
					setIsClinicalDocUploadFromCommon(false);
					// setShowClinicalTextArea(false);
					handleUploadCancel();
					handleUploadAudioCancel();
					setAnalysisCompleted('error');
					setIsMissingFieldsThere(false);
					notification.error({
						message: analyseCoverageState.message,
					});
					analyseCoverageReset();
				} else {
					// setSummary(analyseCoverageState.data?.clinical_summary);
					setIsClinicalUploadModalOpen(false);
					setIsClinicalUploadAudioModalOpen(false);
					setClinicalDocsLoading(false);
					setLoading(true);
					const payload = {
						analysis_id: id,
						state: state,
						provider: provider,
						clinicalSummary:
							analyseCoverageState.data?.clinical_summary,
						supportingDocsSummary: supportingDocsSummary,
					};
					analyseCoverage(payload);
				}
			} else {
				// Check for error conditions first
				if (analyseCoverageState.data?.is_clinical_summary === 0) {
					// setLoading(false);
					setAppealLetterLoading(false);
					setIsClinicalDocUploadFromCommon(false);
					// setShowClinicalTextArea(false);
					handleUploadCancel();
					handleUploadAudioCancel();
					setAnalysisCompleted('error');
					setIsMissingFieldsThere(false);
					notification.error({
						message: analyseCoverageState.message,
					});
					analyseCoverageReset();
					return;
				} else {
					setClinicalSelectedOption('clinical_text');
					setClinicalSummaryTextLoading(false);
					setClinicalDocumentsToDisplay(
						updatedClinicalDocumentFileList,
					);
					setClinicalAudioToDisplay(updatedClinicalAudioFileList);
					handleUploadCancel();
					handleUploadAudioCancel();
					setClinicalDocsLoading(false);
					setAppealLetterLoading(false);
					setShowClinicalTextArea(false);
					setFeedbackId(analyseCoverageState.data.feedback_id);
					setSupportingDocsSummary(
						analyseCoverageState.data?.supportingDocsSummary,
					);
					setLastAnalysisData(analyseCoverageState.data);
					// if (isClinicalDocUploadFromCommon) {
					setSummary(
						analyseCoverageState.data['original_query'] ?? '',
					);
					// }
					setPageContent({
						...pageContent,
						originalQuery:
							analyseCoverageState.data['original_query'] ?? '',
						pastHistory:
							analyseCoverageState.data['Past History'] ?? [],
						presentComplaint:
							analyseCoverageState.data['Present Complaint'] ??
							[],
						recommendedProcedure:
							analyseCoverageState.data['Suggested Treatment'] ??
							[],
						medicalCoverage: [
							analyseCoverageState.data['Medicare Evaluation']
								? analyseCoverageState.data[
										'Medicare Evaluation'
								  ]['Coverage']
								: 'No',
							analyseCoverageState.data['Medicare Evaluation']
								? analyseCoverageState.data[
										'Medicare Evaluation'
								  ]['Rationale']
								: '',
							analyseCoverageState.data['Medicare Evaluation']
								? analyseCoverageState.data[
										'Medicare Evaluation'
								  ]['reference']
								: null,
						],
						missing_points_for_coverage:
							analyseCoverageState.data
								.missing_points_for_coverage ?? [],
					});

					setAnalysisCompleted('success');
					analyseCoverageReset();
					// setLoading(false);
					setIsUploadClinicalDoc(false);
					setIsClinicalDocUploadFromCommon(false);

					if (
						analyseCoverageState.data.hasOwnProperty(
							'is_fields_missing',
						) &&
						analyseCoverageState.data.is_fields_missing &&
						analyseCoverageState.data.hasOwnProperty(
							'missing_fields',
						)
					) {
						if (
							analyseCoverageState.data.missing_fields.length > 0
						) {
							setMissingFields(
								analyseCoverageState.data.missing_fields ?? [],
							);
							setMissingFieldInstructions(
								analyseCoverageState.data
									.missing_field_instructions ?? [],
							);
							setMissingFieldTitle(
								analyseCoverageState.data.missing_field_title ??
									'',
							);
							setMissingFieldsModal(true);
							setIsMissingFieldsThere(true);
						}
					} else {
						setShowAnalysisData(true);
						setIsMissingFieldsThere(false);
					}
				}
			}
		}

		if (analyseCoverageState.apiState === 'error') {
			// setLoading(false);
			setAppealLetterLoading(false);
			setIsClinicalDocUploadFromCommon(false);
			// setShowClinicalTextArea(false);
			handleUploadCancel();
			handleUploadAudioCancel();
			setAnalysisCompleted('error');
			setIsMissingFieldsThere(false);
			notification.error({
				message:
					'Hey, we are facing high traffic. Please check back in few minutes.',
			});
			analyseCoverageReset();
		}
	}, [analyseCoverageState]);

	useEffect(() => {
		if (getAnalysisState.apiState === 'success') {
			setFeedbackId(getAnalysisState.data.coverage_feedback_response._id);
			setLastAnalysisData(
				getAnalysisState.data?.coverage_feedback_response,
			);
			setAppealLetterLoading(false);
			setPageContent({
				...pageContent,
				originalQuery: summary ?? '',
				pastHistory:
					getAnalysisState.data.coverage_feedback_response[
						'Past History'
					] ?? [],
				presentComplaint:
					getAnalysisState.data.coverage_feedback_response[
						'Present Complaint'
					] ?? [],
				recommendedProcedure:
					getAnalysisState.data.coverage_feedback_response[
						'Suggested Treatment'
					] ?? [],
				medicalCoverage: [
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['Coverage']
						: 'No',
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['Rationale']
						: '',
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['reference']
						: null,
				],
				missing_points_for_coverage:
					getAnalysisState.data.coverage_feedback_response
						.missing_points_for_coverage ?? [],
				status: getAnalysisState.data.coverage_feedback_response.status,
			});
			setLoading(false);
			setCoverageLoading(false);
			setLooksGoodLoading(false);
			setModalOpen([false, '', '']);
			document.getElementById('getQust').classList.add('bottom-100');
			document.getElementById('getAns').classList.add('top-0');
			document.getElementById('getAns').classList.remove('top-100');
			document.getElementById('getAns')?.classList.remove('d-none');
		}

		if (getAnalysisState.apiState === 'error') {
			setLoading(false);
			setAppealLetterLoading(false);
			notification.error({
				message:
					'Hey, we are facing high traffic. Please check back in few minutes.',
			});
		}
		getAnalysisReset();
	}, [getAnalysisState]);

	useEffect(() => {
		if (reviseCoverageState.apiState === 'success') {
			setFeedbackId(reviseCoverageState.data.feedback_id);
			setLastAnalysisData(reviseCoverageState.data);
			setPageContent({
				...pageContent,
				originalQuery: reviseCoverageState.data['original_query'] ?? '',
				pastHistory: reviseCoverageState.data['Past History'] ?? [],
				presentComplaint:
					reviseCoverageState.data['Present Complaint'] ?? [],
				recommendedProcedure:
					reviseCoverageState.data['Suggested Treatment'] ?? [],
				medicalCoverage: [
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'Coverage'
						  ]
						: 'No',
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'Rationale'
						  ]
						: '',
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'reference'
						  ]
						: null,
				],
				missing_points_for_coverage:
					reviseCoverageState.data.missing_points_for_coverage ?? [],
				status: reviseCoverageState.data.status,
			});
			setAnalysisCompleted('success');
			if (
				reviseCoverageState.data.hasOwnProperty('is_fields_missing') &&
				reviseCoverageState.data.is_fields_missing &&
				reviseCoverageState.data.hasOwnProperty('missing_fields')
			) {
				if (reviseCoverageState.data.missing_fields.length > 0) {
					setMissingFields(
						reviseCoverageState.data.missing_fields ?? [],
					);
					setMissingFieldInstructions(
						reviseCoverageState.data.missing_field_instructions ??
							[],
					);
					setMissingFieldTitle(
						reviseCoverageState.data.missing_field_title ?? '',
					);
					setMissingFieldsModal(true);
					setIsMissingFieldsThere(true);
				}
			} else {
				setShowAnalysisData(true);
				setIsMissingFieldsThere(false);
			}
			reviseCoverageReset();
		}
		if (reviseCoverageState.apiState === 'error') {
			setAnalysisCompleted('error');
			reviseCoverageReset();
			notification.error({
				message:
					'Hey, we are facing high traffic. Please check back in few minutes.',
			});
			document.getElementById('notification')?.classList.remove('d-none');
			document.getElementById('getAns')?.classList.remove('d-none');
		}
	}, [reviseCoverageState]);

	useEffect(() => {
		if (sendFeedbackState.apiState === 'success') {
			sendFeedbackReset();
			setAppealLetterLoading(false);
			if (sendFeedbackState.data === 'negative')
				setThanksModal([true, 'Thanks for making us Better!']);
			else
				setThanksModal([true, 'Case has been analysed and confirmed!']);
			if (callGetAnalysisAPiBtn) {
				getAnalysis({ feedback_id: feedbackId });
				setCallGetAnalysisAPiBtn(true);
			} else {
				setCoverageLoading(false);
				setLooksGoodLoading(false);
				setModalOpen([false, '', '']);
			}
		} else if (sendFeedbackState.apiState === 'error') {
			sendFeedbackReset();
			setAppealLetterLoading(false);
			setCallGetAnalysisAPiBtn(true);
			setCoverageLoading(false);
			setLooksGoodLoading(false);
		}
	}, [sendFeedbackState]);

	useEffect(() => {
		document.getElementById('getQust').classList.remove('bottom-100');
		document.getElementById('getAns').classList.remove('top-0');
		document.getElementById('getAns').classList.add('top-100');
		document.getElementById('getAns')?.classList.add('d-none');
		getStateDropdown();
		summaryForm.setFieldsValue({
			clinical_category: 'clinical_text',
		});
	}, []);

	// Open the modal and set a timer to automatically close it after 3 seconds
	useEffect(() => {
		let timer;
		if (thanksModal) {
			timer = setTimeout(() => {
				setThanksModal([false, '']);
			}, 5000);
		}

		// Clear the timer if the component unmounts or showThanksModal changes
		return () => clearTimeout(timer);
	}, [thanksModal]);

	useEffect(() => {
		// Add the class to the body tag
		document.body.classList.add('aipaBody');

		// Clean up to remove the class when the component unmounts
		return () => {
			document.body.classList.remove('aipaBody');
		};
	}, []);

	return (
		<>
			{loading && (
				<AnalysisLoader
					analysisId={id}
					isOpenAnalysis={'true'}
					analysisCompleted={analysisCompleted}
					setAnalysisCompleted={setAnalysisCompleted}
					setLoading={setLoading}
					loading={loading}
					isMissingFieldsThere={isMissingFieldsThere}
					setIsMissingFieldsThere={setIsMissingFieldsThere}
				/>
			)}

			<XContainer fluid>
				<div className="marginTopMobile" id=""></div>
				<XAffix
					id="notification"
					className="d-none zIndex"
					offsetTop={0}
				>
					<div className="marginBottomMobile"></div>
					<div
						className="mb-3"
						style={{
							backgroundColor: 'white',
							borderRadius: '20px',
							zIndex: '1111110',
						}}
					>
						<XNotificationBar style={{ zIndex: '22222' }}>
							Clinical Summary has been changed !! Click to{' '}
							<XButtonReload
								type="button"
								style={{ padding: '18px' }}
								className="refreshBtn"
								onClick={() => {
									setLoading(true);
									document
										.getElementById('notification')
										?.classList.add('d-none');

									reviseCoverageReset();
									// Update lastAnalysisData with the latest pageContent values
									const updatedLastAnalysisData = {
										...lastAnalysisData, // Keep all the existing keys and values intact
										'Past History': pageContent.pastHistory, // Update pastHistory
										'Present Complaint':
											pageContent.presentComplaint, // Update presentComplaint
										'Suggested Treatment':
											pageContent.recommendedProcedure, // Update recommendedProcedure
										'Medicare Evaluation': {
											...lastAnalysisData[
												'Medicare Evaluation'
											],
											Coverage:
												pageContent.medicalCoverage[0], // Update medicalCoverage fields
											Rationale:
												pageContent.medicalCoverage[1],
											reference:
												pageContent.medicalCoverage[2],
										},
										original_query:
											pageContent.originalQuery, // Update original_query
										past_history: pageContent.pastHistory, // Update past_history in lastAnalysisData
										present_complaint:
											pageContent.presentComplaint, // Update present_complaint in lastAnalysisData
										status: pageContent.status, // Update status in lastAnalysisData
										missing_points_for_coverage:
											pageContent.missing_points_for_coverage, // Update missing_points_for_coverage
										// Add other updates if necessary, or keep them unchanged
									};
									reviseCoverage({
										// case_id: patientData._id,
										analysis_id: id,
										feedbackId: feedbackId,
										clinicalHistory:
											pageContent.pastHistory,
										presentComplaint:
											pageContent.presentComplaint,
										recommendedProcedure:
											pageContent.recommendedProcedure,
										medicalCoverage:
											pageContent.medicalCoverage,
										state: state,
										provider: provider,
										last_analysis_data:
											updatedLastAnalysisData,
										supportingDocsSummary:
											supportingDocsSummary,
									});
								}}
							>
								Reanalyse it !!
							</XButtonReload>
							<XButtonCloseBar
								type="button"
								className="closeBar mt-3"
							>
								<CloseOutlined
									onClick={() => {
										document
											.getElementById('notification')
											?.classList.add('d-none');
									}}
									style={{ fontSize: '20px' }}
								/>
							</XButtonCloseBar>
						</XNotificationBar>
					</div>
					<div className="marginNone"></div>
				</XAffix>
			</XContainer>
			<>
				<Col lg={24} xl={24} xs={0} md={0}>
					<Header headerTag="AIPA" />
				</Col>
				<Col lg={0} xl={0} xs={24} md={24}>
					<HeaderFile activeNav="aipa" />
				</Col>
			</>
			<div id="getQust" className="">
				{/* Start Desktop design */}
				<AipaDesktopBody>
					<XContainer fluid>
						<XForm
							form={summaryForm}
							name="loginForm"
							layout="vertical"
							autoComplete="off"
							onFinish={handleShazamClick}
						>
							<Row
								gutter={(15, 15)}
								className="align-items-center"
							>
								<Col xs={{ span: 3, offset: 0 }}>
									<XLottieAipaLoader>
										<XLottieAipa
											animationData={LoaderJson}
											loop={true}
										/>
									</XLottieAipaLoader>
								</Col>
								<Col xs={{ span: 19, offset: 1 }}>
									<XDecodexTitle className="text-start">
										DeCodeX
									</XDecodexTitle>
									<ParaDeocdex className="text-start mt-2">
										DeCodeX utilizes comprehensive
										standardized clinical notes to provide
										insights into Medicare coverage.
									</ParaDeocdex>
								</Col>
							</Row>
							<Row gutter={(15, 15)} className="mt-4">
								<Col xs={10}>
									<Row gutter={(15, 15)}>
										<Col xs={24}>
											<XForm.Item
												className="addCaseLabel mb-0"
												name="clinical_category"
												rules={[
													{
														required: true,
														message:
															'Please select option!',
													},
												]}
											>
												<XRadio.Group
													className="w-100"
													defaultValue={
														clinicalSelectedOption
													}
													onChange={(e) => {
														handleInputChange(
															e,
															formData,
															setFormData,
														);
														if (
															e.target.value ===
															'clinical_text'
														) {
															setClinicalSelectedOption(
																'clinical_text',
															);
															// setAudioBlob(null);
															handleStop();
														} else if (
															e.target.value ===
															'clinical_document'
														) {
															setClinicalSelectedOption(
																'clinical_document',
															);
															handleStop();
															// setAudioBlob(null);
														} else if (
															e.target.value ===
															'dictate_audio'
														) {
															setClinicalSelectedOption(
																'dictate_audio',
															);
															setClinicalDocumentFileList(
																[],
															);
															setUpdatedClinicalDocumentFileList(
																[],
															);
															summaryForm.setFieldsValue(
																{
																	clinical_summary:
																		'',
																},
															);
															setFormData({
																...formData,
																clinical_summary:
																	'',
															});
															setSummary('');
														}
													}}
												>
													<XRadio
														value={'clinical_text'}
													>
														Enter Clinical Summary
													</XRadio>
													<XRadio
														value={
															'clinical_document'
														}
													>
														Upload Clinical Summary
													</XRadio>
													<XRadio
														value={'dictate_audio'}
													>
														Dictate
													</XRadio>
												</XRadio.Group>
											</XForm.Item>
										</Col>
										<Col xs={24}>
											<XDivider />
										</Col>
										<Col xs={24}>
											<AddInputIcon>
												<EnvironmentOutlined />
											</AddInputIcon>
											<XForm.Item
												name="state"
												rules={[
													{
														required: true,
														message:
															'Please select State',
													},
												]}
												className="mb-0"
											>
												<XSelectIcons
													size="large"
													placeholder="Select State"
													name="state"
													showSearch={true}
													// onSearch={(search) => {
													// 	if (
													// 		search.length >= 3
													// 	) {
													// 		getStateDropdown({
													// 			search,
													// 		});
													// 	} else if (
													// 		search.length === 0
													// 	) {
													// 		getStateDropdown();
													// 	}
													// }}
													filterOption={filterByLabel}
													options={
														getStateDropdownState.apiState ===
															'success' &&
														getStateDropdownState
															.data.all_states
															?.length > 0 &&
														getStateDropdownState.data.all_states.map(
															(itm) => {
																return {
																	label: itm.state,
																	value: itm.state,
																	providers:
																		itm.providers,
																};
															},
														)
													}
													onSelect={(
														value,
														options,
													) => {
														setState(value);
														summaryForm.setFieldValue(
															'insurance_company',
															null,
														);
														setInsuranceCompanies(
															options.providers,
														);
													}}
												></XSelectIcons>
											</XForm.Item>
										</Col>
										<Col xs={24} className="mt-2">
											<AddInputIcon>
												<UserSwitchOutlined />
											</AddInputIcon>
											<XForm.Item
												name="insurance_company"
												className="mb-0"
												rules={[
													{
														required: true,
														message:
															'Please select insurance provider',
													},
												]}
											>
												<XSelectIcons
													size="large"
													placeholder="Select Insurance Company"
													name="insurance_company"
													showSearch={true}
													filterOption={filterByLabel}
													options={
														insuranceCompanies.length >
															0 &&
														insuranceCompanies.map(
															(itm) => {
																return {
																	label: itm.name,
																	value: itm.name,
																};
															},
														)
													}
													onSelect={(value) => {
														setProvider(value);
													}}
												></XSelectIcons>
											</XForm.Item>
										</Col>
									</Row>
								</Col>
								<Col xs={14}>
									{clinicalSelectedOption ===
										'clinical_text' && (
										<XForm.Item
											className="addCaseLabel mb-0"
											name="clinical_summary"
											rules={[
												{
													required: true,
													message:
														'Please enter clinical summary',
												},
											]}
										>
											<XInputTextArea.TextArea
												className="textAreaStyle"
												name="clinical_summary"
												// showCount
												autoSize={{
													minRows: 9,
												}}
												onChange={(e) => {
													handleInputChange(
														e,
														formData,
														setFormData,
													);
													setSummary(e.target.value);
													setClinicalDocumentFileList(
														[],
													);
													setUpdatedClinicalDocumentFileList(
														[],
													);
													handleStop();
												}}
												placeholder="Enter Detailed Clinical Summary"
											/>
										</XForm.Item>
									)}
									{clinicalSelectedOption ===
										'clinical_document' && (
										<XCardDesktop className="bodyPadding h-auto">
											<XForm.Item
												className="mb-0"
												name="clinical_document"
												rules={[
													{
														required: true,
														validator: (
															_,
															value,
														) => {
															if (
																clinicalDocumentFileList.length ===
																0
															) {
																return Promise.reject(
																	new Error(
																		'Please upload Clinical Document!',
																	),
																);
															}
															return Promise.resolve();
														},
													},
												]}
											>
												<div className="uploadClinicalDocument">
													<Dragger
														style={{
															background: 'none',
														}}
														action={null}
														multiple={false}
														maxCount={1}
														showUploadList={{
															showPreviewIcon: true,
															showRemoveIcon: true,
														}}
														className="supporting_files"
														listType="picture-card"
														fileList={
															clinicalDocumentFileList
														}
														onPreview={
															handlePreviewClinicalSummary
														}
														onChange={
															handleClinicalDocumentChange
														}
														onRemove={(file) => {
															handleRemoveClinicalDocuments(
																file,
															);
															return false; // Prevent default removal behavior to avoid triggering onChange
														}}
														accept=".pdf"
														beforeUpload={(
															file,
														) => {
															const isJPEG =
																file.type ===
																'image/jpeg';
															const isJPG =
																file.type ===
																'image/jpg';
															const isPNG =
																file.type ===
																'image/png';
															const isGIF =
																file.type ===
																'image/gif';
															const isVideo =
																file.type.startsWith(
																	'video/',
																);
															const isAudio =
																file.type.startsWith(
																	'audio/',
																);

															const isLt10M =
																file.size /
																	1024 /
																	1024 <
																10;

															if (!isLt10M) {
																notification.error(
																	{
																		message:
																			'File Size Exceeded',
																		description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
																	},
																);
															}
															if (
																isGIF ||
																isVideo ||
																isAudio ||
																isJPEG ||
																isJPG ||
																isPNG
															) {
																notification.error(
																	{
																		message:
																			'File Type Error',
																		description: `GIF, video, image and audio files are not allowed.`,
																	},
																);
															}

															return (
																isLt10M &&
																!isGIF &&
																!isVideo &&
																!isAudio &&
																!isJPEG &&
																!isJPG &&
																!isPNG
															);
														}}
													>
														{clinicalDocumentFileList.length >=
														1 ? null : (
															<>
																<img
																	src="/images/aipa-clinical-document.svg"
																	alt=""
																/>
																<p className="uploadClinicalDocumentText">
																	Click or
																	drag file to
																	this area to
																	upload
																</p>
															</>
														)}
													</Dragger>
												</div>
											</XForm.Item>
										</XCardDesktop>
									)}
									{clinicalSelectedOption ===
										'dictate_audio' && (
										<XForm.Item
											name="dictate_audio_note"
											rules={[
												{
													required: true,
													validator: (_, value) => {
														if (!audioBlob) {
															return Promise.reject(
																new Error(
																	'Please record an audio note before proceeding.',
																),
															);
														}
														return Promise.resolve();
													},
												},
											]}
										>
											<XCardDesktop>
												<div className="timmer mt-3">
													<span>{recordingTime}</span>
												</div>
												<WaveformVisualizer
													isRecording={isRecording}
													isPlaying={isPlaying}
													audioData={
														audioElementRef.current
													}
												/>

												<div className="actionBtns mt-0">
													<Space type="verticle">
														{!audioBlob ? (
															// Recording controls
															isRecording ||
															isPaused ? (
																<>
																	<Tooltip
																		key="stop-recording"
																		title="Stop Recording"
																	>
																		<XActionBtns
																			size="large"
																			type="primary"
																			shape="circle"
																			onClick={() => {
																				handleStop();
																			}}
																			icon={
																				<CloseOutlined />
																			}
																		/>
																	</Tooltip>
																	<Tooltip
																		key={
																			isPaused
																				? 'resume-recording'
																				: 'pause-recording'
																		}
																		title={
																			isPaused
																				? 'Resume Recording'
																				: 'Pause Recording'
																		}
																	>
																		<XActionPlay
																			type="primary"
																			shape="circle"
																			onClick={
																				isPaused
																					? handleResume
																					: handlePause
																			}
																			icon={
																				isPaused ? (
																					<PlayCircleOutlined />
																				) : (
																					<PauseOutlined />
																				)
																			}
																		/>
																	</Tooltip>
																	<Tooltip
																		key="finish-recording"
																		title="Finish Recording"
																	>
																		<XActionBtns
																			size="large"
																			type="primary"
																			shape="circle"
																			onClick={
																				handleDone
																			}
																			icon={
																				<CheckOutlined />
																			}
																		/>
																	</Tooltip>
																</>
															) : (
																<Tooltip
																	key="start-dictation"
																	title="Start Dictation"
																>
																	<AipaAudioBtn
																		size="large"
																		onClick={
																			toggleDictation
																		}
																		icon={
																			<AudioOutlined />
																		}
																	/>
																</Tooltip>
															)
														) : (
															// Playback controls
															<>
																<Tooltip
																	key="restart"
																	title="Restart"
																>
																	<XActionBtns
																		size="large"
																		type="primary"
																		shape="circle"
																		onClick={() => {
																			handleStop();
																			// setAudioBlob(
																			// 	null,
																			// );
																			// audioElementRef.current =
																			// 	null;
																		}}
																		icon={
																			<RedoOutlined />
																		}
																	/>
																</Tooltip>
																<Tooltip
																	key={
																		isPlaying
																			? 'pause-playback'
																			: 'play-recording'
																	}
																	title={
																		isPlaying
																			? 'Pause Recording'
																			: 'Play Recording'
																	}
																>
																	<XActionPlay
																		type="primary"
																		shape="circle"
																		onClick={
																			isPlaying
																				? handlePausePlayback
																				: handlePlayRecording
																		}
																		icon={
																			isPlaying ? (
																				<PauseOutlined />
																			) : (
																				<PlayCircleOutlined />
																			)
																		}
																	/>
																</Tooltip>
																<XActionBtns
																	size="large"
																	type="primary"
																	shape="circle"
																	style={{
																		visibility:
																			'hidden',
																	}} // Makes the button invisible
																	// icon={
																	// 	<PauseOutlined />
																	// } // Any icon, it won't be visible
																/>
															</>
														)}
													</Space>
												</div>
											</XCardDesktop>
										</XForm.Item>
									)}
									{/* {clinicalSelectedOption ===
										'dictate_audio' && (
										<>
											<XForm.Item
												className="addCaseLabel mb-0"
												name="dictate_summary"
												rules={[
													{
														required: true,
														message:
															'Please record clinical summary',
													},
												]}
											>
												{transcribedText &&
												!isRecording &&
												!isPaused ? (
													<div>
														<XInputTextArea.TextArea
															className="textAreaStyle"
															name="dictate_summary"
															autoSize={{
																minRows: 9,
															}}
															defaultValue={
																transcribedText
															}
															onChange={(e) => {
																handleInputChange(
																	e,
																	formData,
																	setFormData,
																);
																setSummary(
																	e.target
																		.value,
																);
																setClinicalDocumentFileList(
																	[],
																);
																setUpdatedClinicalDocumentFileList(
																	[],
																);
															}}
															placeholder="Enter Detailed Clinical Summary"
														/>
														<div className="text-end mt-2">
															<XShazaamBtn
																type="primary"
																onClick={() => {
																	setTranscribedText(
																		'',
																	);
																	setRecordingTime(
																		'00:00',
																	);
																	setStartTime(
																		null,
																	);
																	setElapsedTime(
																		0,
																	);
																	summaryForm.setFieldsValue(
																		{
																			dictate_summary:
																				'',
																		},
																	);
																	setSummary(
																		'',
																	);
																}}
															>
																Record Again
															</XShazaamBtn>
														</div>
													</div>
												) : (
													<XCardDesktop>
														<div className="timmer mt-3">
															<span>
																{recordingTime}
															</span>
														</div>
														<WaveformVisualizer
															isRecording={
																isRecording
															}
														/>
														<div className="actionBtns mt-0">
															<Space type="verticle">
																{isRecording ||
																isPaused ? (
																	<>
																		<XActionBtns
																			size="large"
																			type="primary"
																			shape="circle"
																			onClick={
																				handleStop
																			}
																			icon={
																				<CloseOutlined />
																			}
																		/>
																		<Popover
																			style={{
																				maxWidth:
																					'200px',
																			}}
																			content={
																				interimTranscript ||
																				transcribedText
																			}
																			open={
																				(isRecording ||
																					isPaused) &&
																				(interimTranscript ||
																					transcribedText)
																			}
																			placement="bottom"
																			autoAdjustOverflow={
																				true
																			}
																		>
																			<XActionPlay
																				type="primary"
																				shape="circle"
																				onClick={
																					isPaused
																						? handleResume
																						: handlePause
																				}
																				icon={
																					isPaused ? (
																						<PlayCircleOutlined />
																					) : (
																						<PauseOutlined />
																					)
																				}
																			/>
																		</Popover>
																		<XActionBtns
																			size="large"
																			type="primary"
																			shape="circle"
																			onClick={
																				handleDone
																			}
																			icon={
																				<CheckOutlined />
																			}
																		/>
																	</>
																) : (
																	<AipaAudioBtn
																		size="large"
																		onClick={
																			toggleDictation
																		}
																		icon={
																			<AudioOutlined />
																		}
																	/>
																)}
															</Space>
														</div>
													</XCardDesktop>
												)}
											</XForm.Item>
										</>
									)} */}
								</Col>
							</Row>
							<Row gutter={(15, 15)} className="mt-5">
								<Col xs={{ span: 14, offset: 10 }}>
									<XShazaamBtn
										size="large"
										htmlType="submit"
										type="primary"
									>
										Shazaam !
									</XShazaamBtn>
								</Col>
							</Row>
						</XForm>
					</XContainer>
				</AipaDesktopBody>
				{/* End Desktop design */}
				{/* Start Mobile design */}
				<AipaMobileBody className="paddingPage">
					<XContainer fluid className="paddingPage">
						<XForm
							form={summaryForm}
							name="loginForm"
							layout="vertical"
							autoComplete="off"
							onFinish={handleShazamClick}
						>
							<Row>
								<Col
									xs={{ span: 16, offset: 4 }}
									className="text-center"
								>
									<XLottieAipaLoader>
										<XLottieAipa
											animationData={LoaderJson}
											loop={true}
										/>
									</XLottieAipaLoader>
								</Col>
								<Col xs={24}>
									<XDecodexTitle>DeCodeX</XDecodexTitle>
								</Col>
								<Col xs={24} className="mt-3">
									<ParaDeocdex>
										DeCodeX utilizes comprehensive
										standardized clinical notes to provide
										insights into Medicare coverage.
									</ParaDeocdex>
								</Col>
								<Col xs={24} className="mt-4">
									<AddInputIcon>
										{clinicalSelectedOption ===
											'clinical_text' && (
											<img
												src="/images/aipa-clinical-icon.svg"
												alt=""
											/>
										)}
										{clinicalSelectedOption ===
											'clinical_document' && (
											<UploadOutlined />
										)}
										{clinicalSelectedOption ===
											'dictate_audio' && (
											<AudioOutlined />
										)}
									</AddInputIcon>
									<XForm.Item
										className="addCaseLabel mb-0"
										name="clinical_category"
										rules={[
											{
												required: true,
												message:
													'Please select option!',
											},
										]}
									>
										<XSelectIcons
											size="large"
											defaultValue={
												clinicalSelectedOption
											}
											options={[
												{
													value: 'clinical_text',
													label: 'Enter Clinical Summary',
												},
												{
													value: 'clinical_document',
													label: 'Upload Clinical Summary',
												},
												{
													value: 'dictate_audio',
													label: 'Dictate',
												},
											]}
											onChange={(value) => {
												handleInputChange(
													{
														target: {
															name: 'clinical_category',
															value,
														},
													},
													formData,
													setFormData,
												);
												setClinicalSelectedOption(
													value,
												);
											}}
										></XSelectIcons>
									</XForm.Item>
								</Col>
								<Col xs={24} className="mt-3">
									{clinicalSelectedOption ===
										'clinical_text' && (
										<XForm.Item
											className="addCaseLabel mb-0"
											name="clinical_summary"
											rules={[
												{
													required: true,
													message:
														'Please enter clinical summary',
												},
											]}
										>
											<XInputTextArea.TextArea
												className="textAreaStyle"
												name="clinical_summary"
												// showCount
												autoSize={{
													minRows: 9,
												}}
												onChange={(e) => {
													handleInputChange(
														e,
														formData,
														setFormData,
													);
													setSummary(e.target.value);
													setClinicalDocumentFileList(
														[],
													);
													setUpdatedClinicalDocumentFileList(
														[],
													);
												}}
												placeholder="Enter Detailed Clinical Summary"
											/>
										</XForm.Item>
									)}
									{clinicalSelectedOption ===
										'clinical_document' && (
										<XCardDesktop className="bodyPadding h-auto">
											<XForm.Item
												className="mb-0"
												name="clinical_document"
												rules={[
													{
														required: true,
														validator: (
															_,
															value,
														) => {
															if (
																clinicalDocumentFileList.length ===
																0
															) {
																return Promise.reject(
																	new Error(
																		'Please upload Clinical Document!',
																	),
																);
															}
															return Promise.resolve();
														},
													},
												]}
											>
												<div className="uploadClinicalDocument">
													<Dragger
														style={{
															background: 'none',
														}}
														action={null}
														multiple={false}
														maxCount={1}
														showUploadList={{
															showPreviewIcon: true,
															showRemoveIcon: true,
														}}
														className="supporting_files"
														listType="picture-card"
														fileList={
															clinicalDocumentFileList
														}
														onPreview={
															handlePreviewClinicalSummary
														}
														onChange={
															handleClinicalDocumentChange
														}
														onRemove={(file) => {
															handleRemoveClinicalDocuments(
																file,
															);
															return false; // Prevent default removal behavior to avoid triggering onChange
														}}
														accept=".pdf"
														beforeUpload={(
															file,
														) => {
															const isJPEG =
																file.type ===
																'image/jpeg';
															const isJPG =
																file.type ===
																'image/jpg';
															const isPNG =
																file.type ===
																'image/png';
															const isGIF =
																file.type ===
																'image/gif';
															const isVideo =
																file.type.startsWith(
																	'video/',
																);
															const isAudio =
																file.type.startsWith(
																	'audio/',
																);

															const isLt10M =
																file.size /
																	1024 /
																	1024 <
																10;

															if (!isLt10M) {
																notification.error(
																	{
																		message:
																			'File Size Exceeded',
																		description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
																	},
																);
															}
															if (
																isGIF ||
																isVideo ||
																isAudio ||
																isJPEG ||
																isJPG ||
																isPNG
															) {
																notification.error(
																	{
																		message:
																			'File Type Error',
																		description: `GIF, video, image and audio files are not allowed.`,
																	},
																);
															}

															return (
																isLt10M &&
																!isGIF &&
																!isVideo &&
																!isAudio &&
																!isJPEG &&
																!isJPG &&
																!isPNG
															);
														}}
													>
														{clinicalDocumentFileList.length >=
														1 ? null : (
															<>
																<img
																	src="/images/aipa-clinical-document.svg"
																	alt=""
																/>
																<p className="uploadClinicalDocumentText">
																	Click or
																	drag file to
																	this area to
																	upload
																</p>
															</>
														)}
													</Dragger>
												</div>
											</XForm.Item>
										</XCardDesktop>
									)}
									{clinicalSelectedOption ===
										'dictate_audio' && (
										<XForm.Item
											name="dictate_audio_note"
											rules={[
												{
													required: true,
													validator: (_, value) => {
														if (!audioBlob) {
															return Promise.reject(
																new Error(
																	'Please record an audio note before proceeding.',
																),
															);
														}
														return Promise.resolve();
													},
												},
											]}
										>
											<XCardDesktop>
												<div className="timmer mt-3">
													<span>{recordingTime}</span>
												</div>
												<WaveformVisualizer
													isRecording={isRecording}
													isPlaying={isPlaying}
													audioData={
														audioElementRef.current
													}
												/>

												<div className="actionBtns mt-0">
													<Space type="verticle">
														{!audioBlob ? (
															// Recording controls
															isRecording ||
															isPaused ? (
																<>
																	<Tooltip
																		key="stop-recording"
																		title="Stop Recording"
																	>
																		<XActionBtns
																			size="large"
																			type="primary"
																			shape="circle"
																			onClick={() => {
																				handleStop();
																			}}
																			icon={
																				<CloseOutlined />
																			}
																		/>
																	</Tooltip>
																	<Tooltip
																		key={
																			isPaused
																				? 'resume-recording'
																				: 'pause-recording'
																		}
																		title={
																			isPaused
																				? 'Resume Recording'
																				: 'Pause Recording'
																		}
																	>
																		<XActionPlay
																			type="primary"
																			shape="circle"
																			onClick={
																				isPaused
																					? handleResume
																					: handlePause
																			}
																			icon={
																				isPaused ? (
																					<PlayCircleOutlined />
																				) : (
																					<PauseOutlined />
																				)
																			}
																		/>
																	</Tooltip>
																	<Tooltip
																		key="finish-recording"
																		title="Finish Recording"
																	>
																		<XActionBtns
																			size="large"
																			type="primary"
																			shape="circle"
																			onClick={
																				handleDone
																			}
																			icon={
																				<CheckOutlined />
																			}
																		/>
																	</Tooltip>
																</>
															) : (
																<Tooltip
																	key="start-dictation"
																	title="Start Dictation"
																>
																	<AipaAudioBtn
																		size="large"
																		onClick={
																			toggleDictation
																		}
																		icon={
																			<AudioOutlined />
																		}
																	/>
																</Tooltip>
															)
														) : (
															// Playback controls
															<>
																<Tooltip
																	key="restart"
																	title="Restart"
																>
																	<XActionBtns
																		size="large"
																		type="primary"
																		shape="circle"
																		onClick={() => {
																			handleStop();
																			// setAudioBlob(
																			// 	null,
																			// );
																			// audioElementRef.current =
																			// 	null;
																		}}
																		icon={
																			<RedoOutlined />
																		}
																	/>
																</Tooltip>
																<Tooltip
																	key={
																		isPlaying
																			? 'pause-playback'
																			: 'play-recording'
																	}
																	title={
																		isPlaying
																			? 'Pause Recording'
																			: 'Play Recording'
																	}
																>
																	<XActionPlay
																		type="primary"
																		shape="circle"
																		onClick={
																			isPlaying
																				? handlePausePlayback
																				: handlePlayRecording
																		}
																		icon={
																			isPlaying ? (
																				<PauseOutlined />
																			) : (
																				<PlayCircleOutlined />
																			)
																		}
																	/>
																</Tooltip>
																<XActionBtns
																	size="large"
																	type="primary"
																	shape="circle"
																	style={{
																		visibility:
																			'hidden',
																	}} // Makes the button invisible
																	// icon={
																	// 	<PauseOutlined />
																	// } // Any icon, it won't be visible
																/>
															</>
														)}
													</Space>
												</div>
											</XCardDesktop>
										</XForm.Item>
									)}
									{/* {clinicalSelectedOption ===
										'dictate_audio' && (
										<>
											<XForm.Item
												className="addCaseLabel mb-0"
												name="dictate_summary"
												rules={[
													{
														required: true,
														message:
															'Please record clinical summary',
													},
												]}
											>
												{transcribedText &&
												!isRecording &&
												!isPaused ? (
													<div>
														<XInputTextArea.TextArea
															className="textAreaStyle"
															name="dictate_summary"
															autoSize={{
																minRows: 9,
															}}
															defaultValue={
																transcribedText
															}
															onChange={(e) => {
																handleInputChange(
																	e,
																	formData,
																	setFormData,
																);
																setSummary(
																	e.target
																		.value,
																);
																setClinicalDocumentFileList(
																	[],
																);
																setUpdatedClinicalDocumentFileList(
																	[],
																);
															}}
															placeholder="Enter Detailed Clinical Summary"
														/>
														<div className="text-end mt-2">
															<XShazaamBtn
																type="primary"
																onClick={() => {
																	setTranscribedText(
																		'',
																	);
																	setRecordingTime(
																		'00:00',
																	);
																	setStartTime(
																		null,
																	);
																	setElapsedTime(
																		0,
																	);
																	summaryForm.setFieldsValue(
																		{
																			dictate_summary:
																				'',
																		},
																	);
																	setSummary(
																		'',
																	);
																}}
															>
																Record Again
															</XShazaamBtn>
														</div>
													</div>
												) : (
													<RecordCard>
														<div className="timmer">
															<span>
																{recordingTime}
															</span>
														</div>
														<WaveformVisualizer
															isRecording={
																isRecording
															}
														/>
														<div className="actionBtns mt-0">
															<Space type="verticle">
																{isRecording ||
																isPaused ? (
																	<>
																		<XActionBtns
																			size="large"
																			type="primary"
																			shape="circle"
																			onClick={
																				handleStop
																			}
																			icon={
																				<CloseOutlined />
																			}
																		/>
																		<Popover
																			style={{
																				maxWidth:
																					'300px',
																			}}
																			content={
																				interimTranscript ||
																				transcribedText
																			}
																			open={
																				(isRecording ||
																					isPaused) &&
																				(interimTranscript ||
																					transcribedText)
																			}
																			placement="bottom"
																			autoAdjustOverflow={
																				true
																			}
																		>
																			<XActionPlay
																				type="primary"
																				shape="circle"
																				onClick={
																					isPaused
																						? handleResume
																						: handlePause
																				}
																				icon={
																					isPaused ? (
																						<PlayCircleOutlined />
																					) : (
																						<PauseOutlined />
																					)
																				}
																			/>
																		</Popover>
																		<XActionBtns
																			size="large"
																			type="primary"
																			shape="circle"
																			onClick={
																				handleDone
																			}
																			icon={
																				<CheckOutlined />
																			}
																		/>
																	</>
																) : (
																	<AipaAudioBtn
																		size="large"
																		onClick={
																			toggleDictation
																		}
																		icon={
																			<AudioOutlined />
																		}
																	/>
																)}
															</Space>
														</div>
													</RecordCard>
												)}
											</XForm.Item>
										</>
									)} */}
								</Col>
								<Col xs={24} className="mt-3">
									<AddInputIcon>
										<EnvironmentOutlined />
									</AddInputIcon>
									<XForm.Item
										name="state"
										rules={[
											{
												required: true,
												message: 'Please select State',
											},
										]}
										className="mb-0"
									>
										<XSelectIcons
											size="large"
											placeholder="Select State"
											name="state"
											showSearch={true}
											onSearch={(search) => {
												if (search.length >= 3) {
													getStateDropdown({
														search,
													});
												} else if (
													search.length === 0
												) {
													getStateDropdown();
												}
											}}
											filterOption={filterByLabel}
											options={
												getStateDropdownState.apiState ===
													'success' &&
												getStateDropdownState.data
													.all_states?.length > 0 &&
												getStateDropdownState.data.all_states.map(
													(itm) => {
														return {
															label: itm.state,
															value: itm.state,
															providers:
																itm.providers,
														};
													},
												)
											}
											onSelect={(value, options) => {
												setState(value);
												summaryForm.setFieldValue(
													'insurance_company',
													null,
												);
												setInsuranceCompanies(
													options.providers,
												);
											}}
										></XSelectIcons>
									</XForm.Item>
								</Col>
								<Col xs={24} className="mt-3">
									<AddInputIcon>
										<UserSwitchOutlined />
									</AddInputIcon>
									<XForm.Item
										name="insurance_company"
										className="mb-0"
										rules={[
											{
												required: true,
												message:
													'Please select insurance provider',
											},
										]}
									>
										<XSelectIcons
											size="large"
											placeholder="Select Insurance Company"
											name="insurance_company"
											showSearch={true}
											filterOption={filterByLabel}
											options={
												insuranceCompanies.length > 0 &&
												insuranceCompanies.map(
													(itm) => {
														return {
															label: itm.name,
															value: itm.name,
														};
													},
												)
											}
											onSelect={(value) => {
												setProvider(value);
											}}
										></XSelectIcons>
									</XForm.Item>
								</Col>
								<Col xs={24} className="text-center mt-5">
									<XShazaamBtn
										size="large"
										htmlType="submit"
										type="primary"
									>
										Shazaam !
									</XShazaamBtn>
								</Col>
							</Row>
						</XForm>
					</XContainer>
				</AipaMobileBody>
				{/* End Mobile design */}
			</div>

			<XContainer className="slideBox" fluid>
				<div id="getAns" className="slideUp top-100 mt-0 d-none">
					{appealLetterLoading && (
						<XLottieOverlay>
							<XLottie
								animationData={AppealLoading}
								loop={true}
							/>
						</XLottieOverlay>
					)}
					{showAppealLetterPreview ? (
						<>
							<Row>
								<AppealLetter
									aipa={true}
									appealLetterContent={appealLetterContent}
									appealLetterLoading={appealLetterLoading}
									showAppealLetterPreview={
										showAppealLetterPreview
									}
									setShowAppealLetterPreview={
										setShowAppealLetterPreview
									}
									setAppealLetterLoading={
										setAppealLetterLoading
									}
									previewAppealLetter={previewAppealLetter}
									id={id}
								/>
							</Row>
						</>
					) : (
						<Row gutter={(0, 30)}>
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								{/* new component start */}
								<CaseOperations
									aipa={true}
									previewMode={false}
									matchGender={''}
									loading={loading}
									summary={summary}
									state={state}
									provider={provider}
									setSummary={setSummary}
									handleRemoveClinicalDocuments={
										handleRemoveClinicalDocuments
									}
									handleClinicalSummaryDoc={
										handleClinicalSummaryDoc
									}
									isOpenAnalysis={'true'}
									patientData={''}
									mainDataLoading={''}
									handleClinicalDocsChange={
										handleClinicalDocsChange
									}
									clinicalDocumentFileList={
										clinicalDocumentFileList
									}
									clinicalDocsForm={clinicalDocsForm}
									handleUploadCancel={handleUploadCancel}
									clinicalDocsLoading={clinicalDocsLoading}
									setIsClinicalUploadModalOpen={
										setIsClinicalUploadModalOpen
									}
									isClinicalUploadModalOpen={
										isClinicalUploadModalOpen
									}
									isClinicalUploadAudioModalOpen={
										isClinicalUploadAudioModalOpen
									}
									setIsClinicalUploadAudioModalOpen={
										setIsClinicalUploadAudioModalOpen
									}
									setPreviewImage={setPreviewImage}
									previewImage={previewImage}
									setPreviewTitle={setPreviewTitle}
									previewTitle={previewTitle}
									setPdfPreviewOpen={setPdfPreviewOpen}
									pdfPreviewOpen={pdfPreviewOpen}
									documentsToDisplay={supportingFiles}
									supportingDocsSummary={
										supportingDocsSummary
									}
									clinicalSummaryTextLoading={
										clinicalSummaryTextLoading
									}
									setClinicalSummaryTextLoading={
										setClinicalSummaryTextLoading
									}
									setShowClinicalTextArea={
										setShowClinicalTextArea
									}
									showClinicalTextArea={showClinicalTextArea}
									setFormData={setFormData}
									formData={formData}
									id={id}
									setPageContent={setPageContent}
									pageContent={pageContent}
									clinicalDocumentsToDisplay={
										clinicalDocumentsToDisplay
									}
									clinicalAudioToDisplay={
										clinicalAudioToDisplay
									}
									feedbackId={feedbackId}
									reviseCoverage={reviseCoverage}
									lastAnalysisData={lastAnalysisData}
									setLoading={setLoading}
									getBase64={getBase64}
									setClinicalDocsLoading={
										setClinicalDocsLoading
									}
									setSubmitClinicalDocs={
										setSubmitClinicalDocs
									}
									setUpdatedClinicalDocumentFileList={''}
									form={form}
									setModalTextArea={setModalTextArea}
									setModalOpen={setModalOpen}
									modalOpen={modalOpen}
									sendFeedback={sendFeedback}
									sendFeedbackState={sendFeedbackState}
									modalTextArea={modalTextArea}
									updatePageContent={updatePageContent}
									missingFieldsForm={missingFieldsForm}
									missingFieldsCardRef={missingFieldsCardRef}
									handleAddOption={handleAddOption}
									missingFields={missingFields}
									missingFieldInstructions={
										missingFieldInstructions
									}
									setMissingFieldsModal={
										setMissingFieldsModal
									}
									setMissingFieldInstructions={
										setMissingFieldInstructions
									}
									setMissingFields={setMissingFields}
									missingFieldTitle={missingFieldTitle}
									missingFieldsModal={missingFieldsModal}
									audioBlob={audioBlob}
									recordingTime={recordingTime}
									isRecording={isRecording}
									isPlaying={isPlaying}
									audioElementRef={audioElementRef}
									isPaused={isPaused}
									handleStop={handleStop}
									handleResume={handleResume}
									handlePause={handlePause}
									handleDone={handleDone}
									toggleDictation={toggleDictation}
									handlePausePlayback={handlePausePlayback}
									handlePlayRecording={handlePlayRecording}
									handleRemoveClinicalAudios={
										handleRemoveClinicalAudios
									}
									handleClinicalSummaryAudio={
										handleClinicalSummaryAudio
									}
									handleUploadAudioCancel={
										handleUploadAudioCancel
									}
									audioPlayModal={audioPlayModal}
									setAudioPlayModal={setAudioPlayModal}
									fileToPlay={fileToPlay}
									setFileToPlay={setFileToPlay}
									handlePlayRecordedNote={
										handlePlayRecordedNote
									}
									clinicalAudioForm={clinicalAudioForm}
									setRecordingTime={setRecordingTime}
								/>
								{/* new component end */}

								{!missingFieldsModal ? (
									<>
										<div className="showMobile">
											<Row
												gutter={(15, 15)}
												className="mt-4"
											>
												<Col xs={12}>
													<XButtonConfirm
														className="w-100"
														type="primary"
														size="large"
														shape="round"
														style={{
															background: '#fff',
														}}
														icon={
															<img
																className="rotateButton"
																src="/images/like-icon.svg"
																alt=""
															/>
														}
														onClick={() => {
															form.setFieldsValue(
																{
																	modal_text_area:
																		pageContent.medicalCoverage
																			? pageContent
																					.medicalCoverage[1]
																			: '',
																	medicare_flag:
																		pageContent.medicalCoverage
																			? pageContent
																					.medicalCoverage[0]
																			: '',
																},
															);
															setModalTextArea(
																pageContent
																	.medicalCoverage[1],
															);
															setModalOpen([
																true,
																`Edit Medicare Coverage`,
																pageContent
																	.medicalCoverage[1],
																1,
																'feedbackSummary',
															]);
															setCallGetAnalysisAPiBtn(
																true,
															);
														}}
													>
														Refine
													</XButtonConfirm>
												</Col>
												<Col xs={12}>
													<XButtonConfirm
														className="w-100"
														type="primary"
														size="large"
														shape="round"
														style={{
															background: '#fff',
														}}
														icon={
															<img
																src="/images/like-icon.svg"
																alt=""
															/>
														}
														loading={
															LooksGoodLoading
														}
														onClick={() => {
															setLooksGoodLoading(
																true,
															);
															setCallGetAnalysisAPiBtn(
																true,
															);
															sendFeedback({
																feedback_id:
																	feedbackId,
																feedback:
																	'positive',
																status: 'approved',
																response:
																	pageContent.medicalCoverage,
															});
														}}
													>
														Looks Good
													</XButtonConfirm>
												</Col>
												{pageContent
													.medicalCoverage[0] ===
													'Yes' && (
													<>
														<Col
															xs={24}
															className="mt-4"
														>
															<XButtonConfirm
																className="btnActive w-100"
																onClick={() => {
																	setAppealLetterLoading(
																		true,
																	);
																	// setLoading(true);
																	previewAppealLetter(
																		'pre',
																		lastAnalysisData.appeal_letter_generated,
																	);
																}}
																type="primary"
																shape="round"
																// icon={
																// 	<img
																// 		src="/images/check 1.svg"
																// 		alt=""
																// 	/>
																// }
																size="large"
															>
																Generate Appeal
																Letter
															</XButtonConfirm>
														</Col>
													</>
												)}
											</Row>
										</div>
										<div className="showDesktop">
											<Row className="text-end">
												<Col
													xs={24}
													lg={12}
													className="mt-4"
												>
													<Space
														style={{
															width: '100%',
														}}
													>
														<XButtonConfirm
															className="mb-2 mb-md-0 mb-sm-0"
															type="primary"
															size="large"
															shape="round"
															style={{
																background:
																	'#fff',
															}}
															icon={
																<img
																	className="rotateButton"
																	src="/images/like-icon.svg"
																	alt=""
																/>
															}
															onClick={() => {
																form.setFieldsValue(
																	{
																		modal_text_area:
																			pageContent.medicalCoverage
																				? pageContent
																						.medicalCoverage[1]
																				: '',
																		medicare_flag:
																			pageContent.medicalCoverage
																				? pageContent
																						.medicalCoverage[0]
																				: '',
																	},
																);
																setModalTextArea(
																	pageContent
																		.medicalCoverage[1],
																);
																setModalOpen([
																	true,
																	`Edit Medicare Coverage`,
																	pageContent
																		.medicalCoverage[1],
																	1,
																	'feedbackSummary',
																]);
																setCallGetAnalysisAPiBtn(
																	true,
																);
															}}
														>
															Refine
														</XButtonConfirm>
														<XButtonConfirm
															className="mb-2 mb-md-0 mb-sm-0"
															type="primary"
															size="large"
															shape="round"
															style={{
																background:
																	'#fff',
															}}
															icon={
																<img
																	src="/images/like-icon.svg"
																	alt=""
																/>
															}
															loading={
																LooksGoodLoading
															}
															onClick={() => {
																setLooksGoodLoading(
																	true,
																);
																setCallGetAnalysisAPiBtn(
																	true,
																);
																sendFeedback({
																	feedback_id:
																		feedbackId,
																	feedback:
																		'positive',
																	status: 'approved',
																	response:
																		pageContent.medicalCoverage,
																});
															}}
														>
															Looks Good
														</XButtonConfirm>
													</Space>
												</Col>
												{pageContent
													.medicalCoverage[0] ===
													'Yes' && (
													<>
														<Col
															xs={24}
															lg={12}
															className="mt-4 text-md-end"
														>
															<XButtonConfirm
																onClick={() => {
																	setAppealLetterLoading(
																		true,
																	);
																	// setLoading(true);
																	previewAppealLetter(
																		'pre',
																		lastAnalysisData.appeal_letter_generated,
																	);
																}}
																type="primary"
																shape="round"
																// icon={
																// 	<img
																// 		src="/images/check 1.svg"
																// 		alt=""
																// 	/>
																// }
																size="large"
																className="btnActive"
															>
																Generate Appeal
																Letter
															</XButtonConfirm>
														</Col>
													</>
												)}
											</Row>
										</div>
									</>
								) : (
									<></>
								)}
							</Col>
						</Row>
					)}
				</div>
			</XContainer>

			<XModal
				centered
				open={thanksModal[0]}
				closable={false} // Hide the close icon
				// onOk={() => setThanksModal([false, ''])}
				// onCancel={() => {
				// 	setThanksModal([false, '']);
				// }}
				className="modalButton"
				okText="Submit"
				footer={null}
			>
				<Row
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}
					gutter={(15, 0)}
					className="mt-2"
				>
					<Col xs={24} lg={18}>
						<XLottieMargin>
							<XLottie animationData={Thanks} loop={true} />
						</XLottieMargin>

						<XPara1
							style={{ textAlign: 'center' }}
							className="mt-0"
						>
							{thanksModal[1]}
						</XPara1>
					</Col>
				</Row>
			</XModal>

			{/* Modal for adding new option */}
			<XModal
				title="Add New Option"
				open={newCheckBoxModal[0]}
				onOk={handleNewCheckBoxModal}
				okText="Submit"
				okButtonProps={{
					style: {
						backgroundColor: '#007299',
						borderColor: '#007299',
						color: '#fff',
						borderRadius: '20px',
					},
				}}
				onCancel={() => {
					setNewCheckBoxModal([false, null]);
					setNewOptionText('');
					newOptionForm.setFieldsValue({});
					newOptionForm.resetFields();
				}}
			>
				<XForm
					form={newOptionForm}
					name="groupForm"
					layout="vertical"
					autoComplete="off"
				>
					<XForm.Item
						name={'new-option'}
						rules={[
							{
								required: true,
								message: 'Please input option',
							},
						]}
					>
						<Input
							size="large"
							className="mt-3 mb-3"
							value={newOptionText}
							onChange={(e) => setNewOptionText(e.target.value)}
							placeholder="Enter new option text"
						/>
					</XForm.Item>
				</XForm>
			</XModal>
		</>
	);
};

const mapStateToProps = (state) => ({
	analyseCoverageState: state.analyseCoverage,
	reviseCoverageState: state.reviseCoverage,
	sendFeedbackState: state.sendFeedback,
	getStateDropdownState: state.getStateDropdown,
	getAnalysisState: state.getAnalysis,
	getAppealLetterState: state.getAppealLetter,
});

const mapDispatchToProps = (dispatch) => ({
	analyseCoverage: (params) => dispatch(analyseCoverageApi(params)),
	analyseCoverageReset: () => dispatch(analyseCoverageReset()),
	reviseCoverage: (params) => dispatch(reviseCoverageApi(params)),
	reviseCoverageReset: () => dispatch(reviseCoverageReset()),
	sendFeedback: (params) => dispatch(sendFeedbackApi(params)),
	sendFeedbackReset: () => dispatch(sendFeedbackReset()),
	getStateDropdown: () => dispatch(getStateDropdownApi()),
	getStateDropdownReset: () => dispatch(getStateDropdownReset()),
	getAnalysis: (params) => dispatch(getAnalysisApi(params)),
	getAnalysisReset: () => dispatch(getAnalysisReset()),
	getAppealLetter: (params) => dispatch(getAppealLetterApi(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewAipa);
