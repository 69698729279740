import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, notification, Card, Result, Radio, Space } from 'antd';
import { Button, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import {
	XForm,
	XModal,
	XContainer,
	XButton,
	FilterButton,
	XShareButton,
	XSelect,
	XSearch,
	XButton2,
} from '../../styles/GlobalStyle';

import moment from 'moment';
import CaseSearch from '../../components/CaseSearch/CaseSearch';
// actions

import { getHospitalDropdownApi } from '../../actions/getHospitalDropdownAction';
import {
	checkModulePermissions,
	truncateString,
	urlPagination,
} from '../../utils/helper';
import { dateFilters } from '../../utils/constants';
import HeaderFile from '../../components/Header/HeaderFile';
import DataTable from '../../components/DataTable/DataTable';
import Header from '../../components/Header/Header';
import { XButtonImg } from '../Manage/ManageStyle';
import {
	filterByLabel,
	handleInputChange,
	handleSelectChange,
} from '../../utils/formFunctions';

import { getRegionsReset, getRegionsApi } from '../../actions/getRegionsAction';
import {
	getRegionByIdApi,
	getRegionByIdReset,
} from '../../actions/getRegionByIdAction';
import { editRegionApi, editRegionReset } from '../../actions/editRegionAction';
import {
	deleteRegionApi,
	deleteRegionReset,
} from '../../actions/deleteRegionAction';
import { addRegionApi, addRegionReset } from '../../actions/addRegionAction';

import {
	getOrganizationsApi,
	getOrganizationsReset,
} from '../../actions/getOrganizationsAction';
import {
	SearchButton,
	XButtonConfirm,
	XInputText,
} from '../AnalyseCoverage/AnalyseCoverageStyle';
import { XRadio } from '../CaseForm/CaseFormStyle';

const Regions = (props) => {
	const {
		getHospitalDropdownState,
		getRegionsState,
		getRegionByIdState,
		editRegionState,
		deleteRegionState,
		addRegionState,
		getHospitalDropdown,
		getRegions,
		getRegionById,
		editRegion,
		deleteRegion,
		addRegion,
		editRegionReset,
		addRegionReset,
		getOrganizations,
		getOrganizationsState,
	} = props;

	const navigate = useNavigate();
	const defaultFilters = {
		search: '',
		page: 1,
		sort: 'updatedAt',
		sortDirection: 'descend',
		created_on: 'all',
		hospitals: [],
		limit: 10,
	};
	const [tableData, setTableData] = useState([]);
	const [totalRecords, setTotalRecords] = useState('');
	const [modalOpen, setModalOpen] = useState([false, '']);
	const [editRegionModal, setEditRegionModal] = useState(false);
	const [displayOrgInput, setDisplayOrgInput] = useState(false);
	let modulePermissions = checkModulePermissions('region-management');

	const [form] = XForm.useForm();
	const [form2] = XForm.useForm();
	const [formData, setFormData] = useState({});
	const [submitLoading, setSubmitLoading] = useState(false);
	const [loading, setLoading] = useState(false);

	const [open, setOpen] = useState(false);
	const getFiltersFromUrl = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const urlFilters = {};

		// Iterate through each query parameter and set it in the state
		for (const [key, value] of urlParams.entries()) {
			urlFilters[key] = value;
		}

		// Merge with default filters or initial state as needed
		const filters = {
			...defaultFilters, // Replace with your default filters
			...urlFilters,
		};

		return filters;
	};
	const initialFilters = getFiltersFromUrl();

	const [filters, setFilters] = useState(initialFilters);

	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
	};

	const onChange = (key) => {
		setFilters({
			...filters,
			[key.target.name]: key.target.value,
		});
	};

	const columns = [
		{
			title: 'Name',
			width: 200,
			dataIndex: 'name',
			fixed: 'left',
			render: (name) => (
				<div>
					<div>{name}</div>
				</div>
			),
		},
		{
			title: 'Hospitals',
			dataIndex: 'hospitals',
			render: (hospitals) => (
				<div>
					<div>{hospitals}</div>
				</div>
			),
		},
		{
			title: 'Organization',
			dataIndex: 'organization',
			render: (organization) => (
				<div>
					<div>{organization}</div>
				</div>
			),
		},
		{
			title: 'Created At',
			dataIndex: 'createdAt',
			render: (createdAt) => (
				<div>
					<div>{createdAt}</div>
				</div>
			),
		},
		{
			title: 'Action',
			width: 150,
			dataIndex: 'action',
			fixed: 'right',
		},
	];

	const showDeleteConfirm = (_id, name) => {
		XModal.confirm({
			title: 'Delete Region?',
			content: (
				<p className="modalPara1">
					Are you sure you want to delete {name}?
				</p>
			),
			icon: <img className="me-3" src="/images/delete-icon.svg" alt="" />,
			okText: 'Yes, Confirm',
			okType: 'danger',

			cancelText: 'No, Cancel',
			className: 'confirmModal',
			okButtonProps: {
				style: {
					backgroundColor: '#007299',
					borderColor: '#007299',
					color: '#fff',
				},
			},
			cancelButtonProps: {
				style: {
					background: '#fff',
					color: '#007299',
					borderRadius: '20px',
					borderColor: '#007299',
				},
			},
			onOk() {
				setLoading(true);
				deleteRegion({ id: _id });
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const handleEdit = (item) => {
		setEditRegionModal(true);

		setFormData({
			_id: item._id,
		});
		setModalOpen([true, `Edit Region`]);
		getRegionById({
			id: item._id,
		});
		getOrganizations();
	};

	// use effects
	useEffect(() => {
		if (getRegionsState.apiState === 'loading') {
			setLoading(true);
		}
		if (getRegionsState.apiState === 'success') {
			if (getRegionsState.data.regions.length > 0) {
				const updatedData = [];
				const updatedSearchOptions = [];
				setTotalRecords(getRegionsState.data.total_count);
				getRegionsState.data.regions.map((item, index) => {
					updatedSearchOptions.push({ value: item.name });
					updatedData.push({
						key: item._id,
						_id: item._id,
						name: item.name,
						hospitals: item.hospitals
							? truncateString(item.hospitals, 80)
							: '-',
						organization: item.organization,
						createdAt: (
							<div className="showDate">
								{moment(item.createdAt).format('DD-MM-YYYY')}
							</div>
						),
						action: (
							<div className="text-center">
								<Row>
									<Col xs={{ span: 6, offset: 6 }}>
										{modulePermissions.sub_modules.includes(
											'update-regions',
										) && (
											<XButtonImg
												onClick={() => {
													handleEdit(item);
												}}
												type="text"
											>
												<img
													src="./images/pencil.svg"
													alt=""
												/>
											</XButtonImg>
										)}
									</Col>
									<Col xs={{ span: 9, offset: 3 }}>
										{modulePermissions.sub_modules.includes(
											'delete-regions',
										) && (
											<XButtonImg
												onClick={() =>
													showDeleteConfirm(
														item._id,
														item.name,
													)
												}
												type="text"
											>
												<img
													src="./images/delete-icon-card.svg"
													alt=""
												/>
											</XButtonImg>
										)}
									</Col>
								</Row>
							</div>
						),
					});
				});
				setTableData(updatedData);
				setLoading(false);
			} else {
				setTableData([]);
				setTotalRecords(0);
			}
		}
	}, [getRegionsState]);

	useEffect(() => {
		if (getRegionByIdState.apiState === 'success') {
			setFormData({
				id: getRegionByIdState.data._id,
				name: getRegionByIdState.data.name,
				hospitals: getRegionByIdState.data.hospitals.map(
					(ele) => ele._id,
				),
				organization: getRegionByIdState.data.organization.id,
			});
			form.setFieldsValue({
				id: getRegionByIdState.data._id,
				name: getRegionByIdState.data.name,
				hospitals: getRegionByIdState.data.hospitals.map(
					(ele) => ele._id,
				),
				organization: getRegionByIdState.data.organization.id,
			});
		}
	}, [getRegionByIdState]);

	useEffect(() => {
		if (editRegionState.apiState === 'success') {
			setFormData({});
			editRegionReset();
			getRegions();

			notification.success({
				message: editRegionState.message,
			});
			form.resetFields();
			setSubmitLoading(false);
			setModalOpen([false, '']);
		} else if (editRegionState.apiState === 'error') {
			setSubmitLoading(false);
			notification.error({
				message: editRegionState.message,
			});
			editRegionReset();
		}
	}, [editRegionState]);

	useEffect(() => {
		if (addRegionState.apiState === 'success') {
			setFormData({});
			addRegionReset();
			getRegions();
			notification.success({
				message: addRegionState.message,
			});
			form.resetFields();
			setSubmitLoading(false);
			setModalOpen([false, '']);
		} else if (addRegionState.apiState === 'error') {
			setSubmitLoading(false);
			notification.error({
				message: 'Error in adding Region',
			});
			addRegionReset();
		}
	}, [addRegionState]);

	useEffect(() => {
		if (deleteRegionState.apiState === 'success') {
			console.log('delllllllll');
			deleteRegionReset();
			getRegions();
			getOrganizations();
		}
	}, [deleteRegionState]);

	const applyFilters = () => {
		const queryParams = new URLSearchParams(window.location.search);

		// Iterate through filters and update the query parameters
		Object.entries(filters).forEach(([key, value]) => {
			const currentValue = queryParams.get(key);

			// Check if the parameter exists in the current query and has a value, if yes, update its value
			if (currentValue !== null) {
				queryParams.set(key, value);
			} else if (queryParams.has(key)) {
				// If the parameter exists but has no value, keep its existing value
				queryParams.set(key, queryParams.get(key));
			} else {
				// If the parameter does not exist in the current query, append it with the current value
				queryParams.append(key, value);
			}
		});

		// Set the page to 1
		queryParams.set('page', '1');
		queryParams.set('sort', 'createdAt');
		queryParams.set('sortDirection', 'descend');
		filters.page = 1;

		// Update the URL
		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
		window.history.pushState({}, '', newUrl);
	};

	useEffect(() => {
		// Retrieve filters from the updated URL
		const updatedFilters = getFiltersFromUrl();
		// Make API call with updated filters and pagination
		setFilters({ ...updatedFilters });
		getRegions({ ...updatedFilters });
	}, [window.location.search]);

	useEffect(() => {
		localStorage.getItem('role') === 'organization-admin'
			? setDisplayOrgInput(true)
			: setDisplayOrgInput(false);
	}, []);

	const clearFilters = () => {
		const queryParams = new URLSearchParams(window.location.search);

		// Iterate through defaultFilters and update the query parameters
		Object.entries(defaultFilters).forEach(([key, value]) => {
			const currentValue = queryParams.get(key);

			// Check if the parameter exists in the current query and has a value, if yes, update its value
			if (currentValue !== null) {
				queryParams.set(key, value);
			} else if (queryParams.has(key)) {
				// If the parameter exists but has no value, keep its existing value
				queryParams.set(key, queryParams.get(key));
			} else {
				// If the parameter does not exist in the current query, append it with the default value
				queryParams.append(key, value);
			}
		});

		// Clear the form fields
		form2.resetFields();

		// Update the state with default values
		setFilters(defaultFilters);

		// Update the URL
		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
		window.history.pushState({}, '', newUrl);
	};

	const addRegionSubmit = () => {
		addRegion({
			...formData,
		});
	};

	const updateRegionSubmit = () => {
		editRegion({
			...formData,
		});
	};

	return (
		<>
			{props.displayHeader ? (
				<></>
			) : (
				<>
					<Col lg={24} xl={24} xs={0} md={0}>
						<Header headerTag="Regions" />
					</Col>
					<Col lg={0} xl={0} xs={24} md={24}>
						<HeaderFile activeNav="regions" />
					</Col>
				</>
			)}
			<XContainer fluid>
				{modulePermissions.sub_modules.includes('list-regions') ? (
					<Card
						bodyStyle={{ padding: '10px' }}
						style={{ borderRadius: '30px' }}
					>
						<CaseSearch
							filters={[
								<XForm.Item
									name="hospitals"
									colon={false}
									label="Hospitals"
									style={{
										display: 'flex',
										flexDirection: 'column',
									}}
									className="removeFormMargin"
								>
									<XSelect
										size="large"
										allowClear
										mode="multiple"
										maxTagCount={(3, 'responsive')}
										showSearch={true}
										className="filter-sub-item"
										placeholder="Select Hospitals"
										defaultValue={
											filters.hospitals
												? filters.hospitals
												: []
										}
										onFocus={() => {
											getHospitalDropdown();
										}}
										onSearch={(search) => {
											if (search.length >= 3) {
												getHospitalDropdown({
													search,
												});
											} else if (search.length === 0) {
												getHospitalDropdown();
											}
										}}
										onClear={() => {
											setFilters({
												...filters,
												hospitals: '',
											});
										}}
										filterOption={filterByLabel}
										options={
											getHospitalDropdownState.apiState ===
												'success' &&
											getHospitalDropdownState.data
												.hospitals?.length > 0 &&
											getHospitalDropdownState.data.hospitals.map(
												(itm) => {
													return {
														label: itm.name,
														value: itm._id,
													};
												},
											)
										}
										onChange={(value, options) => {
											setFilters({
												...filters,
												hospitals: value,
											});
										}}
									/>
								</XForm.Item>,
								<XForm.Item
									name="created_on"
									colon={false}
									label="Date Within"
									className="removeFormMargin"
								>
									<XRadio.Group
										onChange={onChange}
										name="created_on"
										defaultValue={
											filters.created_on
												? filters.created_on
												: null
										}
									>
										<Row>
											{dateFilters.map((item, index) => (
												<Col
													xs={12}
													lg={12}
													md={12}
													xl={12}
												>
													<XRadio
														value={item.value}
														className="filter-sub-item"
													>
														{item.label}
													</XRadio>
												</Col>
											))}
										</Row>
									</XRadio.Group>
								</XForm.Item>,
							]}
							onClose={onClose}
							open={open}
							applyFilters={applyFilters}
							form={form2}
							clearFilters={clearFilters}
						/>
						<Row gutter={(15, 15)} style={{ padding: '20px ' }}>
							<Col
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
								xs={24}
								md={10}
								lg={4}
								xl={4}
								xxl={3}
								className="mb-lg-2"
							>
								<p className="tableHeader">
									Total {totalRecords} Regions
								</p>{' '}
							</Col>
							<Col
								xs={24}
								md={14}
								lg={{ span: 6 }}
								xl={{ span: 6 }}
								xxl={{ span: 6 }}
								className="mb-4 mb-lg-0"
							>
								<Space>
									<XInputText
										allowClear
										style={{ borderRadius: '25px' }}
										// size="large"
										placeholder="Search by Region Name"
										defaultValue={
											filters.search
												? filters.search
												: null
										}
										onChange={(v) => {
											if (v.target.value === '') {
												urlPagination(
													filters,
													'search',
													'',
												);
												setFilters({
													...filters,
													search: '',
												});
											} else {
												setFilters({
													...filters,
													search: v.target.value,
												});
											}
										}}
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
												const searchValue =
													filters.search
														? filters.search
														: '';
												if (searchValue) {
													urlPagination(
														filters,
														'search',
														searchValue,
													);
													setFilters({
														...filters,
														search: searchValue,
													});
												}
											}
										}}
									/>
									<SearchButton
										style={{
											borderRadius: '20px',
											color: '#007299',
										}}
										onClick={() => {
											// Trigger search when the button is clicked
											const searchValue = filters.search
												? filters.search
												: '';
											if (searchValue) {
												urlPagination(
													filters,
													'search',
													searchValue,
												);
												setFilters({
													...filters,
													search: searchValue,
												});
											}
										}}
										className="px-4"
										size="large"
									>
										Search
									</SearchButton>
								</Space>
								{/* <XForm.Item>
									<XSearch
										placeholder="Search by Region Name"
										allowClear
										enterButton="Search"
										size="large"
										defaultValue={
											filters.search
												? filters.search
												: null
										}
										onChange={(v) => {
											if (v.target.value === '') {
												urlPagination(
													filters,
													'search',
													'',
												);
												setFilters({
													...filters,
													search: '',
												});
											}
										}}
										onSearch={(v) => {
											urlPagination(filters, 'search', v);
											setFilters({
												...filters,
												search: v,
											});
										}}
									/>
								</XForm.Item> */}
							</Col>
							<Col
								xs={24}
								md={12}
								lg={{ span: 4, offset: 6 }}
								xl={{ span: 4, offset: 6 }}
								xxl={{ span: 4, offset: 7 }}
							>
								<XForm.Item className="text-right">
									<FilterButton
										onClick={() => showDrawer()}
										icon={
											<img
												src="/images/filter-add.svg"
												alt="bell"
												height={'15px'}
											/>
										}
									>
										Filters
									</FilterButton>
								</XForm.Item>
							</Col>
							<Col
								xs={24}
								md={12}
								lg={{ span: 4 }}
								xl={{ span: 4 }}
								xxl={{ span: 4 }}
							>
								{modulePermissions.sub_modules.includes(
									'add-regions',
								) ? (
									<XForm.Item className="text-right">
										<XButton2
											onClick={() => {
												form.resetFields();
												setModalOpen([
													true,
													'Add Region',
												]);
												getOrganizations();
												setEditRegionModal(false);
											}}
											icon={<PlusOutlined />}
										>
											Region
										</XButton2>
									</XForm.Item>
								) : (
									<></>
								)}
							</Col>
						</Row>
						<DataTable
							tableData={tableData}
							columns={columns}
							totalRecords={totalRecords}
							setFilters={setFilters}
							filters={filters}
							// state={getRegionsState.apiState}
							state={loading ? 'loading' : ''}
							page={'region'}
							handleEdit={handleEdit}
							showDeleteConfirm={showDeleteConfirm}
						/>
					</Card>
				) : (
					<Result
						status="403"
						title="403"
						subTitle="Sorry, you are not authorized to access this page."
						extra={
							<Button
								type="primary"
								onClick={() => navigate('/')}
							>
								Back Home
							</Button>
						}
					/>
				)}
			</XContainer>
			<XModal
				title={modalOpen[1]}
				centered
				open={modalOpen[0]}
				onCancel={() => {
					form.resetFields();
					setFormData({});
					setModalOpen([false, '']);
				}}
				footer={null}
				okButtonProps={{
					style: {
						display: 'none',
						backgroundColor: '#007299',
						borderColor: '#007299',
						color: '#fff',
					},
				}}
				cancelButtonProps={{
					style: {
						display: 'none',
						background: '#fff',
						color: '#007299',
						borderRadius: '20px',
					},
				}}
				width={850}
			>
				<XForm
					form={form}
					name="roleForm"
					layout="vertical"
					autoComplete="off"
					onFinish={() => {
						setSubmitLoading(true);
						editRegionModal
							? updateRegionSubmit()
							: addRegionSubmit();
					}}
				>
					<Row gutter={(15, 30)} className="mt-4">
						<Col xs={24} lg={12}>
							<XForm.Item
								name="name"
								label="Name"
								rules={[
									{
										required: true,
										message: 'Please enter Region name',
									},
								]}
							>
								<Input
									size="large"
									placeholder="Enter Region Name"
									name="name"
									onChange={(e) =>
										handleInputChange(
											e,
											formData,
											setFormData,
										)
									}
								/>
							</XForm.Item>
						</Col>
						{displayOrgInput ? (
							<></>
						) : (
							<Col xs={24} lg={12}>
								<XForm.Item
									name="organization"
									label={'Organization'}
									rules={[
										{
											required: true,
											message: 'Please enter Region name',
										},
									]}
								>
									<XSelect
										size="large"
										placeholder="Select Organization"
										name="organization"
										showSearch={true}
										className="removeBg"
										onSearch={(search) => {
											if (search.length >= 3) {
												getOrganizations({
													search,
												});
											} else if (search.length === 0) {
												getOrganizations();
											}
										}}
										filterOption={filterByLabel}
										options={
											getOrganizationsState.apiState ===
												'success' &&
											getOrganizationsState.data.list
												?.length > 0 &&
											getOrganizationsState.data.list.map(
												(itm) => {
													return {
														label: itm.name,
														value: itm._id,
													};
												},
											)
										}
										onSelect={(value, options) => {
											handleSelectChange(
												'organization',
												value,
												formData,
												setFormData,
											);
										}}
									></XSelect>
								</XForm.Item>
							</Col>
						)}
					</Row>
					<Row gutter={[30, 5]} className="mt-3">
						<Col xs={24} lg={12}></Col>
						<Col xs={24} lg={12} className="text-end">
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									gap: '8px',
									flexWrap: 'wrap',
								}}
							>
								<XForm.Item className="text-right ">
									<Button
										style={{
											background: '#fff',
											margin: 0,
											color: '#007299',
										}}
										size="large"
										onClick={() => {
											form.resetFields();
											setFormData({});
											setModalOpen([false, '']);
										}}
									>
										Cancel
									</Button>
								</XForm.Item>
								<XForm.Item className="text-right">
									<XButtonConfirm
										loading={submitLoading}
										style={{
											background: '#007299',
											color: '#fff',
											margin: 0,
										}}
										htmlType="submit"
										size="large"
										disabled={
											(editRegionModal &&
												!modulePermissions.sub_modules.includes(
													'update-regions',
												)) ||
											!modulePermissions.sub_modules.includes(
												'add-regions',
											)
										}
									>
										Submit
									</XButtonConfirm>
								</XForm.Item>
							</div>
						</Col>{' '}
					</Row>
				</XForm>
			</XModal>
		</>
	);
};

const mapStateToProps = (state) => ({
	getHospitalDropdownState: state.getHospitalDropdown,
	getRegionsState: state.getRegions,
	getRegionByIdState: state.getRegionById,
	editRegionState: state.editRegion,
	deleteRegionState: state.deleteRegion,
	addRegionState: state.addRegion,
	getOrganizationsState: state.getOrganizations,
});

const mapDispatchToProps = (dispatch) => ({
	getRegions: (params) => dispatch(getRegionsApi(params)),
	getRegionsReset: (params) => dispatch(getRegionsReset(params)),
	getRegionById: (params) => dispatch(getRegionByIdApi(params)),
	getRegionByIdReset: (params) => dispatch(getRegionByIdReset(params)),
	editRegion: (params) => dispatch(editRegionApi(params)),
	editRegionReset: (params) => dispatch(editRegionReset(params)),
	deleteRegion: (params) => dispatch(deleteRegionApi(params)),
	deleteRegionReset: (params) => dispatch(deleteRegionReset(params)),
	addRegion: (params) => dispatch(addRegionApi(params)),
	addRegionReset: (params) => dispatch(addRegionReset(params)),
	getHospitalDropdown: (params) => dispatch(getHospitalDropdownApi(params)),
	getOrganizations: (data) => dispatch(getOrganizationsApi(data)),
	getOrganizationsReset: () => dispatch(getOrganizationsReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Regions);
