import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, notification } from 'antd';
import {
	CenterWrapper,
	CenterWrapper2,
	Logo,
	ReclaimRevolutionCard,
	ReclaimRevolutionWrap,
	Title,
	XButtonOverlay,
	XContainer,
	XForm,
	XFromsInput,
	XInputBtn,
	XLoginButton,
	XLottie,
	XSelectDropDown,
	XSubmitButton,
	XsuccessfullyMsg,
} from './indexStyle';
import { Space, Input, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import {
	accessRequestApi,
	accessRequestReset,
} from '../../actions/accessRequestAction';
import {
	handleInputChange,
	handleSelectChange,
} from '../../utils/formFunctions';
import { ArrowRightOutlined } from '@ant-design/icons';
import animationData from '../../json/reclaim-revolution.json';

const AccessRequest = (props) => {
	const { accessRequestState, accessRequest, accessRequestReset } = props;
	const [form] = XForm.useForm();
	const [formData, setFormData] = useState({});
	const [loading, setLoading] = useState(false);
	const [emailValue, setEmailValue] = useState(true);
	const [submitValue, setSubmitValue] = useState(true);

	const emailHandleClick = () => {
		form.validateFields(['email'])
			.then(() => {
				// Validation successful
				setEmailValue(false);
				// Proceed with your next steps here
			})
			.catch((errorInfo) => {
				console.error('Validation Failed:', errorInfo);
				// Handle the validation error here if needed
			});
	};

	const submitHandleClick = () => {
		setSubmitValue(false);
	};

	useEffect(() => {
		document.body.classList.add('reclaimRevolutionBody');
		return () => {
			document.body.classList.remove('reclaimRevolutionBody');
		};
	}, []);

	const handleSubmit = () => {
		setLoading(true);
		accessRequest(formData);
	};

	useEffect(() => {
		if (accessRequestState.apiState === 'success') {
			accessRequestReset();
			setLoading(false);
			setSubmitValue(false);
		} else if (accessRequestState.apiState === 'error') {
			notification.error({ message: accessRequestState.message });
			setLoading(false);
		}
	}, [accessRequestState]);

	return (
		<>
			<XContainer fluid>
				<Link to="/login">
					<XLoginButton>Login</XLoginButton>
				</Link>

				<XForm
					form={form}
					name="loginForm"
					layout="vertical"
					autoComplete="off"
					onFinish={() => {
						handleSubmit();
						// setLoading(true);
						// login(formData);
					}}
				>
					{emailValue ? (
						<>
							<CenterWrapper className="">
								<Row className="align-items-center">
									<Col xs={24} sm={24} lg={24} xl={24}>
										<ReclaimRevolutionWrap>
											<Row
												gutter={(15, 15)}
												className="text-center"
											>
												<Col xs={24}>
													<Logo>
														<img
															className="img-fluid m-auto"
															src="./images/reclaim-revolution-logo.svg"
															alt="logo"
														/>
													</Logo>
												</Col>
												<Col xs={24} className="my-4">
													<Title>
														Sign up to join the{' '}
														<br />
														<span>
															Reclaim Revolution!
														</span>
													</Title>
												</Col>
											</Row>
											<ReclaimRevolutionCard className="noPadding">
												<Row gutter={(15, 15)}>
													<Col xs={24}>
														<XForm.Item
															name="email"
															rules={[
																{
																	required: true,
																	message:
																		'Please enter email address',
																},
																{
																	type: 'email',
																	message:
																		'Please enter a valid email address',
																},
															]}
														>
															<Space.Compact className="w-100">
																<XInputBtn
																	name="email"
																	size="large"
																	placeholder="Enter your email"
																	onChange={(
																		e,
																	) =>
																		handleInputChange(
																			e,
																			formData,
																			setFormData,
																		)
																	}
																/>
																<XButtonOverlay
																	onClick={
																		emailHandleClick
																	}
																	shape="round"
																	size="large"
																	icon={
																		<ArrowRightOutlined />
																	}
																	type="primary"
																	// htmlType="submit"
																/>
															</Space.Compact>
														</XForm.Item>
													</Col>
												</Row>
											</ReclaimRevolutionCard>
										</ReclaimRevolutionWrap>
									</Col>
								</Row>
							</CenterWrapper>
						</>
					) : (
						<>
							{submitValue ? (
								<CenterWrapper2 className="">
									<Row className="align-items-center">
										<Col xs={24} sm={24} lg={24} xl={24}>
											<ReclaimRevolutionWrap>
												<Row
													gutter={(15, 15)}
													className="text-center"
												>
													<Col xs={24}>
														<Logo>
															<img
																className="img-fluid m-auto"
																src="./images/reclaim-revolution-logo.svg"
																alt="logo"
															/>
														</Logo>
													</Col>
													<Col
														xs={24}
														className="my-4"
													>
														<Title>
															Sign up to join the{' '}
															<br />
															<span>
																Reclaim
																Revolution!
															</span>
														</Title>
													</Col>
												</Row>
												<ReclaimRevolutionCard className="customCards hasBackground">
													<Row
														gutter={(15, 15)}
														className="align-items-center"
													>
														<Col xs={24}>
															<XForm.Item
																className="loginInputsLbl"
																name="name"
																label="Name"
																rules={[
																	{
																		required: true,
																		message:
																			'Please enter name',
																	},
																]}
															>
																<XFromsInput
																	size={
																		'large'
																	}
																	name="name"
																	onChange={(
																		e,
																	) =>
																		handleInputChange(
																			e,
																			formData,
																			setFormData,
																		)
																	}
																	placeholder="Enter your name"
																/>
															</XForm.Item>
														</Col>
														<Col xs={24}>
															<XForm.Item
																className="loginInputsLbl"
																name="phone"
																label="Phone"
																rules={[
																	{
																		required: true,
																		message:
																			'Please enter phone',
																	},
																	{
																		pattern:
																			/^[0-9]{10}$/, // Adjust this regex based on your needs (e.g., country-specific formats)
																		message:
																			'Please enter a valid 10-digit phone number',
																	},
																]}
															>
																<XFromsInput
																	size={
																		'large'
																	}
																	name="phone"
																	onChange={(
																		e,
																	) =>
																		handleInputChange(
																			e,
																			formData,
																			setFormData,
																		)
																	}
																	placeholder="Enter your phone"
																/>
															</XForm.Item>
														</Col>
														<Col xs={24}>
															<XForm.Item
																className="loginInputsLbl"
																name="country"
																label="Country"
																rules={[
																	{
																		required: true,
																		message:
																			'Please enter country',
																	},
																]}
															>
																<XSelectDropDown
																	size="large"
																	placeholder="Select country"
																	name="country"
																	allowClear
																	options={[
																		{
																			value: 'India',
																			label: 'India',
																		},
																		{
																			value: 'US',
																			label: 'Us',
																		},
																	]}
																	onSelect={(
																		value,
																	) => {
																		handleSelectChange(
																			'country',
																			value,
																			formData,
																			setFormData,
																		);
																	}}
																/>
															</XForm.Item>
														</Col>
														<Col xs={24}>
															<XForm.Item
																className="loginInputsLbl"
																name="profession"
																label="Profession"
																rules={[
																	{
																		required: true,
																		message:
																			'Please enter profession',
																	},
																]}
															>
																<XFromsInput
																	size={
																		'large'
																	}
																	name="profession"
																	onChange={(
																		e,
																	) =>
																		handleInputChange(
																			e,
																			formData,
																			setFormData,
																		)
																	}
																	placeholder="Enter your profession"
																/>
															</XForm.Item>
														</Col>
														<Col xs={24}>
															<XForm.Item
																className="loginInputsLbl"
																name="organization"
																label="Organization"
																rules={[
																	{
																		required: true,
																		message:
																			'Please enter organization',
																	},
																]}
															>
																<XFromsInput
																	size={
																		'large'
																	}
																	name="organization"
																	onChange={(
																		e,
																	) =>
																		handleInputChange(
																			e,
																			formData,
																			setFormData,
																		)
																	}
																	placeholder="Enter your organization"
																/>
															</XForm.Item>
														</Col>
														<Col
															xs={24}
															className="text-center"
														>
															<XSubmitButton
																// onClick={
																// 	submitHandleClick
																// }
																loading={
																	loading
																}
																htmlType="submit"
																size="large"
															>
																Submit Request
															</XSubmitButton>
														</Col>
													</Row>
												</ReclaimRevolutionCard>
											</ReclaimRevolutionWrap>
										</Col>
									</Row>
								</CenterWrapper2>
							) : (
								<CenterWrapper2>
									<Row className="align-items-center">
										<Col xs={24} sm={24} lg={24} xl={24}>
											<ReclaimRevolutionWrap>
												<Row
													gutter={(15, 15)}
													className="text-center"
												>
													<Col xs={24}>
														<Logo>
															<img
																className="img-fluid m-auto"
																src="./images/reclaim-revolution-logo.svg"
																alt="logo"
															/>
														</Logo>
													</Col>
													<Col
														xs={24}
														className="my-4"
													>
														<Title>
															Sign up to join the{' '}
															<br />
															<span>
																Reclaim
																Revolution!
															</span>
														</Title>
													</Col>
												</Row>
												<ReclaimRevolutionCard className="customCards hasBackground">
													<Row
														gutter={(15, 15)}
														className="align-items-center"
													>
														<Col
															xs={24}
															className="text-center"
														>
															<XLottie
																animationData={
																	animationData
																}
																loop={true}
																style={{
																	width: '20vw',
																	height: 'auto',
																}}
															/>
														</Col>
														<Col
															xs={24}
															className="text-center"
														>
															<XsuccessfullyMsg className="mb-5">
																<span>
																	You request
																	has been
																	successfully
																	submitted.
																</span>
																<br />
																Please check
																your inbox for
																more details
															</XsuccessfullyMsg>
														</Col>
													</Row>
												</ReclaimRevolutionCard>
											</ReclaimRevolutionWrap>
										</Col>
									</Row>
								</CenterWrapper2>
							)}
						</>
					)}
				</XForm>
			</XContainer>
		</>
	);
};

const mapStateToProps = (state) => ({
	accessRequestState: state.accessRequest,
});

const mapDispatchToProps = (dispatch) => ({
	accessRequest: (params) => dispatch(accessRequestApi(params)),
	accessRequestReset: () => dispatch(accessRequestReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessRequest);
