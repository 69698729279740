// import React, { useState, useEffect } from 'react';
// import { connect } from 'react-redux';
// import { Row, Col, notification } from 'antd';
// import {
// 	CenterWrapper,
// 	Logo,
// 	Title,
// 	XCheckbox,
// 	XForm,
// 	XLoginBTN,
// 	XLottie,
// 	XMobileLogin,
// } from './LoginStyle';
// import { XContainer, XButton } from '../../styles/GlobalStyle';
// import { Input, Button } from 'antd';
// import { Link, useNavigate } from 'react-router-dom';
// import LoginAnimation from '../../json/Login.json';
// import { loginApi, loginReset } from '../../actions/loginAction';
// import { handleInputChange } from '../../utils/formFunctions';
// import {
// 	checkModulePermissions,
// 	getExpiresAt,
// 	getFirstAvailableRoute,
// 	isLogin,
// } from '../../utils/helper';
// import { requestPermissionAndGetToken } from '../../firebase/firebase';

// const Login = (props) => {
// 	const { login, loginReset, loginState } = props;
// 	const [form] = XForm.useForm();
// 	const [formData, setFormData] = useState({});
// 	const [loading, setLoading] = useState(false);
// 	const navigate = useNavigate();

// 	useEffect(() => {
// 		if (isLogin()) {
// 			let expiresAt = getExpiresAt();
// 			if (expiresAt !== null && new Date() < new Date(expiresAt))
// 				navigate('/');
// 		}
// 	}, []);

// 	const generateFcmToken = async () => {
// 		await requestPermissionAndGetToken();
// 	};

// 	useEffect(() => {
// 		if (loginState.apiState === 'success') {
// 			setLoading(false);
// 			localStorage.setItem('user_id', loginState.data.id);
// 			localStorage.setItem('accessToken', loginState.data.accessToken);
// 			localStorage.setItem('refreshToken', loginState.data.refreshToken);
// 			localStorage.setItem('username', loginState.data.username);
// 			localStorage.setItem('email', loginState.data.email);
// 			localStorage.setItem('profile', loginState.data.profile_image);
// 			localStorage.setItem('expiresAt', loginState.data.expiresAt);
// 			localStorage.setItem('role', loginState.data.role.role);
// 			localStorage.setItem(
// 				'org_logo',
// 				loginState.data.organization_logo || '',
// 			);
// 			localStorage.setItem(
// 				'changePassword',
// 				loginState.data.changePassword,
// 			);
// 			localStorage.setItem(
// 				'permissions',
// 				JSON.stringify(loginState.data.permissions),
// 			);
// 			localStorage.setItem(
// 				'dashboardData',
// 				JSON.stringify(loginState.data.dashboardData),
// 			);

// 			generateFcmToken();
// 			loginReset();
// 			if (loginState.data.changePassword) {
// 				navigate('/consent');
// 			} else {
// 				navigate('/');
// 			}
// 			// Check permissions after login
// 			const dashboardPermission = checkModulePermissions('dashboard');
// 			if (dashboardPermission.authorized) {
// 				navigate('/');
// 			} else {
// 				const firstAvailableRoute = getFirstAvailableRoute();
// 				navigate(firstAvailableRoute ? firstAvailableRoute.path : '/');
// 			}
// 		} else if (loginState.apiState === 'error') {
// 			setLoading(false);
// 			notification.error({ message: loginState.message });
// 			loginReset();
// 		}
// 	}, [loginState]);

// 	// useEffect(() => {
// 	// 	// Add the class to the body tag
// 	// 	document.body.classList.add('reclaimBody');

// 	// 	// Clean up to remove the class when the component unmounts
// 	// 	return () => {
// 	// 		document.body.classList.remove('reclaimBody');
// 	// 	};
// 	// }, []);

// 	useEffect(() => {
//         // Add the class to the body tag
//         document.body.classList.add('reclaimLoginBody');

//         // Clean up to remove the class when the component unmounts
//         return () => {
//             document.body.classList.remove('reclaimLoginBody');
//         };
//     }, []);

// 	return (
// 		<>
// 			{/* <XMobileLogin>
// 				<Row>
// 					<Col xs={24}>
// 						<img
// 							className="reclaim-login-logo"
// 							src="/images/reclaim-login-logo.svg"
// 							alt="Logo"
// 						/>
// 					</Col>
// 				</Row>
// 			</XMobileLogin> */}
// 			{/* <XContainer fluid className="showDesktop"> */}
// 			<XContainer fluid>
// 				<Row className="showDesktop">
// 					<Col xs={24}>
// 						<Logo>
// 							<img
// 								className="img-fluid"
// 								src="./images/logo.svg"
// 								alt="logo"
// 							/>
// 						</Logo>
// 					</Col>
// 				</Row>
// 				<CenterWrapper>
// 					<Row className="showMobile mb-5">
// 						<Col xs={24}>
// 							<Logo>
// 								<img
// 									className="img-fluid"
// 									src="./images/reclaim-login-logo.svg"
// 									alt="logo"
// 								/>
// 							</Logo>
// 						</Col>
// 					</Row>

// 					<Row className="align-items-center">
// 						<Col
// 							xs={{ span: 24, order: 2 }}
// 							sm={{ span: 24, order: 2 }}
// 							lg={{ span: 10, order: 1 }}
// 						>
// 							<Title className="mb-4 mb-lg-5 showDesktop">
// 								Welcome to Medicare Coverage Analysis Platform..
// 							</Title>
// 							<XForm
// 								form={form}
// 								name="loginForm"
// 								layout="vertical"
// 								autoComplete="off"
// 								onFinish={() => {
// 									setLoading(true);
// 									login(formData);
// 								}}
// 							>
// 								<XForm.Item
// 									className="loginInputsLbl"
// 									name="email"
// 									label="Email Address"
// 									rules={[
// 										{
// 											required: true,
// 											message:
// 												'Please enter email address',
// 										},
// 									]}
// 								>
// 									<Input
// 										size={'large'}
// 										name="email"
// 										placeholder="Enter Email Address"
// 										onChange={(e) =>
// 											handleInputChange(
// 												e,
// 												formData,
// 												setFormData,
// 											)
// 										}
// 									/>
// 								</XForm.Item>
// 								<XForm.Item
// 									className="loginInputsLbl"
// 									name="password"
// 									label="Password"
// 									rules={[
// 										{
// 											required: true,
// 											message: 'Please enter password',
// 										},
// 									]}
// 								>
// 									<Input.Password
// 										name="password"
// 										size={'large'}
// 										placeholder="Enter Password"
// 										onChange={(e) =>
// 											handleInputChange(
// 												e,
// 												formData,
// 												setFormData,
// 											)
// 										}
// 									/>
// 								</XForm.Item>
// 								<XForm.Item>
// 									<XForm.Item
// 										className="showMobile"
// 										name="remember"
// 										valuePropName="checked"
// 										noStyle
// 									>
// 										<XCheckbox>Keep me signed in</XCheckbox>
// 									</XForm.Item>
// 									<Link
// 										className="login-form-forgot"
// 										to="/forgot-email-password"
// 									>
// 										Forgot password?
// 									</Link>
// 								</XForm.Item>

// 								<XForm.Item className="mt-4">
// 									{loading ? (
// 										<XLoginBTN type="primary" loading>
// 											Logging In...
// 										</XLoginBTN>
// 									) : (
// 										<XLoginBTN htmlType="submit">
// 											Log In
// 										</XLoginBTN>
// 									)}
// 								</XForm.Item>
// 							</XForm>
// 						</Col>
// 						<Col
// 							xs={{ span: 24, order: 1 }}
// 							sm={{ span: 24, order: 1 }}
// 							lg={{ span: 10, offset: 4, order: 2 }}
// 							className="text-center showDesktop"
// 						>
// 							<XLottie
// 								animationData={LoginAnimation}
// 								loop={true}
// 							/>
// 						</Col>
// 					</Row>
// 				</CenterWrapper>
// 			</XContainer>
// 		</>
// 	);
// };

// const mapStateToProps = (state) => ({
// 	loginState: state.login,
// });

// const mapDispatchToProps = (dispatch) => ({
// 	login: (params) => dispatch(loginApi(params)),
// 	loginReset: () => dispatch(loginReset()),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Login);

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, notification } from 'antd';
import {
	CenterWrapper,
	Logo,
	Title,
	XCheckbox,
	XContainer,
	XForm,
	XLoginBTN,
	XLottie,
	XMobileLogin,
} from './LoginStyle';
import { XButton } from '../../styles/GlobalStyle';
import { Input, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import LoginAnimation from '../../json/Login.json';
import { loginApi, loginReset } from '../../actions/loginAction';
import { handleInputChange } from '../../utils/formFunctions';
import {
	checkModulePermissions,
	getExpiresAt,
	getFirstAvailableRoute,
	isLogin,
} from '../../utils/helper';
import { requestPermissionAndGetToken } from '../../firebase/firebase';
import Lottie from 'lottie-react';
import animationData from '../../json/new-reclaim-login.json';

const Login = (props) => {
	const { login, loginReset, loginState } = props;
	const [form] = XForm.useForm();
	const [formData, setFormData] = useState({});
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (isLogin()) {
			let expiresAt = getExpiresAt();
			if (expiresAt !== null && new Date() < new Date(expiresAt))
				navigate('/');
		}
	}, []);

	const generateFcmToken = async () => {
		await requestPermissionAndGetToken();
	};

	useEffect(() => {
		if (loginState.apiState === 'success') {
			setLoading(false);
			localStorage.setItem('user_id', loginState.data.id);
			localStorage.setItem('accessToken', loginState.data.accessToken);
			localStorage.setItem('refreshToken', loginState.data.refreshToken);
			localStorage.setItem('username', loginState.data.username);
			localStorage.setItem('email', loginState.data.email);
			localStorage.setItem('profile', loginState.data.profile_image);
			localStorage.setItem('expiresAt', loginState.data.expiresAt);
			localStorage.setItem('role', loginState.data.role.role);
			localStorage.setItem(
				'org_logo',
				loginState.data.organization_logo || '',
			);
			localStorage.setItem(
				'changePassword',
				loginState.data.changePassword,
			);
			localStorage.setItem(
				'permissions',
				JSON.stringify(loginState.data.permissions),
			);
			localStorage.setItem(
				'dashboardData',
				JSON.stringify(loginState.data.dashboardData),
			);

			generateFcmToken();
			loginReset();
			if (loginState.data.changePassword) {
				navigate('/consent');
			} else {
				navigate('/');
			}
			// Check permissions after login
			const dashboardPermission = checkModulePermissions('dashboard');
			if (dashboardPermission.authorized) {
				navigate('/');
			} else {
				const firstAvailableRoute = getFirstAvailableRoute();
				navigate(firstAvailableRoute ? firstAvailableRoute.path : '/');
			}
		} else if (loginState.apiState === 'error') {
			setLoading(false);
			notification.error({ message: loginState.message });
			loginReset();
		}
	}, [loginState]);

	// useEffect(() => {
	// 	// Add the class to the body tag
	// 	document.body.classList.add('reclaimBody');

	// 	// Clean up to remove the class when the component unmounts
	// 	return () => {
	// 		document.body.classList.remove('reclaimBody');
	// 	};
	// }, []);

	useEffect(() => {
		// Add the class to the body tag
		document.body.classList.add('reclaimLoginBody');

		// Clean up to remove the class when the component unmounts
		return () => {
			document.body.classList.remove('reclaimLoginBody');
		};
	}, []);

	return (
		<>
			{/* <XMobileLogin>
				<Row>
					<Col xs={24}>
						<img
							className="reclaim-login-logo"
							src="/images/reclaim-login-logo.svg"
							alt="Logo"
						/>
					</Col>
				</Row>
			</XMobileLogin> */}
			{/* <XContainer fluid className="showDesktop"> */}
			<XContainer fluid>
				{/* <Row className="showDesktop">
					<Col xs={24}>
						<Logo>
							<img
								className="img-fluid"
								src="./images/reclaim-login-logo.svg"
								alt="logo"
							/>
						</Logo>
					</Col>
				</Row> */}
				<CenterWrapper>
					<Row className="showMobile mb-5 ">
						<Col xs={24}>
							<Logo>
								<img
									className="img-fluid"
									src="./images/reclaim-login-logo.svg"
									alt="logo"
								/>
							</Logo>
						</Col>
					</Row>

					<Row className="align-items-center">
						<Col
							xs={{ span: 24, order: 2 }}
							sm={{ span: 24, order: 2 }}
							lg={{ span: 10, order: 1 }}
						>
							<Row className="showDesktop mb-5">
								<Col xs={24}>
									<Logo>
										<img
											className="img-fluid"
											src="./images/reclaim-login-logo.svg"
											alt="logo"
										/>
									</Logo>
								</Col>
							</Row>
							<XForm
								form={form}
								name="loginForm"
								layout="vertical"
								autoComplete="off"
								onFinish={() => {
									setLoading(true);
									login(formData);
								}}
							>
								<XForm.Item
									className="loginInputsLbl"
									name="email"
									label="Email Address"
									rules={[
										{
											required: true,
											message:
												'Please enter email address',
										},
									]}
								>
									<Input
										size={'large'}
										name="email"
										placeholder="Enter Email Address"
										onChange={(e) =>
											handleInputChange(
												e,
												formData,
												setFormData,
											)
										}
									/>
								</XForm.Item>
								<XForm.Item
									className="loginInputsLbl"
									name="password"
									label="Password"
									rules={[
										{
											required: true,
											message: 'Please enter password',
										},
									]}
								>
									<Input.Password
										name="password"
										size={'large'}
										placeholder="Enter Password"
										onChange={(e) =>
											handleInputChange(
												e,
												formData,
												setFormData,
											)
										}
									/>
								</XForm.Item>
								<XForm.Item>
									<XForm.Item
										className="showMobile"
										name="remember"
										valuePropName="checked"
										noStyle
									>
										<XCheckbox>Keep me signed in</XCheckbox>
									</XForm.Item>
									<Link
										className="login-form-forgot"
										to="/forgot-email-password"
									>
										Forgot password?
									</Link>
								</XForm.Item>

								<XForm.Item className="mt-4">
									{loading ? (
										<XLoginBTN type="primary" loading>
											Logging In...
										</XLoginBTN>
									) : (
										<XLoginBTN htmlType="submit">
											Log In
										</XLoginBTN>
									)}
								</XForm.Item>
							</XForm>
						</Col>
						<Col
							xs={{ span: 24, order: 1 }}
							sm={{ span: 24, order: 1 }}
							lg={{ span: 10, offset: 4, order: 2 }}
							className="text-center showDesktop"
						>
							{/* <img src="./images/reclaim-desktop-logo-main.svg" alt="logo" /> */}
							<XLottie
								animationData={animationData}
								loop={true}
								style={{ width: '40vw', height: 'auto' }}
							/>
						</Col>
					</Row>
				</CenterWrapper>
			</XContainer>
		</>
	);
};

const mapStateToProps = (state) => ({
	loginState: state.login,
});

const mapDispatchToProps = (dispatch) => ({
	login: (params) => dispatch(loginApi(params)),
	loginReset: () => dispatch(loginReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
