// import React, { useRef, useEffect } from 'react';

// interface WaveformVisualizerProps {
//   isRecording: boolean;
// }

// const WaveformVisualizer: React.FC<WaveformVisualizerProps> = ({ isRecording }) => {
//   const canvasRef = useRef<HTMLCanvasElement>(null);
//   const audioContextRef = useRef<AudioContext | null>(null);
//   const analyserRef = useRef<AnalyserNode | null>(null);
//   const animationFrameRef = useRef<number | null>(null);
//   const dataArrayRef = useRef<number[]>([]);

//   useEffect(() => {
//     if (isRecording) {
//       startVisualization();
//     } else {
//       stopVisualization();
//     }

//     return () => {
//       stopVisualization();
//     };
//   }, [isRecording]);

//   const startVisualization = async () => {
//     if (!audioContextRef.current) {
//       audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
//     }

//     if (!analyserRef.current) {
//       analyserRef.current = audioContextRef.current.createAnalyser();
//       analyserRef.current.fftSize = 256;
//     }

//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//       const source = audioContextRef.current.createMediaStreamSource(stream);
//       source.connect(analyserRef.current);
//       drawBars();
//     } catch (error) {
//       console.error('Error accessing microphone:', error);
//     }
//   };

//   const stopVisualization = () => {
//     if (animationFrameRef.current) {
//       cancelAnimationFrame(animationFrameRef.current);
//     }
//     if (audioContextRef.current) {
//       audioContextRef.current.close();
//       audioContextRef.current = null;
//     }
//     analyserRef.current = null;

//     // Draw stopped state
//     const canvas = canvasRef.current;
//     if (canvas) {
//       const ctx = canvas.getContext('2d');
//       if (ctx) {
//         ctx.clearRect(0, 0, canvas.width, canvas.height);
//         drawStoppedBars(ctx, canvas.width, canvas.height);
//       }
//     }
//   };

//   const drawStoppedBars = (ctx: CanvasRenderingContext2D, width: number, height: number) => {
//     const barCount = 83; // Number of bars to match the SVG
//     const barWidth = 3;
//     const barHeight = 5;
//     const barGap = 3;

//     ctx.clearRect(0, 0, width, height);

//     for (let i = 0; i < barCount; i++) {
//       const x = i * (barWidth + barGap);
//       const y = (height - barHeight) / 2;

//       ctx.fillStyle = 'rgba(0, 114, 153, 0.2)';
//       ctx.beginPath();
//       ctx.roundRect(x, y, barWidth, barHeight, 1.5);
//       ctx.fill();
//     }
//   };

//   const drawBars = () => {
//     const canvas = canvasRef.current;
//     const analyser = analyserRef.current;

//     if (!canvas || !analyser) return;

//     const ctx = canvas.getContext('2d');
//     if (!ctx) return;

//     const width = canvas.width;
//     const height = canvas.height;
//     const bufferLength = analyser.frequencyBinCount;
//     const dataArray = new Uint8Array(bufferLength);
//     const barWidth = 3;
//     const barGap = 3;
//     const totalBars = Math.floor(width / (barWidth + barGap));

//     let offset = 0;

//     const draw = () => {
//       animationFrameRef.current = requestAnimationFrame(draw);
//       analyser.getByteFrequencyData(dataArray);

//       ctx.clearRect(0, 0, width, height);

//       // Draw center line
//       ctx.beginPath();
//       ctx.strokeStyle = '#007299';
//       ctx.lineWidth = 1;
//       ctx.moveTo(width / 2, 0);
//       ctx.lineTo(width / 2, height);
//       ctx.stroke();

//       // Draw bars
//       for (let i = 0; i < totalBars; i++) {
//         const x = width - (i * (barWidth + barGap) + offset);
//         const dataIndex = Math.floor(i * bufferLength / totalBars);
//         const value = dataArray[dataIndex];
//         const barHeight = Math.max((value / 255) * height * 0.8, 5); // Minimum height of 5px
//         const y = (height - barHeight) / 2;

//         if (x < width / 2) {
//           ctx.fillStyle = '#007299';
//         } else {
//           ctx.fillStyle = 'rgba(0, 114, 153, 0.2)';
//         }

//         ctx.beginPath();
//         ctx.roundRect(x, y, barWidth, barHeight, 1.5);
//         ctx.fill();
//       }

//       // Update offset for scrolling effect
//       offset += 1;
//       if (offset >= barWidth + barGap) {
//         offset = 0;
//       }
//     };

//     draw();
//   };

//   return (
//     <div className="relative">
//       <canvas
//         ref={canvasRef}
//         width={517}
//         height={100}
//         style={{
//           width: '100%',
//           maxWidth: '517px',
//           height: 'auto'
//         }}
//       />
//       {isRecording && (
//         <div
//           className="absolute top-0 left-1/2 h-8 w-px bg-[#007299] -translate-x-1/2 -translate-y-full"
//           style={{ marginTop: '-1px' }}
//         />
//       )}
//     </div>
//   );
// };

// export default WaveformVisualizer;

// import React, { useRef, useEffect } from 'react';

// const WaveformVisualizer = ({ isRecording }) => {
//   const canvasRef = useRef(null);
//   const audioContextRef = useRef(null);
//   const analyserRef = useRef(null);
//   const animationFrameRef = useRef(null);
//   const barPositionsRef = useRef([]);

//   useEffect(() => {
//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext('2d');

//     // Set canvas size
//     canvas.width = 517;
//     canvas.height = 110;

//     if (isRecording) {
//       startVisualization(canvas, ctx);
//     } else {
//       stopVisualization(canvas, ctx);
//     }

//     return () => {
//       if (animationFrameRef.current) {
//         cancelAnimationFrame(animationFrameRef.current);
//       }
//       if (audioContextRef.current) {
//         audioContextRef.current.close();
//       }
//     };
//   }, [isRecording]);

//   const startVisualization = async (canvas, ctx) => {
//     if (!audioContextRef.current) {
//       audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
//     }

//     if (!analyserRef.current) {
//       analyserRef.current = audioContextRef.current.createAnalyser();
//       analyserRef.current.fftSize = 256;
//     }

//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//       const source = audioContextRef.current.createMediaStreamSource(stream);
//       source.connect(analyserRef.current);
//       drawBars(canvas, ctx);
//     } catch (error) {
//       console.error('Error accessing microphone:', error);
//     }
//   };

//   const stopVisualization = (canvas, ctx) => {
//     if (animationFrameRef.current) {
//       cancelAnimationFrame(animationFrameRef.current);
//     }
//     if (audioContextRef.current) {
//       audioContextRef.current.close();
//       audioContextRef.current = null;
//     }
//     analyserRef.current = null;
//     barPositionsRef.current = [];

//     drawStoppedBars(ctx, canvas.width, canvas.height);
//   };

//   const drawStoppedBars = (ctx, width, height) => {
//     const barCount = 83;
//     const barWidth = 3;
//     const barHeight = 5;
//     const barGap = 3;

//     ctx.clearRect(0, 0, width, height);

//     for (let i = 0; i < barCount; i++) {
//       const x = i * (barWidth + barGap);
//       const y = (height - barHeight) / 2;

//       ctx.fillStyle = 'rgba(0, 114, 153, 0.2)';
//       ctx.beginPath();
//       ctx.roundRect(x, y, barWidth, barHeight, 1.5);
//       ctx.fill();
//     }
//   };

//   const drawBars = (canvas, ctx) => {
//     const width = canvas.width;
//     const height = canvas.height;
//     const analyser = analyserRef.current;
//     const bufferLength = analyser.frequencyBinCount;
//     const dataArray = new Uint8Array(bufferLength);
//     const barWidth = 3;
//     const barGap = 3;
//     const scrollSpeed = 1;

//     const draw = () => {
//       animationFrameRef.current = requestAnimationFrame(() => draw(canvas, ctx));
//       analyser.getByteFrequencyData(dataArray);

//       ctx.clearRect(0, 0, width, height);

//       // Draw center line
//       ctx.beginPath();
//       ctx.strokeStyle = '#007299';
//       ctx.lineWidth = 1;
//       ctx.moveTo(width / 2, 0);
//       ctx.lineTo(width / 2, height);
//       ctx.stroke();

//       // Update bar positions
//       barPositionsRef.current = barPositionsRef.current.map(bar => ({
//         ...bar,
//         x: bar.x - scrollSpeed
//       })).filter(bar => bar.x + barWidth > 0);

//       // Add new bar if needed
//       if (barPositionsRef.current.length === 0 ||
//           width - barPositionsRef.current[barPositionsRef.current.length - 1].x > barWidth + barGap) {
//         barPositionsRef.current.push({
//           x: width,
//           height: 5 // Default height
//         });
//       }

//       // Draw bars
//       barPositionsRef.current.forEach((bar, index) => {
//         let barHeight = bar.height;

//         // Only update the rightmost bar based on audio input
//         if (index === barPositionsRef.current.length - 1) {
//           const dataIndex = Math.floor(bufferLength * 0.5); // Use mid-range frequency
//           barHeight = (dataArray[dataIndex] / 255) * height * 0.8;
//           bar.height = barHeight;
//         }

//         const y = (height - barHeight) / 2;

//         if (bar.x < width / 2) {
//           ctx.fillStyle = '#007299';
//         } else {
//           ctx.fillStyle = 'rgba(0, 114, 153, 0.2)';
//         }

//         ctx.beginPath();
//         ctx.roundRect(bar.x, y, barWidth, barHeight, 1.5);
//         ctx.fill();
//       });
//     };

//     draw();
//   };

//   return (
//     <div className="relative">
//       <canvas
//         ref={canvasRef}
//         style={{
//           width: '100%',
//           maxWidth: '517px',
//           height: 'auto'
//         }}
//       />
//       {isRecording && (
//         <div
//           className="absolute top-0 left-1/2 h-8 w-px bg-[#007299] -translate-x-1/2 -translate-y-full"
//           style={{ marginTop: '-1px' }}
//         />
//       )}
//     </div>
//   );
// };

// export default WaveformVisualizer;

import React, { useRef, useEffect } from 'react';

const WaveformVisualizer = ({ isRecording, isPlaying, audioData }) => {
	const canvasRef = useRef(null);
	const audioContextRef = useRef(null);
	const analyserRef = useRef(null);
	const animationFrameRef = useRef(null);
	const dataArrayRef = useRef([]);
	const audioDataSourceRef = useRef(null);

	useEffect(() => {
		if (isRecording) {
			startVisualization();
		} else {
			stopVisualization();
		}

		return () => {
			stopVisualization();
		};
	}, [isRecording]);

	useEffect(() => {
		if (!audioData) {
			audioDataSourceRef.current = null;
		}
	}, [audioData]);

	useEffect(() => {
		if (isPlaying) {
			startVisualization();
		} else {
			stopVisualizationForRecordedPlayback();
		}

		return () => {
			stopVisualizationForRecordedPlayback();
		};
	}, [isPlaying]);

	const startVisualization = async () => {
		if (!audioContextRef.current) {
			audioContextRef.current = new (window.AudioContext ||
				window.webkitAudioContext)();
		}

		if (!analyserRef.current) {
			analyserRef.current = audioContextRef.current.createAnalyser();
			analyserRef.current.fftSize = 256;
		}

		try {
			if (isRecording) {
				const stream = await navigator.mediaDevices.getUserMedia({
					audio: true,
				});
				const source =
					audioContextRef.current.createMediaStreamSource(stream);
				source.connect(analyserRef.current);
				drawBars();
			} else if (isPlaying && audioData) {
				// Playback visualization

				if (!audioDataSourceRef.current) {
					// Step 2: Create and store MediaElementAudioSourceNode in the ref
					audioDataSourceRef.current =
						audioContextRef.current.createMediaElementSource(
							audioData,
						);
				}
				audioDataSourceRef.current.connect(analyserRef.current);
				analyserRef.current.connect(
					audioContextRef.current.destination,
				);

				audioData.play();
				drawBars();
			}
		} catch (error) {
			console.error('Error accessing microphone:', error);
		}
	};

	const stopVisualization = () => {
		if (animationFrameRef.current) {
			cancelAnimationFrame(animationFrameRef.current);
		}
		if (audioContextRef.current) {
			audioContextRef.current.close();
			audioContextRef.current = null;
		}
		analyserRef.current = null;

		// Draw stopped state
		const canvas = canvasRef.current;
		if (canvas) {
			const ctx = canvas.getContext('2d');
			if (ctx) {
				ctx.clearRect(0, 0, canvas.width, canvas.height);
				drawStoppedBars(ctx, canvas.width, canvas.height);
			}
		}
	};

	const stopVisualizationForRecordedPlayback = () => {
		if (animationFrameRef.current) {
			cancelAnimationFrame(animationFrameRef.current);
			animationFrameRef.current = null;
		}

		// Draw stopped state
		const canvas = canvasRef.current;
		if (canvas) {
			const ctx = canvas.getContext('2d');
			if (ctx) {
				ctx.clearRect(0, 0, canvas.width, canvas.height);
				drawStoppedBars(ctx, canvas.width, canvas.height);
			}
		}
	};

	const drawStoppedBars = (ctx, width, height) => {
		const barCount = 83; // Number of bars to match the SVG
		const barWidth = 3;
		const barHeight = 5;
		const barGap = 3;

		ctx.clearRect(0, 0, width, height);

		for (let i = 0; i < barCount; i++) {
			const x = i * (barWidth + barGap);
			const y = (height - barHeight) / 2;

			ctx.fillStyle = 'rgba(0, 114, 153, 0.2)';
			ctx.beginPath();
			ctx.roundRect(x, y, barWidth, barHeight, 1.5);
			ctx.fill();
		}
	};

	const drawBars = () => {
		const canvas = canvasRef.current;
		const analyser = analyserRef.current;

		if (!canvas || !analyser) return;

		const ctx = canvas.getContext('2d');
		if (!ctx) return;

		const width = canvas.width;
		const height = canvas.height;
		const bufferLength = analyser.frequencyBinCount;
		const dataArray = new Uint8Array(bufferLength);
		const barWidth = 3;
		const barGap = 3;
		const totalBars = Math.floor(width / (barWidth + barGap));

		let offset = 0;

		const draw = () => {
			animationFrameRef.current = requestAnimationFrame(draw);
			analyser.getByteFrequencyData(dataArray);
			ctx.clearRect(0, 0, width, height);

			// Draw center line
			ctx.beginPath();
			ctx.strokeStyle = '#007299';
			ctx.lineWidth = 1;
			ctx.moveTo(width / 2, 0);
			ctx.lineTo(width / 2, height);
			ctx.stroke();

			// Draw bars
			for (let i = 0; i < totalBars; i++) {
				const x = width - (i * (barWidth + barGap) + offset);
				const dataIndex = Math.floor((i * bufferLength) / totalBars);
				const value = dataArray[dataIndex];
				const barHeight = Math.max((value / 255) * height * 0.8, 5); // Minimum height of 5px
				const y = (height - barHeight) / 2;

				if (x < width / 2) {
					ctx.fillStyle = '#007299';
				} else {
					ctx.fillStyle = 'rgba(0, 114, 153, 0.2)';
				}

				ctx.beginPath();
				ctx.roundRect(x, y, barWidth, barHeight, 1.5);
				ctx.fill();
			}

			// Update offset for scrolling effect
			offset += 1;
			if (offset >= barWidth + barGap) {
				offset = 0;
			}
		};

		draw();
	};

	return (
		<div className="w-100 text-center">
			<canvas
				ref={canvasRef}
				width={517}
				height={100}
				style={{
					width: '100%',
					maxWidth: '517px',
					height: 'auto',
				}}
			/>
			{(isRecording || isPlaying) && (
				<div
					className="absolute top-0 left-1/2 h-8 w-px bg-[#007299] -translate-x-1/2 -translate-y-full"
					style={{ marginTop: '-1px' }}
				/>
			)}
			{/* {isRecording ||
				 (
					<div
						className="absolute top-0 left-1/2 h-8 w-px bg-[#007299] -translate-x-1/2 -translate-y-full"
						style={{ marginTop: '-1px' }}
					/>
				)} */}
		</div>
	);
};

export default WaveformVisualizer;
