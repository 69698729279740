import styled from 'styled-components';
import '../../styles/GlobalStyle';
import {
	Select,
	Card,
	Button,
	Radio,
	Divider,
	Input,
	Upload,
	Avatar,
} from 'antd';
import Lottie from 'lottie-react';
import { UploadOutlined } from '@ant-design/icons';

export const AipaMobileBody = styled.section`
	width: 100%;
	position: relative;
	${'' /* margin-top: -80px; */}
	font-family: ${({ theme }) => theme.colors.fontInter};
	.logoWrap {
		background: #fff;
		width: 50px;
		height: 50px;
		border-radius: 40px;
		box-shadow: 3px 3px 10px 3px #dddddd;
	}
	.userWrap {
		float: right;
		background: #c0e0e7;
		width: 50px;
		height: 50px;
		border-radius: 40px;
		text-align: center;
		align-items: center;
		padding: 5px;
		overflow: hidden;
		img {
			border-radius: 40px;
			box-shadow: 1px 1px 2px 1px #dddddd;
		}
	}
	@media only screen and (min-width: 992.99px) {
		display: none;
	}
`;
export const XHeader = styled.section`
	width: 100%;
	positive: relative;
	padding: 15px;
	@media only screen and (min-width: 992.99px) {
		display: none;
	}
`;
export const XTitleHeader = styled.h1`
	font-family: ${({ theme }) => theme.colors.fontInter};
	margin: 0px;
	width: 100%;
	positive: relative;
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 48px;
	color: #0e4c88;
`;
export const XLottieAipaLoader = styled.section`
	width: 100%;
	position: relative;
	margin-top: -15px;
	@media only screen and (min-width: 992.99px) {
		margin-top: 0px;
	}
`;
export const XLottieAipa = styled(Lottie)`
	position: relative;
	background: transparent;
	width: 100%;
`;
export const XDecodexTitle = styled.h1`
	margin: 0px;
	width: 100%;
	position: relative;
	text-align: center;
	font-style: normal;
	font-weight: 500;
	font-size: 40px;
	line-height: 40px;
	letter-spacing: 0.025em;
	text-transform: capitalize;
	color: #007299;
	font-family: ClashDisplay-Medium !important;
	@media only screen and (min-width: 992.99px) {
		color: #002141;
	}
`;
export const ParaDeocdex = styled.p`
	margin: 0px;
	width: 100%;
	position: relative;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	text-align: center;
	color: #002141;
	font-family: ${({ theme }) => theme.colors.fontInter};
	@media only screen and (min-width: 992.99px) {
		color: #002141;
	}
`;
export const XSelectIcons = styled(Select)`
	width: 100%;
	position: relative;
	background: transparent;
	.ant-select {
		background: #fff !important;
	}
	.ant-select-selection-placeholder {
		color: #000 !important;
	}
	@media only screen and (min-width: 992.99px) {
		box-shadow: 0px 0px 15px rgba(0, 114, 153, 0.05);
		height: 50px !important;
	}
	.ant-select-selection-item {
		font-weight: 500 !important;
		font-size: 16px !important;
		line-height: 20px !important;
		color: #000 !important;
		@media only screen and (min-width: 992.99px) {
			font-size: 16px !important;
			line-height: 20px !important;
		}
	}
	.ant-select-selector {
		background: #fff !important;
		padding-left: 40px !important;
		left: 0px !important;
		border-color: #dcdcdc !important;
	}
	.ant-select-selection-search {
		padding-left: 40px !important;
		left: 0px !important;
		@media only screen and (min-width: 992.99px) {
			background: rgba(255, 255, 255, 0.3);
			border-radius: 15px !important;
		}
	}
`;
export const AddInputIcon = styled.span`
	position: absolute;
	left: 15px;
	top: 25px;
	transform: translateY(-50%);
	z-index: 999;
	font-size: 16px;
	img {
		max-width: 16px;
		margin-top: -2px;
	}
	@media only screen and (min-width: 992.99px) {
		top: 25px;
		left: 22px;
	}
`;
export const RecordCard = styled(Card)`
	width: 100%;
	position: relative;
	background: rgba(255, 255, 255, 0.3);
	box-shadow: 0px 0px 15px rgba(0, 114, 153, 0.05);
	border-radius: 10px;
	.timmer {
		width: 100%;
		position: relative;
		text-align: center;
		span {
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;
			color: #007299;
			background: #ffffff;
			border: 1px solid #007299;
			box-shadow: inset -2px -1px 4px #69eacb;
			border-radius: 3px;
			padding: 3px 10px;
		}
	}
	.actionBtns {
		width: 100%;
		position: relative;
		text-align: center;
	}
`;
export const XActionBtns = styled(Button)`
	background: rgba(0, 114, 153, 0.8);
	&:disabled {
		background: rgba(0, 114, 153, 0.2) !important;
		svg {
			color: #fff;
		}
	}
	&:hover,
	&:active {
		background: rgba(0, 114, 153, 0.8) !important;
		color: #fff !important;
	}
`;
export const XActionPlay = styled(Button)`
	margin:0px 10px;
	background: linear-gradient(90deg, #02759B 0%, #7EE6E9 100.01%); !important;
	border:none !important;
	min-width:60px !important;
	min-height:60px !important;
	box-shadow: 0px 10px 10px #7EE6E9;
	&:hover,
	&:active {
		background: linear-gradient(
			90deg,
			#02759b 0%,
			#7ee6e9 100.01%
		) !important;
		color: #fff !important;
		border-color: #02759b !important;
	}
	svg{
		font-size:2rem;
	}
`;
export const XShazaamBtn = styled(Button)`
	margin-left: 0px !important;
	background: #0e4c88 !important;
	border: 2px solid #f2f2f2 !important;
	box-shadow: 0px 156px 94px rgba(14, 76, 136, 0.1),
		0px 69px 69px rgba(14, 76, 136, 0.1),
		0px 17px 38px rgba(14, 76, 136, 0.1) !important;
	border-radius: 999px;
	font-weight: 500;
	font-size: 14px !important;
	line-height: 24px;
	letter-spacing: -0.02em;
	color: #ffffff;
	height: auto !important;
	padding: 10px 35px !important;
	&:hover,
	&:active {
		background: #0e4c88 !important;
		border: 2px solid #f2f2f2 !important;
		box-shadow: 0px 156px 94px rgba(14, 76, 136, 0.1),
			0px 69px 69px rgba(14, 76, 136, 0.1),
			0px 17px 38px rgba(14, 76, 136, 0.1) !important;
		border-color: #02759b !important;
	}
`;

export const AipaDesktopBody = styled.section`
	width: 100%;
	position: relative;
	margin-bottom: 15px;
	font-family: ${({ theme }) => theme.colors.fontInter};
	@media only screen and (max-width: 992.99px) {
		display: none;
	}
`;
export const XRadio = styled(Radio)`
	width: 100%;
	position: relative;
	background: rgba(255, 255, 255, 0.3) !important;
	box-shadow: 0px 0px 15px rgba(0, 114, 153, 0.05) !important;
	border-radius: 15px !important;
	border: 1px solid #dbdbdb;
	padding: 15px;
	margin-bottom: 7px;
	color: ${({ theme }) => theme.colors.menu2};
	font-weight: 400;
	font-size: 16px !important;
	line-height: 20px !important;

	&:last-child {
		margin-bottom: 0px;
	}
	span.ant-radio + * {
		padding-left: 10px !important;
	}
	.ant-form-item-control-input {
		min-height: auto !important;
	}
	.ant-radio-checked .ant-radio-inner {
		background: #007299;
		border-color: #007299;
	}
`;
export const XDivider = styled(Divider)`
	width: 100%;
	position: relative;
`;
export const XCardDesktop = styled(Card)`
	width: 100%;
	position: relative;
	height: 100%;
	background: rgba(255, 255, 255, 0.5);
	box-shadow: 0px 0px 15px rgba(0, 114, 153, 0.05);
	border-radius: 10px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	min-height: 327px !important;
	&.bodyPadding .ant-card-body {
		padding: 0px !important;
	}
	&.bodyHeight {
		min-height: auto !important;
	}
	.uploadClinicalDocument {
		height: 325px !important;
	}
	.ant-upload-list-item-file {
		position: relative;
		text-align: center !important;
	}
	.anticon-file {
		position: absolute;
		width: 30px;
		top: 35%;
		left: 0px;
		right: 0px;
		margin: 0px auto;
	}
	.ant-upload-list-item-name {
		position: absolute;
		width: 100%;
		top: 50%;
		left: 0px;
		right: 0px;
		margin: 0px auto;
	}
	p.uploadClinicalDocumentText {
		margin: 0px;
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		color: #002141;
		opacity: 0.5;
	}
	.ant-upload-picture-card-wrapper {
		height: 100%;
	}
	.ant-upload-list-picture-card {
		width: 100%;
		position: absolute;
		display: block !important;
		top: 0;
	}
	.ant-upload-list-item-container {
		background: #fff;
		width: 100% !important;
		position: relative;
		margin: 0px !important;
		min-height: 325px !important;
	}
	.timmer {
		width: 100%;
		position: relative;
		text-align: center;
		span {
			font-style: normal;
			font-weight: 500;
			font-size: 26px;
			line-height: 34px;
			color: #007299;
			background: #ffffff;
			border: 1px solid #007299;
			box-shadow: inset -2px -1px 4px #69eacb;
			border-radius: 3px;
			padding: 0px 15px;
		}
	}
	.actionBtns {
		width: 100%;
		position: relative;
		text-align: center;
	}
`;
export const XCardDesktop2 = styled(Card)`
	width: 100%;
	position: relative;
	height: 100%;
	background: rgba(255, 255, 255, 0.5);
	box-shadow: 0px 0px 15px rgba(0, 114, 153, 0.05);
	border-radius: 10px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	min-height: 188px !important;
	&.bodyPadding .ant-card-body {
		padding: 0px !important;
	}
	&.bodyHeight {
		min-height: auto !important;
	}
	.uploadClinicalDocument {
		height: 208px !important;
	}
	.ant-upload-list-item-file {
		position: relative;
		text-align: center !important;
	}
	.anticon-file {
		position: absolute;
		width: 30px;
		top: 35%;
		left: 0px;
		right: 0px;
		margin: 0px auto;
	}
	.ant-upload-list-item-name {
		position: absolute;
		width: 100%;
		top: 50%;
		left: 0px;
		right: 0px;
		margin: 0px auto;
	}
	p.uploadClinicalDocumentText {
		margin: 0px;
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		color: #002141;
		opacity: 0.5;
	}
	.ant-upload-picture-card-wrapper {
		height: 100%;
	}
	.ant-upload-list-picture-card {
		width: 100%;
		position: absolute;
		display: block !important;
		top: 0;
	}
	.ant-upload-list-item-container {
		background: #fff;
		width: 100% !important;
		position: relative;
		margin: 0px !important;
		min-height: 200px !important;

	}
	.timmer {
		width: 100%;
		position: relative;
		text-align: center;
		span {
			font-style: normal;
			font-weight: 500;
			font-size: 26px;
			line-height: 34px;
			color: #007299;
			background: #ffffff;
			border: 1px solid #007299;
			box-shadow: inset -2px -1px 4px #69eacb;
			border-radius: 3px;
			padding: 0px 15px;
		}
	}
	.actionBtns {
		width: 100%;
		position: relative;
		text-align: center;
	}
`;
export const XInputTextArea = styled(Input)`
	width: 100%;
	position: relative;
`;
export const XUpload = styled(Upload)`
	width: 100%;
	position: relative;
`;
export const XAvatar = styled(Avatar)`
	background: rgba(34, 71, 164, 0.1) !important;
	padding: 5px;
	img {
		filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	}
`;
// export const PlaceholderText = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: 8px 12px;
//     border: 1px solid #d9d9d9;
//     border-radius: 4px;
//     background-color: #fafafa;
//     color: #bfbfbf;
//     cursor: pointer;
//     width: 100%;
//     box-sizing: border-box;
// `;

export const UploadContainer = styled.div`
    width: 100%;
  padding:10px;
  cursor:pointer;
    text-align: center;
`;

// Styled component for the icon
export const LargeIcon = styled(UploadOutlined)`
    font-size: 32px; // Increase icon size
    color: #000; // Set icon color
    opacity: 0.5; // Set icon opacity
    margin-bottom: 16px; // Add space between icon and text
`;

// Styled component for the text
export const PlaceholderText = styled.div`
    color: black;
    opacity: 0.5;
`;

export const UploadPolicyContainer = styled.div`
    width: 100%;
	display:flex;
  padding:13px;
  cursor:pointer;
    text-align: center;
	justify-content:space-between;
	align-items:center;
`;
export const SmallIcon=styled(UploadOutlined)`
    font-size: 24px; // Increase icon size
    color: #000; // Set icon color
    opacity: 0.5; 
`;
