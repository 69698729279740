import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Card,
	Result,
	Input,
	Divider,
	Typography,
	notification,
	Button,
} from 'antd';

import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import {
	XForm,
	XContainer,
	XButton,
	FilterButton,
	XSearch,
	XSelect,
	OutlineCircle,
	XNextPrevBtn,
} from '../../styles/GlobalStyle';
import { XRadio } from '../CaseForm/CaseFormStyle';
import CaseSearch from '../../components/CaseSearch/CaseSearch';
// actions

import {
	editPayerConfigurationApi,
	editPayerConfigurationReset,
} from '../../actions/editPayerConfigurationAction';

import { checkModulePermissions, truncateString } from '../../utils/helper';
import { dateFilters } from '../../utils/constants';
import HeaderFile from '../../components/Header/HeaderFile';
import DataTable from '../../components/DataTable/DataTable';
import Header from '../../components/Header/Header';
import { XButtonImg } from '../Manage/ManageStyle';
import { filterByLabel } from '../../utils/formFunctions';
import { FormDataContext } from './PayerConfigurationForm';
const { Text, Link } = Typography;

const Step2 = (props) => {
	const {
		editPayerConfiguration,
		editPayerConfigurationReset,
		editPayerConfigurationState,
	} = props;

	const { form, formDataState, currentState, editModeState } =
		useContext(FormDataContext);

	useEffect(() => {
		if (editPayerConfigurationState.apiState == 'success') {
			// currentState.setCurrent((old)=>{
			//     return old+1;
			// });

			notification.success({
				message: editPayerConfigurationState.message,
			});
			editPayerConfigurationReset();
		}
	}, [editPayerConfigurationState]);

	const handlePrev = () => {
		currentState.setCurrent((old) => {
			return old - 1;
		});
	};

	const handleNext = () => {
		form.validateFields([
			'pre_service-rc-organization_determination-timeline',
			'pre_service-rc-organization_determination-timeline_type',
			'pre_service-rc-organization_determination-in_process',
			'pre_service-rc-organization_determination-at_risk',
			'pre_service-rc-organization_determination-escalation',
			'pre_service-rc-organization_determination-untimely',
			'pre_service-rc-first_appeal-timeline',
			'pre_service-rc-first_appeal-timeline_type',
			'pre_service-rc-first_appeal-in_process',
			'pre_service-rc-first_appeal-at_risk',
			'pre_service-rc-first_appeal-escalation',
			'pre_service-rc-first_appeal-untimely',
			'pre_service-rc-second_appeal-timeline',
			'pre_service-rc-second_appeal-timeline_type',
			'pre_service-rc-second_appeal-in_process',
			'pre_service-rc-second_appeal-at_risk',
			'pre_service-rc-second_appeal-escalation',
			'pre_service-rc-second_appeal-untimely',
			'pre_service-rc-third_appeal-timeline',
			'pre_service-rc-third_appeal-timeline_type',
			'pre_service-rc-third_appeal-in_process',
			'pre_service-rc-third_appeal-at_risk',
			'pre_service-rc-third_appeal-escalation',
			'pre_service-rc-third_appeal-untimely',
			'pre_service-rc-fourth_appeal-timeline',
			'pre_service-rc-fourth_appeal-timeline_type',
			'pre_service-rc-fourth_appeal-in_process',
			'pre_service-rc-fourth_appeal-at_risk',
			'pre_service-rc-fourth_appeal-escalation',
			'pre_service-rc-fourth_appeal-untimely',
			'pre_service-rc-judicial_review-timeline',
			'pre_service-rc-judicial_review-timeline_type',
			'pre_service-rc-judicial_review-in_process',
			'pre_service-rc-judicial_review-at_risk',
			'pre_service-rc-judicial_review-escalation',
			'pre_service-rc-judicial_review-untimely',
			'pre_service-ma_standard-organization_determination-timeline',
			'pre_service-ma_standard-organization_determination-timeline_type',
			'pre_service-ma_standard-organization_determination-in_process',
			'pre_service-ma_standard-organization_determination-at_risk',
			'pre_service-ma_standard-organization_determination-untimely',
			'pre_service-ma_standard-first_appeal-timeline',
			'pre_service-ma_standard-first_appeal-timeline_type',
			'pre_service-ma_standard-first_appeal-in_process',
			'pre_service-ma_standard-first_appeal-at_risk',
			'pre_service-ma_standard-first_appeal-untimely',
			'pre_service-ma_standard-second_appeal-timeline',
			'pre_service-ma_standard-second_appeal-timeline_type',
			'pre_service-ma_standard-second_appeal-in_process',
			'pre_service-ma_standard-second_appeal-at_risk',
			'pre_service-ma_standard-second_appeal-untimely',
			'pre_service-ma_standard-third_appeal-timeline',
			'pre_service-ma_standard-third_appeal-timeline_type',
			'pre_service-ma_standard-third_appeal-in_process',
			'pre_service-ma_standard-third_appeal-at_risk',
			'pre_service-ma_standard-third_appeal-untimely',
			'pre_service-ma_standard-fourth_appeal-timeline',
			'pre_service-ma_standard-fourth_appeal-timeline_type',
			'pre_service-ma_standard-fourth_appeal-in_process',
			'pre_service-ma_standard-fourth_appeal-at_risk',
			'pre_service-ma_standard-fourth_appeal-untimely',
			'pre_service-ma_standard-judicial_review-timeline',
			'pre_service-ma_standard-judicial_review-timeline_type',
			'pre_service-ma_standard-judicial_review-in_process',
			'pre_service-ma_standard-judicial_review-at_risk',
			'pre_service-ma_standard-judicial_review-untimely',
		])
			.then((values) => {
				formDataState.setFormData((old) => {
					return { ...old, ...values };
				});
				currentState.setCurrent((old) => {
					return old + 1;
				});
			})
			.catch((error) => {
				return;
			});
	};

	const handleUpdate = () => {
		form.validateFields([
			'pre_service-rc-organization_determination-timeline',
			'pre_service-rc-organization_determination-timeline_type',
			'pre_service-rc-organization_determination-in_process',
			'pre_service-rc-organization_determination-at_risk',
			'pre_service-rc-organization_determination-escalation',
			'pre_service-rc-organization_determination-untimely',
			'pre_service-rc-first_appeal-timeline',
			'pre_service-rc-first_appeal-timeline_type',
			'pre_service-rc-first_appeal-in_process',
			'pre_service-rc-first_appeal-at_risk',
			'pre_service-rc-first_appeal-escalation',
			'pre_service-rc-first_appeal-untimely',
			'pre_service-rc-second_appeal-timeline',
			'pre_service-rc-second_appeal-timeline_type',
			'pre_service-rc-second_appeal-in_process',
			'pre_service-rc-second_appeal-at_risk',
			'pre_service-rc-second_appeal-escalation',
			'pre_service-rc-second_appeal-untimely',
			'pre_service-rc-third_appeal-timeline',
			'pre_service-rc-third_appeal-timeline_type',
			'pre_service-rc-third_appeal-in_process',
			'pre_service-rc-third_appeal-at_risk',
			'pre_service-rc-third_appeal-escalation',
			'pre_service-rc-third_appeal-untimely',
			'pre_service-rc-fourth_appeal-timeline',
			'pre_service-rc-fourth_appeal-timeline_type',
			'pre_service-rc-fourth_appeal-in_process',
			'pre_service-rc-fourth_appeal-at_risk',
			'pre_service-rc-fourth_appeal-escalation',
			'pre_service-rc-fourth_appeal-untimely',
			'pre_service-rc-judicial_review-timeline',
			'pre_service-rc-judicial_review-timeline_type',
			'pre_service-rc-judicial_review-in_process',
			'pre_service-rc-judicial_review-at_risk',
			'pre_service-rc-judicial_review-escalation',
			'pre_service-rc-judicial_review-untimely',
			'pre_service-ma_standard-organization_determination-timeline',
			'pre_service-ma_standard-organization_determination-timeline_type',
			'pre_service-ma_standard-organization_determination-in_process',
			'pre_service-ma_standard-organization_determination-at_risk',
			'pre_service-ma_standard-organization_determination-untimely',
			'pre_service-ma_standard-first_appeal-timeline',
			'pre_service-ma_standard-first_appeal-timeline_type',
			'pre_service-ma_standard-first_appeal-in_process',
			'pre_service-ma_standard-first_appeal-at_risk',
			'pre_service-ma_standard-first_appeal-untimely',
			'pre_service-ma_standard-second_appeal-timeline',
			'pre_service-ma_standard-second_appeal-timeline_type',
			'pre_service-ma_standard-second_appeal-in_process',
			'pre_service-ma_standard-second_appeal-at_risk',
			'pre_service-ma_standard-second_appeal-untimely',
			'pre_service-ma_standard-third_appeal-timeline',
			'pre_service-ma_standard-third_appeal-timeline_type',
			'pre_service-ma_standard-third_appeal-in_process',
			'pre_service-ma_standard-third_appeal-at_risk',
			'pre_service-ma_standard-third_appeal-untimely',
			'pre_service-ma_standard-fourth_appeal-timeline',
			'pre_service-ma_standard-fourth_appeal-timeline_type',
			'pre_service-ma_standard-fourth_appeal-in_process',
			'pre_service-ma_standard-fourth_appeal-at_risk',
			'pre_service-ma_standard-fourth_appeal-untimely',
			'pre_service-ma_standard-judicial_review-timeline',
			'pre_service-ma_standard-judicial_review-timeline_type',
			'pre_service-ma_standard-judicial_review-in_process',
			'pre_service-ma_standard-judicial_review-at_risk',
			'pre_service-ma_standard-judicial_review-untimely',
		])
			.then((values) => {
				formDataState.setFormData((old) => {
					return { ...old, ...values };
				});

				editPayerConfiguration({
					update_type: 'pre_service',
					id: formDataState.formData.id,
					...values,
				});
			})
			.catch((error) => {
				return;
			});
	};

	return (
		<>
			<XForm
				form={form}
				name="timelineConfigFrom"
				layout="vertical"
				autoComplete="off"
			>
				<Row gutter={(15, 30)} className="mt-4">
					<Col xs={24}>
						<p className="modalPara1 text-dark m-0">
							<strong>RC</strong>
						</p>
					</Col>
				</Row>

				<Row gutter={(15, 15)}>
					<Col xs={24}>
						<Divider
							orientation="left"
							orientationMargin="0"
							className="mb-4"
						>
							<Text>Organization Determination</Text>
						</Divider>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-organization_determination-timeline"
							label="Timeline"
							rules={[
								{
									required: true,
									message: 'Please enter timeline',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Timeline"
								name="pre_service-rc-organization_determination-timeline"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-organization_determination-timeline_type"
							label="Timeline Type"
							rules={[
								{
									required: true,
									message: 'Please select timeline type',
								},
							]}
						>
							<XRadio.Group size="large" value={'days'}>
								<XRadio value={'hours'}>Hours</XRadio>
								<XRadio value={'days'}>Days</XRadio>
							</XRadio.Group>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-organization_determination-in_process"
							label="In Process"
							rules={[
								{
									required: true,
									message: 'Please enter In process start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter In Process Start"
								name="pre_service-rc-organization_determination-in_process"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-organization_determination-at_risk"
							label="At Risk"
							rules={[
								{
									required: true,
									message: 'Please enter At Risk start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter At Risk Start"
								name="pre_service-rc-organization_determination-at_risk"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-organization_determination-escalation"
							label="Escalation"
							rules={[
								{
									required: true,
									message: 'Please enter Escalation start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Escalation Start"
								name="pre_service-rc-organization_determination-escalation"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-organization_determination-untimely"
							label="Untimely"
							rules={[
								{
									required: true,
									message: 'Please enter Untimely start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Untimely Start"
								name="pre_service-rc-organization_determination-untimely"
								type="number"
							/>
						</XForm.Item>
					</Col>
				</Row>

				<Row gutter={(15, 15)}>
					<Col xs={24}>
						<Divider
							orientation="left"
							orientationMargin="0"
							className="mb-4"
						>
							<Text>First Appeal</Text>
						</Divider>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-first_appeal-timeline"
							label="Timeline"
							rules={[
								{
									required: true,
									message: 'Please enter timeline',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Timeline"
								name="pre_service-rc-first_appeal-timeline"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-first_appeal-timeline_type"
							label="Timeline Type"
							rules={[
								{
									required: true,
									message: 'Please select timeline type',
								},
							]}
						>
							<XRadio.Group size="large" value={'days'}>
								<XRadio value={'hours'}>Hours</XRadio>
								<XRadio value={'days'}>Days</XRadio>
							</XRadio.Group>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-first_appeal-in_process"
							label="In Process"
							rules={[
								{
									required: true,
									message: 'Please enter In process start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter In Process Start"
								name="pre_service-rc-first_appeal-in_process"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-first_appeal-at_risk"
							label="At Risk"
							rules={[
								{
									required: true,
									message: 'Please enter At Risk start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter At Risk Start"
								name="pre_service-rc-first_appeal-at_risk"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-first_appeal-escalation"
							label="Escalation"
							rules={[
								{
									required: true,
									message: 'Please enter Escalation start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Escalation Start"
								name="pre_service-rc-first_appeal-escalation"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-first_appeal-untimely"
							label="Untimely"
							rules={[
								{
									required: true,
									message: 'Please enter Untimely start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Untimely Start"
								name="pre_service-rc-first_appeal-untimely"
								type="number"
							/>
						</XForm.Item>
					</Col>
				</Row>

				<Row gutter={(15, 15)}>
					<Col xs={24}>
						<Divider
							orientation="left"
							orientationMargin="0"
							className="mb-4"
						>
							<Text>Second Appeal</Text>
						</Divider>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-second_appeal-timeline"
							label="Timeline"
							rules={[
								{
									required: true,
									message: 'Please enter timeline',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Timeline"
								name="pre_service-rc-second_appeal-timeline"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-second_appeal-timeline_type"
							label="Timeline Type"
							rules={[
								{
									required: true,
									message: 'Please select timeline type',
								},
							]}
						>
							<XRadio.Group size="large" value={'days'}>
								<XRadio value={'hours'}>Hours</XRadio>
								<XRadio value={'days'}>Days</XRadio>
							</XRadio.Group>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-second_appeal-in_process"
							label="In Process"
							rules={[
								{
									required: true,
									message: 'Please enter In process start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter In Process Start"
								name="pre_service-rc-second_appeal-in_process"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-second_appeal-at_risk"
							label="At Risk"
							rules={[
								{
									required: true,
									message: 'Please enter At Risk start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter At Risk Start"
								name="pre_service-rc-second_appeal-at_risk"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-second_appeal-escalation"
							label="Escalation"
							rules={[
								{
									required: true,
									message: 'Please enter Escalation start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Escalation Start"
								name="pre_service-rc-second_appeal-escalation"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-second_appeal-untimely"
							label="Untimely"
							rules={[
								{
									required: true,
									message: 'Please enter Untimely start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Untimely Start"
								name="pre_service-rc-second_appeal-untimely"
								type="number"
							/>
						</XForm.Item>
					</Col>
				</Row>

				<Row gutter={(15, 15)}>
					<Col xs={24}>
						<Divider
							orientation="left"
							orientationMargin="0"
							className="mb-4"
						>
							<Text>Third Appeal</Text>
						</Divider>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-third_appeal-timeline"
							label="Timeline"
							rules={[
								{
									required: true,
									message: 'Please enter timeline',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Timeline"
								name="pre_service-rc-third_appeal-timeline"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-third_appeal-timeline_type"
							label="Timeline Type"
							rules={[
								{
									required: true,
									message: 'Please select timeline type',
								},
							]}
						>
							<XRadio.Group size="large" value={'days'}>
								<XRadio value={'hours'}>Hours</XRadio>
								<XRadio value={'days'}>Days</XRadio>
							</XRadio.Group>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-third_appeal-in_process"
							label="In Process"
							rules={[
								{
									required: true,
									message: 'Please enter In process start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter In Process Start"
								name="pre_service-rc-third_appeal-in_process"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-third_appeal-at_risk"
							label="At Risk"
							rules={[
								{
									required: true,
									message: 'Please enter At Risk start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter At Risk Start"
								name="pre_service-rc-third_appeal-at_risk"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-third_appeal-escalation"
							label="Escalation"
							rules={[
								{
									required: true,
									message: 'Please enter Escalation start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Escalation Start"
								name="pre_service-rc-third_appeal-escalation"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-third_appeal-untimely"
							label="Untimely"
							rules={[
								{
									required: true,
									message: 'Please enter Untimely start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Untimely Start"
								name="pre_service-rc-third_appeal-untimely"
								type="number"
							/>
						</XForm.Item>
					</Col>
				</Row>

				<Row gutter={(15, 15)}>
					<Col xs={24}>
						<Divider
							orientation="left"
							orientationMargin="0"
							className="mb-4"
						>
							<Text>Fourth Appeal</Text>
						</Divider>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-fourth_appeal-timeline"
							label="Timeline"
							rules={[
								{
									required: true,
									message: 'Please enter timeline',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Timeline"
								name="pre_service-rc-fourth_appeal-timeline"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-fourth_appeal-timeline_type"
							label="Timeline Type"
							rules={[
								{
									required: true,
									message: 'Please select timeline type',
								},
							]}
						>
							<XRadio.Group size="large" value={'days'}>
								<XRadio value={'hours'}>Hours</XRadio>
								<XRadio value={'days'}>Days</XRadio>
							</XRadio.Group>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-fourth_appeal-in_process"
							label="In Process"
							rules={[
								{
									required: true,
									message: 'Please enter In process start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter In Process Start"
								name="pre_service-rc-fourth_appeal-in_process"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-fourth_appeal-at_risk"
							label="At Risk"
							rules={[
								{
									required: true,
									message: 'Please enter At Risk start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter At Risk Start"
								name="pre_service-rc-fourth_appeal-at_risk"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-fourth_appeal-escalation"
							label="Escalation"
							rules={[
								{
									required: true,
									message: 'Please enter Escalation start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Escalation Start"
								name="pre_service-rc-fourth_appeal-escalation"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-fourth_appeal-untimely"
							label="Untimely"
							rules={[
								{
									required: true,
									message: 'Please enter Untimely start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Untimely Start"
								name="pre_service-rc-fourth_appeal-untimely"
								type="number"
							/>
						</XForm.Item>
					</Col>
				</Row>

				<Row gutter={(15, 15)}>
					<Col xs={24}>
						<Divider
							orientation="left"
							orientationMargin="0"
							className="mb-4"
						>
							<Text>Judicial Review</Text>
						</Divider>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-judicial_review-timeline"
							label="Timeline"
							rules={[
								{
									required: true,
									message: 'Please enter timeline',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Timeline"
								name="pre_service-rc-judicial_review-timeline"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-judicial_review-timeline_type"
							label="Timeline Type"
							rules={[
								{
									required: true,
									message: 'Please select timeline type',
								},
							]}
						>
							<XRadio.Group size="large" value={'days'}>
								<XRadio value={'hours'}>Hours</XRadio>
								<XRadio value={'days'}>Days</XRadio>
							</XRadio.Group>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-judicial_review-in_process"
							label="In Process"
							rules={[
								{
									required: true,
									message: 'Please enter In process start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter In Process Start"
								name="pre_service-rc-judicial_review-in_process"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-judicial_review-at_risk"
							label="At Risk"
							rules={[
								{
									required: true,
									message: 'Please enter At Risk start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter At Risk Start"
								name="pre_service-rc-judicial_review-at_risk"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-judicial_review-escalation"
							label="Escalation"
							rules={[
								{
									required: true,
									message: 'Please enter Escalation start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Escalation Start"
								name="pre_service-rc-judicial_review-escalation"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-rc-judicial_review-untimely"
							label="Untimely"
							rules={[
								{
									required: true,
									message: 'Please enter Untimely start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Untimely Start"
								name="pre_service-rc-judicial_review-untimely"
								type="number"
							/>
						</XForm.Item>
					</Col>
				</Row>

				<Row gutter={(15, 30)} className="mt-4">
					<Col xs={24}>
						<p className="modalPara1 text-dark m-0">
							<strong>MA Standard</strong>
						</p>
					</Col>
				</Row>
				<Row gutter={(15, 15)}>
					<Col xs={24}>
						<Divider
							orientation="left"
							orientationMargin="0"
							className="mb-4"
						>
							<Text>Organization Determination</Text>
						</Divider>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-organization_determination-timeline"
							label="Timeline"
							rules={[
								{
									required: true,
									message: 'Please enter timeline',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Timeline"
								name="pre_service-ma_standard-organization_determination-timeline"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-organization_determination-timeline_type"
							label="Timeline Type"
							rules={[
								{
									required: true,
									message: 'Please select timeline type',
								},
							]}
						>
							<XRadio.Group size="large" value={'days'}>
								<XRadio value={'hours'}>Hours</XRadio>
								<XRadio value={'days'}>Days</XRadio>
							</XRadio.Group>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-organization_determination-in_process"
							label="In Process"
							rules={[
								{
									required: true,
									message: 'Please enter In process start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter In Process Start"
								name="pre_service-ma_standard-organization_determination-in_process"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-organization_determination-at_risk"
							label="At Risk"
							rules={[
								{
									required: true,
									message: 'Please enter At Risk start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter At Risk Start"
								name="pre_service-ma_standard-organization_determination-at_risk"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-organization_determination-untimely"
							label="Untimely"
							rules={[
								{
									required: true,
									message: 'Please enter Untimely start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Untimely Start"
								name="pre_service-ma_standard-organization_determination-untimely"
								type="number"
							/>
						</XForm.Item>
					</Col>
				</Row>
				<Row gutter={(15, 15)}>
					<Col xs={24}>
						<Divider
							orientation="left"
							orientationMargin="0"
							className="mb-4"
						>
							<Text>First Appeal</Text>
						</Divider>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-first_appeal-timeline"
							label="Timeline"
							rules={[
								{
									required: true,
									message: 'Please enter timeline',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Timeline"
								name="pre_service-ma_standard-first_appeal-timeline"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-first_appeal-timeline_type"
							label="Timeline Type"
							rules={[
								{
									required: true,
									message: 'Please select timeline type',
								},
							]}
						>
							<XRadio.Group size="large" value={'days'}>
								<XRadio value={'hours'}>Hours</XRadio>
								<XRadio value={'days'}>Days</XRadio>
							</XRadio.Group>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-first_appeal-in_process"
							label="In Process"
							rules={[
								{
									required: true,
									message: 'Please enter In process start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter In Process Start"
								name="pre_service-ma_standard-first_appeal-in_process"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-first_appeal-at_risk"
							label="At Risk"
							rules={[
								{
									required: true,
									message: 'Please enter At Risk start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter At Risk Start"
								name="pre_service-ma_standard-first_appeal-at_risk"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-first_appeal-untimely"
							label="Untimely"
							rules={[
								{
									required: true,
									message: 'Please enter Untimely start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Untimely Start"
								name="pre_service-ma_standard-first_appeal-untimely"
								type="number"
							/>
						</XForm.Item>
					</Col>
				</Row>

				<Row gutter={(15, 15)}>
					<Col xs={24}>
						<Divider
							orientation="left"
							orientationMargin="0"
							className="mb-4"
						>
							<Text>Second Appeal</Text>
						</Divider>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-second_appeal-timeline"
							label="Timeline"
							rules={[
								{
									required: true,
									message: 'Please enter timeline',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Timeline"
								name="pre_service-ma_standard-second_appeal-timeline"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-second_appeal-timeline_type"
							label="Timeline Type"
							rules={[
								{
									required: true,
									message: 'Please select timeline type',
								},
							]}
						>
							<XRadio.Group size="large" value={'days'}>
								<XRadio value={'hours'}>Hours</XRadio>
								<XRadio value={'days'}>Days</XRadio>
							</XRadio.Group>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-second_appeal-in_process"
							label="In Process"
							rules={[
								{
									required: true,
									message: 'Please enter In process start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter In Process Start"
								name="pre_service-ma_standard-second_appeal-in_process"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-second_appeal-at_risk"
							label="At Risk"
							rules={[
								{
									required: true,
									message: 'Please enter At Risk start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter At Risk Start"
								name="pre_service-ma_standard-second_appeal-at_risk"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-second_appeal-untimely"
							label="Untimely"
							rules={[
								{
									required: true,
									message: 'Please enter Untimely start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Untimely Start"
								name="pre_service-ma_standard-second_appeal-untimely"
								type="number"
							/>
						</XForm.Item>
					</Col>
				</Row>

				<Row gutter={(15, 15)}>
					<Col xs={24}>
						<Divider
							orientation="left"
							orientationMargin="0"
							className="mb-4"
						>
							<Text>Third Appeal</Text>
						</Divider>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-third_appeal-timeline"
							label="Timeline"
							rules={[
								{
									required: true,
									message: 'Please enter timeline',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Timeline"
								name="pre_service-ma_standard-third_appeal-timeline"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-third_appeal-timeline_type"
							label="Timeline Type"
							rules={[
								{
									required: true,
									message: 'Please select timeline type',
								},
							]}
						>
							<XRadio.Group size="large" value={'days'}>
								<XRadio value={'hours'}>Hours</XRadio>
								<XRadio value={'days'}>Days</XRadio>
							</XRadio.Group>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-third_appeal-in_process"
							label="In Process"
							rules={[
								{
									required: true,
									message: 'Please enter In process start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter In Process Start"
								name="pre_service-ma_standard-third_appeal-in_process"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-third_appeal-at_risk"
							label="At Risk"
							rules={[
								{
									required: true,
									message: 'Please enter At Risk start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter At Risk Start"
								name="pre_service-ma_standard-third_appeal-at_risk"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-third_appeal-untimely"
							label="Untimely"
							rules={[
								{
									required: true,
									message: 'Please enter Untimely start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Untimely Start"
								name="pre_service-ma_standard-third_appeal-untimely"
								type="number"
							/>
						</XForm.Item>
					</Col>
				</Row>

				<Row gutter={(15, 15)}>
					<Col xs={24}>
						<Divider
							orientation="left"
							orientationMargin="0"
							className="mb-4"
						>
							<Text>Fourth Appeal</Text>
						</Divider>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-fourth_appeal-timeline"
							label="Timeline"
							rules={[
								{
									required: true,
									message: 'Please enter timeline',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Timeline"
								name="pre_service-ma_standard-fourth_appeal-timeline"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-fourth_appeal-timeline_type"
							label="Timeline Type"
							rules={[
								{
									required: true,
									message: 'Please select timeline type',
								},
							]}
						>
							<XRadio.Group size="large" value={'days'}>
								<XRadio value={'hours'}>Hours</XRadio>
								<XRadio value={'days'}>Days</XRadio>
							</XRadio.Group>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-fourth_appeal-in_process"
							label="In Process"
							rules={[
								{
									required: true,
									message: 'Please enter In process start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter In Process Start"
								name="pre_service-ma_standard-fourth_appeal-in_process"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-fourth_appeal-at_risk"
							label="At Risk"
							rules={[
								{
									required: true,
									message: 'Please enter At Risk start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter At Risk Start"
								name="pre_service-ma_standard-fourth_appeal-at_risk"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-fourth_appeal-untimely"
							label="Untimely"
							rules={[
								{
									required: true,
									message: 'Please enter Untimely start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Untimely Start"
								name="pre_service-ma_standard-fourth_appeal-untimely"
								type="number"
							/>
						</XForm.Item>
					</Col>
				</Row>

				<Row gutter={(15, 15)}>
					<Col xs={24}>
						<Divider
							orientation="left"
							orientationMargin="0"
							className="mb-4"
						>
							<Text>Judicial Review</Text>
						</Divider>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-judicial_review-timeline"
							label="Timeline"
							rules={[
								{
									required: true,
									message: 'Please enter timeline',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Timeline"
								name="pre_service-ma_standard-judicial_review-timeline"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-judicial_review-timeline_type"
							label="Timeline Type"
							rules={[
								{
									required: true,
									message: 'Please select timeline type',
								},
							]}
						>
							<XRadio.Group size="large" value={'days'}>
								<XRadio value={'hours'}>Hours</XRadio>
								<XRadio value={'days'}>Days</XRadio>
							</XRadio.Group>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-judicial_review-in_process"
							label="In Process"
							rules={[
								{
									required: true,
									message: 'Please enter In process start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter In Process Start"
								name="pre_service-ma_standard-judicial_review-in_process"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-judicial_review-at_risk"
							label="At Risk"
							rules={[
								{
									required: true,
									message: 'Please enter At Risk start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter At Risk Start"
								name="pre_service-ma_standard-judicial_review-at_risk"
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
						<XForm.Item
							name="pre_service-ma_standard-judicial_review-untimely"
							label="Untimely"
							rules={[
								{
									required: true,
									message: 'Please enter Untimely start',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Untimely Start"
								name="pre_service-ma_standard-judicial_review-untimely"
								type="number"
							/>
						</XForm.Item>
					</Col>
				</Row>

				<Row gutter={[15, 15]}>
					{editModeState.editMode ? (
						<Col xs={24} className="text-center text-md-end">
							<XNextPrevBtn
								style={{ marginLeft: '0px' }}
								className="prev"
								onClick={handlePrev}
							>
								<LeftOutlined />
								&nbsp; Preview
							</XNextPrevBtn>

							<XNextPrevBtn onClick={handleNext}>
								Next&nbsp;
								<RightOutlined />
							</XNextPrevBtn>
							<Divider
								type="vertical"
								className="d-none d-sm-inline"
								style={{ height: '30px', marginInline: '20px' }}
								orientationMargin={1}
							/>
							<XNextPrevBtn
								className="mt-3 mt-sm-0"
								style={{ marginLeft: '0px' }}
								onClick={handleUpdate}
							>
								Update
							</XNextPrevBtn>
						</Col>
					) : (
						<Col xs={24} className="text-end text-md-end">
							<XNextPrevBtn
								style={{ marginLeft: '0px' }}
								className="prev"
								onClick={handlePrev}
							>
								<LeftOutlined />
								&nbsp; Preview
							</XNextPrevBtn>

							<XNextPrevBtn className="next" onClick={handleNext}>
								Next&nbsp;
								<RightOutlined />
							</XNextPrevBtn>
						</Col>
					)}
				</Row>
			</XForm>
		</>
	);
};

const mapStateToProps = (state) => ({
	editPayerConfigurationState: state.editPayerConfiguration,
});

const mapDispatchToProps = (dispatch) => ({
	editPayerConfiguration: (data) => dispatch(editPayerConfigurationApi(data)),
	editPayerConfigurationReset: () => dispatch(editPayerConfigurationReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step2);
