import '../../assets/fonts/clash-display/WEB/css/clash-display.css';
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Button,
	Input,
	Space,
	Divider,
	notification,
	Affix,
	Select,
	Tooltip,
	Tag,
	List,
	Checkbox,
	Radio,
	Modal,
	Card,
	Image,
	Upload,
	Skeleton,
	Spin,
} from 'antd';
import { XLottie } from '../Login/LoginStyle';
import { useNavigate } from 'react-router-dom';
import LoginAnimation from '../../json/Login.json';
// import ReloadingAnimation from '../../json/reloading.json';
import ReloadingAnimation from '../../json/decodex_updated05.json';
import Thanks from '../../json/thanksNew1.json';
import {
	XContainer,
	XForm,
	XModal,
	XAlertBar,
	XButton,
	XSelect,
	XCardsCriteriaBg,
	XRoundButton,
	XModalTitle,
	XModalSubtitle,
	XList,
	XCircle,
	XLabel,
	XSelectAddCase,
	XAffix,
} from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import { XCardsCaseBg } from '../../styles/GlobalStyle';
import { XButtonImg } from '../Manage/ManageStyle';
import {
	XLottieMargin,
	Title,
	XPara1,
	XCardsGrdBG,
	XButtonAdd,
	XTitleHead,
	XButtonLike,
	XButtonCloseBar,
	XButtonReload,
	XNotificationBar,
	XResults,
	XLottieOverlay,
	XButtonConfirm,
	XCheckboxGroup,
	XuploadAddCase,
	XViewMoreBTN,
} from './AnalyseCoverageStyle';
import {
	PlusOutlined,
	EditOutlined,
	DeleteOutlined,
	ExclamationCircleFilled,
	CopyOutlined,
	MailOutlined,
	CloseOutlined,
	ReloadOutlined,
	SyncOutlined,
	EyeOutlined,
	FilePdfOutlined,
	InboxOutlined,
	DownOutlined,
	UpOutlined,
	EnvironmentOutlined,
	UserSwitchOutlined,
	AudioOutlined,
	UploadOutlined,
} from '@ant-design/icons';
// Actions
import {
	analyseCoverageApi,
	analyseCoverageReset,
} from '../../actions/analyseCoverageAction';
import {
	reviseCoverageApi,
	reviseCoverageReset,
} from '../../actions/reviseCoverageAction';
import {
	sendFeedbackApi,
	sendFeedbackReset,
} from '../../actions/sendFeedbackAction';
import {
	getStateDropdownApi,
	getStateDropdownReset,
} from '../../actions/getStateDropdownAction';
import Header from '../../components/Header/Header';
import {
	filterByLabel,
	handleInputChange,
	handleSelectChange,
} from '../../utils/formFunctions';
import {
	getAnalysisReset,
	getAnalysisApi,
} from '../../actions/getAnalysisAction';
import AnalysisLoader from '../../components/AnalysisLoader/AnalysisLoader';
import CaseOperations from '../../components/HorizontalTimeline/CaseOperations';
import {
	XAddCodeSticky,
	XButtonModal,
	XICDModal,
	XICDModalFooterBTN,
	XRelatedCard,
	XRelatedReadMore,
	XTabIcdCpt,
} from '../CaseForm/CaseFormStyle';
import config from '../../config';
import {
	getIcdCodesApi,
	getIcdCodesReset,
} from '../../actions/getIcdCodesAction';
import { XcaseAnsyCardScroll } from '../CaseAnalysis/CaseAnalysisStyle';
import {
	getCptCodesApi,
	getCptCodesReset,
} from '../../actions/getCptCodesAction';
import ReactReadMoreReadLess from 'react-read-more-read-less';
import DrawerCodingTools from '../../components/CodingTools/DrawerCodingTools';
import {
	AipaMobileBody,
	AddInputIcon,
	ParaDeocdex,
	XDecodexTitle,
	XHeader,
	XLottieAipa,
	XLottieAipaLoader,
	XSelectIcons,
	XTitleHeader,
	RecordCard,
	XActionBtns,
	XActionPlay,
	XShazaamBtn,
	AipaDesktopBody,
	XRadio,
	XDivider,
	XCardDesktop,
	XInputTextArea,
	XUpload,
} from '../AipaNew/NewAipaStyle';
import LoaderJson from '../../json/aipa_animation.json';
import { AipaAudioBtn } from '../Aipa/AipaStyle';
import axios from 'axios';
import { getUserToken } from '../../utils/helper';
const { TextArea } = Input;
const moment = require('moment');

const { Dragger } = Upload;

const AnalyseCoverage = (props) => {
	const {
		analyseCoverage,
		analyseCoverageReset,
		analyseCoverageState,
		reviseCoverage,
		reviseCoverageReset,
		reviseCoverageState,
		sendFeedback,
		sendFeedbackReset,
		sendFeedbackState,
		getStateDropdownState,
		getStateDropdownReset,
		getStateDropdown,
		getAnalysis,
		getAnalysisState,
		getAnalysisReset,
	} = props;
	const missingFieldsCardRef = useRef(null);
	const [form] = XForm.useForm();
	const [formUpload] = XForm.useForm();
	const [clinicalDocsForm] = XForm.useForm();
	const [showClinicalTextArea, setShowClinicalTextArea] = useState(false);
	const [isClinicalUploadModalOpen, setIsClinicalUploadModalOpen] =
		useState(false);
	const [clinicalDocsLoading, setClinicalDocsLoading] = useState(false);
	const [clinicalDocumentFileList, setClinicalDocumentFileList] = useState(
		[],
	);
	const [submitClinicalDocs, setSubmitClinicalDocs] = useState(false); // Initial value can be 'textarea' or 'upload'
	const [formData, setFormData] = useState();
	const [clinicalSummaryTextLoading, setClinicalSummaryTextLoading] =
		useState(false);
	const [supportingDocsSummary, setSupportingDocsSummary] = useState('');
	const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const [previewTitle, setPreviewTitle] = useState('Upload Acknowledgement');
	const [newOptionForm] = XForm.useForm();
	const [summaryForm] = XForm.useForm();
	const [loading, setLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState([false, '', '', '', '']);
	const [thanksModal, setThanksModal] = useState([false, '']);
	const [summary, setSummary] = useState('');
	const [state, setState] = useState('');
	const [provider, setProvider] = useState('');
	const [modalTextArea, setModalTextArea] = useState('');
	const [coverage, setCoverage] = useState('Yes');
	const [feedbackId, setFeedbackId] = useState(null);
	const [pageContent, setPageContent] = useState({
		pastHistory: [],
		presentComplaint: [],
		recommendedProcedure: [],
		medicalCoverage: [],
		status: '',
		missing_points_for_coverage: [],
	});
	const [insuranceCompanies, setInsuranceCompanies] = useState([]);
	const [callGetAnalysisAPiBtn, setCallGetAnalysisAPiBtn] = useState(true);
	const [missingFieldsModal, setMissingFieldsModal] = useState(false);
	const [missingFields, setMissingFields] = useState([]);
	const [missingFieldInstructions, setMissingFieldInstructions] = useState(
		[],
	);
	const [missingFieldTitle, setMissingFieldTitle] = useState('');
	const [missingFieldsForm] = XForm.useForm();
	const [lastAnalysisData, setLastAnalysisData] = useState({});
	const [showAnalysisData, setShowAnalysisData] = useState(false);
	const [analysisCompleted, setAnalysisCompleted] = useState('');
	const [newOptionText, setNewOptionText] = useState('');
	const [newCheckBoxModal, setNewCheckBoxModal] = useState([false, null]);
	const [id, setId] = useState('');
	const [coverageloading, setCoverageLoading] = useState(false);
	const [LooksGoodLoading, setLooksGoodLoading] = useState(false);
	const [isMissingFieldsThere, setIsMissingFieldsThere] = useState(false);
	const [
		updatedClinicalDocumentFileList,
		setUpdatedClinicalDocumentFileList,
	] = useState([]);
	const [documentName, setDocumentName] = useState('');
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
	const [uploadedSupporingDoc, setUploadedSupporingDoc] = useState([]);
	const [previewOpen, setPreviewOpen] = useState(false);
	const [clinicalDocumentsToDisplay, setClinicalDocumentsToDisplay] =
		useState([]);
	const [supportingDoc, setSupportingDoc] = useState([]);
	const [clinicalSelectedOption, setClinicalSelectedOption] =
		useState('clinical_text'); // Initial value can be 'textarea' or 'upload'
	const [supportingFiles, setSupportingFiles] = useState([]);
	const [isClinicalDocUploadFromCommon, setIsClinicalDocUploadFromCommon] =
		useState(false);
	const [isUploadClinicalDoc, setIsUploadClinicalDoc] = useState(false);

	const [icdform] = XForm.useForm();
	const [cptform] = XForm.useForm();
	const [openIcdCptDrawer, setOpenIcdCptDrawer] = useState(true);
	const [openIcdCptDrawer2, setOpenIcdCptDrawer2] = useState(false);
	const [activeCode, setActiveCode] = useState(null);
	const [icdFilters, setIcdFilters] = useState({
		search: '',
		loaded_count: 0,
		limit: 10,
		related: false,
		childCode: '',
	});
	const [icdCodes, setIcdCodes] = useState([]); // To store fetched ICD codes
	const [icdLoading, setIcdLoading] = useState(false); // To manage loading state
	const [searchResults, setSearchResults] = useState([]); // To store search results
	const [triggeredBySearch, setTriggeredBySearch] = useState(false); // To track search-triggered calls
	const [triggeredByEnter, setTriggeredByEnter] = useState(false); // To track search-triggered calls
	const [loadMore, setLoadMore] = useState(false); // To track search-triggered calls
	const [icdSearchLoading, setIcdSearchLoading] = useState(false); // To manage loading state
	//CPT Codes
	const [cptFilters, setCptFilters] = useState({
		search: '',
		loaded_count: 0,
		limit: 10,
	});
	const [cptCodes, setCptCodes] = useState([]); // To store fetched ICD codes
	const [cptLoading, setCptLoading] = useState(false); // To manage loading state
	const [cptSearchResults, setCptSearchResults] = useState([]); // To store search results
	const [cptTriggeredBySearch, setCptTriggeredBySearch] = useState(false); // To track search-triggered calls
	const [cptTriggeredByEnter, setCptTriggeredByEnter] = useState(false); // To track search-triggered calls
	const [cptLoadMore, setCptLoadMore] = useState(false); // To track search-triggered calls
	const [cptSearchLoading, setCptSearchLoading] = useState(false); // To manage loading state
	// State to track the "fixed" prop value
	const [isFixed, setIsFixed] = useState(true);

	// Open the modal and set a timer to automatically close it after 3 seconds
	useEffect(() => {
		let timer;
		if (thanksModal) {
			timer = setTimeout(() => {
				setThanksModal([false, '']);
			}, 5000);
		}

		// Clear the timer if the component unmounts or showThanksModal changes
		return () => clearTimeout(timer);
	}, [thanksModal]);

	useEffect(() => {
		// Function to check screen width and update "isFixed" only if below 991px
		const handleResize = () => {
			if (window.innerWidth < 991) {
				setIsFixed(false);
				closeIcdCptDrawer();
				setOpenIcdCptDrawer2(false);
				setActiveCode(null);
				setIcdLoading(false);
				setTriggeredBySearch(false);
				setIcdSearchLoading(false);
				setLoadMore(false);
				icdform.resetFields();
				setCptLoading(false);
				setCptTriggeredBySearch(false);
				setCptSearchLoading(false);
				setCptLoadMore(false);
				cptform.resetFields();
			} else {
				setIsFixed(true); // Ensures `fixed` is `true` on larger screens
				showIcdCptDrawer();
			}
		};

		// Initial check on mount
		handleResize();

		// Add event listener for window resize
		window.addEventListener('resize', handleResize);

		// Cleanup the event listener on component unmount
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (modalOpen[0] && modalOpen[4] !== 'feedbackSummary') {
			// Check if modal is open and it's not the feedbackSummary modal
			const [description, code] = modalTextArea.split(' - ');
			form.setFieldsValue({
				modal_text_area: description || '', // Prepopulate description
				code: code ? code.split(': ')[1] : '', // Extract and prepopulate code
			});
		}
	}, [modalOpen, modalTextArea, form]);

	// Coding tools Add this useEffect to handle tab switching
	const [activeTab, setActiveTab] = useState('1');
	// Add new state variables to track drawer states separately
	const [openIcdDrawer, setOpenIcdDrawer] = useState(true);
	const [openCptDrawer, setOpenCptDrawer] = useState(true);

	useEffect(() => {
		if (openIcdCptDrawer2 && activeCode?.type === 'CPT') {
			setActiveTab('2');
		} else {
			setActiveTab('1');
		}
	}, [openIcdCptDrawer2]);
	// Example of handleCodeClick
	const handleCodeClick = (codeType, codeData) => {
		console.log(activeCode, 'activecode');
		// Check if the code has leading or trailing spaces
		let trimmedCode = codeData.code;

		if (trimmedCode !== trimmedCode.trim()) {
			trimmedCode = trimmedCode.trim();
		}

		// // Check if it's a child code
		// const isChildCode = trimmedCode.includes('.');
		// const parentCode = isChildCode
		// 	? trimmedCode.split('.')[0]
		// 	: trimmedCode;
		setIcdCodes();
		closeIcdCptDrawer();
		setActiveCode({
			type: codeType,
			code: codeData.code,
			description: codeData.description,
			codeTypeText: codeData.codeTypeText,
			buttontext: codeData.buttontext,
		});

		if (codeType === 'CPT') {
			// // Set filters to fetch related codes
			setCptFilters({
				related: true,
				childCode: trimmedCode,
			});
			setCptTriggeredByEnter(true);
			setOpenIcdCptDrawer2(true); // Open the related codes drawer
			// setOpenIcdCptDrawer(false);
			setOpenCptDrawer(false);
			setOpenIcdDrawer(true);
			setActiveTab('2');
		} else {
			// Set filters to fetch related codes
			setIcdFilters({
				related: true,
				childCode: trimmedCode,
			});
			setTriggeredByEnter(true);
			setOpenIcdCptDrawer2(true); // Open the related codes drawer
			// setOpenIcdCptDrawer(false);
			setOpenIcdDrawer(false);
			setOpenCptDrawer(true);
			setActiveTab('1');
		}
	};

	const showIcdCptDrawer = () => {
		setOpenIcdCptDrawer(true);
		setIcdCodes();
		setActiveCode(null);
		setSearchResults();
		setIcdLoading(false);
		setTriggeredBySearch(false);
		setIcdSearchLoading(false);
		setLoadMore(false);
		icdform.resetFields();
		setCptCodes();
		setCptSearchResults();
		setCptLoading(false);
		setCptTriggeredBySearch(false);
		setCptSearchLoading(false);
		setCptLoadMore(false);
		cptform.resetFields();
	};

	const closeIcdCptDrawer = () => {
		setOpenIcdDrawer(true);
		setOpenCptDrawer(true);
		setOpenIcdCptDrawer(false);
		setActiveCode(null);
		setIcdLoading(false);
		setTriggeredBySearch(false);
		setIcdSearchLoading(false);
		setLoadMore(false);
		icdform.resetFields();
		setCptLoading(false);
		setCptTriggeredBySearch(false);
		setCptSearchLoading(false);
		setCptLoadMore(false);
		cptform.resetFields();
	};

	const closeIcdCptDrawer2 = () => {
		setOpenIcdDrawer(true);
		setOpenCptDrawer(true);
		setOpenIcdCptDrawer2(false);
		setActiveCode(null);
		setIcdLoading(false);
		setTriggeredBySearch(false);
		setIcdSearchLoading(false);
		setLoadMore(false);
		icdform.resetFields();
		showIcdCptDrawer();
		setCptLoading(false);
		setCptTriggeredBySearch(false);
		setCptSearchLoading(false);
		setCptLoadMore(false);
		cptform.resetFields();
	};

	const handleUploadCancel = () => {
		setIsClinicalUploadModalOpen(false);
		setIsUploadModalOpen(false);
		setClinicalDocumentFileList([]);
		setUpdatedClinicalDocumentFileList([]);
		setClinicalDocsLoading(false);
		setSubmitClinicalDocs(false);
		setFormData({
			...formData,
			clinical_summary: '',
		});

		clinicalDocsForm.setFieldsValue({ clinical_docs: '' });
	};

	const handleSupportingDocChange = ({ fileList: newFileList }) => {
		const updatedFileList = newFileList.map((file) => {
			if (file.status === 'error') {
				return { ...file, status: 'done' };
			}
			return file;
		});
		setSupportingDoc(updatedFileList);
		formUpload.validateFields(['supporting_docs']);
	};

	const uploadSupportingDocButton = (
		<Button
			onClick={() => setIsUploadModalOpen(true)}
			className="m-0"
			style={{
				borderRadius: '6px',
				border: '1px dashed #d9d9d9',
				display: 'block',
				height: '100px',
				padding: '4px 15px',
				width: '100px',
				background: 'rgba(0, 0, 0, 0.02)',
			}}
		>
			<PlusOutlined style={{ color: '#000' }} />
			<div style={{ color: '#000' }}>Upload</div>
		</Button>
	);

	const handlePreview = async (file) => {
		if (file && file.name && file.name.split('.').includes('pdf')) {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			setPreviewImage(file.url || file.preview);
			setPdfPreviewOpen(true);
		} else {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			setPreviewImage(file.url || file.preview);
			setPreviewOpen(true);
			setPreviewTitle(
				file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
			);
		}
	};

	const handlePreviewClinicalSummary = async (file) => {
		// Check if the file is a PDF
		if (file && file.name && file.name.split('.').includes('pdf')) {
			// If there's a URL, use it directly; otherwise, generate a preview from the file
			const fileUrl = file.url ? `${config.s3_url}${file.url}` : null;
			setPreviewImage(fileUrl || file.preview);
			setPdfPreviewOpen(true); // Open PDF preview modal
		} else {
			// For non-PDF files, generate a preview if necessary
			if (!file.url && !file.preview && file.originFileObj) {
				file.preview = await getBase64(file.originFileObj);
			}
			setPreviewImage(file.url || file.preview);
			setPreviewOpen(true); // Open image preview modal
			setPreviewTitle(
				file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
			);
		}
	};

	const handleClinicalDocumentChange = ({ fileList: newFileList }) => {
		const updatedFileList = newFileList.map((file) => {
			if (file.status === 'error') {
				return { ...file, status: 'done' };
			}
			return file;
		});
		setClinicalDocumentFileList(updatedFileList);
		setUpdatedClinicalDocumentFileList(updatedFileList);
		summaryForm.setFieldsValue({
			clinical_summary: '',
		});
		setFormData({
			...formData,
			clinical_summary: '',
		});
		setSummary('');
		summaryForm.validateFields(['clinical_document']);
	};

	const uploadButton = (
		<Button
			style={{
				border: 0,
				background: 'none',
				margin: '0',
				boxShadow: 'none',
			}}
		>
			<PlusOutlined style={{ color: '#000' }} />
			<div style={{ color: '#000' }}>Upload</div>
		</Button>
	);

	const handleRemoveFile = async (file) => {
		Modal.confirm({
			title: 'Confirm Deletion',
			content: 'Are you sure you want to delete this file?',
			okText: 'Yes',
			cancelText: 'No',
			cancelButtonProps: {
				// Add an icon to the Cancel button
				style: {
					backgroundColor: '#fff', // Red background for Cancel
					color: '#007299', // Text color
				},
			},
			okButtonProps: {
				style: {
					backgroundColor: '#007299',
					borderColor: '#007299',
					color: '#fff',
				},
			},
			onOk: async () => {
				const updatedFileList = supportingFiles.filter(
					(item) => item.url.uid !== file.url.uid,
				);
				const UploadedFile = uploadedSupporingDoc.filter(
					(item) => item.url.uid !== file.url.uid,
				);
				// const updatedUploadedFile = updatedUploadedSupporingDoc.filter(
				// 	(item) => item.url.uid !== file.url.uid,
				// );
				setSupportingFiles(updatedFileList);
				// setUpdatedUploadedSupporingDoc(updatedUploadedFile);
				setUploadedSupporingDoc(UploadedFile);
				// if (!file.url.originFileObj) {
				// 	setDeletedUploadedSupportingDoc((prevFiles) => [
				// 		...prevFiles,
				// 		file,
				// 	]);
				// }
			},
			onCancel: () => {
				console.log('Deletion cancelled');
			},
		});
	};

	const handleRemoveClinicalDocuments = async (file) => {
		Modal.confirm({
			title: 'Confirm Deletion',
			content: 'Are you sure you want to delete this file?',
			okText: 'Yes',
			cancelText: 'No',
			cancelButtonProps: {
				// Add an icon to the Cancel button
				style: {
					backgroundColor: '#fff', // Red background for Cancel
					color: '#007299', // Text color
				},
			},
			okButtonProps: {
				style: {
					backgroundColor: '#007299',
					borderColor: '#007299',
					color: '#fff',
				},
			},
			onOk: async () => {
				const updatedFileList = clinicalDocumentFileList.filter(
					(item) => item.uid !== file.uid,
				);
				const updatedFileListToDisplay =
					clinicalDocumentsToDisplay.filter(
						(item) => item.uid !== file.uid,
					);
				setClinicalDocumentFileList(updatedFileList);
				setUpdatedClinicalDocumentFileList(updatedFileList);
				setClinicalDocumentsToDisplay(updatedFileListToDisplay);
				form.validateFields(['clinical_document']);
				if (!file.originFileObj) {
					// setDeletedClinicalDocuments((prevFiles) => [
					// 	...prevFiles,
					// 	file,
					// ]);
				}
			},
			onCancel: () => {
				form.validateFields(['clinical_document']);
				console.log('Deletion cancelled');
			},
		});
	};

	// Function to generate a random alphanumeric string of given length
	const generateRandomAlphaNumeric = (length) => {
		const characters =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let result = '';
		for (let i = 0; i < length; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * characters.length),
			);
		}
		return result;
	};

	// Function to generate an ID with specified format
	const generateCustomId = () => {
		const randomPart = generateRandomAlphaNumeric(9); // First 9 characters alphanumeric
		const timestampPart = moment().format('YYDDMMHHMMSSMS'); // Last 15 characters formatted as YYDDMMHHMMSSMS
		const customId = randomPart + timestampPart;

		return customId;
	};
	// const showDeleteConfirm = (title, id, content, item) => {
	// 	XModal.confirm({
	// 		title: `Delete ${title}?`,
	// 		content: (
	// 			<p className="modalPara1">
	// 				Are you sure you want to delete - {content}?
	// 			</p>
	// 		),
	// 		icon: <img className="me-2" src="/images/delete-icon.svg" alt="" />,
	// 		okText: 'Yes, Confirm',
	// 		okType: 'danger',
	// 		cancelText: 'No, Cancel',
	// 		className: 'confirmModal',
	// 		onOk() {
	// 			deletePageContent(item, id);
	// 		},
	// 		onCancel() {
	// 			console.log('Cancel');
	// 		},
	// 	});
	// };

	const updatePageContent = (title, id, content) => {
		if (id === null) {
			if (title === 'originalQuery') {
				setPageContent({
					...pageContent,
					originalQuery: content,
				});
			} else {
				setPageContent({
					...pageContent,
					[title]: [...pageContent[[title]], content],
				});
			}
		} else {
			const updatedPageContent = { ...pageContent };
			updatedPageContent[[title]][id] = content;
			setPageContent(updatedPageContent);
		}
		// setSummary(formData.clinical_summary);
		setClinicalSummaryTextLoading(false);
		setShowClinicalTextArea(false);
		setModalOpen([false, '', ' ']);
		setModalTextArea('');
		form.resetFields();
		document.getElementById('notification').classList.remove('d-none');
	};

	// const deletePageContent = (item, id) => {
	// 	const updatedPageContent = { ...pageContent };
	// 	updatedPageContent[[item]].splice(id, 1);
	// 	setPageContent(updatedPageContent);
	// 	setModalOpen([false, '', ' ']);
	// 	setModalTextArea('');
	// 	form.resetFields();
	// 	document.getElementById('notification').classList.remove('d-none');
	// };

	const handleAddOption = (index) => {
		setNewCheckBoxModal([true, index]);
		setNewOptionText('');
		newOptionForm.setFieldsValue({});
		newOptionForm.resetFields();
	};

	const handleSupportingDocSubmit = async () => {
		formUpload
			.validateFields()
			.then(async () => {
				if (supportingDoc && supportingDoc.length > 0 && documentName) {
					let obj = {
						url: supportingDoc[0],
						document_name: documentName,
					};
					setUploadedSupporingDoc((prevFiles) => [...prevFiles, obj]);
					obj.preview = await getBase64(
						supportingDoc[0].originFileObj,
					);
					setSupportingFiles((prevFiles) => [...prevFiles, obj]);
					setSupportingDoc([]);
					setDocumentName('');
					formUpload.resetFields();
					setIsUploadModalOpen(false);
				}
			})
			.catch((err) => {});
	};

	const handleNewCheckBoxModal = () => {
		// Create a new option object
		newOptionForm
			.validateFields()
			.then(() => {
				const newOption = {
					label: newOptionText,
					value: newOptionText,
				};

				if (
					newCheckBoxModal[1] !== null &&
					newCheckBoxModal[1] >= 0 &&
					newCheckBoxModal[1] < missingFields.length
				) {
					const updatedFields = [...missingFields];
					updatedFields[newCheckBoxModal[1]].options.push(newOption);
					setMissingFields(updatedFields);

					// Get the current form values
					const currentValues = missingFieldsForm.getFieldsValue();

					// Add the new option to the appropriate field and mark it as checked
					if (
						!currentValues[updatedFields[newCheckBoxModal[1]].name]
					) {
						currentValues[updatedFields[newCheckBoxModal[1]].name] =
							[];
					}
					currentValues[updatedFields[newCheckBoxModal[1]].name].push(
						newOptionText,
					);

					// Update the form with the new values
					missingFieldsForm.setFieldsValue(currentValues);
				}

				// Close the modal and reset the newOptionText state
				setNewCheckBoxModal([false, null]);
				setNewOptionText('');
				newOptionForm.setFieldsValue({});
				newOptionForm.resetFields();
			})
			.catch((info) => {
				console.log('Validate Failed:', info);
			});
	};

	const handleClinicalSummaryDoc = async () => {
		let uploadPromises = [];
		let uploadedClinicalDocs = [];

		// Handle Clinical Documents Upload
		if (clinicalDocumentFileList.length > 0) {
			const clinicalDocPromises = clinicalDocumentFileList.map(
				async (fileData) => {
					let get_ext = fileData.originFileObj.name.split('.');
					let ext = get_ext[get_ext.length - 1];
					const res = await axios.get(
						`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=clinical_documents&file_name=${fileData.originFileObj.uid}&ext=${ext}&id=${id}`,
						{
							headers: {
								'Content-type':
									'application/json; charset=UTF-8',
								Authorization: 'Bearer ' + getUserToken(),
							},
						},
					);

					const result = await axios.put(
						res.data.data,
						fileData.originFileObj,
					);
					if (result.status === 200) {
						const documentPath = `/clinical_documents/${id}/${fileData.originFileObj.uid}.${ext}`;
						uploadedClinicalDocs.push({
							uid: fileData.originFileObj.uid,
							name: fileData.originFileObj.name,
							status: 'done',
							url: documentPath,
						});
					}
				},
			);
			uploadPromises = uploadPromises.concat(clinicalDocPromises);
		}
		// Wait for all uploads to complete
		await Promise.all(uploadPromises);
		// Update state with uploaded files
		setUpdatedClinicalDocumentFileList(uploadedClinicalDocs);

		// Make the API call with updated file information
		const payload = {
			analysis_id: id,
			state: state,
			provider: provider,
			clinical_docs: uploadedClinicalDocs,
			isExtractedTextReturn: true,
		};

		setIsUploadClinicalDoc(true);
		setIsClinicalDocUploadFromCommon(true);
		// setLoading(true); //added now
		// setIsClinicalUploadModalOpen(false);
		analyseCoverage(payload);

		// const formData = new FormData();

		// // // Append supportingDocs files
		// // supportingFiles.forEach((file) => {
		// // 	formData.append('supporting_docs', file.url?.originFileObj); // 'supportingDocs' will be the backend key
		// // });

		// // Append clinicalDocs files
		// clinicalDocumentFileList.forEach((file) => {
		// 	formData.append('clinical_docs', file.originFileObj); // 'clinicalDocs' will be the backend key
		// });
		// formData.append('supportingDocsSummary', supportingDocsSummary);
		// // formData.append('state', state);
		// // formData.append('provider', provider);
		// // formData.append('analysis_id', id);
		// // Log to check FormData contents
		// // setLoading(true);
		// setIsClinicalDocUploadFromCommon(true);
		// analyseCoverage(formData);
	};

	const getBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	// const getBase64 = (file, callback) => {
	// 	const reader = new FileReader();
	// 	reader.readAsDataURL(file);
	// 	reader.onload = () => callback(reader.result);
	// 	reader.onerror = (error) => console.error('Error reading file:', error);
	// };

	const handleClinicalDocsChange = ({ fileList: newFileList }) => {
		const updatedFileList = newFileList.map((file) => {
			if (file.status === 'error') {
				return { ...file, status: 'done' };
			}
			// file.preview = await getBase64(file.originFileObj);
			return file;
		});
		// const updatedFileList = newFileList.map((file) => {
		// 	if (file.status === 'error') {
		// 		return { ...file, status: 'done' };
		// 	}

		// 	// Get base64 string for the file and set preview
		// 	getBase64(file.originFileObj, (base64String) => {
		// 		let newFile = file;
		// 		newFile.preview = base64String;
		// 		setUpdatedClinicalDocumentFileList([newFile]);

		// 	});

		// 	return file;
		// });
		setClinicalDocumentFileList(updatedFileList);
		setUpdatedClinicalDocumentFileList(updatedFileList);
		clinicalDocsForm.validateFields(['clinical_docs']);
	};

	// Function to handle form submission
	const handleShazamClick = async () => {
		try {
			setLoading(true); // Assuming you have setLoading function declared somewhere
			const newId = generateCustomId();
			setId(newId); // Update state with generated ID

			let uploadPromises = [];
			let uploadedClinicalDocs = [];
			let uploadedSupportingDocs = [];

			// Handle Clinical Documents Upload
			if (clinicalDocumentFileList.length > 0) {
				const clinicalDocPromises = clinicalDocumentFileList.map(
					async (fileData) => {
						let get_ext = fileData.originFileObj.name.split('.');
						let ext = get_ext[get_ext.length - 1];
						const res = await axios.get(
							`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=clinical_documents&file_name=${fileData.originFileObj.uid}&ext=${ext}&id=${newId}`,
							{
								headers: {
									'Content-type':
										'application/json; charset=UTF-8',
									Authorization: 'Bearer ' + getUserToken(),
								},
							},
						);

						const result = await axios.put(
							res.data.data,
							fileData.originFileObj,
						);
						if (result.status === 200) {
							const documentPath = `/clinical_documents/${newId}/${fileData.originFileObj.uid}.${ext}`;
							uploadedClinicalDocs.push({
								uid: fileData.originFileObj.uid,
								name: fileData.originFileObj.name,
								status: 'done',
								url: documentPath,
							});
						}
					},
				);
				uploadPromises = uploadPromises.concat(clinicalDocPromises);
			}

			// Handle Supporting Files Upload
			if (supportingFiles.length > 0) {
				const supportingDocPromises = supportingFiles.map(
					async (fileData) => {
						let get_ext =
							fileData.url.originFileObj.name.split('.');
						let ext = get_ext[get_ext.length - 1];
						const res = await axios.get(
							`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=case_supporting_documents&file_name=${fileData.url.originFileObj.uid}&ext=${ext}&id=${newId}`,
							{
								headers: {
									'Content-type':
										'application/json; charset=UTF-8',
									Authorization: 'Bearer ' + getUserToken(),
								},
							},
						);

						const result = await axios.put(
							res.data.data,
							fileData.url.originFileObj,
						);
						if (result.status === 200) {
							const documentPath = `/case_supporting_documents/${newId}/${fileData.url.originFileObj.uid}.${ext}`;
							uploadedSupportingDocs.push({
								uid: fileData.url.originFileObj.uid,
								name: fileData.url.originFileObj.name,
								status: 'done',
								url: documentPath,
							});
						}
					},
				);
				uploadPromises = uploadPromises.concat(supportingDocPromises);
			}

			// Wait for all uploads to complete
			await Promise.all(uploadPromises);

			// Update state with uploaded files
			setUpdatedClinicalDocumentFileList(uploadedClinicalDocs);
			setSupportingDoc(uploadedSupportingDocs);

			// Make the API call with updated file information
			const payload = {
				analysis_id: newId,
				clinicalSummary: summary,
				state: state,
				provider: provider,
				clinical_docs: uploadedClinicalDocs,
				supporting_docs: uploadedSupportingDocs,
			};
			// const formData = new FormData();
			// // Append supportingDocs files
			// supportingFiles.forEach((file) => {
			// 	formData.append('supporting_docs', file.url?.originFileObj); // 'supportingDocs' will be the backend key
			// });

			// // Append clinicalDocs files
			// clinicalDocumentFileList.forEach((file) => {
			// 	formData.append('clinical_docs', file.originFileObj); // 'clinicalDocs' will be the backend key
			// });
			// formData.append('clinicalSummary', summary);
			// formData.append('state', state);
			// formData.append('provider', provider);
			// formData.append('analysis_id', newId);
			// Log to check FormData contents
			setIsUploadClinicalDoc(true);
			setIsClinicalDocUploadFromCommon(true);
			setLoading(true);
			analyseCoverage(payload);

			// Assuming analyseCoverage function makes an API call
			// analyseCoverage({
			// 	clinicalSummary: summary,
			// 	state: state,
			// 	provider: provider,
			// 	analysis_id: newId, // Use the newly generated ID here
			// 	supportingDocsSummary: '',
			// })
			// 	.then(() => {
			// 		setLoading(false);
			// 	})
			// 	.catch((error) => {
			// 		setLoading(false);
			// 		console.error('API Error:', error);
			// 	});
		} catch (error) {
			setLoading(false);
			notification.error({
				message: 'Upload Error',
				description:
					'An error occurred while uploading files. Please try again.',
			});
			console.error('Error in handleShazamClick:', error);
		}
	};

	useEffect(() => {
		if (showAnalysisData) {
			document.getElementById('getQust').classList.add('bottom-100');
			document.getElementById('getAns').classList.add('top-0');
			document.getElementById('getAns').classList.remove('top-100');
			document.getElementById('getAns')?.classList.remove('d-none');
		}
	}, [showAnalysisData]);

	useEffect(() => {
		if (missingFieldsModal && missingFieldsCardRef.current) {
			// ADD
			document.getElementById('getQust').classList.add('bottom-100');
			document.getElementById('getAns').classList.add('top-0');
			document.getElementById('getAns').classList.remove('top-100');
			document.getElementById('getAns')?.classList.remove('d-none');
			// ADD
			missingFieldsCardRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [missingFieldsModal]);

	useEffect(() => {
		if (analyseCoverageState.apiState === 'success') {
			if (analyseCoverageState.data.isClinicalText) {
				setSummary(analyseCoverageState.data?.clinical_summary);
				setIsClinicalUploadModalOpen(false);
				setClinicalDocsLoading(false);
				setLoading(true);
				const payload = {
					analysis_id: id,
					state: state,
					provider: provider,
					clinicalSummary:
						analyseCoverageState.data?.clinical_summary,
					supportingDocsSummary: supportingDocsSummary,
				};
				analyseCoverage(payload);
			} else {
				setClinicalSummaryTextLoading(false);
				setClinicalDocumentsToDisplay(updatedClinicalDocumentFileList);
				handleUploadCancel();
				setClinicalDocsLoading(false);
				setShowClinicalTextArea(false);
				// setUpdateModalButtonText('Update');
				//
				setFeedbackId(analyseCoverageState.data.feedback_id);
				setSupportingDocsSummary(
					analyseCoverageState.data?.supportingDocsSummary,
				);
				if (isClinicalDocUploadFromCommon) {
					setSummary(
						analyseCoverageState.data['original_query'] ?? '',
					);
				}
				setLastAnalysisData(analyseCoverageState.data);
				// if (isClinicalDocUploadFromCommon) {
				// 	setPageContent({
				// 		...pageContent,
				// 		originalQuery:
				// 			analyseCoverageState.data['original_query'] ?? '',
				// 	});
				// } else {
				setPageContent({
					...pageContent,
					originalQuery:
						analyseCoverageState.data['original_query'] ?? '',
					pastHistory:
						analyseCoverageState.data['Past History'] ?? [],
					presentComplaint:
						analyseCoverageState.data['Present Complaint'] ?? [],
					recommendedProcedure:
						analyseCoverageState.data['Suggested Treatment'] ?? [],
					medicalCoverage: [
						analyseCoverageState.data['Medicare Evaluation']
							? analyseCoverageState.data['Medicare Evaluation'][
									'Coverage'
							  ]
							: 'No',
						analyseCoverageState.data['Medicare Evaluation']
							? analyseCoverageState.data['Medicare Evaluation'][
									'Rationale'
							  ]
							: '',
						analyseCoverageState.data['Medicare Evaluation']
							? analyseCoverageState.data['Medicare Evaluation'][
									'reference'
							  ]
							: null,
					],
					missing_points_for_coverage:
						analyseCoverageState.data.missing_points_for_coverage ??
						[],
				});
				// }
				setAnalysisCompleted('success');
				analyseCoverageReset();
				setIsUploadClinicalDoc(false);
				setIsClinicalDocUploadFromCommon(false);

				if (
					analyseCoverageState.data.hasOwnProperty(
						'is_fields_missing',
					) &&
					analyseCoverageState.data.is_fields_missing &&
					analyseCoverageState.data.hasOwnProperty('missing_fields')
				) {
					if (analyseCoverageState.data.missing_fields.length > 0) {
						setMissingFields(
							analyseCoverageState.data.missing_fields ?? [],
						);
						setMissingFieldInstructions(
							analyseCoverageState.data
								.missing_field_instructions ?? [],
						);
						setMissingFieldTitle(
							analyseCoverageState.data.missing_field_title ?? '',
						);
						setMissingFieldsModal(true);
						setIsMissingFieldsThere(true);
					}
				} else {
					setShowAnalysisData(true);
					setIsMissingFieldsThere(false);
				}
			}
		}

		if (analyseCoverageState.apiState === 'error') {
			setIsClinicalDocUploadFromCommon(false);
			setAnalysisCompleted('error');
			setIsMissingFieldsThere(false);
			notification.error({
				message:
					'Hey, we are facing high traffic. Please check back in few minutes.',
			});
			analyseCoverageReset();
		}
	}, [analyseCoverageState]);

	useEffect(() => {
		if (getAnalysisState.apiState === 'success') {
			setFeedbackId(getAnalysisState.data.coverage_feedback_response._id);
			setLastAnalysisData(
				getAnalysisState.data?.coverage_feedback_response,
			);
			setPageContent({
				...pageContent,
				originalQuery: summary ?? '',
				pastHistory:
					getAnalysisState.data.coverage_feedback_response[
						'Past History'
					] ?? [],
				presentComplaint:
					getAnalysisState.data.coverage_feedback_response[
						'Present Complaint'
					] ?? [],
				recommendedProcedure:
					getAnalysisState.data.coverage_feedback_response[
						'Suggested Treatment'
					] ?? [],
				medicalCoverage: [
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['Coverage']
						: 'No',
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['Rationale']
						: '',
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['reference']
						: null,
				],
				missing_points_for_coverage:
					getAnalysisState.data.coverage_feedback_response
						.missing_points_for_coverage ?? [],
				status: getAnalysisState.data.coverage_feedback_response.status,
			});
			setLoading(false);
			setCoverageLoading(false);
			setLooksGoodLoading(false);
			setModalOpen([false, '', '']);
			document.getElementById('getQust').classList.add('bottom-100');
			document.getElementById('getAns').classList.add('top-0');
			document.getElementById('getAns').classList.remove('top-100');
			document.getElementById('getAns')?.classList.remove('d-none');
		}

		if (getAnalysisState.apiState === 'error') {
			setLoading(false);
			notification.error({
				message:
					'Hey, we are facing high traffic. Please check back in few minutes.',
			});
		}
		getAnalysisReset();
	}, [getAnalysisState]);

	useEffect(() => {
		if (reviseCoverageState.apiState === 'success') {
			setFeedbackId(reviseCoverageState.data.feedback_id);
			setLastAnalysisData(reviseCoverageState.data);
			setPageContent({
				...pageContent,
				originalQuery: reviseCoverageState.data['original_query'] ?? '',
				pastHistory: reviseCoverageState.data['Past History'] ?? [],
				presentComplaint:
					reviseCoverageState.data['Present Complaint'] ?? [],
				recommendedProcedure:
					reviseCoverageState.data['Suggested Treatment'] ?? [],
				medicalCoverage: [
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'Coverage'
						  ]
						: 'No',
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'Rationale'
						  ]
						: '',
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'reference'
						  ]
						: null,
				],
				missing_points_for_coverage:
					reviseCoverageState.data.missing_points_for_coverage ?? [],
				status: reviseCoverageState.data.status,
			});

			setAnalysisCompleted('success');

			if (
				reviseCoverageState.data.hasOwnProperty('is_fields_missing') &&
				reviseCoverageState.data.is_fields_missing &&
				reviseCoverageState.data.hasOwnProperty('missing_fields')
			) {
				if (reviseCoverageState.data.missing_fields.length > 0) {
					setMissingFields(
						reviseCoverageState.data.missing_fields ?? [],
					);
					setMissingFieldInstructions(
						reviseCoverageState.data.missing_field_instructions ??
							[],
					);
					setMissingFieldTitle(
						reviseCoverageState.data.missing_field_title ?? '',
					);
					setMissingFieldsModal(true);
					setIsMissingFieldsThere(true);
				}
			} else {
				setShowAnalysisData(true);
				setIsMissingFieldsThere(false);
			}
			reviseCoverageReset();
		}
		if (reviseCoverageState.apiState === 'error') {
			setAnalysisCompleted('error');
			reviseCoverageReset();
			notification.error({
				message:
					'Hey, we are facing high traffic. Please check back in few minutes.',
			});
			document.getElementById('notification')?.classList.remove('d-none');
			document.getElementById('getAns')?.classList.remove('d-none');
		}
	}, [reviseCoverageState]);

	useEffect(() => {
		if (sendFeedbackState.apiState === 'success') {
			sendFeedbackReset();
			if (sendFeedbackState.data === 'negative')
				setThanksModal([true, 'Thanks for making us Better!']);
			else
				setThanksModal([true, 'Case has been analysed and confirmed!']);
			if (callGetAnalysisAPiBtn) {
				getAnalysis({ feedback_id: feedbackId });
				setCallGetAnalysisAPiBtn(true);
			} else {
				setCoverageLoading(false);
				setLooksGoodLoading(false);
				setModalOpen([false, '', '']);
			}
		} else if (sendFeedbackState.apiState === 'error') {
			sendFeedbackReset();
			setCallGetAnalysisAPiBtn(true);
			setCoverageLoading(false);
			setLooksGoodLoading(false);
		}
	}, [sendFeedbackState]);

	useEffect(() => {
		document.getElementById('getQust').classList.remove('bottom-100');
		document.getElementById('getAns').classList.remove('top-0');
		document.getElementById('getAns').classList.add('top-100');
		document.getElementById('getAns')?.classList.add('d-none');
		getStateDropdown();
		summaryForm.setFieldsValue({
			clinical_category: 'clinical_text',
		});
	}, []);

	useEffect(() => {
		// Add the class to the body tag
		document.body.classList.add('aipaBody');

		// Clean up to remove the class when the component unmounts
		return () => {
			document.body.classList.remove('aipaBody');
		};
	}, []);

	return (
		<>
			<XContainer fluid>
				<div className="marginTopMobile "></div>
				<XAffix
					id="notification"
					className="d-none zIndex"
					offsetTop={10}
				>
					<div className="marginBottomMobile"></div>
					<div
						className="mb-3"
						style={{
							backgroundColor: 'white',
							borderRadius: '20px',
							zIndex: '1111110',
						}}
					>
						<XNotificationBar style={{ zIndex: '22222' }}>
							Clinical Summary has been changed !! Click to{' '}
							<XButtonReload
								type="button"
								style={{ padding: '18px' }}
								className="refreshBtn"
								onClick={() => {
									setLoading(true);
									// closeIcdCptDrawer();
									closeIcdCptDrawer2();
									document
										.getElementById('notification')
										?.classList.add('d-none');

									reviseCoverageReset();
									// Update lastAnalysisData with the latest pageContent values
									const updatedLastAnalysisData = {
										...lastAnalysisData, // Keep all the existing keys and values intact
										'Past History': pageContent.pastHistory, // Update pastHistory
										'Present Complaint':
											pageContent.presentComplaint, // Update presentComplaint
										'Suggested Treatment':
											pageContent.recommendedProcedure, // Update recommendedProcedure
										'Medicare Evaluation': {
											...lastAnalysisData[
												'Medicare Evaluation'
											],
											Coverage:
												pageContent.medicalCoverage[0], // Update medicalCoverage fields
											Rationale:
												pageContent.medicalCoverage[1],
											reference:
												pageContent.medicalCoverage[2],
										},
										original_query:
											pageContent.originalQuery, // Update original_query
										past_history: pageContent.pastHistory, // Update past_history in lastAnalysisData
										present_complaint:
											pageContent.presentComplaint, // Update present_complaint in lastAnalysisData
										status: pageContent.status, // Update status in lastAnalysisData
										missing_points_for_coverage:
											pageContent.missing_points_for_coverage, // Update missing_points_for_coverage
										// Add other updates if necessary, or keep them unchanged
									};
									reviseCoverage({
										// case_id: patientData._id,
										analysis_id: id,
										feedbackId: feedbackId,
										clinicalHistory:
											pageContent.pastHistory,
										presentComplaint:
											pageContent.presentComplaint,
										recommendedProcedure:
											pageContent.recommendedProcedure,
										medicalCoverage:
											pageContent.medicalCoverage,
										state: state,
										provider: provider,
										last_analysis_data:
											updatedLastAnalysisData,
										supportingDocsSummary:
											supportingDocsSummary,
									});
								}}
							>
								Reanalyse it !!
							</XButtonReload>
							<XButtonCloseBar
								type="button"
								className="closeBar mt-3"
							>
								<CloseOutlined
									onClick={() => {
										document
											.getElementById('notification')
											?.classList.add('d-none');
									}}
									style={{ fontSize: '20px' }}
								/>
							</XButtonCloseBar>
						</XNotificationBar>
					</div>
					<div className="marginNone"></div>
				</XAffix>
			</XContainer>
			<>
				<Col lg={24} xl={24} xs={0} md={0}>
					<Header headerTag="Analyse Coverage" />
				</Col>
				<Col lg={0} xl={0} xs={24} md={24}>
					<HeaderFile activeNav="analyseCoverage" />
				</Col>
			</>

			{loading && (
				<AnalysisLoader
					analysisId={id}
					isOpenAnalysis={'true'}
					analysisCompleted={analysisCompleted}
					setAnalysisCompleted={setAnalysisCompleted}
					setLoading={setLoading}
					loading={loading}
					isMissingFieldsThere={isMissingFieldsThere}
					setIsMissingFieldsThere={setIsMissingFieldsThere}
				/>
			)}

			<div id="getQust" className="">
				{/* Start Desktop design */}
				<AipaDesktopBody>
					<XContainer fluid>
						<XForm
							form={summaryForm}
							name="loginForm"
							layout="vertical"
							autoComplete="off"
							onFinish={handleShazamClick}
						>
							<Row
								gutter={(15, 15)}
								className="align-items-center"
							>
								<Col xs={{ span: 3, offset: 0 }}>
									<XLottieAipaLoader>
										<XLottieAipa
											animationData={LoaderJson}
											loop={true}
										/>
									</XLottieAipaLoader>
								</Col>
								<Col xs={{ span: 19, offset: 1 }}>
									<XDecodexTitle className="text-start">
										DeCodeX
									</XDecodexTitle>
									<ParaDeocdex className="text-start mt-2">
										DeCodeX utilizes comprehensive
										standardized clinical notes to generate
										recommended best-fit diagnostic ICD-10,
										procedural CPT/HCPCS or drug NDC codes.
										It also provides insights into Medicare
										coverage.
									</ParaDeocdex>
								</Col>
							</Row>
							<Row
								gutter={(15, 15)}
								className="mt-4 align-items-center"
							>
								<Col xs={10}>
									<Row gutter={(15, 15)}>
										<Col xs={24}>
											<XForm.Item
												className="addCaseLabel mb-0"
												name="clinical_category"
												rules={[
													{
														required: true,
														message:
															'Please select option!',
													},
												]}
											>
												<XRadio.Group
													className="w-100"
													defaultValue={
														clinicalSelectedOption
													}
													onChange={(e) => {
														handleInputChange(
															e,
															formData,
															setFormData,
														);
														if (
															e.target.value ===
															'clinical_text'
														) {
															setClinicalSelectedOption(
																'clinical_text',
															);
														} else if (
															e.target.value ===
															'clinical_document'
														) {
															setClinicalSelectedOption(
																'clinical_document',
															);
														}
													}}
												>
													<XRadio
														value={'clinical_text'}
													>
														Enter Clinical Summary
													</XRadio>
													<XRadio
														value={
															'clinical_document'
														}
													>
														Upload Clinical Summary
													</XRadio>
												</XRadio.Group>
											</XForm.Item>
										</Col>
										<Col xs={24}>
											<XDivider />
										</Col>
										<Col xs={24}>
											<AddInputIcon>
												<EnvironmentOutlined />
											</AddInputIcon>
											<XForm.Item
												name="state"
												rules={[
													{
														required: true,
														message:
															'Please select State',
													},
												]}
												className="mb-0"
											>
												<XSelectIcons
													size="large"
													placeholder="Select State"
													name="state"
													showSearch={true}
													// onSearch={(search) => {
													// 	if (
													// 		search.length >= 3
													// 	) {
													// 		getStateDropdown({
													// 			search,
													// 		});
													// 	} else if (
													// 		search.length === 0
													// 	) {
													// 		getStateDropdown();
													// 	}
													// }}
													filterOption={filterByLabel}
													options={
														getStateDropdownState.apiState ===
															'success' &&
														getStateDropdownState
															.data.all_states
															?.length > 0 &&
														getStateDropdownState.data.all_states.map(
															(itm) => {
																return {
																	label: itm.state,
																	value: itm.state,
																	providers:
																		itm.providers,
																};
															},
														)
													}
													onSelect={(
														value,
														options,
													) => {
														setState(value);
														summaryForm.setFieldValue(
															'insurance_company',
															null,
														);
														setInsuranceCompanies(
															options.providers,
														);
													}}
												></XSelectIcons>
											</XForm.Item>
										</Col>
										<Col xs={24} className="mt-2">
											<AddInputIcon>
												<UserSwitchOutlined />
											</AddInputIcon>
											<XForm.Item
												name="insurance_company"
												className="mb-0"
												rules={[
													{
														required: true,
														message:
															'Please select insurance provider',
													},
												]}
											>
												<XSelectIcons
													size="large"
													placeholder="Select Insurance Company"
													name="insurance_company"
													showSearch={true}
													filterOption={filterByLabel}
													options={
														insuranceCompanies.length >
															0 &&
														insuranceCompanies.map(
															(itm) => {
																return {
																	label: itm.name,
																	value: itm.name,
																};
															},
														)
													}
													onSelect={(value) => {
														setProvider(value);
													}}
												></XSelectIcons>
											</XForm.Item>
										</Col>
									</Row>
								</Col>
								<Col xs={14}>
									{clinicalSelectedOption ===
										'clinical_text' && (
										<XForm.Item
											className="addCaseLabel mb-0"
											name="clinical_summary"
											rules={[
												{
													required: true,
													message:
														'Please enter clinical summary',
												},
											]}
										>
											<XInputTextArea.TextArea
												className="textAreaStyle"
												name="clinical_summary"
												// showCount
												autoSize={{
													minRows: 9,
												}}
												onChange={(e) => {
													handleInputChange(
														e,
														formData,
														setFormData,
													);
													setSummary(e.target.value);
													setClinicalDocumentFileList(
														[],
													);
													setUpdatedClinicalDocumentFileList(
														[],
													);
												}}
												placeholder="Enter Detailed Clinical Summary"
											/>
										</XForm.Item>
									)}
									{clinicalSelectedOption ===
										'clinical_document' && (
										<XCardDesktop className="bodyPadding h-auto">
											<XForm.Item
												className="mb-0"
												name="clinical_document"
												rules={[
													{
														required: true,
														validator: (
															_,
															value,
														) => {
															if (
																clinicalDocumentFileList.length ===
																0
															) {
																return Promise.reject(
																	new Error(
																		'Please upload Clinical Document!',
																	),
																);
															}
															return Promise.resolve();
														},
													},
												]}
											>
												<div className="uploadClinicalDocument">
													<Dragger
														style={{
															background: 'none',
														}}
														action={null}
														multiple={false}
														maxCount={1}
														showUploadList={{
															showPreviewIcon: true,
															showRemoveIcon: true,
														}}
														className="supporting_files"
														listType="picture-card"
														fileList={
															clinicalDocumentFileList
														}
														onPreview={
															handlePreviewClinicalSummary
														}
														onChange={
															handleClinicalDocumentChange
														}
														onRemove={(file) => {
															handleRemoveClinicalDocuments(
																file,
															);
															return false; // Prevent default removal behavior to avoid triggering onChange
														}}
														accept=".pdf"
														beforeUpload={(
															file,
														) => {
															const isJPEG =
																file.type ===
																'image/jpeg';
															const isJPG =
																file.type ===
																'image/jpg';
															const isPNG =
																file.type ===
																'image/png';
															const isGIF =
																file.type ===
																'image/gif';
															const isVideo =
																file.type.startsWith(
																	'video/',
																);
															const isAudio =
																file.type.startsWith(
																	'audio/',
																);

															const isLt10M =
																file.size /
																	1024 /
																	1024 <
																10;

															if (!isLt10M) {
																notification.error(
																	{
																		message:
																			'File Size Exceeded',
																		description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
																	},
																);
															}
															if (
																isGIF ||
																isVideo ||
																isAudio ||
																isJPEG ||
																isJPG ||
																isPNG
															) {
																notification.error(
																	{
																		message:
																			'File Type Error',
																		description: `GIF, video, image and audio files are not allowed.`,
																	},
																);
															}

															return (
																isLt10M &&
																!isGIF &&
																!isVideo &&
																!isAudio &&
																!isJPEG &&
																!isJPG &&
																!isPNG
															);
														}}
													>
														{clinicalDocumentFileList.length >=
														1 ? null : (
															<>
																<img
																	src="/images/aipa-clinical-document.svg"
																	alt=""
																/>
																<p className="uploadClinicalDocumentText">
																	Click or
																	drag file to
																	this area to
																	upload
																</p>
															</>
														)}
													</Dragger>
												</div>
											</XForm.Item>
										</XCardDesktop>
									)}
								</Col>
								{/* Supporting Docs new column start */}
								<Col xs={24} className="mt-3">
									<XCardDesktop className="bodyPadding bodyHeight h-auto">
										<XForm.Item
											className="addCaseLabel mb-0 p-3"
											label="Upload Supporting Documents (Optional)"
										>
											<Space
												size={[5, 5]}
												style={{
													flexWrap: 'wrap',
													alignItems: 'start',
												}}
											>
												{supportingFiles.length > 0 &&
													supportingFiles.map(
														(ele) => {
															return (
																<>
																	{(
																		ele.url
																			? ele.url.name
																					.split(
																						'.',
																					)
																					.includes(
																						'pdf',
																					)
																			: ele.originFileObj.name
																					.split(
																						'.',
																					)
																					.includes(
																						'pdf',
																					)
																	) ? (
																		<>
																			<div className="image-container_case_filing">
																				<Card
																					hoverable
																					style={{
																						// display:
																						// 	'flex',
																						justifyContent:
																							'center',
																						alignItems:
																							'center',
																						borderRadius:
																							'6px',
																						border: '1px dashed #d9d9d9',
																						display:
																							'block',
																						height: '100px',
																						padding:
																							'4px 15px',
																						width: '100px',
																						background:
																							'rgba(0, 0, 0, 0.02)',
																						// margin: '20px',
																					}}
																					onClick={() =>
																						handlePreview(
																							ele.url,
																						)
																					}
																					id="timeline-documents-hover"
																				>
																					<div
																						style={{
																							display:
																								'flex',
																							justifyContent:
																								'center',
																							alignItems:
																								'center',
																							flexDirection:
																								'column',
																							textAlign:
																								'center',
																						}}
																					>
																						<FilePdfOutlined
																							style={{
																								fontSize:
																									'30px',
																								border: 'none',
																								background:
																									'transparent',
																							}}
																						/>
																					</div>
																				</Card>
																				<div
																					className="overlay_case_filing"
																					style={{
																						height: '100px',
																					}}
																				>
																					<EyeOutlined
																						onClick={() =>
																							handlePreview(
																								ele.url,
																							)
																						}
																						className="icon eye-icon"
																					/>
																					<DeleteOutlined
																						onClick={() =>
																							handleRemoveFile(
																								ele,
																							)
																						}
																						className="icon download-icon"
																					/>
																				</div>
																				<Tooltip
																					title={
																						ele.document_name
																					}
																				>
																					<div
																						style={{
																							textAlign:
																								'center',
																							marginTop:
																								'5px',
																							fontSize:
																								'12px',
																							color: '#000',
																							whiteSpace:
																								'nowrap',
																							overflow:
																								'hidden',
																							textOverflow:
																								'ellipsis',
																							width: '100px', // or any other width as per requirement
																						}}
																					>
																						{
																							ele.document_name
																						}
																					</div>
																				</Tooltip>
																			</div>
																		</>
																	) : (
																		<>
																			<div className="image-container_case_filing">
																				<Image
																					width={
																						100
																					}
																					height={
																						100
																					}
																					style={{
																						borderRadius:
																							'10px',
																					}}
																					className="supporting-docs-hover"
																					src={`${ele.preview}`}
																					onClick={() =>
																						handlePreview(
																							ele.url,
																						)
																					}
																				/>
																				<div
																					className="overlay_case_filing"
																					style={{
																						height: '100px',
																					}}
																				>
																					<EyeOutlined
																						onClick={() =>
																							handlePreview(
																								ele.url,
																							)
																						}
																						className="icon eye-icon"
																					/>
																					<DeleteOutlined
																						onClick={() =>
																							handleRemoveFile(
																								ele,
																							)
																						}
																						className="icon download-icon"
																					/>
																				</div>
																				<Tooltip
																					title={
																						ele.document_name
																					}
																				>
																					<div
																						style={{
																							textAlign:
																								'center',
																							marginTop:
																								'5px',
																							fontSize:
																								'12px',
																							color: '#000',
																							whiteSpace:
																								'nowrap',
																							overflow:
																								'hidden',
																							textOverflow:
																								'ellipsis',
																							width: '100px', // or any other width as per requirement
																						}}
																					>
																						{
																							ele.document_name
																						}
																					</div>
																				</Tooltip>
																			</div>
																		</>
																	)}
																</>
															);
														},
													)}
												{supportingFiles.length > 25
													? null
													: uploadSupportingDocButton}
											</Space>
										</XForm.Item>
									</XCardDesktop>
								</Col>
							</Row>
							<Row gutter={(15, 15)} className="mt-5">
								<Col xs={{ span: 14, offset: 10 }}>
									<XShazaamBtn
										size="large"
										htmlType="submit"
										type="primary"
									>
										Shazaam !
									</XShazaamBtn>
								</Col>
							</Row>
						</XForm>
					</XContainer>
				</AipaDesktopBody>
				{/* End Desktop design */}
				{/* Start Mobile design */}
				<AipaMobileBody className="paddingPage">
					<XContainer fluid className="paddingPage">
						<XForm
							form={summaryForm}
							name="loginForm"
							layout="vertical"
							autoComplete="off"
							onFinish={handleShazamClick}
						>
							<Row>
								<Col
									xs={{ span: 16, offset: 4 }}
									className="text-center"
								>
									<XLottieAipaLoader>
										<XLottieAipa
											animationData={LoaderJson}
											loop={true}
										/>
									</XLottieAipaLoader>
								</Col>
								<Col xs={24}>
									<XDecodexTitle>DeCodeX</XDecodexTitle>
								</Col>
								<Col xs={24} className="mt-3">
									<ParaDeocdex>
										DeCodeX utilizes comprehensive
										standardized clinical notes to generate
										recommended best-fit diagnostic ICD-10,
										procedural CPT/HCPCS or drug NDC codes.
										It also provides insights into Medicare
										coverage.
									</ParaDeocdex>
								</Col>
								<Col xs={24} className="mt-4">
									<AddInputIcon>
										{clinicalSelectedOption ===
											'clinical_text' && (
											<img
												src="/images/aipa-clinical-icon.svg"
												alt=""
											/>
										)}
										{clinicalSelectedOption ===
											'clinical_document' && (
											<UploadOutlined />
										)}
									</AddInputIcon>
									<XForm.Item
										className="addCaseLabel mb-0"
										name="clinical_category"
										rules={[
											{
												required: true,
												message:
													'Please select option!',
											},
										]}
									>
										<XSelectIcons
											size="large"
											defaultValue={
												clinicalSelectedOption
											}
											options={[
												{
													value: 'clinical_text',
													label: 'Enter Clinical Summary',
												},
												{
													value: 'clinical_document',
													label: 'Upload Clinical Summary',
												},
											]}
											onChange={(value) => {
												handleInputChange(
													{
														target: {
															name: 'clinical_category',
															value,
														},
													},
													formData,
													setFormData,
												);
												setClinicalSelectedOption(
													value,
												);
											}}
										></XSelectIcons>
									</XForm.Item>
								</Col>
								<Col xs={24} className="mt-3">
									{clinicalSelectedOption ===
										'clinical_text' && (
										<XForm.Item
											className="addCaseLabel mb-0"
											name="clinical_summary"
											rules={[
												{
													required: true,
													message:
														'Please enter clinical summary',
												},
											]}
										>
											<XInputTextArea.TextArea
												className="textAreaStyle"
												name="clinical_summary"
												// showCount
												autoSize={{
													minRows: 9,
												}}
												onChange={(e) => {
													handleInputChange(
														e,
														formData,
														setFormData,
													);
													setSummary(e.target.value);
													setClinicalDocumentFileList(
														[],
													);
													setUpdatedClinicalDocumentFileList(
														[],
													);
												}}
												placeholder="Enter Detailed Clinical Summary"
											/>
										</XForm.Item>
									)}
									{clinicalSelectedOption ===
										'clinical_document' && (
										<XCardDesktop className="bodyPadding h-auto">
											<XForm.Item
												className="mb-0"
												name="clinical_document"
												rules={[
													{
														required: true,
														validator: (
															_,
															value,
														) => {
															if (
																clinicalDocumentFileList.length ===
																0
															) {
																return Promise.reject(
																	new Error(
																		'Please upload Clinical Document!',
																	),
																);
															}
															return Promise.resolve();
														},
													},
												]}
											>
												<div className="uploadClinicalDocument">
													<Dragger
														style={{
															background: 'none',
														}}
														action={null}
														multiple={false}
														maxCount={1}
														showUploadList={{
															showPreviewIcon: true,
															showRemoveIcon: true,
														}}
														className="supporting_files"
														listType="picture-card"
														fileList={
															clinicalDocumentFileList
														}
														onPreview={
															handlePreviewClinicalSummary
														}
														onChange={
															handleClinicalDocumentChange
														}
														onRemove={(file) => {
															handleRemoveClinicalDocuments(
																file,
															);
															return false; // Prevent default removal behavior to avoid triggering onChange
														}}
														accept=".pdf"
														beforeUpload={(
															file,
														) => {
															const isJPEG =
																file.type ===
																'image/jpeg';
															const isJPG =
																file.type ===
																'image/jpg';
															const isPNG =
																file.type ===
																'image/png';
															const isGIF =
																file.type ===
																'image/gif';
															const isVideo =
																file.type.startsWith(
																	'video/',
																);
															const isAudio =
																file.type.startsWith(
																	'audio/',
																);

															const isLt10M =
																file.size /
																	1024 /
																	1024 <
																10;

															if (!isLt10M) {
																notification.error(
																	{
																		message:
																			'File Size Exceeded',
																		description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
																	},
																);
															}
															if (
																isGIF ||
																isVideo ||
																isAudio ||
																isJPEG ||
																isJPG ||
																isPNG
															) {
																notification.error(
																	{
																		message:
																			'File Type Error',
																		description: `GIF, video, image and audio files are not allowed.`,
																	},
																);
															}

															return (
																isLt10M &&
																!isGIF &&
																!isVideo &&
																!isAudio &&
																!isJPEG &&
																!isJPG &&
																!isPNG
															);
														}}
													>
														{clinicalDocumentFileList.length >=
														1 ? null : (
															<>
																<img
																	src="/images/aipa-clinical-document.svg"
																	alt=""
																/>
																<p className="uploadClinicalDocumentText">
																	Click or
																	drag file to
																	this area to
																	upload
																</p>
															</>
														)}
													</Dragger>
												</div>
											</XForm.Item>
										</XCardDesktop>
									)}
								</Col>
								<Col xs={24} className="mt-3">
									<AddInputIcon>
										<EnvironmentOutlined />
									</AddInputIcon>
									<XForm.Item
										name="state"
										rules={[
											{
												required: true,
												message: 'Please select State',
											},
										]}
										className="mb-0"
									>
										<XSelectIcons
											size="large"
											placeholder="Select State"
											name="state"
											showSearch={true}
											// onSearch={(search) => {
											// 	if (search.length >= 3) {
											// 		getStateDropdown({
											// 			search,
											// 		});
											// 	} else if (
											// 		search.length === 0
											// 	) {
											// 		getStateDropdown();
											// 	}
											// }}
											filterOption={filterByLabel}
											options={
												getStateDropdownState.apiState ===
													'success' &&
												getStateDropdownState.data
													.all_states?.length > 0 &&
												getStateDropdownState.data.all_states.map(
													(itm) => {
														return {
															label: itm.state,
															value: itm.state,
															providers:
																itm.providers,
														};
													},
												)
											}
											onSelect={(value, options) => {
												setState(value);
												summaryForm.setFieldValue(
													'insurance_company',
													null,
												);
												setInsuranceCompanies(
													options.providers,
												);
											}}
										></XSelectIcons>
									</XForm.Item>
								</Col>
								<Col xs={24} className="mt-3">
									<AddInputIcon>
										<UserSwitchOutlined />
									</AddInputIcon>
									<XForm.Item
										name="insurance_company"
										className="mb-0"
										rules={[
											{
												required: true,
												message:
													'Please select insurance provider',
											},
										]}
									>
										<XSelectIcons
											size="large"
											placeholder="Select Insurance Company"
											name="insurance_company"
											showSearch={true}
											filterOption={filterByLabel}
											options={
												insuranceCompanies.length > 0 &&
												insuranceCompanies.map(
													(itm) => {
														return {
															label: itm.name,
															value: itm.name,
														};
													},
												)
											}
											onSelect={(value) => {
												setProvider(value);
											}}
										></XSelectIcons>
									</XForm.Item>
								</Col>
								{/* Supporting Docs new column start */}
								<Col xs={24} className="mt-3 h-auto">
									<XCardDesktop className="bodyPadding bodyHeight h-auto">
										<XForm.Item
											className="addCaseLabel mb-0 p-3"
											label="Upload Supporting Documents (Optional)"
										>
											<Space
												size={[5, 5]}
												style={{
													flexWrap: 'wrap',
													alignItems: 'start',
												}}
											>
												{supportingFiles.length > 0 &&
													supportingFiles.map(
														(ele) => {
															return (
																<>
																	{(
																		ele.url
																			? ele.url.name
																					.split(
																						'.',
																					)
																					.includes(
																						'pdf',
																					)
																			: ele.originFileObj.name
																					.split(
																						'.',
																					)
																					.includes(
																						'pdf',
																					)
																	) ? (
																		<>
																			<div className="image-container_case_filing">
																				<Card
																					hoverable
																					style={{
																						// display:
																						// 	'flex',
																						justifyContent:
																							'center',
																						alignItems:
																							'center',
																						borderRadius:
																							'6px',
																						border: '1px dashed #d9d9d9',
																						display:
																							'block',
																						height: '100px',
																						padding:
																							'4px 15px',
																						width: '100px',
																						background:
																							'rgba(0, 0, 0, 0.02)',
																						// margin: '20px',
																					}}
																					onClick={() =>
																						handlePreview(
																							ele.url,
																						)
																					}
																					id="timeline-documents-hover"
																				>
																					<div
																						style={{
																							display:
																								'flex',
																							justifyContent:
																								'center',
																							alignItems:
																								'center',
																							flexDirection:
																								'column',
																							textAlign:
																								'center',
																						}}
																					>
																						<FilePdfOutlined
																							style={{
																								fontSize:
																									'30px',
																								border: 'none',
																								background:
																									'transparent',
																							}}
																						/>
																					</div>
																				</Card>
																				<div
																					className="overlay_case_filing"
																					style={{
																						height: '100px',
																					}}
																				>
																					<EyeOutlined
																						onClick={() =>
																							handlePreview(
																								ele.url,
																							)
																						}
																						className="icon eye-icon"
																					/>
																					<DeleteOutlined
																						onClick={() =>
																							handleRemoveFile(
																								ele,
																							)
																						}
																						className="icon download-icon"
																					/>
																				</div>
																				<Tooltip
																					title={
																						ele.document_name
																					}
																				>
																					<div
																						style={{
																							textAlign:
																								'center',
																							marginTop:
																								'5px',
																							fontSize:
																								'12px',
																							color: '#000',
																							whiteSpace:
																								'nowrap',
																							overflow:
																								'hidden',
																							textOverflow:
																								'ellipsis',
																							width: '100px', // or any other width as per requirement
																						}}
																					>
																						{
																							ele.document_name
																						}
																					</div>
																				</Tooltip>
																			</div>
																		</>
																	) : (
																		<>
																			<div className="image-container_case_filing">
																				<Image
																					width={
																						100
																					}
																					height={
																						100
																					}
																					style={{
																						borderRadius:
																							'10px',
																					}}
																					className="supporting-docs-hover"
																					src={`${ele.preview}`}
																					onClick={() =>
																						handlePreview(
																							ele.url,
																						)
																					}
																				/>
																				<div
																					className="overlay_case_filing"
																					style={{
																						height: '100px',
																					}}
																				>
																					<EyeOutlined
																						onClick={() =>
																							handlePreview(
																								ele.url,
																							)
																						}
																						className="icon eye-icon"
																					/>
																					<DeleteOutlined
																						onClick={() =>
																							handleRemoveFile(
																								ele,
																							)
																						}
																						className="icon download-icon"
																					/>
																				</div>
																				<Tooltip
																					title={
																						ele.document_name
																					}
																				>
																					<div
																						style={{
																							textAlign:
																								'center',
																							marginTop:
																								'5px',
																							fontSize:
																								'12px',
																							color: '#000',
																							whiteSpace:
																								'nowrap',
																							overflow:
																								'hidden',
																							textOverflow:
																								'ellipsis',
																							width: '100px', // or any other width as per requirement
																						}}
																					>
																						{
																							ele.document_name
																						}
																					</div>
																				</Tooltip>
																			</div>
																		</>
																	)}
																</>
															);
														},
													)}
												{supportingFiles.length > 25
													? null
													: uploadSupportingDocButton}
											</Space>
										</XForm.Item>
									</XCardDesktop>
								</Col>
								<Col xs={24} className="text-center mt-5">
									<XShazaamBtn
										size="large"
										htmlType="submit"
										type="primary"
									>
										Shazaam !
									</XShazaamBtn>
								</Col>
							</Row>
						</XForm>
					</XContainer>
				</AipaMobileBody>
				{/* End Mobile design */}
			</div>

			<XContainer className="slideBox" fluid>
				<div id="getAns" className="slideUp top-100 mt-0 d-none">
					<Row gutter={(0, 30)}>
						<Col xs={24} sm={24} md={24} lg={14} xl={16}>
							{/* new component start */}
							<CaseOperations
								aipa={false}
								previewMode={false}
								matchGender={''}
								loading={loading}
								summary={summary}
								state={state}
								provider={provider}
								setSummary={setSummary}
								handleRemoveClinicalDocuments={
									handleRemoveClinicalDocuments
								}
								handleClinicalSummaryDoc={
									handleClinicalSummaryDoc
								}
								isOpenAnalysis={'true'}
								patientData={''}
								mainDataLoading={''}
								handleClinicalDocsChange={
									handleClinicalDocsChange
								}
								clinicalDocumentFileList={
									clinicalDocumentFileList
								}
								clinicalDocsForm={clinicalDocsForm}
								handleUploadCancel={handleUploadCancel}
								clinicalDocsLoading={clinicalDocsLoading}
								setIsClinicalUploadModalOpen={
									setIsClinicalUploadModalOpen
								}
								isClinicalUploadModalOpen={
									isClinicalUploadModalOpen
								}
								setPreviewImage={setPreviewImage}
								previewImage={previewImage}
								setPreviewTitle={setPreviewTitle}
								previewTitle={previewTitle}
								setPdfPreviewOpen={setPdfPreviewOpen}
								pdfPreviewOpen={pdfPreviewOpen}
								documentsToDisplay={supportingFiles}
								supportingDocsSummary={supportingDocsSummary}
								clinicalSummaryTextLoading={
									clinicalSummaryTextLoading
								}
								setClinicalSummaryTextLoading={
									setClinicalSummaryTextLoading
								}
								setShowClinicalTextArea={
									setShowClinicalTextArea
								}
								showClinicalTextArea={showClinicalTextArea}
								setFormData={setFormData}
								formData={formData}
								id={id}
								// editCase={editCase}
								setPageContent={setPageContent}
								pageContent={pageContent}
								clinicalDocumentsToDisplay={
									clinicalDocumentsToDisplay
								}
								feedbackId={feedbackId}
								reviseCoverage={reviseCoverage}
								lastAnalysisData={lastAnalysisData}
								// closeIcdCptDrawer2={closeIcdCptDrawer2}
								// closeIcdCptDrawer={closeIcdCptDrawer}
								setLoading={setLoading}
								getBase64={getBase64}
								setClinicalDocsLoading={setClinicalDocsLoading}
								setSubmitClinicalDocs={setSubmitClinicalDocs}
								setUpdatedClinicalDocumentFileList={''}
								form={form}
								setModalTextArea={setModalTextArea}
								setModalOpen={setModalOpen}
								handleCodeClick={handleCodeClick}
								modalOpen={modalOpen}
								sendFeedback={sendFeedback}
								sendFeedbackState={sendFeedbackState}
								modalTextArea={modalTextArea}
								updatePageContent={updatePageContent}
								missingFieldsForm={missingFieldsForm}
								missingFieldsCardRef={missingFieldsCardRef}
								handleAddOption={handleAddOption}
								missingFields={missingFields}
								missingFieldInstructions={
									missingFieldInstructions
								}
								setMissingFieldsModal={setMissingFieldsModal}
								setMissingFieldInstructions={
									setMissingFieldInstructions
								}
								setMissingFields={setMissingFields}
								missingFieldTitle={missingFieldTitle}
								missingFieldsModal={missingFieldsModal}
								// LooksGoodLoading={LooksGoodLoading}
								// coverageloading={coverageloading}
								// setCoverageLoading={
								// 	setCoverageLoading
								// }
								// setLooksGoodLoading={
								// 	setLooksGoodLoading
								// }
							/>
							{/* new component end */}

							{!missingFieldsModal ? (
								<>
									<div className="showMobile">
										<Row gutter={(15, 15)} className="mt-4">
											<Col xs={12}>
												<XButtonConfirm
													className="w-100"
													type="primary"
													size="large"
													shape="round"
													style={{
														background: '#fff',
													}}
													icon={
														<img
															className="rotateButton"
															src="/images/like-icon.svg"
															alt=""
														/>
													}
													onClick={() => {
														form.setFieldsValue({
															modal_text_area:
																pageContent.medicalCoverage
																	? pageContent
																			.medicalCoverage[1]
																	: '',
															medicare_flag:
																pageContent.medicalCoverage
																	? pageContent
																			.medicalCoverage[0]
																	: '',
														});
														setModalTextArea(
															pageContent
																.medicalCoverage[1],
														);
														setModalOpen([
															true,
															`Edit Medicare Coverage`,
															pageContent
																.medicalCoverage[1],
															1,
															'feedbackSummary',
														]);
														setCallGetAnalysisAPiBtn(
															true,
														);
													}}
												>
													Refine
												</XButtonConfirm>
											</Col>
											<Col xs={12}>
												<XButtonConfirm
													className="w-100"
													type="primary"
													size="large"
													shape="round"
													style={{
														background: '#fff',
													}}
													icon={
														<img
															src="/images/like-icon.svg"
															alt=""
														/>
													}
													loading={LooksGoodLoading}
													onClick={() => {
														setLooksGoodLoading(
															true,
														);
														setCallGetAnalysisAPiBtn(
															true,
														);
														sendFeedback({
															feedback_id:
																feedbackId,
															feedback:
																'positive',
															status: 'approved',
															response:
																pageContent.medicalCoverage,
														});
													}}
												>
													Looks Good
												</XButtonConfirm>
											</Col>
											<Col xs={24} className="mt-4">
												<XButtonConfirm
													className="btnActive w-100"
													loading={coverageloading}
													onClick={() => {
														setCoverageLoading(
															true,
														);
														setCallGetAnalysisAPiBtn(
															true,
														);

														sendFeedback({
															feedback_id:
																feedbackId,
															status: 'approved',
															response:
																pageContent.medicalCoverage,
														});
													}}
													disabled={
														pageContent.status ===
														'approved'
															? true
															: false
													}
													type="primary"
													shape="round"
													// icon={
													// 	<img
													// 		src="/images/check 1.svg"
													// 		alt=""
													// 	/>
													// }
													size="large"
												>
													Confirm Coverage
												</XButtonConfirm>
											</Col>
										</Row>
									</div>
									<div className="showDesktop">
										<Row className="text-end">
											<Col
												xs={24}
												lg={12}
												className="mt-4"
											>
												<Space
													style={{
														width: '100%',
													}}
												>
													<XButtonConfirm
														className="mb-2 mb-md-0 mb-sm-0"
														type="primary"
														size="large"
														shape="round"
														style={{
															background: '#fff',
														}}
														icon={
															<img
																className="rotateButton"
																src="/images/like-icon.svg"
																alt=""
															/>
														}
														onClick={() => {
															form.setFieldsValue(
																{
																	modal_text_area:
																		pageContent.medicalCoverage
																			? pageContent
																					.medicalCoverage[1]
																			: '',
																	medicare_flag:
																		pageContent.medicalCoverage
																			? pageContent
																					.medicalCoverage[0]
																			: '',
																},
															);
															setModalTextArea(
																pageContent
																	.medicalCoverage[1],
															);
															setModalOpen([
																true,
																`Edit Medicare Coverage`,
																pageContent
																	.medicalCoverage[1],
																1,
																'feedbackSummary',
															]);
															setCallGetAnalysisAPiBtn(
																true,
															);
														}}
													>
														Refine
													</XButtonConfirm>
													<XButtonConfirm
														className="mb-2 mb-md-0 mb-sm-0"
														type="primary"
														size="large"
														shape="round"
														style={{
															background: '#fff',
														}}
														icon={
															<img
																src="/images/like-icon.svg"
																alt=""
															/>
														}
														loading={
															LooksGoodLoading
														}
														onClick={() => {
															setLooksGoodLoading(
																true,
															);
															setCallGetAnalysisAPiBtn(
																true,
															);
															sendFeedback({
																feedback_id:
																	feedbackId,
																feedback:
																	'positive',
																status: 'approved',
																response:
																	pageContent.medicalCoverage,
															});
														}}
													>
														Looks Good
													</XButtonConfirm>
												</Space>
											</Col>
											<Col
												xs={24}
												lg={12}
												className="mt-4 text-md-end"
											>
												<XButtonConfirm
													loading={coverageloading}
													onClick={() => {
														setCoverageLoading(
															true,
														);
														setCallGetAnalysisAPiBtn(
															true,
														);

														sendFeedback({
															feedback_id:
																feedbackId,
															status: 'approved',
															response:
																pageContent.medicalCoverage,
														});
													}}
													disabled={
														pageContent.status ===
														'approved'
															? true
															: false
													}
													type="primary"
													shape="round"
													// icon={
													// 	<img
													// 		src="/images/check 1.svg"
													// 		alt=""
													// 	/>
													// }
													size="large"
													className="btnActive"
												>
													Confirm Coverage
												</XButtonConfirm>
											</Col>
										</Row>
									</div>
								</>
							) : (
								<></>
							)}
						</Col>
						{/* // todo coding tools */}
						<Col
							xs={24}
							sm={24}
							md={24}
							lg={10}
							xl={8}
							className="mt-4 mt-lg-0"
						>
							<DrawerCodingTools
								activeTab={activeTab}
								setActiveTab={setActiveTab}
								openIcdDrawer={openIcdDrawer}
								setOpenIcdDrawer={setOpenIcdDrawer}
								openCptDrawer={openCptDrawer}
								setOpenCptDrawer={setOpenCptDrawer}
								previewMode={false}
								fixed={isFixed}
								icdform={icdform}
								cptform={cptform}
								activeCode={activeCode}
								setActiveCode={setActiveCode}
								closeIcdCptDrawer={closeIcdCptDrawer}
								openIcdCptDrawer={openIcdCptDrawer}
								handleCodeClick={handleCodeClick}
								setModalOpen={setModalOpen}
								setModalTextArea={setModalTextArea}
								closeIcdCptDrawer2={closeIcdCptDrawer2}
								openIcdCptDrawer2={openIcdCptDrawer2}
								modalOpen={modalOpen}
								updatePageContent={updatePageContent}
								modalTextArea={modalTextArea}
								setIcdCodes={setIcdCodes}
								icdCodes={icdCodes}
								searchResults={searchResults}
								setSearchResults={setSearchResults}
								icdLoading={icdLoading}
								setIcdLoading={setIcdLoading}
								triggeredBySearch={triggeredBySearch}
								setTriggeredBySearch={setTriggeredBySearch}
								triggeredByEnter={triggeredByEnter}
								setTriggeredByEnter={setTriggeredByEnter}
								loadMore={loadMore}
								setLoadMore={setLoadMore}
								icdSearchLoading={icdSearchLoading}
								setIcdSearchLoading={setIcdSearchLoading}
								cptCodes={cptCodes}
								setCptCodes={setCptCodes}
								cptLoading={cptLoading}
								setCptLoading={setCptLoading}
								cptSearchResults={cptSearchResults}
								setCptSearchResults={setCptSearchResults}
								cptTriggeredBySearch={cptTriggeredBySearch}
								setCptTriggeredBySearch={
									setCptTriggeredBySearch
								}
								cptTriggeredByEnter={cptTriggeredByEnter}
								setCptTriggeredByEnter={setCptTriggeredByEnter}
								cptLoadMore={cptLoadMore}
								setCptLoadMore={setCptLoadMore}
								cptSearchLoading={cptSearchLoading}
								setCptSearchLoading={setCptSearchLoading}
								icdFilters={icdFilters}
								setIcdFilters={setIcdFilters}
								cptFilters={cptFilters}
								setCptFilters={setCptFilters}
							/>
						</Col>
					</Row>
					{/* //todo Drawer of the Coding Tools Start */}
					{!isFixed && (
						<XAddCodeSticky
							type="primary"
							onClick={showIcdCptDrawer}
						>
							Coding Tools
						</XAddCodeSticky>
					)}
				</div>
			</XContainer>

			<Modal
				title="Upload Supporting Documents"
				open={isUploadModalOpen}
				onOk={handleSupportingDocSubmit}
				onCancel={handleUploadCancel}
				okButtonProps={{
					style: {
						backgroundColor: '#007299',
						borderColor: '#007299',
						color: '#fff',
						borderRadius: '20px',
					},
				}}
				cancelButtonProps={{
					style: { color: '#007299', borderRadius: '20px' },
				}}
			>
				<XForm form={formUpload} layout="vertical" autoComplete="off">
					<XForm.Item
						name="Supporting_docs"
						rules={[
							{
								required: true,
								validator: (_, value) => {
									if (supportingDoc.length === 0) {
										return Promise.reject(
											new Error('Please Select file!'),
										);
									}
									return Promise.resolve();
								},
							},
						]}
					>
						<Dragger
							fileList={supportingDoc}
							maxCount={1}
							beforeUpload={(file) => {
								const isGIF = file.type === 'image/gif';
								const isVideo = file.type.startsWith('video/');
								const isAudio = file.type.startsWith('audio/');
								const isJPEG = file.type === 'image/jpeg';
								const isJPG = file.type === 'image/jpg';
								const isPNG = file.type === 'image/png';
								const isLt10M = file.size / 1024 / 1024 < 10;

								if (!isLt10M) {
									notification.error({
										message: 'File Size Exceeded',
										description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
									});
								}
								if (
									isGIF ||
									isVideo ||
									isAudio ||
									isJPEG ||
									isJPG ||
									isPNG
								) {
									notification.error({
										message: 'File Type Error',
										description: `GIF, video, image and audio files are not allowed.`,
									});
								}

								return (
									isLt10M &&
									!isGIF &&
									!isVideo &&
									!isAudio &&
									!isJPEG &&
									!isJPG &&
									!isPNG
								);
							}}
							// accept=".pdf,.jpeg,.jpg,.png"
							accept=".pdf"
							onChange={handleSupportingDocChange}
						>
							<p className="ant-upload-drag-icon">
								<InboxOutlined style={{ color: '#007299' }} />
							</p>
							<p className="ant-upload-text">
								Click or drag file to this area to upload
							</p>
						</Dragger>
					</XForm.Item>
					<XForm.Item
						name="document_name"
						label="Document Name"
						className="mt-3"
						rules={[
							{
								required: true,
								message: 'Document Name is required',
							},
						]}
					>
						<Input
							placeholder="Enter Document Name"
							// value={documentName}
							name="document_name"
							onChange={(e) => {
								setDocumentName(e.target.value);
							}}
						/>
					</XForm.Item>
				</XForm>
			</Modal>

			<XModal
				centered
				open={thanksModal[0]}
				closable={false} // Hide the close icon
				// onOk={() => setThanksModal([false, ''])}
				// onCancel={() => {
				// 	setThanksModal([false, '']);
				// }}
				className="modalButton"
				okText="Submit"
				footer={null}
			>
				<Row
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}
					gutter={(15, 0)}
					className="mt-2"
				>
					<Col xs={24} lg={18}>
						<XLottieMargin>
							<XLottie animationData={Thanks} loop={true} />
						</XLottieMargin>

						<XPara1
							style={{ textAlign: 'center' }}
							className="mt-0"
						>
							{thanksModal[1]}
						</XPara1>
					</Col>
				</Row>
			</XModal>

			{/* Modal for adding new option */}
			<XModal
				title="Add New Option"
				open={newCheckBoxModal[0]}
				onOk={handleNewCheckBoxModal}
				okText="Submit"
				okButtonProps={{
					style: {
						backgroundColor: '#007299',
						borderColor: '#007299',
						color: '#fff',
						borderRadius: '20px',
					},
				}}
				onCancel={() => {
					setNewCheckBoxModal([false, null]);
					setNewOptionText('');
					newOptionForm.setFieldsValue({});
					newOptionForm.resetFields();
				}}
			>
				<XForm
					form={newOptionForm}
					name="groupForm"
					layout="vertical"
					autoComplete="off"
				>
					<XForm.Item
						name={'new-option'}
						rules={[
							{
								required: true,
								message: 'Please input option',
							},
						]}
					>
						<Input
							size="large"
							className="mt-3 mb-3"
							value={newOptionText}
							onChange={(e) => setNewOptionText(e.target.value)}
							placeholder="Enter new option text"
						/>
					</XForm.Item>
				</XForm>
			</XModal>
		</>
	);
};

const mapStateToProps = (state) => ({
	analyseCoverageState: state.analyseCoverage,
	reviseCoverageState: state.reviseCoverage,
	sendFeedbackState: state.sendFeedback,
	getStateDropdownState: state.getStateDropdown,
	getAnalysisState: state.getAnalysis,
});

const mapDispatchToProps = (dispatch) => ({
	analyseCoverage: (params) => dispatch(analyseCoverageApi(params)),
	analyseCoverageReset: () => dispatch(analyseCoverageReset()),
	reviseCoverage: (params) => dispatch(reviseCoverageApi(params)),
	reviseCoverageReset: () => dispatch(reviseCoverageReset()),
	sendFeedback: (params) => dispatch(sendFeedbackApi(params)),
	sendFeedbackReset: () => dispatch(sendFeedbackReset()),
	getStateDropdown: () => dispatch(getStateDropdownApi()),
	getStateDropdownReset: () => dispatch(getStateDropdownReset()),
	getAnalysis: (params) => dispatch(getAnalysisApi(params)),
	getAnalysisReset: () => dispatch(getAnalysisReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyseCoverage);
