import React from 'react';
import './GlobalLoader.css'; // Import your custom styles

const GlobalLoader = ({ isLoading }) => {
	if (!isLoading) return null;

	return (
		<div className="global-loader">
			<div className="loader-overlay"></div>
		</div>
	);
};

export default GlobalLoader;
