import axios from 'axios';
import config from '../config';

export const ACCESS_REQUEST = 'ACCESS_REQUEST';
export const ACCESS_REQUEST_SUCCESS = 'ACCESS_REQUEST_SUCCESS';
export const ACCESS_REQUEST_FAIL = 'ACCESS_REQUEST_FAIL';
export const ACCESS_REQUEST_RESET = 'ACCESS_REQUEST_RESET';

export const accessRequest = (params) => {
	return {
		type: ACCESS_REQUEST,
		params,
	};
};

export const accessRequestSuccess = (response) => {
	return {
		type: ACCESS_REQUEST_SUCCESS,
		response,
	};
};

export const accessRequestFail = (response) => {
	return {
		type: ACCESS_REQUEST_FAIL,
		response,
	};
};

export const accessRequestReset = () => {
	return {
		type: ACCESS_REQUEST_RESET,
	};
};

export const accessRequestApi = (data) => {
	return (dispatch) => {
		dispatch(accessRequest());
		axios
			.post(`${config.api.base_url}/access-request/create`, data)
			.then((response) => {
				const requestData = response.data;
				dispatch(accessRequestSuccess(requestData));
			})
			.catch((error) => {
				dispatch(accessRequestFail(error.response.data));
			});
	};
};
