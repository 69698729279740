import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Result, Radio, Space } from 'antd';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import {
	XForm,
	XContainer,
	XButton,
	FilterButton,
	XSearch,
	XSelect,
	XButton2,
} from '../../styles/GlobalStyle';

import CaseSearch from '../../components/CaseSearch/CaseSearch';
// actions
import {
	getPayerConfigurationsApi,
	getPayerConfigurationsReset,
} from '../../actions/getPayerConfigurationsAction';
import { checkModulePermissions, truncateString } from '../../utils/helper';
import { dateFilters } from '../../utils/constants';
import HeaderFile from '../../components/Header/HeaderFile';
import DataTable from '../../components/DataTable/DataTable';
import Header from '../../components/Header/Header';
import { XButtonImg } from '../Manage/ManageStyle';
import { filterByLabel } from '../../utils/formFunctions';
import {
	SearchButton,
	XInputText,
} from '../AnalyseCoverage/AnalyseCoverageStyle';
import {
	getHospitalsApi,
	getHospitalsReset,
} from '../../actions/getHospitalsAction';

const PayerConfiguration = (props) => {
	const { getPayerConfigurations, getPayerConfigurationsState } = props;
	const defaultFilters = {
		search: '',
		page: 1,
		sort: 'updatedAt',
		sortDirection: 'descend',
		created_on: 'all',
		state: '',
		limit: 10,
		region: '',
	};
	const navigate = useNavigate();
	const [tableData, setTableData] = useState([]);
	const [totalRecords, setTotalRecords] = useState('');
	const getFiltersFromUrl = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const urlFilters = {};

		// Iterate through each query parameter and set it in the state
		for (const [key, value] of urlParams.entries()) {
			urlFilters[key] = value;
		}

		// Merge with default filters or initial state as needed
		const filters = {
			...defaultFilters, // Replace with your default filters
			...urlFilters,
		};

		return filters;
	};
	const initialFilters = getFiltersFromUrl();

	const [filters, setFilters] = useState(initialFilters);

	const [form2] = XForm.useForm();

	const [open, setOpen] = useState(false);

	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
	};

	const onChange = (key) => {
		setFilters({
			...filters,
			[key.target.name]: key.target.value,
		});
	};

	const columns = [
		{
			title: 'Hospital',
			width: 200,
			dataIndex: 'hospital',
			// fixed: 'left',
			render: (hospital) => (
				<div>
					<div>{hospital}</div>
				</div>
			),
		},
		{
			title: 'Insurance Company',
			width: 200,
			dataIndex: 'insurance_company',
			render: (insurance_company) => (
				<div>
					<div>{insurance_company}</div>
				</div>
			),
		},
		{
			title: 'Action',
			width: 150,
			dataIndex: 'action',
			fixed: 'right',
		},
	];

	const handleEdit = (item) => {
		navigate(`/payer-configuration/edit/${item._id}`);
	};

	// use effects

	useEffect(() => {
		if (getPayerConfigurationsState.apiState === 'success') {
			if (getPayerConfigurationsState.data.list.length > 0) {
				const updatedData = [];
				const updatedSearchOptions = [];
				setTotalRecords(getPayerConfigurationsState.data.total_count);
				getPayerConfigurationsState.data.list.map((item, index) => {
					updatedSearchOptions.push({ value: item.name });
					updatedData.push({
						key: item._id,
						_id: item._id,
						hospital: item.hospital[0].name,
						insurance_company: item.insurance_company[0].name,
						action: (
							<div className="text-center">
								<Row>
									<Col xs={{ span: 6, offset: 6 }}>
										<XButtonImg
											onClick={() => {
												handleEdit(item);
											}}
											type="text"
										>
											<img
												src="./images/pencil.svg"
												alt=""
											/>
										</XButtonImg>
									</Col>
								</Row>
							</div>
						),
					});
				});
				setTableData(updatedData);
			} else {
				setTableData([]);
				setTotalRecords(0);
			}
		}
	}, [getPayerConfigurationsState]);

	const applyFilters = () => {
		const queryParams = new URLSearchParams(window.location.search);

		// Iterate through filters and update the query parameters
		Object.entries(filters).forEach(([key, value]) => {
			const currentValue = queryParams.get(key);

			// Check if the parameter exists in the current query and has a value, if yes, update its value
			if (currentValue !== null) {
				queryParams.set(key, value);
			} else if (queryParams.has(key)) {
				// If the parameter exists but has no value, keep its existing value
				queryParams.set(key, queryParams.get(key));
			} else {
				// If the parameter does not exist in the current query, append it with the current value
				queryParams.append(key, value);
			}
		});

		// Set the page to 1
		queryParams.set('page', '1');
		queryParams.set('sort', 'createdAt');
		queryParams.set('sortDirection', 'descend');
		filters.page = 1;

		// Update the URL
		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
		window.history.pushState({}, '', newUrl);
	};

	useEffect(() => {
		// Retrieve filters from the updated URL
		const updatedFilters = getFiltersFromUrl();
		setFilters({ ...updatedFilters });

		// Make API call with updated filters and pagination
		checkModulePermissions('payer-configuration').sub_modules.includes(
			'list-payer-configuration',
		) && getPayerConfigurations({ ...updatedFilters });
	}, [window.location.search]);

	const clearFilters = () => {
		const queryParams = new URLSearchParams(window.location.search);

		// Iterate through defaultFilters and update the query parameters
		Object.entries(defaultFilters).forEach(([key, value]) => {
			const currentValue = queryParams.get(key);

			// Check if the parameter exists in the current query and has a value, if yes, update its value
			if (currentValue !== null) {
				queryParams.set(key, value);
			} else if (queryParams.has(key)) {
				// If the parameter exists but has no value, keep its existing value
				queryParams.set(key, queryParams.get(key));
			} else {
				// If the parameter does not exist in the current query, append it with the default value
				queryParams.append(key, value);
			}
		});

		// Clear the form fields
		form2.resetFields();

		// Update the state with default values
		setFilters(defaultFilters);

		// Update the URL
		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
		window.history.pushState({}, '', newUrl);
	};

	return (
		<>
			{props.displayHeader ? (
				<></>
			) : (
				<>
					<Col lg={24} xl={24} xs={0} md={0}>
						<Header headerTag="Payer Configuration" />
					</Col>
					<Col lg={0} xl={0} xs={24} md={24}>
						<HeaderFile activeNav="payerConfiguration" />
					</Col>
				</>
			)}
			<XContainer fluid>
				{checkModulePermissions('payer-configuration').authorized ? (
					<Card
						bodyStyle={{ padding: '10px' }}
						style={{ borderRadius: '30px' }}
					>
						<Row gutter={(15, 15)} style={{ padding: '20px ' }}>
							<Col
								xs={24}
								md={14}
								lg={{ span: 10 }}
								xl={{ span: 8 }}
								xxl={{ span: 6 }}
								className="mb-4 mb-lg-0"
							>
								<Space>
									<XInputText
										allowClear
										style={{ borderRadius: '25px' }}
										// size="large"
										placeholder="Search for Hospitals and Payers"
										defaultValue={
											filters.search
												? filters.search
												: null
										}
										onChange={(v) => {
											if (v.target.value === '') {
												// getOrganizations({
												// 	...filters,
												// 	search: '',
												// });
												getPayerConfigurations({
													...filters,
													search: '',
												});
											} else {
												setFilters({
													...filters,
													search: v.target.value,
												});
											}
										}}
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
												const searchValue =
													filters.search
														? filters.search
														: '';
												if (searchValue) {
													// getOrganizations({
													// 	...filters,
													// 	search: searchValue,
													// });
													getPayerConfigurations({
														...filters,
														search: searchValue,
													});
													setFilters({
														...filters,
														search: searchValue,
													});
												}
											}
										}}
									/>
									<SearchButton
										style={{
											borderRadius: '20px',
											color: '#007299',
										}}
										onClick={() => {
											// Trigger search when the button is clicked
											const searchValue = filters.search
												? filters.search
												: '';
											if (searchValue) {
												// getOrganizations({
												// 	...filters,
												// 	search: searchValue,
												// });
												getPayerConfigurations({
													...filters,
													search: searchValue,
												});
												setFilters({
													...filters,
													search: searchValue,
												});
											}
										}}
										className="px-4"
										size="large"
									>
										Search
									</SearchButton>
								</Space>
								{/* <XForm.Item>
									<XSearch
										placeholder="Search for Hospitals and Payers"
										allowClear
										enterButton="Search"
										size="large"
										onChange={(v) => {
											if (v.target.value === '') {
												// getOrganizations({
												// 	...filters,
												// 	search: '',
												// });
											}
										}}
										onSearch={(v) => {
											// getOrganizations({
											// 	...filters,
											// 	search: v,
											// });
											setFilters({
												...filters,
												search: v,
											});
										}}
									/>
								</XForm.Item> */}
							</Col>
							{/* <Col
								xs={24}
								md={10}
								lg={{ span: 4 }}
								xl={{ span: 4 }}
								xxl={{ span: 4 }}
							></Col> */}
							<Col
								xs={24}
								md={10}
								lg={{ offset: 6, span: 8 }}
								xl={{ offset: 12, span: 4 }}
								xxl={{ offset: 14, span: 4 }}
							>
								<XForm.Item className="text-right">
									<XButton2
										onClick={() =>
											navigate('/payer-configuration/add')
										}
										icon={<PlusOutlined />}
									>
										Payer Configuration
									</XButton2>
								</XForm.Item>
							</Col>
						</Row>
						<DataTable
							tableData={tableData}
							columns={columns}
							totalRecords={totalRecords}
							setFilters={setFilters}
							filters={filters}
							state={getPayerConfigurationsState.apiState}
							page={'payer-configuration'}
							handleEdit={handleEdit}
						/>
					</Card>
				) : (
					<Result
						status="403"
						title="403"
						subTitle="Sorry, you are not authorized to access this page."
						extra={
							<Button
								type="primary"
								onClick={() => navigate('/')}
							>
								Back Home
							</Button>
						}
					/>
				)}
			</XContainer>
		</>
	);
};

const mapStateToProps = (state) => ({
	getPayerConfigurationsState: state.getPayerConfigurations,
});

const mapDispatchToProps = (dispatch) => ({
	getPayerConfigurations: (data) => dispatch(getPayerConfigurationsApi(data)),
	getPayerConfigurationsReset: () => dispatch(getPayerConfigurationsReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayerConfiguration);
