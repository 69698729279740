import styled from 'styled-components';
import '../../styles/GlobalStyle';
import { Form, Checkbox, Button, Card, Input, Select } from 'antd';
import Lottie from 'lottie-react';
import Container from 'react-bootstrap/Container';

export const CenterWrapper = styled.section`
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 1px);
	background: transparent;
	@media only screen and (max-width: 768.99px) {
		display: block;
		align-items: center;
		position: relative;
		padding: 50% 0%;
	}
	/* @media only screen and (max-width: 992.99px) {
		display: inline-table;
		align-items: center;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
	} */

	@media only screen and (min-width: 2000.99px) {
		display: block !important;
	}
`;
export const CenterWrapper2 = styled.section`
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	padding: 30px 0px;
	@media only screen and (min-width: 768.99px) {
	}
	@media only screen and (min-width: 2000.99px) {
		/* display: block !important; */
	}
`;
export const ReclaimRevolutionWrap = styled.div`
	width: 100%;
	position: relative;
	max-width: 500px;
	margin: 0px auto;
	@media only screen and (max-width: 768.99px) {
		width: 90% !important;
		margin: 0px auto;
	}
`;
export const ReclaimRevolutionCard = styled(Card)`
	width: 100%;
	position: relative;
	border: none;
	background: transparent;
	&.hasBackground {
		background: #fff;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 20px;
	}
	&.noPadding {
		.ant-card-body {
			@media only screen and (max-width: 768.99px) {
				padding: 0px !important;
			}
		}
	}
	&.customCards {
		width: 100%;
		position: relative;
		&::before {
			content: '';
			display: block;
			position: absolute;
			width: calc(100% + 30px);
			height: calc(100% - 30px);
			top: 15px;
			bottom: 15px;
			left: -15px;
			right: -15px;
			z-index: -1;
			background: rgba(255, 255, 255, 0.5);
			border: 1px solid rgba(0, 0, 0, 0.1);
			border-radius: 20px;
			@media only screen and (max-width: 768.99px) {
				display: none;
			}
		}
		&::after {
			content: '';
			display: block;
			position: absolute;
			width: calc(100% + 60px);
			height: calc(100% - 60px);
			top: 30px;
			bottom: 30px;
			left: -30px;
			right: -30px;
			z-index: -2;
			background: rgba(255, 255, 255, 0.5);
			border: 1px solid rgba(0, 0, 0, 0.1);
			border-radius: 20px;

			/* animation-name: shufflethree;
			animation-duration: 0.2s;
			animation-iteration-count: 4;
			animation-delay: 1.2s;

			@keyframes shufflethree {
				0% {
					transform: translate(0, 0);
				}
				50% {
					transform: translate(1.2em, 0);
				}
				100% {
					transform: translate(0, 0);
				}
			} */

			@media only screen and (max-width: 768.99px) {
				display: none;
			}
		}
	}
`;
export const Logo = styled.div`
	width: 100%;
	position: relative;
	text-align: center;
	@media only screen and (max-width: 767.99px) {
	}
`;
export const Title = styled.h1`
	margin: 0px;
	text-align: center;
	font-style: normal;
	font-weight: 400;
	font-size: 36px;
	line-height: 48px;
	color: #0e4c88;
	span {
		font-weight: 600;
	}
	@media only screen and (max-width: 768.99px) {
		font-size: 26px;
		line-height: 38px;
	}
`;
export const XInputBtn = styled(Input)`
	background: #ffffff !important;
	border: 1px solid rgba(0, 0, 0, 0.1) !important;
	border-radius: 20px !important;
	border: none;
	height: auto !important;
	padding: 15px 60px 15px 15px !important;
	@media only screen and (max-width: 992.99px) {
	}
`;
export const XButtonOverlay = styled(Button)`
	width: auto !important;
	position: absolute;
	right: 10px;
	top: 7px;
	background: #0e4c88;
	color: #fff;
	z-index: 99;
	padding: 10px !important;
	border-radius: 5px !important;
	&:hover {
		background: #0e4c88 !important;
		color: #fff;
	}
	@media only screen and (max-width: 992.99px) {
	}
`;
export const XForm = styled(Form)`
	.loginInputsLbl label {
		font-size: 14px;
		color: #0b0b0b;
	}
`;
export const XFromsInput = styled(Input)`
	background: #f4f9fc !important;
	border: 1px solid #edf1f3 !important;
	box-shadow: 0px 1px 2px rgba(228, 229, 231, 0.24) !important;
	border-radius: 10px !important;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 160%;
	@media only screen and (max-width: 992.99px) {
	}
`;
export const XSelectDropDown = styled(Select)`
	.ant-select-selector {
		border: 1px solid #edf1f3 !important;
		border-radius: 20px !important;
		background: #f4f9fc !important;
		box-shadow: 0px 1px 2px rgba(228, 229, 231, 0.24) !important;
		border-radius: 10px !important;

		font-style: normal;
		font-weight: 500 !important;
		font-size: 14px !important;
		line-height: 160%;
	}
	@media only screen and (max-width: 992.99px) {
	}
`;
export const XSubmitButton = styled(Button)`
	background: #0e4c88 !important;
	border: 4px solid rgba(255, 255, 255, 0.9) !important;
	border-radius: 15px;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	color: #ffffff;
	height: auto;
	padding: 10px 15px !important;
	&:hover {
		background: #0e4c88 !important;
		border: 4px solid rgba(255, 255, 255, 0.9) !important;
		color: #ffffff !important;
	}
	@media only screen and (max-width: 768.99px) {
		font-size: 14px;
		line-height: 14px;
	}
`;
export const XLottie = styled(Lottie)`
	position: relative;
	background: transparent;
	margin: 0px auto;
	@media only screen and (max-width: 768.99px) {
		width: 75%;
		position: relative;
		margin: 0px auto;
	}
`;
export const XsuccessfullyMsg = styled.p`
	margin: 0px;
	font-style: normal;
	font-weight: 400 !important;
	font-size: 16px;
	line-height: 28px;
	text-align: center;
	color: #0b0b0b;
	font-family: ${({ theme }) => theme.colors.fontPoppins};
	span {
		font-weight: 600 !important;
	}
	@media only screen and (max-width: 768.99px) {
		font-size: 14px;
		line-height: 24px;
	}
`;
export const XLoginButton = styled(Button)`
	position: absolute;
	right: 15px;
	top: 15px;
	background: #0e4c88 !important;
	border: 4px solid rgba(255, 255, 255, 0.9) !important;
	border-radius: 15px;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	color: #ffffff;
	height: auto;
	padding: 10px 15px !important;
	z-index: 2;
	&:hover {
		background: #0e4c88 !important;
		border: 4px solid rgba(255, 255, 255, 0.9) !important;
		color: #ffffff !important;
	}
	@media only screen and (max-width: 768.99px) {
		font-size: 14px;
		line-height: 14px;
		right: 5px;
		top: 5px;
		z-index: 1;
	}
`;
export const XContainer = styled(Container)`
	width: 100%;
	position: relative;
	@media only screen and (max-width: 768.99px) {
		padding: 0px;
	}
	@media only screen and (min-width: 1920.99px) {
		width: 1800px;
		// padding: 160px;
	}
	@media only screen and (min-width: 2000.99px) {
		width: 100%;
	}
`;
