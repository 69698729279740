// import '../../assets/fonts/clash-display/WEB/css/clash-display.css';
// import React, { useEffect, useRef, useState } from 'react';
// import { useLocation } from 'react-router-dom';
// import { XAffix, XContainer } from '../../styles/GlobalStyle';
// import { Row, Col, Space, Popover, Card, Tooltip, Image } from 'antd';
// import Header from '../../components/Header/Header';
// import HeaderFile from '../../components/Header/HeaderFile';
// import {
// 	AipaMobileBody,
// 	AddInputIcon,
// 	ParaDeocdex,
// 	XDecodexTitle,
// 	XHeader,
// 	XLottieAipa,
// 	XLottieAipaLoader,
// 	XSelectIcons,
// 	XTitleHeader,
// 	RecordCard,
// 	XActionBtns,
// 	XActionPlay,
// 	XShazaamBtn,
// 	AipaDesktopBody,
// 	XRadio,
// 	XDivider,
// 	XCardDesktop,
// 	XInputTextArea,
// 	XUpload,
// 	XAvatar,
// } from './PolicyStyle.js';
// import LoaderJson from '../../json/reclaim_hero.json';
// import {
// 	AudioOutlined,
// 	UserSwitchOutlined,
// 	EnvironmentOutlined,
// 	CheckOutlined,
// 	CloseOutlined,
// 	PauseOutlined,
// 	InboxOutlined,
// 	FilePdfOutlined,
// 	EyeOutlined,
// 	DeleteOutlined,
// } from '@ant-design/icons';
// import { connect } from 'react-redux';
// import {
// 	Button,
// 	Input,
// 	notification,
// 	Affix,
// 	List,
// 	Checkbox,
// 	Radio,
// 	Modal,
// } from 'antd';
// import { XLottie } from '../Login/LoginStyle';
// import LoginAnimation from '../../json/Login.json';
// import Thanks from '../../json/thanksNew.json';
// import {
// 	XForm,
// 	XModal,
// 	XSelect,
// 	XRoundButton,
// 	XModalTitle,
// 	XModalSubtitle,
// 	XList,
// 	XCircle,
// 	XLabel,
// } from '../../styles/GlobalStyle';
// import {
// 	XLottieMargin,
// 	Title,
// 	XPara1,
// 	XButtonCloseBar,
// 	XButtonReload,
// 	XNotificationBar,
// 	XButtonConfirm,
// 	XCheckboxGroup,
// 	XuploadAddCase,
// 	XLottieOverlay,
// } from '../AnalyseCoverage/AnalyseCoverageStyle';
// import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
// // Actions
// import {
// 	analyseCoverageApi,
// 	analyseCoverageReset,
// } from '../../actions/analyseCoverageAction';
// import {
// 	reviseCoverageApi,
// 	reviseCoverageReset,
// } from '../../actions/reviseCoverageAction';
// import {
// 	sendFeedbackApi,
// 	sendFeedbackReset,
// } from '../../actions/sendFeedbackAction';
// import {
// 	getStateDropdownApi,
// 	getStateDropdownReset,
// } from '../../actions/getStateDropdownAction';
// import { filterByLabel, handleInputChange } from '../../utils/formFunctions';
// import {
// 	getAnalysisReset,
// 	getAnalysisApi,
// } from '../../actions/getAnalysisAction';
// import AnalysisLoader from '../../components/AnalysisLoader/AnalysisLoader';
// import { checkModulePermissions, getUserToken } from '../../utils/helper';
// import CaseOperations from '../../components/HorizontalTimeline/CaseOperations';
// import config from '../../config';
// import {
// 	getAppealLetterApi,
// 	getAppealLetterReset,
// } from '../../actions/getAppealLetterAction';
// import AppealLoading from '../../json/reloading.json';
// import AppealLetter from '../../components/HorizontalTimeline/AppealLetter';
// import { AipaAudioBtn } from '../Aipa/AipaStyle';
// import WaveformVisualizer from '../../components/HorizontalTimeline/RecorderWave';
// import axios from 'axios';
// const { TextArea } = Input;
// const { Dragger } = XUpload;

// const Policy = (props) => {
// 	const {
// 		analyseCoverage,
// 		analyseCoverageReset,
// 		analyseCoverageState,
// 		reviseCoverage,
// 		reviseCoverageReset,
// 		reviseCoverageState,
// 		sendFeedback,
// 		sendFeedbackReset,
// 		sendFeedbackState,
// 		getStateDropdownState,
// 		getStateDropdown,
// 		getAnalysis,
// 		getAnalysisState,
// 		getAnalysisReset,
// 		getAppealLetter,
// 		getAppealLetterState,
// 	} = props;
// 	const missingFieldsCardRef = useRef(null);
// 	const [form] = XForm.useForm();
// 	const [formUpload] = XForm.useForm();
// 	const [formSupportingUpload] = XForm.useForm();
// 	const [clinicalDocsForm] = XForm.useForm();
// 	const [showClinicalTextArea, setShowClinicalTextArea] = useState(false);
// 	const [isClinicalUploadModalOpen, setIsClinicalUploadModalOpen] =
// 		useState(false);
// 	const [clinicalDocsLoading, setClinicalDocsLoading] = useState(false);
// 	const [clinicalDocumentFileList, setClinicalDocumentFileList] = useState(
// 		[],
// 	);
// 	const [submitClinicalDocs, setSubmitClinicalDocs] = useState(false); // Initial value can be 'textarea' or 'upload'
// 	const [formData, setFormData] = useState();
// 	const [clinicalSummaryTextLoading, setClinicalSummaryTextLoading] =
// 		useState(false);
// 	const [supportingDocsSummary, setSupportingDocsSummary] = useState('');
// 	const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);
// 	const [previewImage, setPreviewImage] = useState('');
// 	const [previewTitle, setPreviewTitle] = useState('Upload Acknowledgement');
// 	const [newOptionForm] = XForm.useForm();
// 	const [summaryForm] = XForm.useForm();
// 	const [loading, setLoading] = useState(false);
// 	const [modalOpen, setModalOpen] = useState([false, '', '', '', '']);
// 	const [thanksModal, setThanksModal] = useState([false, '']);
// 	const [summary, setSummary] = useState('');
// 	const [state, setState] = useState('');
// 	const [provider, setProvider] = useState('');
// 	const [modalTextArea, setModalTextArea] = useState('');
// 	const [feedbackId, setFeedbackId] = useState(null);
// 	const [pageContent, setPageContent] = useState({
// 		pastHistory: [],
// 		presentComplaint: [],
// 		recommendedProcedure: [],
// 		medicalCoverage: [],
// 		status: '',
// 		missing_points_for_coverage: [],
// 	});
// 	const [insuranceCompanies, setInsuranceCompanies] = useState([]);
// 	const [callGetAnalysisAPiBtn, setCallGetAnalysisAPiBtn] = useState(true);
// 	const [missingFieldsModal, setMissingFieldsModal] = useState(false);
// 	const [missingFields, setMissingFields] = useState([]);
// 	const [missingFieldInstructions, setMissingFieldInstructions] = useState(
// 		[],
// 	);
// 	const [missingFieldTitle, setMissingFieldTitle] = useState('');
// 	const [missingFieldsForm] = XForm.useForm();
// 	const [lastAnalysisData, setLastAnalysisData] = useState({});
// 	const [showAnalysisData, setShowAnalysisData] = useState(false);
// 	const [analysisCompleted, setAnalysisCompleted] = useState('');
// 	const [newOptionText, setNewOptionText] = useState('');
// 	const [newCheckBoxModal, setNewCheckBoxModal] = useState([false, null]);
// 	const [id, setId] = useState('');
// 	const [coverageloading, setCoverageLoading] = useState(false);
// 	const [LooksGoodLoading, setLooksGoodLoading] = useState(false);
// 	const [isMissingFieldsThere, setIsMissingFieldsThere] = useState(false);
// 	const [
// 		updatedClinicalDocumentFileList,
// 		setUpdatedClinicalDocumentFileList,
// 	] = useState([]);
// 	const [previewOpen, setPreviewOpen] = useState(false);
// 	const [clinicalDocumentsToDisplay, setClinicalDocumentsToDisplay] =
// 		useState([]);
// 	const [clinicalSelectedOption, setClinicalSelectedOption] =
// 		useState('clinical_text'); // Initial value can be 'textarea' or 'upload'
// 	const [policyFiles, setPolicyFiles] = useState([]);
// 	const [isClinicalDocUploadFromCommon, setIsClinicalDocUploadFromCommon] =
// 		useState(false);
// 	const [isUploadClinicalDoc, setIsUploadClinicalDoc] = useState(false);
// 	const [appealLetterType, setAppealLetterType] = useState('');
// 	const [appealLetterContent, setAppealLetterContent] = useState('');
// 	const [showAppealLetterPreview, setShowAppealLetterPreview] =
// 		useState(false);
// 	const [appealLetterLoading, setAppealLetterLoading] = useState(false);
// 	const [uploadedPolicyDoc, setUploadedPolicyDoc] = useState([]);
// 	const [policyDoc, setPolicyDoc] = useState([]);
// 	const [isPolicyUploadModalOpen, setIsPolicyUploadModalOpen] =
// 		useState(false);
// 	const [uploadedSupporingDoc, setUploadedSupporingDoc] = useState([]);
// 	const [supportingFiles, setSupportingFiles] = useState([]);
// 	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
// 	const [supportingDoc, setSupportingDoc] = useState([]);
// 	const [documentName, setDocumentName] = useState('');
// 	const location = useLocation();
// 	const isCheckCoveragePage = location.pathname === '/check-coverage';

// 	const [policyType, setPolicyType] = useState('medicare');

// 	useEffect(() => {
// 		if (modalOpen[0] && modalOpen[4] !== 'feedbackSummary') {
// 			// Check if modal is open and it's not the feedbackSummary modal
// 			const [description, code] = modalTextArea.split(' - ');
// 			form.setFieldsValue({
// 				modal_text_area: description || '', // Prepopulate description
// 				code: code ? code.split(': ')[1] : '', // Extract and prepopulate code
// 			});
// 		}
// 	}, [modalOpen, modalTextArea, form]);

// 	useEffect(() => {
// 		if (
// 			getStateDropdownState.apiState === 'success' &&
// 			getStateDropdownState.data &&
// 			getStateDropdownState.data.all_states?.length > 0
// 		) {
// 			// Check if the field is already populated
// 			const currentState = summaryForm.getFieldValue('state');
// 			if (!currentState) {
// 				const preselectedState = 'Washington';
// 				summaryForm.setFieldValue('state', preselectedState);

// 				// Set insurance companies for the preselected state
// 				const selectedState =
// 					getStateDropdownState.data.all_states.find(
// 						(itm) => itm.state === preselectedState,
// 					);

// 				if (selectedState) {
// 					setState(preselectedState);
// 					setInsuranceCompanies(selectedState.providers);
// 				}
// 			}
// 		}
// 	}, [getStateDropdownState]);

// 	useEffect(() => {
// 		if (getAppealLetterState.apiState === 'success') {
// 			setAppealLetterLoading(false);
// 			console.log('getAppealLetterState inpl');
// 			// getAnalysis({ id: id });
// 			setAppealLetterContent(getAppealLetterState.data.appeal_letter);
// 			setShowAppealLetterPreview(true);
// 			getAnalysis({ feedback_id: feedbackId });
// 			// navigate(`/case/filings/preview/${id}`, {
// 			// 	state: {
// 			// 		appealLetterType: appealLetterType,
// 			// 		// id: id,
// 			// 		patientDetails: patientData,
// 			// 		content: getAppealLetterState.data.appeal_letter,
// 			// 		header: getAppealLetterState.data.header,
// 			// 		footer: getAppealLetterState.data.footer,
// 			// 		isEdit: false,
// 			// 	},
// 			// });
// 		}
// 		if (getAppealLetterState.apiState === 'error') {
// 			setAppealLetterLoading(false);
// 			setShowAppealLetterPreview(false);
// 			notification.error({
// 				message: getAppealLetterState.message
// 					? getAppealLetterState.message
// 					: 'Unable to get Appeal Letter content',
// 			});
// 		}
// 		getAppealLetterReset();
// 	}, [getAppealLetterState.apiState]);

// 	const previewAppealLetter = (
// 		type,
// 		regenerate = false,
// 		forceGenerate = false,
// 	) => {
// 		if (forceGenerate) {
// 			const payload = {
// 				analysis_id: id,
// 				aipa: true,
// 				feedbackId: feedbackId,
// 				checkCoverage: true,
// 				policy: true,
// 				policy_type: policyType,
// 				force_generate: true,
// 			};
// 			getAppealLetter(payload);
// 		} else {
// 			getAppealLetter({
// 				id: id,
// 				type: type,
// 				appeal_letter_generated: regenerate,
// 				aipa: true,
// 				provider: provider,
// 				clinical_summary: summary,
// 				state: state,
// 				feedbackId: feedbackId,
// 				checkCoverage: true,
// 			});
// 			setAppealLetterType(type);
// 		}
// 	};

// 	const handleUploadCancel = () => {
// 		setIsPolicyUploadModalOpen(false);
// 		setIsUploadModalOpen(false);
// 		setIsClinicalUploadModalOpen(false);
// 		setClinicalDocumentFileList([]);
// 		setUpdatedClinicalDocumentFileList([]);
// 		setClinicalDocsLoading(false);
// 		setSubmitClinicalDocs(false);
// 		setFormData({
// 			...formData,
// 			clinical_summary: '',
// 		});

// 		clinicalDocsForm.setFieldsValue({ clinical_docs: '' });
// 	};

// 	const handlePreviewClinicalSummary = async (file) => {
// 		// Check if the file is a PDF
// 		if (file && file.name && file.name.split('.').includes('pdf')) {
// 			// If there's a URL, use it directly; otherwise, generate a preview from the file
// 			const fileUrl = file.url ? `${config.s3_url}${file.url}` : null;
// 			setPreviewImage(fileUrl || file.preview);
// 			setPdfPreviewOpen(true); // Open PDF preview modal
// 		} else {
// 			// For non-PDF files, generate a preview if necessary
// 			if (!file.url && !file.preview && file.originFileObj) {
// 				file.preview = await getBase64(file.originFileObj);
// 			}
// 			setPreviewImage(file.url || file.preview);
// 			setPreviewOpen(true); // Open image preview modal
// 			setPreviewTitle(
// 				file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
// 			);
// 		}
// 	};

// 	const handleClinicalDocumentChange = ({ fileList: newFileList }) => {
// 		const updatedFileList = newFileList.map((file) => {
// 			if (file.status === 'error') {
// 				return { ...file, status: 'done' };
// 			}
// 			return file;
// 		});
// 		setClinicalDocumentFileList(updatedFileList);
// 		setUpdatedClinicalDocumentFileList(updatedFileList);
// 		// setClinicalDocumentsToDisplay(updatedFileList);
// 		summaryForm.setFieldsValue({
// 			clinical_summary: '',
// 		});
// 		setFormData({
// 			...formData,
// 			clinical_summary: '',
// 		});
// 		setSummary('');
// 		summaryForm.validateFields(['clinical_document']);
// 	};

// 	const handleRemoveClinicalDocuments = async (file) => {
// 		Modal.confirm({
// 			title: 'Confirm Deletion',
// 			content: 'Are you sure you want to delete this file?',
// 			okText: 'Yes',
// 			cancelText: 'No',
// 			cancelButtonProps: {
// 				// Add an icon to the Cancel button
// 				style: {
// 					backgroundColor: '#fff', // Red background for Cancel
// 					color: '#007299', // Text color
// 				},
// 			},
// 			okButtonProps: {
// 				style: {
// 					backgroundColor: '#007299',
// 					borderColor: '#007299',
// 					color: '#fff',
// 				},
// 			},
// 			onOk: async () => {
// 				const updatedFileList = clinicalDocumentFileList.filter(
// 					(item) => item.uid !== file.uid,
// 				);
// 				const updatedFileListToDisplay =
// 					clinicalDocumentsToDisplay.filter(
// 						(item) => item.uid !== file.uid,
// 					);
// 				setClinicalDocumentFileList(updatedFileList);
// 				setUpdatedClinicalDocumentFileList(updatedFileList);
// 				setClinicalDocumentsToDisplay(updatedFileListToDisplay);
// 				form.validateFields(['clinical_document']);
// 				if (!file.originFileObj) {
// 					// setDeletedClinicalDocuments((prevFiles) => [
// 					// 	...prevFiles,
// 					// 	file,
// 					// ]);
// 				}
// 			},
// 			onCancel: () => {
// 				form.validateFields(['clinical_document']);
// 				console.log('Deletion cancelled');
// 			},
// 		});
// 	};

// 	// Function to generate an ID with specified format
// 	// const generateCustomId = () => {
// 	// 	const randomPart = generateRandomAlphaNumeric(9); // First 9 characters alphanumeric
// 	// 	const timestampPart = moment().format('YYDDMMHHMMSSMS'); // Last 15 characters formatted as YYDDMMHHMMSSMS
// 	// 	const customId = randomPart + timestampPart;

// 	// 	return customId;
// 	// };

// 	// Generate a random 24-character hexadecimal ID
// 	const generateCustomId = () => {
// 		const characters = '0123456789abcdef';
// 		let result = '';
// 		for (let i = 0; i < 24; i++) {
// 			result += characters.charAt(
// 				Math.floor(Math.random() * characters.length),
// 			);
// 		}
// 		return result;
// 	};

// 	const updatePageContent = (title, id, content) => {
// 		if (id === null) {
// 			if (title === 'originalQuery') {
// 				setPageContent({
// 					...pageContent,
// 					originalQuery: content,
// 				});
// 			} else {
// 				setPageContent({
// 					...pageContent,
// 					[title]: [...pageContent[[title]], content],
// 				});
// 			}
// 		} else {
// 			const updatedPageContent = { ...pageContent };
// 			updatedPageContent[[title]][id] = content;
// 			setPageContent(updatedPageContent);
// 		}
// 		// setSummary(formData.clinical_summary);
// 		setClinicalSummaryTextLoading(false);
// 		setShowClinicalTextArea(false);
// 		setModalOpen([false, '', ' ']);
// 		setModalTextArea('');
// 		form.resetFields();
// 		document.getElementById('notification').classList.remove('d-none');
// 	};

// 	const handleAddOption = (index) => {
// 		setNewCheckBoxModal([true, index]);
// 		setNewOptionText('');
// 		newOptionForm.setFieldsValue({});
// 		newOptionForm.resetFields();
// 	};

// 	const handleNewCheckBoxModal = () => {
// 		// Create a new option object
// 		newOptionForm
// 			.validateFields()
// 			.then(() => {
// 				const newOption = {
// 					label: newOptionText,
// 					value: newOptionText,
// 				};

// 				if (
// 					newCheckBoxModal[1] !== null &&
// 					newCheckBoxModal[1] >= 0 &&
// 					newCheckBoxModal[1] < missingFields.length
// 				) {
// 					const updatedFields = [...missingFields];
// 					updatedFields[newCheckBoxModal[1]].options.push(newOption);
// 					setMissingFields(updatedFields);

// 					// Get the current form values
// 					const currentValues = missingFieldsForm.getFieldsValue();

// 					// Add the new option to the appropriate field and mark it as checked
// 					if (
// 						!currentValues[updatedFields[newCheckBoxModal[1]].name]
// 					) {
// 						currentValues[updatedFields[newCheckBoxModal[1]].name] =
// 							[];
// 					}
// 					currentValues[updatedFields[newCheckBoxModal[1]].name].push(
// 						newOptionText,
// 					);

// 					// Update the form with the new values
// 					missingFieldsForm.setFieldsValue(currentValues);
// 				}

// 				// Close the modal and reset the newOptionText state
// 				setNewCheckBoxModal([false, null]);
// 				setNewOptionText('');
// 				newOptionForm.setFieldsValue({});
// 				newOptionForm.resetFields();
// 			})
// 			.catch((info) => {
// 				console.log('Validate Failed:', info);
// 			});
// 	};

// 	const handleClinicalSummaryDoc = async () => {
// 		if (policyType === 'medicare') {
// 			let uploadPromises = [];
// 			let uploadedClinicalDocs = [];

// 			// Handle Clinical Documents Upload
// 			if (clinicalDocumentFileList.length > 0) {
// 				const clinicalDocPromises = clinicalDocumentFileList.map(
// 					async (fileData) => {
// 						let get_ext = fileData.originFileObj.name.split('.');
// 						let ext = get_ext[get_ext.length - 1];
// 						const res = await axios.get(
// 							`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=reclaim&file_name=${fileData.originFileObj.uid}&ext=${ext}&id=${id}`,
// 							{
// 								headers: {
// 									'Content-type':
// 										'application/json; charset=UTF-8',
// 									Authorization: 'Bearer ' + getUserToken(),
// 								},
// 							},
// 						);

// 						const result = await axios.put(
// 							res.data.data,
// 							fileData.originFileObj,
// 						);
// 						if (result.status === 200) {
// 							const documentPath = `reclaim/${id}/${fileData.originFileObj.uid}.${ext}`;
// 							uploadedClinicalDocs.push({
// 								uid: fileData.originFileObj.uid,
// 								name: fileData.originFileObj.name,
// 								status: 'done',
// 								url: documentPath,
// 							});
// 						}
// 					},
// 				);
// 				uploadPromises = uploadPromises.concat(clinicalDocPromises);
// 			}
// 			// Wait for all uploads to complete
// 			await Promise.all(uploadPromises);

// 			// Update state with uploaded files
// 			setUpdatedClinicalDocumentFileList(uploadedClinicalDocs);

// 			// Make the API call with updated file information
// 			const payload = {
// 				analysis_id: id,
// 				state: state,
// 				provider: provider,
// 				aipa: true,
// 				checkCoverage: true,
// 				policy: true,
// 				policy_type: policyType,
// 				clinical_docs: uploadedClinicalDocs,
// 			};

// 			setIsUploadClinicalDoc(true);
// 			setIsClinicalDocUploadFromCommon(true);
// 			analyseCoverage(payload);
// 			setShowAppealLetterPreview(false);
// 		} else {
// 			const formData = new FormData();

// 			// // Append supportingDocs files
// 			// policyFiles.forEach((file) => {
// 			// 	formData.append('supporting_docs', file.url?.originFileObj); // 'supportingDocs' will be the backend key
// 			// });

// 			// Append clinicalDocs files
// 			clinicalDocumentFileList.forEach((file) => {
// 				formData.append('clinical_docs', file.originFileObj); // 'clinicalDocs' will be the backend key
// 			});
// 			formData.append('supportingDocsSummary', supportingDocsSummary);
// 			// formData.append('state', state);
// 			// formData.append('provider', provider);
// 			// formData.append('analysis_id', id);
// 			// Log to check FormData contents
// 			// setLoading(true);
// 			setIsClinicalDocUploadFromCommon(true);
// 			analyseCoverage(formData);
// 		}
// 	};

// 	const getBase64 = (file) =>
// 		new Promise((resolve, reject) => {
// 			const reader = new FileReader();
// 			reader.readAsDataURL(file);
// 			reader.onload = () => resolve(reader.result);
// 			reader.onerror = (error) => reject(error);
// 		});

// 	const handleClinicalDocsChange = ({ fileList: newFileList }) => {
// 		const updatedFileList = newFileList.map((file) => {
// 			if (file.status === 'error') {
// 				return { ...file, status: 'done' };
// 			}
// 			// file.preview = await getBase64(file.originFileObj);
// 			return file;
// 		});
// 		setClinicalDocumentFileList(updatedFileList);
// 		setUpdatedClinicalDocumentFileList(updatedFileList);
// 		clinicalDocsForm.validateFields(['clinical_docs']);
// 	};

// 	// Function to handle form submission
// 	const handleShazamClick = async () => {
// 		try {
// 			setLoading(true);
// 			const newId = generateCustomId();
// 			setId(newId);

// 			let uploadPromises = [];
// 			let uploadedClinicalDocs = [];
// 			let uploadedPolicyDocs = [];
// 			let uploadedSupportingDocs = [];

// 			// Handle Clinical Documents Upload
// 			if (clinicalDocumentFileList.length > 0) {
// 				const clinicalDocPromises = clinicalDocumentFileList.map(
// 					async (fileData) => {
// 						let get_ext = fileData.originFileObj.name.split('.');
// 						let ext = get_ext[get_ext.length - 1];
// 						const res = await axios.get(
// 							`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=reclaim&file_name=${fileData.originFileObj.uid}&ext=${ext}&id=${newId}`,
// 							{
// 								headers: {
// 									'Content-type':
// 										'application/json; charset=UTF-8',
// 									Authorization: 'Bearer ' + getUserToken(),
// 								},
// 							},
// 						);

// 						const result = await axios.put(
// 							res.data.data,
// 							fileData.originFileObj,
// 						);
// 						if (result.status === 200) {
// 							const documentPath = `reclaim/${newId}/${fileData.originFileObj.uid}.${ext}`;
// 							uploadedClinicalDocs.push({
// 								uid: fileData.originFileObj.uid,
// 								name: fileData.originFileObj.name,
// 								status: 'done',
// 								url: documentPath,
// 							});
// 						}
// 					},
// 				);
// 				uploadPromises = uploadPromises.concat(clinicalDocPromises);
// 			}

// 			if (policyType === 'commercial') {
// 				// Handle Policy Files Upload
// 				if (policyFiles.length > 0) {
// 					const policyDocPromises = policyFiles.map(
// 						async (fileData) => {
// 							let get_ext =
// 								fileData.url.originFileObj.name.split('.');
// 							let ext = get_ext[get_ext.length - 1];
// 							const res = await axios.get(
// 								`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=reclaim&file_name=${fileData.url.originFileObj.uid}&ext=${ext}&id=${newId}`,
// 								{
// 									headers: {
// 										'Content-type':
// 											'application/json; charset=UTF-8',
// 										Authorization:
// 											'Bearer ' + getUserToken(),
// 									},
// 								},
// 							);

// 							const result = await axios.put(
// 								res.data.data,
// 								fileData.url.originFileObj,
// 							);
// 							if (result.status === 200) {
// 								const documentPath = `reclaim/${newId}/${fileData.url.originFileObj.uid}.${ext}`;
// 								uploadedPolicyDocs.push({
// 									uid: fileData.url.originFileObj.uid,
// 									name: fileData.url.originFileObj.name,
// 									status: 'done',
// 									url: documentPath,
// 								});
// 							}
// 						},
// 					);
// 					uploadPromises = uploadPromises.concat(policyDocPromises);
// 				}

// 				// Handle Supporting Files Upload
// 				if (supportingFiles.length > 0) {
// 					const supportingDocPromises = supportingFiles.map(
// 						async (fileData) => {
// 							let get_ext =
// 								fileData.url.originFileObj.name.split('.');
// 							let ext = get_ext[get_ext.length - 1];
// 							const res = await axios.get(
// 								`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=reclaim&file_name=${fileData.url.originFileObj.uid}&ext=${ext}&id=${newId}`,
// 								{
// 									headers: {
// 										'Content-type':
// 											'application/json; charset=UTF-8',
// 										Authorization:
// 											'Bearer ' + getUserToken(),
// 									},
// 								},
// 							);

// 							const result = await axios.put(
// 								res.data.data,
// 								fileData.url.originFileObj,
// 							);
// 							if (result.status === 200) {
// 								const documentPath = `reclaim/${newId}/${fileData.url.originFileObj.uid}.${ext}`;
// 								uploadedSupportingDocs.push({
// 									uid: fileData.url.originFileObj.uid,
// 									name: fileData.url.originFileObj.name,
// 									status: 'done',
// 									url: documentPath,
// 								});
// 							}
// 						},
// 					);
// 					uploadPromises = uploadPromises.concat(
// 						supportingDocPromises,
// 					);
// 				}
// 			}

// 			// Wait for all uploads to complete
// 			await Promise.all(uploadPromises);

// 			// Update state with uploaded files
// 			setUpdatedClinicalDocumentFileList(uploadedClinicalDocs);
// 			setPolicyDoc(uploadedPolicyDocs);
// 			setSupportingDoc(uploadedSupportingDocs);

// 			// Make the API call with updated file information
// 			const payload = {
// 				analysis_id: newId,
// 				clinicalSummary: summary,
// 				state: state,
// 				provider: provider,
// 				aipa: true,
// 				checkCoverage: true,
// 				policy: true,
// 				policy_type: policyType,
// 				clinical_docs: uploadedClinicalDocs,
// 				...(policyType === 'commercial' && {
// 					policy_document: uploadedPolicyDocs,
// 					supporting_docs: uploadedSupportingDocs,
// 				}),
// 			};

// 			setIsUploadClinicalDoc(true);
// 			setIsClinicalDocUploadFromCommon(true);
// 			analyseCoverage(payload);
// 			setShowAppealLetterPreview(false);
// 		} catch (error) {
// 			setLoading(false);
// 			notification.error({
// 				message: 'Upload Error',
// 				description:
// 					'An error occurred while uploading files. Please try again.',
// 			});
// 			console.error('Error in handleShazamClick:', error);
// 		}
// 	};

// 	const forceGenerateAppealLetter = async () => {
// 		// Make the API call with updated file information
// 		const payload = {
// 			analysis_id: id,
// 			aipa: true,
// 			checkCoverage: true,
// 			policy: true,
// 			policy_type: policyType,
// 			force_generate: true,
// 		};
// 		analyseCoverage(payload);
// 		console.log('forceGenerateAppealLetter');
// 	};

// 	useEffect(() => {
// 		if (showAnalysisData) {
// 			document.getElementById('getQust').classList.add('bottom-100');
// 			document.getElementById('getAns').classList.add('top-0');
// 			document.getElementById('getAns').classList.remove('top-100');
// 			document.getElementById('getAns')?.classList.remove('d-none');
// 		}
// 	}, [showAnalysisData]);

// 	useEffect(() => {
// 		if (missingFieldsModal && missingFieldsCardRef.current) {
// 			// ADD
// 			document.getElementById('getQust').classList.add('bottom-100');
// 			document.getElementById('getAns').classList.add('top-0');
// 			document.getElementById('getAns').classList.remove('top-100');
// 			document.getElementById('getAns')?.classList.remove('d-none');
// 			// ADD
// 			missingFieldsCardRef.current.scrollIntoView({ behavior: 'smooth' });
// 		}
// 	}, [missingFieldsModal]);

// 	useEffect(() => {
// 		if (analyseCoverageState.apiState === 'success') {
// 			setClinicalSummaryTextLoading(false);
// 			setClinicalDocumentsToDisplay(clinicalDocumentFileList);
// 			// handleUploadCancel();
// 			setClinicalDocsLoading(false);
// 			setAppealLetterLoading(false);
// 			setShowClinicalTextArea(false);
// 			setFeedbackId(analyseCoverageState.data.feedback_id);
// 			setSupportingDocsSummary(
// 				analyseCoverageState.data?.supportingDocsSummary,
// 			);
// 			setLastAnalysisData(analyseCoverageState.data);
// 			if (isClinicalDocUploadFromCommon) {
// 				setSummary(analyseCoverageState.data['original_query'] ?? '');
// 			}
// 			setPageContent({
// 				...pageContent,
// 				originalQuery:
// 					analyseCoverageState.data['original_query'] ?? '',
// 				pastHistory: analyseCoverageState.data['Past History'] ?? [],
// 				presentComplaint:
// 					analyseCoverageState.data['Present Complaint'] ?? [],
// 				recommendedProcedure:
// 					analyseCoverageState.data['Suggested Treatment'] ?? [],
// 				medicalCoverage: [
// 					analyseCoverageState.data['Medicare Evaluation']
// 						? analyseCoverageState.data['Medicare Evaluation'][
// 								'Coverage'
// 						  ]
// 						: 'No',
// 					analyseCoverageState.data['Medicare Evaluation']
// 						? analyseCoverageState.data['Medicare Evaluation'][
// 								'Rationale'
// 						  ]
// 						: '',
// 					analyseCoverageState.data['Medicare Evaluation']
// 						? analyseCoverageState.data['Medicare Evaluation'][
// 								'reference'
// 						  ]
// 						: null,
// 				],
// 				missing_points_for_coverage:
// 					analyseCoverageState.data.missing_points_for_coverage ?? [],
// 			});

// 			setAnalysisCompleted('success');
// 			analyseCoverageReset();
// 			// setLoading(false);
// 			setIsUploadClinicalDoc(false);
// 			setIsClinicalDocUploadFromCommon(false);

// 			if (
// 				analyseCoverageState.data.hasOwnProperty('is_fields_missing') &&
// 				analyseCoverageState.data.is_fields_missing &&
// 				analyseCoverageState.data.hasOwnProperty('missing_fields')
// 			) {
// 				if (analyseCoverageState.data.missing_fields.length > 0) {
// 					setMissingFields(
// 						analyseCoverageState.data.missing_fields ?? [],
// 					);
// 					setMissingFieldInstructions(
// 						analyseCoverageState.data.missing_field_instructions ??
// 							[],
// 					);
// 					setMissingFieldTitle(
// 						analyseCoverageState.data.missing_field_title ?? '',
// 					);
// 					setMissingFieldsModal(true);
// 					setIsMissingFieldsThere(true);
// 				}
// 			} else {
// 				setShowAnalysisData(true);
// 				setIsMissingFieldsThere(false);
// 			}
// 		}

// 		if (analyseCoverageState.apiState === 'error') {
// 			// setLoading(false);
// 			setAppealLetterLoading(false);
// 			setIsClinicalDocUploadFromCommon(false);
// 			// setShowClinicalTextArea(false);
// 			handleUploadCancel()
// 			setAnalysisCompleted('error');
// 			setIsMissingFieldsThere(false);
// 			notification.error({
// 				message:
// 					'Hey, we are facing high traffic. Please check back in few minutes.',
// 			});
// 			analyseCoverageReset();
// 		}
// 	}, [analyseCoverageState]);

// 	useEffect(() => {
// 		if (getAnalysisState.apiState === 'success') {
// 			setFeedbackId(getAnalysisState.data.coverage_feedback_response._id);
// 			setLastAnalysisData(
// 				getAnalysisState.data?.coverage_feedback_response,
// 			);
// 			setAppealLetterLoading(false);
// 			setPageContent({
// 				...pageContent,
// 				originalQuery: summary ?? '',
// 				pastHistory:
// 					getAnalysisState.data.coverage_feedback_response[
// 						'Past History'
// 					] ?? [],
// 				presentComplaint:
// 					getAnalysisState.data.coverage_feedback_response[
// 						'Present Complaint'
// 					] ?? [],
// 				recommendedProcedure:
// 					getAnalysisState.data.coverage_feedback_response[
// 						'Suggested Treatment'
// 					] ?? [],
// 				medicalCoverage: [
// 					getAnalysisState.data.coverage_feedback_response[
// 						'Medicare Evaluation'
// 					]
// 						? getAnalysisState.data.coverage_feedback_response[
// 								'Medicare Evaluation'
// 						  ]['Coverage']
// 						: 'No',
// 					getAnalysisState.data.coverage_feedback_response[
// 						'Medicare Evaluation'
// 					]
// 						? getAnalysisState.data.coverage_feedback_response[
// 								'Medicare Evaluation'
// 						  ]['Rationale']
// 						: '',
// 					getAnalysisState.data.coverage_feedback_response[
// 						'Medicare Evaluation'
// 					]
// 						? getAnalysisState.data.coverage_feedback_response[
// 								'Medicare Evaluation'
// 						  ]['reference']
// 						: null,
// 				],
// 				missing_points_for_coverage:
// 					getAnalysisState.data.coverage_feedback_response
// 						.missing_points_for_coverage ?? [],
// 				status: getAnalysisState.data.coverage_feedback_response.status,
// 			});
// 			setLoading(false);
// 			setCoverageLoading(false);
// 			setLooksGoodLoading(false);
// 			setModalOpen([false, '', '']);
// 			document.getElementById('getQust').classList.add('bottom-100');
// 			document.getElementById('getAns').classList.add('top-0');
// 			document.getElementById('getAns').classList.remove('top-100');
// 			document.getElementById('getAns')?.classList.remove('d-none');
// 		}

// 		if (getAnalysisState.apiState === 'error') {
// 			setLoading(false);
// 			setAppealLetterLoading(false);
// 			notification.error({
// 				message:
// 					'Hey, we are facing high traffic. Please check back in few minutes.',
// 			});
// 		}
// 		getAnalysisReset();
// 	}, [getAnalysisState]);

// 	useEffect(() => {
// 		if (reviseCoverageState.apiState === 'success') {
// 			setFeedbackId(reviseCoverageState.data.feedback_id);
// 			setLastAnalysisData(reviseCoverageState.data);
// 			setPageContent({
// 				...pageContent,
// 				originalQuery: reviseCoverageState.data['original_query'] ?? '',
// 				pastHistory: reviseCoverageState.data['Past History'] ?? [],
// 				presentComplaint:
// 					reviseCoverageState.data['Present Complaint'] ?? [],
// 				recommendedProcedure:
// 					reviseCoverageState.data['Suggested Treatment'] ?? [],
// 				medicalCoverage: [
// 					reviseCoverageState.data['Medicare Evaluation']
// 						? reviseCoverageState.data['Medicare Evaluation'][
// 								'Coverage'
// 						  ]
// 						: 'No',
// 					reviseCoverageState.data['Medicare Evaluation']
// 						? reviseCoverageState.data['Medicare Evaluation'][
// 								'Rationale'
// 						  ]
// 						: '',
// 					reviseCoverageState.data['Medicare Evaluation']
// 						? reviseCoverageState.data['Medicare Evaluation'][
// 								'reference'
// 						  ]
// 						: null,
// 				],
// 				missing_points_for_coverage:
// 					reviseCoverageState.data.missing_points_for_coverage ?? [],
// 				status: reviseCoverageState.data.status,
// 			});
// 			setAnalysisCompleted('success');
// 			if (
// 				reviseCoverageState.data.hasOwnProperty('is_fields_missing') &&
// 				reviseCoverageState.data.is_fields_missing &&
// 				reviseCoverageState.data.hasOwnProperty('missing_fields')
// 			) {
// 				if (reviseCoverageState.data.missing_fields.length > 0) {
// 					setMissingFields(
// 						reviseCoverageState.data.missing_fields ?? [],
// 					);
// 					setMissingFieldInstructions(
// 						reviseCoverageState.data.missing_field_instructions ??
// 							[],
// 					);
// 					setMissingFieldTitle(
// 						reviseCoverageState.data.missing_field_title ?? '',
// 					);
// 					setMissingFieldsModal(true);
// 					setIsMissingFieldsThere(true);
// 				}
// 			} else {
// 				setShowAnalysisData(true);
// 				setIsMissingFieldsThere(false);
// 			}
// 			reviseCoverageReset();
// 		}
// 		if (reviseCoverageState.apiState === 'error') {
// 			setAnalysisCompleted('error');
// 			reviseCoverageReset();
// 			notification.error({
// 				message:
// 					'Hey, we are facing high traffic. Please check back in few minutes.',
// 			});
// 			document.getElementById('notification')?.classList.remove('d-none');
// 			document.getElementById('getAns')?.classList.remove('d-none');
// 		}
// 	}, [reviseCoverageState]);

// 	useEffect(() => {
// 		if (sendFeedbackState.apiState === 'success') {
// 			sendFeedbackReset();
// 			setAppealLetterLoading(false);
// 			if (sendFeedbackState.data === 'negative')
// 				setThanksModal([true, 'Thanks for making us Better!']);
// 			else
// 				setThanksModal([true, 'Case has been analysed and confirmed!']);
// 			if (callGetAnalysisAPiBtn) {
// 				getAnalysis({ feedback_id: feedbackId });
// 				setCallGetAnalysisAPiBtn(true);
// 			} else {
// 				setCoverageLoading(false);
// 				setLooksGoodLoading(false);
// 				setModalOpen([false, '', '']);
// 			}
// 		} else if (sendFeedbackState.apiState === 'error') {
// 			sendFeedbackReset();
// 			setAppealLetterLoading(false);
// 			setCallGetAnalysisAPiBtn(true);
// 			setCoverageLoading(false);
// 			setLooksGoodLoading(false);
// 		}
// 	}, [sendFeedbackState]);

// 	useEffect(() => {
// 		document.getElementById('getQust').classList.remove('bottom-100');
// 		document.getElementById('getAns').classList.remove('top-0');
// 		document.getElementById('getAns').classList.add('top-100');
// 		document.getElementById('getAns')?.classList.add('d-none');
// 		getStateDropdown();
// 		summaryForm.setFieldsValue({
// 			clinical_category: 'clinical_text',
// 		});
// 	}, []);

// 	// Open the modal and set a timer to automatically close it after 3 seconds
// 	useEffect(() => {
// 		let timer;
// 		if (thanksModal) {
// 			timer = setTimeout(() => {
// 				setThanksModal([false, '']);
// 			}, 3000);
// 		}

// 		// Clear the timer if the component unmounts or showThanksModal changes
// 		return () => clearTimeout(timer);
// 	}, [thanksModal]);

// 	useEffect(() => {
// 		// Add the class to the body tag
// 		document.body.classList.add('reclaimBody');

// 		// Clean up to remove the class when the component unmounts
// 		return () => {
// 			document.body.classList.remove('reclaimBody');
// 		};
// 	}, []);

// 	//policy docs
// 	const uploadPolicyDocButton = (
// 		<Button
// 			onClick={() => setIsPolicyUploadModalOpen(true)}
// 			className="m-0"
// 			style={{
// 				borderRadius: '6px',
// 				border: '1px dashed #d9d9d9',
// 				display: 'block',
// 				height: '100px',
// 				padding: '4px 15px',
// 				width: '100px',
// 				background: 'rgba(0, 0, 0, 0.02)',
// 			}}
// 		>
// 			<PlusOutlined style={{ color: '#000' }} />
// 			<div style={{ color: '#000' }}>Upload</div>
// 		</Button>
// 	);

// 	const handlePolicyPreview = async (file) => {
// 		if (file && file.name && file.name.split('.').includes('pdf')) {
// 			if (!file.url && !file.preview) {
// 				file.preview = await getBase64(file.originFileObj);
// 			}
// 			setPreviewImage(file.url || file.preview);
// 			setPdfPreviewOpen(true);
// 		} else {
// 			if (!file.url && !file.preview) {
// 				file.preview = await getBase64(file.originFileObj);
// 			}
// 			setPreviewImage(file.url || file.preview);
// 			setPreviewOpen(true);
// 			setPreviewTitle(
// 				file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
// 			);
// 		}
// 	};

// 	const handleRemovePolicyFile = async (file) => {
// 		Modal.confirm({
// 			title: 'Confirm Deletion',
// 			content: 'Are you sure you want to delete this file?',
// 			okText: 'Yes',
// 			cancelText: 'No',
// 			cancelButtonProps: {
// 				// Add an icon to the Cancel button
// 				style: {
// 					backgroundColor: '#fff', // Red background for Cancel
// 					color: '#007299', // Text color
// 				},
// 			},
// 			okButtonProps: {
// 				style: {
// 					backgroundColor: '#007299',
// 					borderColor: '#007299',
// 					color: '#fff',
// 				},
// 			},
// 			onOk: async () => {
// 				const updatedFileList = policyFiles.filter(
// 					(item) => item.url.uid !== file.url.uid,
// 				);
// 				const UploadedFile = uploadedPolicyDoc.filter(
// 					(item) => item.url.uid !== file.url.uid,
// 				);
// 				// const updatedUploadedFile = updatedUploadedSupporingDoc.filter(
// 				// 	(item) => item.url.uid !== file.url.uid,
// 				// );
// 				setPolicyFiles(updatedFileList);
// 				// setUpdatedUploadedSupporingDoc(updatedUploadedFile);
// 				setUploadedPolicyDoc(UploadedFile);
// 				// if (!file.url.originFileObj) {
// 				// 	setDeletedUploadedSupportingDoc((prevFiles) => [
// 				// 		...prevFiles,
// 				// 		file,
// 				// 	]);
// 				// }
// 			},
// 			onCancel: () => {
// 				console.log('Deletion cancelled');
// 			},
// 		});
// 	};

// 	const handlePolicyDocSubmit = async () => {
// 		formUpload
// 			.validateFields()
// 			.then(async () => {
// 				if (policyDoc && policyDoc.length > 0) {
// 					let obj = {
// 						url: policyDoc[0],
// 					};
// 					setUploadedPolicyDoc((prevFiles) => [...prevFiles, obj]);
// 					obj.preview = await getBase64(policyDoc[0].originFileObj);
// 					setPolicyFiles((prevFiles) => [...prevFiles, obj]);
// 					setPolicyDoc([]);
// 					formUpload.resetFields();
// 					setIsPolicyUploadModalOpen(false);
// 				}
// 			})
// 			.catch((err) => {});
// 	};

// 	const handlePolicyDocChange = ({ fileList: newFileList }) => {
// 		const updatedFileList = newFileList.map((file) => {
// 			if (file.status === 'error') {
// 				return { ...file, status: 'done' };
// 			}
// 			return file;
// 		});
// 		setPolicyDoc(updatedFileList);
// 		formUpload.validateFields(['policy_document']);
// 	};

// 	// Create a function to handle policy type change
// 	const handlePolicyTypeChange = (e) => {
// 		const newPolicyType = e.target.value;
// 		setPolicyType(newPolicyType);

// 		// Reset fields if changing from commercial
// 		if (newPolicyType === 'medicare') {
// 			// Reset policy files
// 			setPolicyFiles([]);

// 			// Reset supporting files
// 			setSupportingFiles([]);

// 			// Reset form fields related to commercial
// 			form.setFieldsValue({
// 				policy_document: undefined,
// 				supporting_docs: undefined,
// 			});

// 			// Reset other related states if needed
// 			setSupportingDoc([]);
// 			setUploadedSupporingDoc([]);
// 		}
// 	};

// 	//Supporting docs
// 	const handleSupportingDocChange = ({ fileList: newFileList }) => {
// 		const updatedFileList = newFileList.map((file) => {
// 			if (file.status === 'error') {
// 				return { ...file, status: 'done' };
// 			}
// 			return file;
// 		});
// 		setSupportingDoc(updatedFileList);
// 		formUpload.validateFields(['supporting_docs']);
// 	};

// 	const uploadSupportingDocButton = (
// 		<Button
// 			onClick={() => setIsUploadModalOpen(true)}
// 			className="m-0"
// 			style={{
// 				borderRadius: '6px',
// 				border: '1px dashed #d9d9d9',
// 				display: 'block',
// 				height: '100px',
// 				padding: '4px 15px',
// 				width: '100px',
// 				background: 'rgba(0, 0, 0, 0.02)',
// 			}}
// 		>
// 			<PlusOutlined style={{ color: '#000' }} />
// 			<div style={{ color: '#000' }}>Upload</div>
// 		</Button>
// 	);

// 	const handlePreview = async (file) => {
// 		if (file && file.name && file.name.split('.').includes('pdf')) {
// 			if (!file.url && !file.preview) {
// 				file.preview = await getBase64(file.originFileObj);
// 			}
// 			setPreviewImage(file.url || file.preview);
// 			setPdfPreviewOpen(true);
// 		} else {
// 			if (!file.url && !file.preview) {
// 				file.preview = await getBase64(file.originFileObj);
// 			}
// 			setPreviewImage(file.url || file.preview);
// 			setPreviewOpen(true);
// 			setPreviewTitle(
// 				file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
// 			);
// 		}
// 	};

// 	const handleSupportingDocSubmit = async () => {
// 		formSupportingUpload
// 			.validateFields()
// 			.then(async () => {
// 				if (supportingDoc && supportingDoc.length > 0 && documentName) {
// 					let obj = {
// 						url: supportingDoc[0],
// 						document_name: documentName,
// 					};
// 					setUploadedSupporingDoc((prevFiles) => [...prevFiles, obj]);
// 					obj.preview = await getBase64(
// 						supportingDoc[0].originFileObj,
// 					);
// 					setSupportingFiles((prevFiles) => [...prevFiles, obj]);
// 					setSupportingDoc([]);
// 					setDocumentName('');
// 					formSupportingUpload.resetFields();
// 					setIsUploadModalOpen(false);
// 				}
// 			})
// 			.catch((err) => {});
// 	};

// 	const handleRemoveFile = async (file) => {
// 		Modal.confirm({
// 			title: 'Confirm Deletion',
// 			content: 'Are you sure you want to delete this file?',
// 			okText: 'Yes',
// 			cancelText: 'No',
// 			cancelButtonProps: {
// 				// Add an icon to the Cancel button
// 				style: {
// 					backgroundColor: '#fff', // Red background for Cancel
// 					color: '#007299', // Text color
// 				},
// 			},
// 			okButtonProps: {
// 				style: {
// 					backgroundColor: '#007299',
// 					borderColor: '#007299',
// 					color: '#fff',
// 				},
// 			},
// 			onOk: async () => {
// 				const updatedFileList = supportingFiles.filter(
// 					(item) => item.url.uid !== file.url.uid,
// 				);
// 				const UploadedFile = uploadedSupporingDoc.filter(
// 					(item) => item.url.uid !== file.url.uid,
// 				);
// 				// const updatedUploadedFile = updatedUploadedSupporingDoc.filter(
// 				// 	(item) => item.url.uid !== file.url.uid,
// 				// );
// 				setSupportingFiles(updatedFileList);
// 				// setUpdatedUploadedSupporingDoc(updatedUploadedFile);
// 				setUploadedSupporingDoc(UploadedFile);
// 				// if (!file.url.originFileObj) {
// 				// 	setDeletedUploadedSupportingDoc((prevFiles) => [
// 				// 		...prevFiles,
// 				// 		file,
// 				// 	]);
// 				// }
// 			},
// 			onCancel: () => {
// 				console.log('Deletion cancelled');
// 			},
// 		});
// 	};

// 	return (
// 		<>
// 			{loading && (
// 				<AnalysisLoader
// 					analysisId={id}
// 					isOpenAnalysis={'true'}
// 					analysisCompleted={analysisCompleted}
// 					setAnalysisCompleted={setAnalysisCompleted}
// 					setLoading={setLoading}
// 					loading={loading}
// 					isMissingFieldsThere={isMissingFieldsThere}
// 					setIsMissingFieldsThere={setIsMissingFieldsThere}
// 				/>
// 			)}
// 			<>
// 				<Col lg={24} xl={24} xs={0} md={0}>
// 					<Header headerTag="Check Coverage" />
// 				</Col>
// 				<Col lg={0} xl={0} xs={24} md={24}>
// 					<HeaderFile activeNav="check-coverage" />
// 				</Col>
// 			</>

// 			<XContainer fluid>
// 				{/* <div className="marginTopMobile" id=""></div> */}
// 				<XAffix
// 					id="notification"
// 					className="d-none zIndex"
// 					offsetTop={10}
// 				>
// 					{/* <div className="marginBottomMobile"></div> */}
// 					<div
// 						className="mb-3"
// 						style={{
// 							backgroundColor: 'white',
// 							borderRadius: '20px',
// 							zIndex: '1111110',
// 						}}
// 					>
// 						<XNotificationBar style={{ zIndex: '22222' }}>
// 							Clinical Summary has been changed !! Click to{' '}
// 							<XButtonReload
// 								type="button"
// 								style={{ padding: '18px' }}
// 								className="refreshBtn"
// 								onClick={() => {
// 									setLoading(true);
// 									document
// 										.getElementById('notification')
// 										?.classList.add('d-none');

// 									reviseCoverageReset();
// 									// Update lastAnalysisData with the latest pageContent values
// 									const updatedLastAnalysisData = {
// 										...lastAnalysisData, // Keep all the existing keys and values intact
// 										'Past History': pageContent.pastHistory, // Update pastHistory
// 										'Present Complaint':
// 											pageContent.presentComplaint, // Update presentComplaint
// 										'Suggested Treatment':
// 											pageContent.recommendedProcedure, // Update recommendedProcedure
// 										'Medicare Evaluation': {
// 											...lastAnalysisData[
// 												'Medicare Evaluation'
// 											],
// 											Coverage:
// 												pageContent.medicalCoverage[0], // Update medicalCoverage fields
// 											Rationale:
// 												pageContent.medicalCoverage[1],
// 											reference:
// 												pageContent.medicalCoverage[2],
// 										},
// 										original_query:
// 											pageContent.originalQuery, // Update original_query
// 										past_history: pageContent.pastHistory, // Update past_history in lastAnalysisData
// 										present_complaint:
// 											pageContent.presentComplaint, // Update present_complaint in lastAnalysisData
// 										status: pageContent.status, // Update status in lastAnalysisData
// 										missing_points_for_coverage:
// 											pageContent.missing_points_for_coverage, // Update missing_points_for_coverage
// 										// Add other updates if necessary, or keep them unchanged
// 									};
// 									reviseCoverage({
// 										// case_id: patientData._id,
// 										analysis_id: id,
// 										feedbackId: feedbackId,
// 										clinicalHistory:
// 											pageContent.pastHistory,
// 										presentComplaint:
// 											pageContent.presentComplaint,
// 										recommendedProcedure:
// 											pageContent.recommendedProcedure,
// 										medicalCoverage:
// 											pageContent.medicalCoverage,
// 										state: state,
// 										provider: provider,
// 										last_analysis_data:
// 											updatedLastAnalysisData,
// 										supportingDocsSummary:
// 											supportingDocsSummary,
// 									});
// 								}}
// 							>
// 								Reanalyse it !!
// 							</XButtonReload>
// 							<XButtonCloseBar
// 								type="button"
// 								className="closeBar mt-3"
// 							>
// 								<CloseOutlined
// 									onClick={() => {
// 										document
// 											.getElementById('notification')
// 											?.classList.add('d-none');
// 									}}
// 									style={{ fontSize: '20px' }}
// 								/>
// 							</XButtonCloseBar>
// 						</XNotificationBar>
// 					</div>
// 					<div className="marginNone"></div>
// 				</XAffix>
// 			</XContainer>

// 			<div id="getQust" className="">
// 				{/* Start Desktop design */}
// 				<AipaDesktopBody>
// 					<XContainer fluid>
// 						<XForm
// 							form={summaryForm}
// 							name="loginForm"
// 							layout="vertical"
// 							autoComplete="off"
// 							onFinish={handleShazamClick}
// 						>
// 							<Row
// 								gutter={(15, 15)}
// 								className="align-items-center"
// 							>
// 								<Col xs={{ span: 3, offset: 0 }}>
// 									<XLottieAipaLoader>
// 										<XLottieAipa
// 											animationData={LoaderJson}
// 											loop={true}
// 										/>
// 									</XLottieAipaLoader>
// 								</Col>
// 								<Col xs={{ span: 19, offset: 1 }}>
// 									<XDecodexTitle className="text-start">
// 										<img
// 											className="img-fluid"
// 											src="/images/reclaim-login-logo.svg"
// 											alt=""
// 										/>
// 									</XDecodexTitle>
// 									<ParaDeocdex className="text-start mt-2">
// 										Reclaim utilizes comprehensive
// 										standardized clinical notes to provide
// 										insights into Medicare coverage.
// 									</ParaDeocdex>
// 								</Col>
// 							</Row>
// 							<Row gutter={(15, 15)} className="mt-4">
// 								<Col xs={10}>
// 									<Row gutter={(15, 15)}>
// 										<Col xs={24}>
// 											<AddInputIcon>
// 												<EnvironmentOutlined />
// 											</AddInputIcon>
// 											<XForm.Item
// 												name="state"
// 												rules={[
// 													{
// 														required: true,
// 														message:
// 															'Please select State',
// 													},
// 												]}
// 												className="mb-0"
// 											>
// 												<XSelectIcons
// 													size="large"
// 													placeholder="Select State"
// 													name="state"
// 													showSearch={true}
// 													// onSearch={(search) => {
// 													// 	if (
// 													// 		search.length >= 3
// 													// 	) {
// 													// 		getStateDropdown({
// 													// 			search,
// 													// 		});
// 													// 	} else if (
// 													// 		search.length === 0
// 													// 	) {
// 													// 		getStateDropdown();
// 													// 	}
// 													// }}
// 													filterOption={filterByLabel}
// 													options={
// 														getStateDropdownState.apiState ===
// 															'success' &&
// 														getStateDropdownState
// 															.data.all_states
// 															?.length > 0 &&
// 														getStateDropdownState.data.all_states.map(
// 															(itm) => {
// 																return {
// 																	label: itm.state,
// 																	value: itm.state,
// 																	providers:
// 																		itm.providers,
// 																};
// 															},
// 														)
// 													}
// 													onSelect={(
// 														value,
// 														options,
// 													) => {
// 														setState(value);
// 														summaryForm.setFieldValue(
// 															'insurance_company',
// 															null,
// 														);
// 														setInsuranceCompanies(
// 															options.providers,
// 														);
// 													}}
// 												></XSelectIcons>
// 											</XForm.Item>
// 										</Col>
// 										<Col xs={24} className="mt-2">
// 											<AddInputIcon>
// 												<UserSwitchOutlined />
// 											</AddInputIcon>
// 											<XForm.Item
// 												name="insurance_company"
// 												className="mb-0"
// 												rules={[
// 													{
// 														required: true,
// 														message:
// 															'Please select provider',
// 													},
// 												]}
// 											>
// 												<XSelectIcons
// 													size="large"
// 													placeholder="Select Insurance Company"
// 													name="insurance_company"
// 													showSearch={true}
// 													filterOption={filterByLabel}
// 													options={
// 														insuranceCompanies.length >
// 															0 &&
// 														insuranceCompanies.map(
// 															(itm) => {
// 																return {
// 																	label: itm.name,
// 																	value: itm.name,
// 																};
// 															},
// 														)
// 													}
// 													onSelect={(value) => {
// 														setProvider(value);
// 													}}
// 												></XSelectIcons>
// 											</XForm.Item>
// 										</Col>
// 										<Col xs={24}>
// 											<XDivider />
// 										</Col>
// 										<Col xs={24}>
// 											<XForm.Item
// 												className="addCaseLabel mb-0"
// 												// name="policy_type"
// 												label="what is your policy type?"
// 												rules={[
// 													{
// 														required: true,
// 														message:
// 															'Please select option!',
// 													},
// 												]}
// 											>
// 												<XRadio.Group
// 													className="w-100"
// 													defaultValue={policyType}
// 													onChange={
// 														handlePolicyTypeChange
// 													}
// 												>
// 													<XRadio value={'medicare'}>
// 														Medicare Policy
// 													</XRadio>
// 													<XRadio
// 														value={'commercial'}
// 													>
// 														Commercial Policy
// 													</XRadio>
// 												</XRadio.Group>
// 											</XForm.Item>
// 										</Col>
// 										<Col xs={24}>
// 											<XDivider />
// 										</Col>
// 										<Col xs={24}>
// 											<XForm.Item
// 												className="addCaseLabel mb-0"
// 												name="clinical_category"
// 												rules={[
// 													{
// 														required: true,
// 														message:
// 															'Please select option!',
// 													},
// 												]}
// 											>
// 												<XRadio.Group
// 													className="w-100"
// 													defaultValue={
// 														clinicalSelectedOption
// 													}
// 													onChange={(e) => {
// 														handleInputChange(
// 															e,
// 															formData,
// 															setFormData,
// 														);
// 														if (
// 															e.target.value ===
// 															'clinical_text'
// 														) {
// 															setClinicalSelectedOption(
// 																'clinical_text',
// 															);
// 														} else if (
// 															e.target.value ===
// 															'clinical_document'
// 														) {
// 															setClinicalSelectedOption(
// 																'clinical_document',
// 															);
// 															// } else if (
// 															// 	e.target.value ===
// 															// 	'dictate_audio'
// 															// ) {
// 															// 	setClinicalSelectedOption(
// 															// 		'dictate_audio',
// 															// 	);
// 														}
// 													}}
// 												>
// 													<XRadio
// 														value={'clinical_text'}
// 													>
// 														Enter Clinical Summary
// 													</XRadio>
// 													<XRadio
// 														value={
// 															'clinical_document'
// 														}
// 													>
// 														Upload Clinical Summary
// 													</XRadio>
// 													{/* <XRadio
// 														value={'dictate_audio'}
// 													>
// 														Dictate
// 													</XRadio> */}
// 												</XRadio.Group>
// 											</XForm.Item>
// 										</Col>
// 									</Row>
// 								</Col>
// 								<Col xs={14} >
// 									{clinicalSelectedOption ===
// 										'clinical_text' && (
// 										<XForm.Item
// 											className="addCaseLabel mb-0"
// 											name="clinical_summary"
// 											rules={[
// 												{
// 													required: true,
// 													message:
// 														'Please enter clinical summary',
// 												},
// 											]}
// 										>
// 											<XInputTextArea.TextArea
// 												className="textAreaStyle"
// 												name="clinical_summary"
// 												// showCount
// 												autoSize={{
// 													minRows: 9,
// 												}}
// 												onChange={(e) => {
// 													handleInputChange(
// 														e,
// 														formData,
// 														setFormData,
// 													);
// 													setSummary(e.target.value);
// 													setClinicalDocumentFileList(
// 														[],
// 													);
// 													setUpdatedClinicalDocumentFileList(
// 														[],
// 													);
// 												}}
// 												placeholder="Enter Detailed Clinical Summary"
// 											/>
// 										</XForm.Item>
// 									)}
// 									{clinicalSelectedOption ===
// 										'clinical_document' && (
// 										<XCardDesktop className="bodyPadding h-auto">
// 											<XForm.Item
// 												className="mb-0"
// 												name="clinical_document"
// 												rules={[
// 													{
// 														required: true,
// 														validator: (
// 															_,
// 															value,
// 														) => {
// 															if (
// 																clinicalDocumentFileList.length ===
// 																0
// 															) {
// 																return Promise.reject(
// 																	new Error(
// 																		'Please upload Clinical Document!',
// 																	),
// 																);
// 															}
// 															return Promise.resolve();
// 														},
// 													},
// 												]}
// 											>
// 												<div className="uploadClinicalDocument">
// 													<Dragger
// 														style={{
// 															background: 'none',
// 														}}
// 														action={null}
// 														multiple={false}
// 														maxCount={1}
// 														showUploadList={{
// 															showPreviewIcon: true,
// 															showRemoveIcon: true,
// 														}}
// 														className="supporting_files"
// 														listType="picture-card"
// 														fileList={
// 															clinicalDocumentFileList
// 														}
// 														onPreview={
// 															handlePreviewClinicalSummary
// 														}
// 														onChange={
// 															handleClinicalDocumentChange
// 														}
// 														onRemove={(file) => {
// 															handleRemoveClinicalDocuments(
// 																file,
// 															);
// 															return false; // Prevent default removal behavior to avoid triggering onChange
// 														}}
// 														accept=".pdf"
// 														beforeUpload={(
// 															file,
// 														) => {
// 															const isJPEG =
// 																file.type ===
// 																'image/jpeg';
// 															const isJPG =
// 																file.type ===
// 																'image/jpg';
// 															const isPNG =
// 																file.type ===
// 																'image/png';
// 															const isGIF =
// 																file.type ===
// 																'image/gif';
// 															const isVideo =
// 																file.type.startsWith(
// 																	'video/',
// 																);
// 															const isAudio =
// 																file.type.startsWith(
// 																	'audio/',
// 																);

// 															const isLt10M =
// 																file.size /
// 																	1024 /
// 																	1024 <
// 																10;

// 															if (!isLt10M) {
// 																notification.error(
// 																	{
// 																		message:
// 																			'File Size Exceeded',
// 																		description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
// 																	},
// 																);
// 															}
// 															if (
// 																isGIF ||
// 																isVideo ||
// 																isAudio ||
// 																isJPEG ||
// 																isJPG ||
// 																isPNG
// 															) {
// 																notification.error(
// 																	{
// 																		message:
// 																			'File Type Error',
// 																		description: `GIF, video, image and audio files are not allowed.`,
// 																	},
// 																);
// 															}

// 															return (
// 																isLt10M &&
// 																!isGIF &&
// 																!isVideo &&
// 																!isAudio &&
// 																!isJPEG &&
// 																!isJPG &&
// 																!isPNG
// 															);
// 														}}
// 													>
// 														{clinicalDocumentFileList.length >=
// 														1 ? null : (
// 															<>
// 																<img
// 																	src="/images/aipa-clinical-document.svg"
// 																	alt=""
// 																/>
// 																<p className="uploadClinicalDocumentText">
// 																	Click or
// 																	drag file to
// 																	this area to
// 																	upload
// 																</p>
// 															</>
// 														)}
// 													</Dragger>
// 												</div>
// 											</XForm.Item>
// 										</XCardDesktop>
// 									)}
// 								</Col>
// 								{/* Policy Docs new column start */}
// 								<Col xs={24} className="mt-3">
// 									<XCardDesktop className="bodyPadding bodyHeight h-auto">
// 										<XForm.Item
// 											className="addCaseLabel mb-0 p-3"
// 											label="Upload Insurance Policy Document"
// 											name="policy_document"
// 											// rules={[
// 											// 	{
// 											// 		required: true,
// 											// 		validator: (_, value) => {
// 											// 			if (
// 											// 				policyFiles.length ===
// 											// 				0
// 											// 			) {
// 											// 				return Promise.reject(
// 											// 					new Error(
// 											// 						'Please upload insurance policy document!',
// 											// 					),
// 											// 				);
// 											// 			}
// 											// 			return Promise.resolve();
// 											// 		},
// 											// 	},
// 											// ]}
// 											rules={[
// 												{
// 													required:
// 														policyType ===
// 														'commercial',
// 													validator: (_, value) => {
// 														if (
// 															policyType ===
// 																'commercial' &&
// 															policyFiles.length ===
// 																0
// 														) {
// 															return Promise.reject(
// 																new Error(
// 																	'Please upload policy document!',
// 																),
// 															);
// 														}
// 														return Promise.resolve();
// 													},
// 												},
// 											]}
// 										>
// 											<Space
// 												size={[5, 5]}
// 												style={{
// 													flexWrap: 'wrap',
// 													alignItems: 'start',
// 												}}
// 											>
// 												{policyFiles.length > 0 &&
// 													policyFiles.map((ele) => {
// 														return (
// 															<>
// 																{(
// 																	ele.url
// 																		? ele.url.name
// 																				.split(
// 																					'.',
// 																				)
// 																				.includes(
// 																					'pdf',
// 																				)
// 																		: ele.originFileObj.name
// 																				.split(
// 																					'.',
// 																				)
// 																				.includes(
// 																					'pdf',
// 																				)
// 																) ? (
// 																	<>
// 																		<div className="image-container_case_filing">
// 																			<Card
// 																				hoverable
// 																				style={{
// 																					// display:
// 																					// 	'flex',
// 																					justifyContent:
// 																						'center',
// 																					alignItems:
// 																						'center',
// 																					borderRadius:
// 																						'6px',
// 																					border: '1px dashed #d9d9d9',
// 																					display:
// 																						'block',
// 																					height: '100px',
// 																					padding:
// 																						'4px 15px',
// 																					width: '100px',
// 																					background:
// 																						'rgba(0, 0, 0, 0.02)',
// 																					// margin: '20px',
// 																				}}
// 																				onClick={() =>
// 																					handlePolicyPreview(
// 																						ele.url,
// 																					)
// 																				}
// 																				id="timeline-documents-hover"
// 																			>
// 																				<div
// 																					style={{
// 																						display:
// 																							'flex',
// 																						justifyContent:
// 																							'center',
// 																						alignItems:
// 																							'center',
// 																						flexDirection:
// 																							'column',
// 																						textAlign:
// 																							'center',
// 																					}}
// 																				>
// 																					<FilePdfOutlined
// 																						style={{
// 																							fontSize:
// 																								'30px',
// 																							border: 'none',
// 																							background:
// 																								'transparent',
// 																						}}
// 																					/>
// 																				</div>
// 																			</Card>
// 																			<div
// 																				className="overlay_case_filing"
// 																				style={{
// 																					height: '100px',
// 																				}}
// 																			>
// 																				<EyeOutlined
// 																					onClick={() =>
// 																						handlePolicyPreview(
// 																							ele.url,
// 																						)
// 																					}
// 																					className="icon eye-icon"
// 																				/>
// 																				<DeleteOutlined
// 																					onClick={() =>
// 																						handleRemovePolicyFile(
// 																							ele,
// 																						)
// 																					}
// 																					className="icon download-icon"
// 																				/>
// 																			</div>
// 																			<Tooltip
// 																				title={
// 																					ele.document_name
// 																				}
// 																			>
// 																				<div
// 																					style={{
// 																						textAlign:
// 																							'center',
// 																						marginTop:
// 																							'5px',
// 																						fontSize:
// 																							'12px',
// 																						color: '#000',
// 																						whiteSpace:
// 																							'nowrap',
// 																						overflow:
// 																							'hidden',
// 																						textOverflow:
// 																							'ellipsis',
// 																						width: '100px', // or any other width as per requirement
// 																					}}
// 																				>
// 																					{
// 																						ele.document_name
// 																					}
// 																				</div>
// 																			</Tooltip>
// 																		</div>
// 																	</>
// 																) : (
// 																	<>
// 																		<div className="image-container_case_filing">
// 																			<Image
// 																				width={
// 																					100
// 																				}
// 																				height={
// 																					100
// 																				}
// 																				style={{
// 																					borderRadius:
// 																						'10px',
// 																				}}
// 																				className="supporting-docs-hover"
// 																				src={`${ele.preview}`}
// 																				onClick={() =>
// 																					handlePolicyPreview(
// 																						ele.url,
// 																					)
// 																				}
// 																			/>
// 																			<div
// 																				className="overlay_case_filing"
// 																				style={{
// 																					height: '100px',
// 																				}}
// 																			>
// 																				<EyeOutlined
// 																					onClick={() =>
// 																						handlePolicyPreview(
// 																							ele.url,
// 																						)
// 																					}
// 																					className="icon eye-icon"
// 																				/>
// 																				<DeleteOutlined
// 																					onClick={() =>
// 																						handleRemovePolicyFile(
// 																							ele,
// 																						)
// 																					}
// 																					className="icon download-icon"
// 																				/>
// 																			</div>
// 																			<Tooltip
// 																				title={
// 																					ele.document_name
// 																				}
// 																			>
// 																				<div
// 																					style={{
// 																						textAlign:
// 																							'center',
// 																						marginTop:
// 																							'5px',
// 																						fontSize:
// 																							'12px',
// 																						color: '#000',
// 																						whiteSpace:
// 																							'nowrap',
// 																						overflow:
// 																							'hidden',
// 																						textOverflow:
// 																							'ellipsis',
// 																						width: '100px', // or any other width as per requirement
// 																					}}
// 																				>
// 																					{
// 																						ele.document_name
// 																					}
// 																				</div>
// 																			</Tooltip>
// 																		</div>
// 																	</>
// 																)}
// 															</>
// 														);
// 													})}
// 												{policyFiles.length > 0
// 													? null
// 													: uploadPolicyDocButton}
// 											</Space>
// 										</XForm.Item>
// 									</XCardDesktop>
// 								</Col>

// 								{/* Supporting Docs new column start */}
// 								<Col xs={24} className="mt-3">
// 									<XCardDesktop className="bodyPadding bodyHeight h-auto">
// 										<XForm.Item
// 											className="addCaseLabel mb-0 p-3"
// 											label="Upload Supporting Documents (Optional)"
// 										>
// 											<Space
// 												size={[5, 5]}
// 												style={{
// 													flexWrap: 'wrap',
// 													alignItems: 'start',
// 												}}
// 											>
// 												{supportingFiles.length > 0 &&
// 													supportingFiles.map(
// 														(ele) => {
// 															return (
// 																<>
// 																	{(
// 																		ele.url
// 																			? ele.url.name
// 																					.split(
// 																						'.',
// 																					)
// 																					.includes(
// 																						'pdf',
// 																					)
// 																			: ele.originFileObj.name
// 																					.split(
// 																						'.',
// 																					)
// 																					.includes(
// 																						'pdf',
// 																					)
// 																	) ? (
// 																		<>
// 																			<div className="image-container_case_filing">
// 																				<Card
// 																					hoverable
// 																					style={{
// 																						// display:
// 																						// 	'flex',
// 																						justifyContent:
// 																							'center',
// 																						alignItems:
// 																							'center',
// 																						borderRadius:
// 																							'6px',
// 																						border: '1px dashed #d9d9d9',
// 																						display:
// 																							'block',
// 																						height: '100px',
// 																						padding:
// 																							'4px 15px',
// 																						width: '100px',
// 																						background:
// 																							'rgba(0, 0, 0, 0.02)',
// 																						// margin: '20px',
// 																					}}
// 																					onClick={() =>
// 																						handlePreview(
// 																							ele.url,
// 																						)
// 																					}
// 																					id="timeline-documents-hover"
// 																				>
// 																					<div
// 																						style={{
// 																							display:
// 																								'flex',
// 																							justifyContent:
// 																								'center',
// 																							alignItems:
// 																								'center',
// 																							flexDirection:
// 																								'column',
// 																							textAlign:
// 																								'center',
// 																						}}
// 																					>
// 																						<FilePdfOutlined
// 																							style={{
// 																								fontSize:
// 																									'30px',
// 																								border: 'none',
// 																								background:
// 																									'transparent',
// 																							}}
// 																						/>
// 																					</div>
// 																				</Card>
// 																				<div
// 																					className="overlay_case_filing"
// 																					style={{
// 																						height: '100px',
// 																					}}
// 																				>
// 																					<EyeOutlined
// 																						onClick={() =>
// 																							handlePreview(
// 																								ele.url,
// 																							)
// 																						}
// 																						className="icon eye-icon"
// 																					/>
// 																					<DeleteOutlined
// 																						onClick={() =>
// 																							handleRemoveFile(
// 																								ele,
// 																							)
// 																						}
// 																						className="icon download-icon"
// 																					/>
// 																				</div>
// 																				<Tooltip
// 																					title={
// 																						ele.document_name
// 																					}
// 																				>
// 																					<div
// 																						style={{
// 																							textAlign:
// 																								'center',
// 																							marginTop:
// 																								'5px',
// 																							fontSize:
// 																								'12px',
// 																							color: '#000',
// 																							whiteSpace:
// 																								'nowrap',
// 																							overflow:
// 																								'hidden',
// 																							textOverflow:
// 																								'ellipsis',
// 																							width: '100px', // or any other width as per requirement
// 																						}}
// 																					>
// 																						{
// 																							ele.document_name
// 																						}
// 																					</div>
// 																				</Tooltip>
// 																			</div>
// 																		</>
// 																	) : (
// 																		<>
// 																			<div className="image-container_case_filing">
// 																				<Image
// 																					width={
// 																						100
// 																					}
// 																					height={
// 																						100
// 																					}
// 																					style={{
// 																						borderRadius:
// 																							'10px',
// 																					}}
// 																					className="supporting-docs-hover"
// 																					src={`${ele.preview}`}
// 																					onClick={() =>
// 																						handlePreview(
// 																							ele.url,
// 																						)
// 																					}
// 																				/>
// 																				<div
// 																					className="overlay_case_filing"
// 																					style={{
// 																						height: '100px',
// 																					}}
// 																				>
// 																					<EyeOutlined
// 																						onClick={() =>
// 																							handlePreview(
// 																								ele.url,
// 																							)
// 																						}
// 																						className="icon eye-icon"
// 																					/>
// 																					<DeleteOutlined
// 																						onClick={() =>
// 																							handleRemoveFile(
// 																								ele,
// 																							)
// 																						}
// 																						className="icon download-icon"
// 																					/>
// 																				</div>
// 																				<Tooltip
// 																					title={
// 																						ele.document_name
// 																					}
// 																				>
// 																					<div
// 																						style={{
// 																							textAlign:
// 																								'center',
// 																							marginTop:
// 																								'5px',
// 																							fontSize:
// 																								'12px',
// 																							color: '#000',
// 																							whiteSpace:
// 																								'nowrap',
// 																							overflow:
// 																								'hidden',
// 																							textOverflow:
// 																								'ellipsis',
// 																							width: '100px', // or any other width as per requirement
// 																						}}
// 																					>
// 																						{
// 																							ele.document_name
// 																						}
// 																					</div>
// 																				</Tooltip>
// 																			</div>
// 																		</>
// 																	)}
// 																</>
// 															);
// 														},
// 													)}
// 												{supportingFiles.length > 25
// 													? null
// 													: uploadSupportingDocButton}
// 											</Space>
// 										</XForm.Item>
// 									</XCardDesktop>
// 								</Col>
// 							</Row>
// 							<Row gutter={(15, 15)} className="mt-5">
// 								<Col xs={{ span: 14, offset: 10 }}>
// 									<XShazaamBtn
// 										size="large"
// 										htmlType="submit"
// 										type="primary"
// 									>
// 										Shazaam !
// 									</XShazaamBtn>
// 								</Col>
// 							</Row>
// 						</XForm>
// 					</XContainer>
// 				</AipaDesktopBody>
// 				{/* End Desktop design */}
// 				{/* Start Mobile design */}
// 				<AipaMobileBody className="paddingPage">
// 					<XContainer fluid className="paddingPage">
// 						<XForm
// 							form={summaryForm}
// 							name="loginForm"
// 							layout="vertical"
// 							autoComplete="off"
// 							onFinish={handleShazamClick}
// 						>
// 							<Row>
// 								<Col
// 									xs={{ span: 16, offset: 4 }}
// 									className="text-center"
// 								>
// 									<XLottieAipaLoader>
// 										<XLottieAipa
// 											animationData={LoaderJson}
// 											loop={true}
// 										/>
// 									</XLottieAipaLoader>
// 								</Col>

// 								<Col xs={24} className="mt-3">
// 									<ParaDeocdex>
// 										Reclaim utilizes comprehensive
// 										standardized clinical notes to provide
// 										insights into Medicare coverage.
// 									</ParaDeocdex>
// 								</Col>

// 								<Col xs={24} className="mt-3">
// 									<AddInputIcon>
// 										<EnvironmentOutlined />
// 									</AddInputIcon>
// 									<XForm.Item
// 										name="state"
// 										rules={[
// 											{
// 												required: true,
// 												message: 'Please select State',
// 											},
// 										]}
// 										className="mb-0"
// 									>
// 										<XSelectIcons
// 											style={{
// 												height: '50px',
// 											}}
// 											placeholder="Select State"
// 											name="state"
// 											showSearch={true}
// 											onSearch={(search) => {
// 												if (search.length >= 3) {
// 													getStateDropdown({
// 														search,
// 													});
// 												} else if (
// 													search.length === 0
// 												) {
// 													getStateDropdown();
// 												}
// 											}}
// 											filterOption={filterByLabel}
// 											options={
// 												getStateDropdownState.apiState ===
// 													'success' &&
// 												getStateDropdownState.data
// 													.all_states?.length > 0 &&
// 												getStateDropdownState.data.all_states.map(
// 													(itm) => {
// 														return {
// 															label: itm.state,
// 															value: itm.state,
// 															providers:
// 																itm.providers,
// 														};
// 													},
// 												)
// 											}
// 											onSelect={(value, options) => {
// 												setState(value);
// 												summaryForm.setFieldValue(
// 													'insurance_company',
// 													null,
// 												);
// 												setInsuranceCompanies(
// 													options.providers,
// 												);
// 											}}
// 										></XSelectIcons>
// 									</XForm.Item>
// 								</Col>

// 								<Col xs={24} className="mt-3">
// 									<AddInputIcon>
// 										<UserSwitchOutlined />
// 									</AddInputIcon>
// 									<XForm.Item
// 										name="insurance_company"
// 										className="mb-0"
// 										rules={[
// 											{
// 												required: true,
// 												message:
// 													'Please select Provider',
// 											},
// 										]}
// 									>
// 										<XSelectIcons
// 											style={{
// 												height: '50px',
// 											}}
// 											placeholder="Select Insurance Company"
// 											name="insurance_company"
// 											showSearch={true}
// 											filterOption={filterByLabel}
// 											options={
// 												insuranceCompanies.length > 0 &&
// 												insuranceCompanies.map(
// 													(itm) => {
// 														return {
// 															label: itm.name,
// 															value: itm.name,
// 														};
// 													},
// 												)
// 											}
// 											onSelect={(value) => {
// 												setProvider(value);
// 											}}
// 										></XSelectIcons>
// 									</XForm.Item>
// 								</Col>

// 								<Col xs={24} className="mt-3 ">
// 									<XForm.Item
// 										className="addCaseLabel mb-0"
// 										// name="policy_type"
// 										label="What is your policy type?"
// 										rules={[
// 											{
// 												required: true,
// 												message:
// 													'Please select option!',
// 											},
// 										]}
// 									>
// 										<XRadio.Group
// 											className="w-100"
// 											defaultValue={policyType}
// 											onChange={handlePolicyTypeChange}
// 										>
// 											<XRadio value={'medicare'}>
// 												Medicare Policy
// 											</XRadio>
// 											<XRadio value={'commercial'}>
// 												Commercial Policy
// 											</XRadio>
// 										</XRadio.Group>
// 									</XForm.Item>
// 								</Col>

// 								{/* Policy Docs new column start */}
// 								{policyType === 'commercial' && (
// 									<>
// 										<Col xs={24} className="mt-3 h-auto">
// 											<XCardDesktop className="bodyPadding bodyHeight h-auto">
// 												<XForm.Item
// 													className="addCaseLabel mb-0 p-3"
// 													label="Upload Insurance Policy Document"
// 													name="policy_document"
// 													rules={[
// 														{
// 															required: true,
// 															validator: (
// 																_,
// 																value,
// 															) => {
// 																if (
// 																	policyFiles.length ===
// 																	0
// 																) {
// 																	return Promise.reject(
// 																		new Error(
// 																			'Please upload insurance policy document!',
// 																		),
// 																	);
// 																}
// 																return Promise.resolve();
// 															},
// 														},
// 													]}
// 												>
// 													<Space
// 														size={[5, 5]}
// 														style={{
// 															flexWrap: 'wrap',
// 															alignItems: 'start',
// 														}}
// 													>
// 														{policyFiles.length >
// 															0 &&
// 															policyFiles.map(
// 																(ele) => {
// 																	return (
// 																		<>
// 																			{(
// 																				ele.url
// 																					? ele.url.name
// 																							.split(
// 																								'.',
// 																							)
// 																							.includes(
// 																								'pdf',
// 																							)
// 																					: ele.originFileObj.name
// 																							.split(
// 																								'.',
// 																							)
// 																							.includes(
// 																								'pdf',
// 																							)
// 																			) ? (
// 																				<>
// 																					<div className="image-container_case_filing">
// 																						<Card
// 																							hoverable
// 																							style={{
// 																								// display:
// 																								// 	'flex',
// 																								justifyContent:
// 																									'center',
// 																								alignItems:
// 																									'center',
// 																								borderRadius:
// 																									'6px',
// 																								border: '1px dashed #d9d9d9',
// 																								display:
// 																									'block',
// 																								height: '100px',
// 																								padding:
// 																									'4px 15px',
// 																								width: '100px',
// 																								background:
// 																									'rgba(0, 0, 0, 0.02)',
// 																								// margin: '20px',
// 																							}}
// 																							onClick={() =>
// 																								handlePolicyPreview(
// 																									ele.url,
// 																								)
// 																							}
// 																							id="timeline-documents-hover"
// 																						>
// 																							<div
// 																								style={{
// 																									display:
// 																										'flex',
// 																									justifyContent:
// 																										'center',
// 																									alignItems:
// 																										'center',
// 																									flexDirection:
// 																										'column',
// 																									textAlign:
// 																										'center',
// 																								}}
// 																							>
// 																								<FilePdfOutlined
// 																									style={{
// 																										fontSize:
// 																											'30px',
// 																										border: 'none',
// 																										background:
// 																											'transparent',
// 																									}}
// 																								/>
// 																							</div>
// 																						</Card>
// 																						<div
// 																							className="overlay_case_filing"
// 																							style={{
// 																								height: '100px',
// 																							}}
// 																						>
// 																							<EyeOutlined
// 																								onClick={() =>
// 																									handlePolicyPreview(
// 																										ele.url,
// 																									)
// 																								}
// 																								className="icon eye-icon"
// 																							/>
// 																							<DeleteOutlined
// 																								onClick={() =>
// 																									handleRemovePolicyFile(
// 																										ele,
// 																									)
// 																								}
// 																								className="icon download-icon"
// 																							/>
// 																						</div>
// 																						<Tooltip
// 																							title={
// 																								ele.document_name
// 																							}
// 																						>
// 																							<div
// 																								style={{
// 																									textAlign:
// 																										'center',
// 																									marginTop:
// 																										'5px',
// 																									fontSize:
// 																										'12px',
// 																									color: '#000',
// 																									whiteSpace:
// 																										'nowrap',
// 																									overflow:
// 																										'hidden',
// 																									textOverflow:
// 																										'ellipsis',
// 																									width: '100px', // or any other width as per requirement
// 																								}}
// 																							>
// 																								{
// 																									ele.document_name
// 																								}
// 																							</div>
// 																						</Tooltip>
// 																					</div>
// 																				</>
// 																			) : (
// 																				<>
// 																					<div className="image-container_case_filing">
// 																						<Image
// 																							width={
// 																								100
// 																							}
// 																							height={
// 																								100
// 																							}
// 																							style={{
// 																								borderRadius:
// 																									'10px',
// 																							}}
// 																							className="supporting-docs-hover"
// 																							src={`${ele.preview}`}
// 																							onClick={() =>
// 																								handlePolicyPreview(
// 																									ele.url,
// 																								)
// 																							}
// 																						/>
// 																						<div
// 																							className="overlay_case_filing"
// 																							style={{
// 																								height: '100px',
// 																							}}
// 																						>
// 																							<EyeOutlined
// 																								onClick={() =>
// 																									handlePolicyPreview(
// 																										ele.url,
// 																									)
// 																								}
// 																								className="icon eye-icon"
// 																							/>
// 																							<DeleteOutlined
// 																								onClick={() =>
// 																									handleRemovePolicyFile(
// 																										ele,
// 																									)
// 																								}
// 																								className="icon download-icon"
// 																							/>
// 																						</div>
// 																						<Tooltip
// 																							title={
// 																								ele.document_name
// 																							}
// 																						>
// 																							<div
// 																								style={{
// 																									textAlign:
// 																										'center',
// 																									marginTop:
// 																										'5px',
// 																									fontSize:
// 																										'12px',
// 																									color: '#000',
// 																									whiteSpace:
// 																										'nowrap',
// 																									overflow:
// 																										'hidden',
// 																									textOverflow:
// 																										'ellipsis',
// 																									width: '100px', // or any other width as per requirement
// 																								}}
// 																							>
// 																								{
// 																									ele.document_name
// 																								}
// 																							</div>
// 																						</Tooltip>
// 																					</div>
// 																				</>
// 																			)}
// 																		</>
// 																	);
// 																},
// 															)}
// 														{policyFiles.length > 0
// 															? null
// 															: uploadPolicyDocButton}
// 													</Space>
// 												</XForm.Item>
// 											</XCardDesktop>
// 										</Col>

// 										{/* Supporting Docs new column start */}
// 										<Col xs={24} className="mt-3">
// 											<XCardDesktop className="bodyPadding bodyHeight h-auto">
// 												<XForm.Item
// 													className="addCaseLabel mb-0 p-3"
// 													label="Upload Supporting Documents (Optional)"
// 												>
// 													<Space
// 														size={[5, 5]}
// 														style={{
// 															flexWrap: 'wrap',
// 															alignItems: 'start',
// 														}}
// 													>
// 														{supportingFiles.length >
// 															0 &&
// 															supportingFiles.map(
// 																(ele) => {
// 																	return (
// 																		<>
// 																			{(
// 																				ele.url
// 																					? ele.url.name
// 																							.split(
// 																								'.',
// 																							)
// 																							.includes(
// 																								'pdf',
// 																							)
// 																					: ele.originFileObj.name
// 																							.split(
// 																								'.',
// 																							)
// 																							.includes(
// 																								'pdf',
// 																							)
// 																			) ? (
// 																				<>
// 																					<div className="image-container_case_filing">
// 																						<Card
// 																							hoverable
// 																							style={{
// 																								// display:
// 																								// 	'flex',
// 																								justifyContent:
// 																									'center',
// 																								alignItems:
// 																									'center',
// 																								borderRadius:
// 																									'6px',
// 																								border: '1px dashed #d9d9d9',
// 																								display:
// 																									'block',
// 																								height: '100px',
// 																								padding:
// 																									'4px 15px',
// 																								width: '100px',
// 																								background:
// 																									'rgba(0, 0, 0, 0.02)',
// 																								// margin: '20px',
// 																							}}
// 																							onClick={() =>
// 																								handlePreview(
// 																									ele.url,
// 																								)
// 																							}
// 																							id="timeline-documents-hover"
// 																						>
// 																							<div
// 																								style={{
// 																									display:
// 																										'flex',
// 																									justifyContent:
// 																										'center',
// 																									alignItems:
// 																										'center',
// 																									flexDirection:
// 																										'column',
// 																									textAlign:
// 																										'center',
// 																								}}
// 																							>
// 																								<FilePdfOutlined
// 																									style={{
// 																										fontSize:
// 																											'30px',
// 																										border: 'none',
// 																										background:
// 																											'transparent',
// 																									}}
// 																								/>
// 																							</div>
// 																						</Card>
// 																						<div
// 																							className="overlay_case_filing"
// 																							style={{
// 																								height: '100px',
// 																							}}
// 																						>
// 																							<EyeOutlined
// 																								onClick={() =>
// 																									handlePreview(
// 																										ele.url,
// 																									)
// 																								}
// 																								className="icon eye-icon"
// 																							/>
// 																							<DeleteOutlined
// 																								onClick={() =>
// 																									handleRemoveFile(
// 																										ele,
// 																									)
// 																								}
// 																								className="icon download-icon"
// 																							/>
// 																						</div>
// 																						<Tooltip
// 																							title={
// 																								ele.document_name
// 																							}
// 																						>
// 																							<div
// 																								style={{
// 																									textAlign:
// 																										'center',
// 																									marginTop:
// 																										'5px',
// 																									fontSize:
// 																										'12px',
// 																									color: '#000',
// 																									whiteSpace:
// 																										'nowrap',
// 																									overflow:
// 																										'hidden',
// 																									textOverflow:
// 																										'ellipsis',
// 																									width: '100px', // or any other width as per requirement
// 																								}}
// 																							>
// 																								{
// 																									ele.document_name
// 																								}
// 																							</div>
// 																						</Tooltip>
// 																					</div>
// 																				</>
// 																			) : (
// 																				<>
// 																					<div className="image-container_case_filing">
// 																						<Image
// 																							width={
// 																								100
// 																							}
// 																							height={
// 																								100
// 																							}
// 																							style={{
// 																								borderRadius:
// 																									'10px',
// 																							}}
// 																							className="supporting-docs-hover"
// 																							src={`${ele.preview}`}
// 																							onClick={() =>
// 																								handlePreview(
// 																									ele.url,
// 																								)
// 																							}
// 																						/>
// 																						<div
// 																							className="overlay_case_filing"
// 																							style={{
// 																								height: '100px',
// 																							}}
// 																						>
// 																							<EyeOutlined
// 																								onClick={() =>
// 																									handlePreview(
// 																										ele.url,
// 																									)
// 																								}
// 																								className="icon eye-icon"
// 																							/>
// 																							<DeleteOutlined
// 																								onClick={() =>
// 																									handleRemoveFile(
// 																										ele,
// 																									)
// 																								}
// 																								className="icon download-icon"
// 																							/>
// 																						</div>
// 																						<Tooltip
// 																							title={
// 																								ele.document_name
// 																							}
// 																						>
// 																							<div
// 																								style={{
// 																									textAlign:
// 																										'center',
// 																									marginTop:
// 																										'5px',
// 																									fontSize:
// 																										'12px',
// 																									color: '#000',
// 																									whiteSpace:
// 																										'nowrap',
// 																									overflow:
// 																										'hidden',
// 																									textOverflow:
// 																										'ellipsis',
// 																									width: '100px', // or any other width as per requirement
// 																								}}
// 																							>
// 																								{
// 																									ele.document_name
// 																								}
// 																							</div>
// 																						</Tooltip>
// 																					</div>
// 																				</>
// 																			)}
// 																		</>
// 																	);
// 																},
// 															)}
// 														{supportingFiles.length >
// 														25
// 															? null
// 															: uploadSupportingDocButton}
// 													</Space>
// 												</XForm.Item>
// 											</XCardDesktop>
// 										</Col>
// 									</>
// 								)}

// 								<Col xs={24} className="mt-4">
// 									<XForm.Item
// 										className="addCaseLabel mb-0"
// 										name="clinical_category"
// 										// value={formData?.clinical_category}
// 										// label="Clinical Summary"
// 										rules={[
// 											{
// 												required: true,
// 												message:
// 													'Please select option!',
// 											},
// 										]}
// 									>
// 										<XRadio.Group
// 											defaultValue={
// 												clinicalSelectedOption
// 											}
// 											onChange={(e) => {
// 												handleInputChange(
// 													e,
// 													formData,
// 													setFormData,
// 												);
// 												if (
// 													e.target.value ===
// 													'clinical_text'
// 												) {
// 													setClinicalSelectedOption(
// 														'clinical_text',
// 													);
// 												} else if (
// 													e.target.value ===
// 													'clinical_document'
// 												) {
// 													setClinicalSelectedOption(
// 														'clinical_document',
// 													);
// 												}
// 											}}
// 											className="w-100"
// 										>
// 											<XRadio
// 												className="pb-2"
// 												value={'clinical_text'}
// 											>
// 												Enter Clinical Summary
// 											</XRadio>
// 											<XRadio
// 												className="pb-2"
// 												value={'clinical_document'}
// 											>
// 												Upload Clinical Summary
// 											</XRadio>
// 										</XRadio.Group>
// 									</XForm.Item>
// 								</Col>

// 								<Col xs={24} className="mt-3">
// 									{clinicalSelectedOption ===
// 										'clinical_text' && (
// 										<XForm.Item
// 											className="addCaseLabel mb-0"
// 											name="clinical_summary"
// 											rules={[
// 												{
// 													required: true,
// 													message:
// 														'Please enter clinical summary',
// 												},
// 											]}
// 										>
// 											<XInputTextArea.TextArea
// 												className="textAreaReclaimStyle"
// 												name="clinical_summary"
// 												// showCount
// 												autoSize={{
// 													minRows: 9,
// 												}}
// 												onChange={(e) => {
// 													handleInputChange(
// 														e,
// 														formData,
// 														setFormData,
// 													);
// 													setSummary(e.target.value);
// 													setClinicalDocumentFileList(
// 														[],
// 													);
// 													setUpdatedClinicalDocumentFileList(
// 														[],
// 													);
// 												}}
// 												placeholder="Enter Detailed Clinical Summary"
// 											/>
// 										</XForm.Item>
// 									)}
// 									{clinicalSelectedOption ===
// 										'clinical_document' && (
// 										<XCardDesktop className="bodyPadding h-auto">
// 											<XForm.Item
// 												className="mb-0"
// 												name="clinical_document"
// 												rules={[
// 													{
// 														required: true,
// 														validator: (
// 															_,
// 															value,
// 														) => {
// 															if (
// 																clinicalDocumentFileList.length ===
// 																0
// 															) {
// 																return Promise.reject(
// 																	new Error(
// 																		'Please upload Clinical Document!',
// 																	),
// 																);
// 															}
// 															return Promise.resolve();
// 														},
// 													},
// 												]}
// 											>
// 												<div className="uploadClinicalDocument">
// 													<Dragger
// 														style={{
// 															background: 'none',
// 														}}
// 														action={null}
// 														multiple={false}
// 														maxCount={1}
// 														showUploadList={{
// 															showPreviewIcon: true,
// 															showRemoveIcon: true,
// 														}}
// 														className="supporting_files"
// 														listType="picture-card"
// 														fileList={
// 															clinicalDocumentFileList
// 														}
// 														onPreview={
// 															handlePreviewClinicalSummary
// 														}
// 														onChange={
// 															handleClinicalDocumentChange
// 														}
// 														onRemove={(file) => {
// 															handleRemoveClinicalDocuments(
// 																file,
// 															);
// 															return false; // Prevent default removal behavior to avoid triggering onChange
// 														}}
// 														accept=".pdf"
// 														beforeUpload={(
// 															file,
// 														) => {
// 															const isJPEG =
// 																file.type ===
// 																'image/jpeg';
// 															const isJPG =
// 																file.type ===
// 																'image/jpg';
// 															const isPNG =
// 																file.type ===
// 																'image/png';
// 															const isGIF =
// 																file.type ===
// 																'image/gif';
// 															const isVideo =
// 																file.type.startsWith(
// 																	'video/',
// 																);
// 															const isAudio =
// 																file.type.startsWith(
// 																	'audio/',
// 																);

// 															const isLt10M =
// 																file.size /
// 																	1024 /
// 																	1024 <
// 																10;

// 															if (!isLt10M) {
// 																notification.error(
// 																	{
// 																		message:
// 																			'File Size Exceeded',
// 																		description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
// 																	},
// 																);
// 															}
// 															if (
// 																isGIF ||
// 																isVideo ||
// 																isAudio ||
// 																isJPEG ||
// 																isJPG ||
// 																isPNG
// 															) {
// 																notification.error(
// 																	{
// 																		message:
// 																			'File Type Error',
// 																		description: `GIF, video, image and audio files are not allowed.`,
// 																	},
// 																);
// 															}

// 															return (
// 																isLt10M &&
// 																!isGIF &&
// 																!isVideo &&
// 																!isAudio &&
// 																!isJPEG &&
// 																!isJPG &&
// 																!isPNG
// 															);
// 														}}
// 													>
// 														{clinicalDocumentFileList.length >=
// 														1 ? null : (
// 															<>
// 																<img
// 																	src="/images/reclaim-clinical-document2.svg"
// 																	alt=""
// 																/>
// 																<p className="uploadClinicalDocumentText mt-3">
// 																	Click file
// 																	to this area
// 																	to upload
// 																</p>
// 															</>
// 														)}
// 													</Dragger>
// 												</div>
// 											</XForm.Item>
// 										</XCardDesktop>
// 									)}
// 								</Col>

// 								<Col xs={24} className="text-center mt-4">
// 									<XShazaamBtn
// 										size="large"
// 										htmlType="submit"
// 										type="primary"
// 									>
// 										Shazaam !
// 									</XShazaamBtn>
// 								</Col>
// 							</Row>
// 						</XForm>
// 					</XContainer>
// 				</AipaMobileBody>
// 				{/* End Mobile design */}
// 			</div>

// 			<XContainer className="marginNagitive slideBox" fluid>
// 				<div
// 					id="getAns"
// 					className="slideUp top-100 d-none"
// 					style={{ marginTop: '100px' }}
// 				>
// 					{appealLetterLoading && (
// 						<XLottieOverlay>
// 							<XLottie
// 								animationData={AppealLoading}
// 								loop={true}
// 							/>
// 						</XLottieOverlay>
// 					)}
// 					{showAppealLetterPreview ? (
// 						<>
// 							<Row>
// 								<AppealLetter
// 									aipa={true}
// 									policyType={policyType}
// 									checkCoverage={true}
// 									appealLetterContent={appealLetterContent}
// 									appealLetterLoading={appealLetterLoading}
// 									showAppealLetterPreview={
// 										showAppealLetterPreview
// 									}
// 									setShowAppealLetterPreview={
// 										setShowAppealLetterPreview
// 									}
// 									setAppealLetterLoading={
// 										setAppealLetterLoading
// 									}
// 									previewAppealLetter={previewAppealLetter}
// 									id={id}
// 									forceGenerateAppealLetter={
// 										forceGenerateAppealLetter
// 									}
// 								/>
// 							</Row>
// 						</>
// 					) : (
// 						<Row gutter={(0, 30)}>
// 							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
// 								{/* new component start */}
// 								<CaseOperations
// 									policyType={policyType}
// 									aipa={true}
// 									checkCoverage={true}
// 									previewMode={policyType === 'commercial'}
// 									matchGender={''}
// 									loading={loading}
// 									summary={summary}
// 									state={state}
// 									provider={provider}
// 									setSummary={setSummary}
// 									handleRemoveClinicalDocuments={
// 										handleRemoveClinicalDocuments
// 									}
// 									handleClinicalSummaryDoc={
// 										handleClinicalSummaryDoc
// 									}
// 									isOpenAnalysis={'true'}
// 									patientData={''}
// 									mainDataLoading={''}
// 									handleClinicalDocsChange={
// 										handleClinicalDocsChange
// 									}
// 									clinicalDocumentFileList={
// 										clinicalDocumentFileList
// 									}
// 									clinicalDocsForm={clinicalDocsForm}
// 									handleUploadCancel={handleUploadCancel}
// 									clinicalDocsLoading={clinicalDocsLoading}
// 									setIsClinicalUploadModalOpen={
// 										setIsClinicalUploadModalOpen
// 									}
// 									isClinicalUploadModalOpen={
// 										isClinicalUploadModalOpen
// 									}
// 									setPreviewImage={setPreviewImage}
// 									previewImage={previewImage}
// 									setPreviewTitle={setPreviewTitle}
// 									previewTitle={previewTitle}
// 									setPdfPreviewOpen={setPdfPreviewOpen}
// 									pdfPreviewOpen={pdfPreviewOpen}
// 									documentsToDisplay={supportingFiles}
// 									supportingDocsSummary={
// 										supportingDocsSummary
// 									}
// 									clinicalSummaryTextLoading={
// 										clinicalSummaryTextLoading
// 									}
// 									setClinicalSummaryTextLoading={
// 										setClinicalSummaryTextLoading
// 									}
// 									setShowClinicalTextArea={
// 										setShowClinicalTextArea
// 									}
// 									showClinicalTextArea={showClinicalTextArea}
// 									setFormData={setFormData}
// 									formData={formData}
// 									id={id}
// 									setPageContent={setPageContent}
// 									pageContent={pageContent}
// 									clinicalDocumentsToDisplay={
// 										clinicalDocumentsToDisplay
// 									}
// 									feedbackId={feedbackId}
// 									reviseCoverage={reviseCoverage}
// 									lastAnalysisData={lastAnalysisData}
// 									setLoading={setLoading}
// 									getBase64={getBase64}
// 									setClinicalDocsLoading={
// 										setClinicalDocsLoading
// 									}
// 									setSubmitClinicalDocs={
// 										setSubmitClinicalDocs
// 									}
// 									setUpdatedClinicalDocumentFileList={''}
// 									form={form}
// 									setModalTextArea={setModalTextArea}
// 									setModalOpen={setModalOpen}
// 									modalOpen={modalOpen}
// 									sendFeedback={sendFeedback}
// 									sendFeedbackState={sendFeedbackState}
// 									modalTextArea={modalTextArea}
// 									updatePageContent={updatePageContent}
// 									missingFieldsForm={missingFieldsForm}
// 									missingFieldsCardRef={missingFieldsCardRef}
// 									handleAddOption={handleAddOption}
// 									missingFields={missingFields}
// 									missingFieldInstructions={
// 										missingFieldInstructions
// 									}
// 									setMissingFieldsModal={
// 										setMissingFieldsModal
// 									}
// 									setMissingFieldInstructions={
// 										setMissingFieldInstructions
// 									}
// 									setMissingFields={setMissingFields}
// 									missingFieldTitle={missingFieldTitle}
// 									missingFieldsModal={missingFieldsModal}
// 									setAppealLetterLoading={
// 										setAppealLetterLoading
// 									}
// 									forceGenerateAppealLetter={
// 										forceGenerateAppealLetter
// 									}
// 								/>
// 								{/* new component end */}

// 								{!missingFieldsModal ? (
// 									<>
// 										<div className="showMobile">
// 											<Row
// 												gutter={(15, 15)}
// 												className="mt-4"
// 											>
// 												<Col xs={12}>
// 													<XButtonConfirm
// 														className="btnRefine w-100"
// 														type="primary"
// 														size="large"
// 														shape="round"
// 														style={{
// 															background: '#fff',
// 														}}
// 														icon={
// 															<img
// 																src={
// 																	isCheckCoveragePage
// 																		? '/images/reclaim-like-icon.svg'
// 																		: '/images/like-icon.svg'
// 																}
// 																className="rotateButton"
// 																alt=""
// 															/>
// 														}
// 														onClick={() => {
// 															form.setFieldsValue(
// 																{
// 																	modal_text_area:
// 																		pageContent.medicalCoverage
// 																			? pageContent
// 																					.medicalCoverage[1]
// 																			: '',
// 																	medicare_flag:
// 																		pageContent.medicalCoverage
// 																			? pageContent
// 																					.medicalCoverage[0]
// 																			: '',
// 																},
// 															);
// 															setModalTextArea(
// 																pageContent
// 																	.medicalCoverage[1],
// 															);
// 															setModalOpen([
// 																true,
// 																`Edit Medicare Coverage`,
// 																pageContent
// 																	.medicalCoverage[1],
// 																1,
// 																'feedbackSummary',
// 															]);
// 															setCallGetAnalysisAPiBtn(
// 																true,
// 															);
// 														}}
// 													>
// 														Refine
// 													</XButtonConfirm>
// 												</Col>
// 												<Col xs={12}>
// 													<XButtonConfirm
// 														className="btnRefine w-100"
// 														type="primary"
// 														size="large"
// 														shape="round"
// 														style={{
// 															background: '#fff',
// 														}}
// 														icon={
// 															<img
// 																src={
// 																	isCheckCoveragePage
// 																		? '/images/reclaim-like-icon.svg'
// 																		: '/images/like-icon.svg'
// 																}
// 																alt=""
// 															/>
// 														}
// 														loading={
// 															LooksGoodLoading
// 														}
// 														onClick={() => {
// 															setLooksGoodLoading(
// 																true,
// 															);
// 															setCallGetAnalysisAPiBtn(
// 																true,
// 															);
// 															sendFeedback({
// 																feedback_id:
// 																	feedbackId,
// 																feedback:
// 																	'positive',
// 																status: 'approved',
// 																response:
// 																	pageContent.medicalCoverage,
// 															});
// 														}}
// 													>
// 														Looks Good
// 													</XButtonConfirm>
// 												</Col>
// 												{pageContent
// 													.medicalCoverage[0] ===
// 													'Yes' && (
// 													<>
// 														<Col
// 															xs={24}
// 															className="mt-4"
// 														>
// 															<XShazaamBtn
// 																className="w-100"
// 																onClick={() => {
// 																	setAppealLetterLoading(
// 																		true,
// 																	);
// 																	// setLoading(true);
// 																	previewAppealLetter(
// 																		'pre',
// 																		lastAnalysisData.appeal_letter_generated,
// 																	);
// 																}}
// 																type="primary"
// 																shape="round"
// 																size="large"
// 															>
// 																Generate Appeal
// 																Letter
// 															</XShazaamBtn>
// 														</Col>
// 													</>
// 												)}
// 											</Row>
// 										</div>
// 										<div className="showDesktop">
// 											<Row className="text-end">
// 												<Col
// 													xs={24}
// 													lg={12}
// 													className="mt-4"
// 												>
// 													<Space
// 														style={{
// 															width: '100%',
// 														}}
// 													>
// 														<XButtonConfirm
// 															className="mb-2 mb-md-0 mb-sm-0"
// 															type="primary"
// 															size="large"
// 															shape="round"
// 															style={{
// 																background:
// 																	'#fff',
// 															}}
// 															icon={
// 																<img
// 																	src={
// 																		isCheckCoveragePage
// 																			? '/images/reclaim-like-icon.svg'
// 																			: '/images/like-icon.svg'
// 																	}
// 																	className="rotateButton"
// 																	alt=""
// 																/>
// 															}
// 															onClick={() => {
// 																form.setFieldsValue(
// 																	{
// 																		modal_text_area:
// 																			pageContent.medicalCoverage
// 																				? pageContent
// 																						.medicalCoverage[1]
// 																				: '',
// 																		medicare_flag:
// 																			pageContent.medicalCoverage
// 																				? pageContent
// 																						.medicalCoverage[0]
// 																				: '',
// 																	},
// 																);
// 																setModalTextArea(
// 																	pageContent
// 																		.medicalCoverage[1],
// 																);
// 																setModalOpen([
// 																	true,
// 																	`Edit Medicare Coverage`,
// 																	pageContent
// 																		.medicalCoverage[1],
// 																	1,
// 																	'feedbackSummary',
// 																]);
// 																setCallGetAnalysisAPiBtn(
// 																	true,
// 																);
// 															}}
// 														>
// 															Refine
// 														</XButtonConfirm>
// 														<XButtonConfirm
// 															className="mb-2 mb-md-0 mb-sm-0"
// 															type="primary"
// 															size="large"
// 															shape="round"
// 															style={{
// 																background:
// 																	'#fff',
// 															}}
// 															icon={
// 																<img
// 																	src={
// 																		isCheckCoveragePage
// 																			? '/images/reclaim-like-icon.svg'
// 																			: '/images/like-icon.svg'
// 																	}
// 																	alt=""
// 																/>
// 															}
// 															loading={
// 																LooksGoodLoading
// 															}
// 															onClick={() => {
// 																setLooksGoodLoading(
// 																	true,
// 																);
// 																setCallGetAnalysisAPiBtn(
// 																	true,
// 																);
// 																sendFeedback({
// 																	feedback_id:
// 																		feedbackId,
// 																	feedback:
// 																		'positive',
// 																	status: 'approved',
// 																	response:
// 																		pageContent.medicalCoverage,
// 																});
// 															}}
// 														>
// 															Looks Good
// 														</XButtonConfirm>
// 													</Space>
// 												</Col>
// 												{pageContent
// 													.medicalCoverage[0] ===
// 													'Yes' && (
// 													<>
// 														<Col
// 															xs={24}
// 															lg={12}
// 															className="mt-4 text-md-end"
// 														>
// 															<XButtonConfirm
// 																onClick={() => {
// 																	setAppealLetterLoading(
// 																		true,
// 																	);
// 																	if (
// 																		policyType ===
// 																			'commercial' &&
// 																		!lastAnalysisData.appeal_letter_generated
// 																	) {
// 																		previewAppealLetter(
// 																			'pre',
// 																			false,
// 																			true,
// 																		);
// 																	} else {
// 																		previewAppealLetter(
// 																			'pre',
// 																			lastAnalysisData.appeal_letter_generated,
// 																		);
// 																	}
// 																}}
// 																type="primary"
// 																shape="round"
// 																size="large"
// 																className="btnActive"
// 															>
// 																Generate Appeal
// 																Letter
// 															</XButtonConfirm>
// 														</Col>
// 													</>
// 												)}
// 											</Row>
// 										</div>
// 									</>
// 								) : (
// 									<></>
// 								)}
// 							</Col>
// 						</Row>
// 					)}
// 				</div>
// 			</XContainer>

// 			<XModal
// 				centered
// 				open={thanksModal[0]}
// 				closable={false} // Hide the close icon
// 				// onOk={() => setThanksModal([false, ''])}
// 				// onCancel={() => {
// 				// 	setThanksModal([false, '']);
// 				// }}
// 				className="modalButton"
// 				okText="Submit"
// 				footer={null}
// 			>
// 				<Row
// 					style={{
// 						display: 'flex',
// 						justifyContent: 'center',
// 					}}
// 					gutter={(15, 0)}
// 					className="mt-2"
// 				>
// 					<Col xs={24} lg={18}>
// 						<XLottieMargin>
// 							<XLottie animationData={Thanks} loop={true} />
// 						</XLottieMargin>

// 						<XPara1
// 							style={{ textAlign: 'center' }}
// 							className="mt-0 mb-4 "
// 						>
// 							{thanksModal[1]}
// 						</XPara1>
// 					</Col>
// 				</Row>
// 			</XModal>

// 			<Modal
// 				title="Upload Insurance Policy Document"
// 				open={isPolicyUploadModalOpen}
// 				onOk={handlePolicyDocSubmit}
// 				onCancel={handleUploadCancel}
// 				okButtonProps={{
// 					style: {
// 						backgroundColor: '#007299',
// 						borderColor: '#007299',
// 						color: '#fff',
// 						borderRadius: '20px',
// 					},
// 				}}
// 				cancelButtonProps={{
// 					style: { color: '#007299', borderRadius: '20px' },
// 				}}
// 			>
// 				<XForm form={formUpload} layout="vertical" autoComplete="off">
// 					<XForm.Item
// 						name="policy_document"
// 						rules={[
// 							{
// 								required: true,
// 								validator: (_, value) => {
// 									if (policyDoc.length === 0) {
// 										return Promise.reject(
// 											new Error('Please Select file!'),
// 										);
// 									}
// 									return Promise.resolve();
// 								},
// 							},
// 						]}
// 					>
// 						<Dragger
// 							fileList={policyDoc}
// 							maxCount={1}
// 							beforeUpload={(file) => {
// 								const isGIF = file.type === 'image/gif';
// 								const isVideo = file.type.startsWith('video/');
// 								const isAudio = file.type.startsWith('audio/');
// 								const isJPEG = file.type === 'image/jpeg';
// 								const isJPG = file.type === 'image/jpg';
// 								const isPNG = file.type === 'image/png';
// 								const isLt10M = file.size / 1024 / 1024 < 10;

// 								if (!isLt10M) {
// 									notification.error({
// 										message: 'File Size Exceeded',
// 										description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
// 									});
// 								}
// 								if (
// 									isGIF ||
// 									isVideo ||
// 									isAudio ||
// 									isJPEG ||
// 									isJPG ||
// 									isPNG
// 								) {
// 									notification.error({
// 										message: 'File Type Error',
// 										description: `GIF, video, image and audio files are not allowed.`,
// 									});
// 								}

// 								return (
// 									isLt10M &&
// 									!isGIF &&
// 									!isVideo &&
// 									!isAudio &&
// 									!isJPEG &&
// 									!isJPG &&
// 									!isPNG
// 								);
// 							}}
// 							// accept=".pdf,.jpeg,.jpg,.png"
// 							accept=".pdf"
// 							onChange={handlePolicyDocChange}
// 						>
// 							<p className="ant-upload-drag-icon">
// 								<InboxOutlined style={{ color: '#007299' }} />
// 							</p>
// 							<p className="ant-upload-text">
// 								Click or drag file to this area to upload
// 							</p>
// 						</Dragger>
// 					</XForm.Item>
// 				</XForm>
// 			</Modal>

// 			<Modal
// 				title="Upload Supporting Documents"
// 				open={isUploadModalOpen}
// 				onOk={handleSupportingDocSubmit}
// 				onCancel={handleUploadCancel}
// 				okButtonProps={{
// 					style: {
// 						backgroundColor: '#007299',
// 						borderColor: '#007299',
// 						color: '#fff',
// 						borderRadius: '20px',
// 					},
// 				}}
// 				cancelButtonProps={{
// 					style: { color: '#007299', borderRadius: '20px' },
// 				}}
// 			>
// 				<XForm
// 					form={formSupportingUpload}
// 					layout="vertical"
// 					autoComplete="off"
// 				>
// 					<XForm.Item
// 						name="Supporting_docs"
// 						rules={[
// 							{
// 								required: true,
// 								validator: (_, value) => {
// 									if (supportingDoc.length === 0) {
// 										return Promise.reject(
// 											new Error('Please Select file!'),
// 										);
// 									}
// 									return Promise.resolve();
// 								},
// 							},
// 						]}
// 					>
// 						<Dragger
// 							fileList={supportingDoc}
// 							maxCount={1}
// 							beforeUpload={(file) => {
// 								const isGIF = file.type === 'image/gif';
// 								const isVideo = file.type.startsWith('video/');
// 								const isAudio = file.type.startsWith('audio/');
// 								const isJPEG = file.type === 'image/jpeg';
// 								const isJPG = file.type === 'image/jpg';
// 								const isPNG = file.type === 'image/png';
// 								const isLt10M = file.size / 1024 / 1024 < 10;

// 								if (!isLt10M) {
// 									notification.error({
// 										message: 'File Size Exceeded',
// 										description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
// 									});
// 								}
// 								if (
// 									isGIF ||
// 									isVideo ||
// 									isAudio ||
// 									isJPEG ||
// 									isJPG ||
// 									isPNG
// 								) {
// 									notification.error({
// 										message: 'File Type Error',
// 										description: `GIF, video, image and audio files are not allowed.`,
// 									});
// 								}

// 								return (
// 									isLt10M &&
// 									!isGIF &&
// 									!isVideo &&
// 									!isAudio &&
// 									!isJPEG &&
// 									!isJPG &&
// 									!isPNG
// 								);
// 							}}
// 							// accept=".pdf,.jpeg,.jpg,.png"
// 							accept=".pdf"
// 							onChange={handleSupportingDocChange}
// 						>
// 							<p className="ant-upload-drag-icon">
// 								<InboxOutlined style={{ color: '#007299' }} />
// 							</p>
// 							<p className="ant-upload-text">
// 								Click or drag file to this area to upload
// 							</p>
// 						</Dragger>
// 					</XForm.Item>
// 					<XForm.Item
// 						name="document_name"
// 						label="Document Name"
// 						className="mt-3"
// 						rules={[
// 							{
// 								required: true,
// 								message: 'Document Name is required',
// 							},
// 						]}
// 					>
// 						<Input
// 							placeholder="Enter Document Name"
// 							// value={documentName}
// 							name="document_name"
// 							onChange={(e) => {
// 								setDocumentName(e.target.value);
// 							}}
// 						/>
// 					</XForm.Item>
// 				</XForm>
// 			</Modal>

// 			{/* Modal for adding new option */}
// 			<XModal
// 				title="Add New Option"
// 				open={newCheckBoxModal[0]}
// 				onOk={handleNewCheckBoxModal}
// 				okText="Submit"
// 				okButtonProps={{
// 					style: {
// 						backgroundColor: '#007299',
// 						borderColor: '#007299',
// 						color: '#fff',
// 						borderRadius: '20px',
// 					},
// 				}}
// 				onCancel={() => {
// 					setNewCheckBoxModal([false, null]);
// 					setNewOptionText('');
// 					newOptionForm.setFieldsValue({});
// 					newOptionForm.resetFields();
// 				}}
// 			>
// 				<XForm
// 					form={newOptionForm}
// 					name="groupForm"
// 					layout="vertical"
// 					autoComplete="off"
// 				>
// 					<XForm.Item
// 						name={'new-option'}
// 						rules={[
// 							{
// 								required: true,
// 								message: 'Please input option',
// 							},
// 						]}
// 					>
// 						<Input
// 							size="large"
// 							className="mt-3 mb-3"
// 							value={newOptionText}
// 							onChange={(e) => setNewOptionText(e.target.value)}
// 							placeholder="Enter new option text"
// 						/>
// 					</XForm.Item>
// 				</XForm>
// 			</XModal>
// 		</>
// 	);
// };

// const mapStateToProps = (state) => ({
// 	analyseCoverageState: state.analyseCoverage,
// 	reviseCoverageState: state.reviseCoverage,
// 	sendFeedbackState: state.sendFeedback,
// 	getStateDropdownState: state.getStateDropdown,
// 	getAnalysisState: state.getAnalysis,
// 	getAppealLetterState: state.getAppealLetter,
// });

// const mapDispatchToProps = (dispatch) => ({
// 	analyseCoverage: (params) => dispatch(analyseCoverageApi(params)),
// 	analyseCoverageReset: () => dispatch(analyseCoverageReset()),
// 	reviseCoverage: (params) => dispatch(reviseCoverageApi(params)),
// 	reviseCoverageReset: () => dispatch(reviseCoverageReset()),
// 	sendFeedback: (params) => dispatch(sendFeedbackApi(params)),
// 	sendFeedbackReset: () => dispatch(sendFeedbackReset()),
// 	getStateDropdown: () => dispatch(getStateDropdownApi()),
// 	getStateDropdownReset: () => dispatch(getStateDropdownReset()),
// 	getAnalysis: (params) => dispatch(getAnalysisApi(params)),
// 	getAnalysisReset: () => dispatch(getAnalysisReset()),
// 	getAppealLetter: (params) => dispatch(getAppealLetterApi(params)),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Policy);

import '../../assets/fonts/clash-display/WEB/css/clash-display.css';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { XAffix, XContainer } from '../../styles/GlobalStyle';
import { Row, Col, Space, Popover, Card, Tooltip, Image } from 'antd';
import Header from '../../components/Header/Header';
import HeaderFile from '../../components/Header/HeaderFile';
import {
	AipaMobileBody,
	AddInputIcon,
	ParaDeocdex,
	XDecodexTitle,
	XHeader,
	XLottieAipa,
	XLottieAipaLoader,
	XSelectIcons,
	XTitleHeader,
	RecordCard,
	XActionBtns,
	XActionPlay,
	XShazaamBtn,
	AipaDesktopBody,
	XRadio,
	XDivider,
	XCardDesktop,
	XInputTextArea,
	XUpload,
	XAvatar,
	PlaceholderText,
	UploadContainer,
	LargeIcon,
	UploadPolicyContainer,
	SmallIcon,
	XCardDesktop2,
} from './PolicyStyle.js';
import LoaderJson from '../../json/reclaim-new-hero.json';
import {
	AudioOutlined,
	UserSwitchOutlined,
	EnvironmentOutlined,
	CheckOutlined,
	CloseOutlined,
	PauseOutlined,
	InboxOutlined,
	FilePdfOutlined,
	EyeOutlined,
	DeleteOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import {
	Button,
	Input,
	notification,
	Affix,
	List,
	Checkbox,
	Radio,
	Modal,
} from 'antd';
import { XLottie } from '../Login/LoginStyle';
import LoginAnimation from '../../json/Login.json';
import Thanks from '../../json/thanksNew.json';
import {
	XForm,
	XModal,
	XSelect,
	XRoundButton,
	XModalTitle,
	XModalSubtitle,
	XList,
	XCircle,
	XLabel,
} from '../../styles/GlobalStyle';
import {
	XLottieMargin,
	Title,
	XPara1,
	XButtonCloseBar,
	XButtonReload,
	XNotificationBar,
	XButtonConfirm,
	XCheckboxGroup,
	XuploadAddCase,
	XLottieOverlay,
} from '../AnalyseCoverage/AnalyseCoverageStyle';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
// Actions
import {
	analyseCoverageApi,
	analyseCoverageReset,
} from '../../actions/analyseCoverageAction';
import {
	reviseCoverageApi,
	reviseCoverageReset,
} from '../../actions/reviseCoverageAction';
import {
	sendFeedbackApi,
	sendFeedbackReset,
} from '../../actions/sendFeedbackAction';
import {
	getStateDropdownApi,
	getStateDropdownReset,
} from '../../actions/getStateDropdownAction';
import { filterByLabel, handleInputChange } from '../../utils/formFunctions';
import {
	getAnalysisReset,
	getAnalysisApi,
} from '../../actions/getAnalysisAction';
import AnalysisLoader from '../../components/AnalysisLoader/AnalysisLoader';
import { checkModulePermissions, getUserToken } from '../../utils/helper';
import CaseOperations from '../../components/HorizontalTimeline/CaseOperations';
import config from '../../config';
import {
	getAppealLetterApi,
	getAppealLetterReset,
} from '../../actions/getAppealLetterAction';
import AppealLoading from '../../json/reloading.json';
import AppealLetter from '../../components/HorizontalTimeline/AppealLetter';
import { AipaAudioBtn } from '../Aipa/AipaStyle';
import WaveformVisualizer from '../../components/HorizontalTimeline/RecorderWave';
import axios from 'axios';
const { TextArea } = Input;
const { Dragger } = XUpload;

const Policy = (props) => {
	const {
		analyseCoverage,
		analyseCoverageReset,
		analyseCoverageState,
		reviseCoverage,
		reviseCoverageReset,
		reviseCoverageState,
		sendFeedback,
		sendFeedbackReset,
		sendFeedbackState,
		getStateDropdownState,
		getStateDropdown,
		getAnalysis,
		getAnalysisState,
		getAnalysisReset,
		getAppealLetter,
		getAppealLetterState,
	} = props;
	const missingFieldsCardRef = useRef(null);
	const [form] = XForm.useForm();
	const [formUpload] = XForm.useForm();
	const [formSupportingUpload] = XForm.useForm();
	const [clinicalDocsForm] = XForm.useForm();
	const [showClinicalTextArea, setShowClinicalTextArea] = useState(false);
	const [isClinicalUploadModalOpen, setIsClinicalUploadModalOpen] =
		useState(false);
	const [clinicalDocsLoading, setClinicalDocsLoading] = useState(false);
	const [clinicalDocumentFileList, setClinicalDocumentFileList] = useState(
		[],
	);
	const [submitClinicalDocs, setSubmitClinicalDocs] = useState(false); // Initial value can be 'textarea' or 'upload'
	const [formData, setFormData] = useState();
	const [clinicalSummaryTextLoading, setClinicalSummaryTextLoading] =
		useState(false);
	const [supportingDocsSummary, setSupportingDocsSummary] = useState('');
	const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const [previewTitle, setPreviewTitle] = useState('Upload Acknowledgement');
	const [newOptionForm] = XForm.useForm();
	const [summaryForm] = XForm.useForm();
	const [loading, setLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState([false, '', '', '', '']);
	const [thanksModal, setThanksModal] = useState([false, '']);
	const [summary, setSummary] = useState('');
	const [state, setState] = useState('');
	const [provider, setProvider] = useState('');
	const [modalTextArea, setModalTextArea] = useState('');
	const [feedbackId, setFeedbackId] = useState(null);
	const [pageContent, setPageContent] = useState({
		pastHistory: [],
		presentComplaint: [],
		recommendedProcedure: [],
		medicalCoverage: [],
		status: '',
		missing_points_for_coverage: [],
	});
	const [insuranceCompanies, setInsuranceCompanies] = useState([]);
	const [callGetAnalysisAPiBtn, setCallGetAnalysisAPiBtn] = useState(true);
	const [missingFieldsModal, setMissingFieldsModal] = useState(false);
	const [missingFields, setMissingFields] = useState([]);
	const [missingFieldInstructions, setMissingFieldInstructions] = useState(
		[],
	);
	const [missingFieldTitle, setMissingFieldTitle] = useState('');
	const [missingFieldsForm] = XForm.useForm();
	const [lastAnalysisData, setLastAnalysisData] = useState({});
	const [showAnalysisData, setShowAnalysisData] = useState(false);
	const [analysisCompleted, setAnalysisCompleted] = useState('');
	const [newOptionText, setNewOptionText] = useState('');
	const [newCheckBoxModal, setNewCheckBoxModal] = useState([false, null]);
	const [id, setId] = useState('');
	const [coverageloading, setCoverageLoading] = useState(false);
	const [LooksGoodLoading, setLooksGoodLoading] = useState(false);
	const [isMissingFieldsThere, setIsMissingFieldsThere] = useState(false);
	const [
		updatedClinicalDocumentFileList,
		setUpdatedClinicalDocumentFileList,
	] = useState([]);
	const [previewOpen, setPreviewOpen] = useState(false);
	const [clinicalDocumentsToDisplay, setClinicalDocumentsToDisplay] =
		useState([]);
	const [clinicalSelectedOption, setClinicalSelectedOption] =
		useState('clinical_text'); // Initial value can be 'textarea' or 'upload'
	const [policyFiles, setPolicyFiles] = useState([]);
	const [isClinicalDocUploadFromCommon, setIsClinicalDocUploadFromCommon] =
		useState(false);
	const [isUploadClinicalDoc, setIsUploadClinicalDoc] = useState(false);
	const [appealLetterType, setAppealLetterType] = useState('');
	const [appealLetterContent, setAppealLetterContent] = useState('');
	const [showAppealLetterPreview, setShowAppealLetterPreview] =
		useState(false);
	const [appealLetterLoading, setAppealLetterLoading] = useState(false);
	const [uploadedPolicyDoc, setUploadedPolicyDoc] = useState([]);
	const [policyDoc, setPolicyDoc] = useState([]);
	const [isPolicyUploadModalOpen, setIsPolicyUploadModalOpen] =
		useState(false);
	const [uploadedSupporingDoc, setUploadedSupporingDoc] = useState([]);
	const [supportingFiles, setSupportingFiles] = useState([]);
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
	const [supportingDoc, setSupportingDoc] = useState([]);
	const [documentName, setDocumentName] = useState('');
	const location = useLocation();
	const isCheckCoveragePage = location.pathname === '/check-coverage';

	const [policyType, setPolicyType] = useState('medicare');

	useEffect(() => {
		if (modalOpen[0] && modalOpen[4] !== 'feedbackSummary') {
			// Check if modal is open and it's not the feedbackSummary modal
			const [description, code] = modalTextArea.split(' - ');
			form.setFieldsValue({
				modal_text_area: description || '', // Prepopulate description
				code: code ? code.split(': ')[1] : '', // Extract and prepopulate code
			});
		}
	}, [modalOpen, modalTextArea, form]);

	// useEffect(() => {
	// 	if (
	// 		getStateDropdownState.apiState === 'success' &&
	// 		getStateDropdownState.data &&
	// 		getStateDropdownState.data.all_states?.length > 0
	// 	) {
	// 		// Check if the field is already populated
	// 		const currentState = summaryForm.getFieldValue('state');
	// 		if (!currentState) {
	// 			const preselectedState = 'Washington';
	// 			summaryForm.setFieldValue('state', preselectedState);

	// 			// Set insurance companies for the preselected state
	// 			const selectedState =
	// 				getStateDropdownState.data.all_states.find(
	// 					(itm) => itm.state === preselectedState,
	// 				);

	// 			if (selectedState) {
	// 				setState(preselectedState);
	// 				setInsuranceCompanies(selectedState.providers);
	// 			}
	// 		}
	// 	}
	// }, [getStateDropdownState]);

	useEffect(() => {
		if (getAppealLetterState.apiState === 'success') {
			setAppealLetterLoading(false);
			console.log('getAppealLetterState inpl');
			// getAnalysis({ id: id });
			setAppealLetterContent(getAppealLetterState.data.appeal_letter);
			setShowAppealLetterPreview(true);
			getAnalysis({ feedback_id: feedbackId });
			// navigate(`/case/filings/preview/${id}`, {
			// 	state: {
			// 		appealLetterType: appealLetterType,
			// 		// id: id,
			// 		patientDetails: patientData,
			// 		content: getAppealLetterState.data.appeal_letter,
			// 		header: getAppealLetterState.data.header,
			// 		footer: getAppealLetterState.data.footer,
			// 		isEdit: false,
			// 	},
			// });
		}
		if (getAppealLetterState.apiState === 'error') {
			setAppealLetterLoading(false);
			setShowAppealLetterPreview(false);
			notification.error({
				message: getAppealLetterState.message
					? getAppealLetterState.message
					: 'Unable to get Appeal Letter content',
			});
		}
		getAppealLetterReset();
	}, [getAppealLetterState.apiState]);

	const previewAppealLetter = (
		type,
		regenerate = false,
		forceGenerate = false,
	) => {
		if (forceGenerate) {
			const payload = {
				analysis_id: id,
				aipa: true,
				feedbackId: feedbackId,
				checkCoverage: true,
				policy: true,
				policy_type: policyType,
				force_generate: true,
			};
			getAppealLetter(payload);
		} else {
			getAppealLetter({
				id: id,
				type: type,
				appeal_letter_generated: regenerate,
				aipa: true,
				provider: provider,
				clinical_summary: summary,
				state: state,
				feedbackId: feedbackId,
				checkCoverage: true,
			});
			setAppealLetterType(type);
		}
	};

	const handleUploadCancel = () => {
		setIsPolicyUploadModalOpen(false);
		setIsUploadModalOpen(false);
		setIsClinicalUploadModalOpen(false);
		// setClinicalDocumentFileList([]);
		setUpdatedClinicalDocumentFileList([]);
		setClinicalDocsLoading(false);
		setSubmitClinicalDocs(false);
		setFormData({
			...formData,
			clinical_summary: '',
		});

		clinicalDocsForm.setFieldsValue({ clinical_docs: '' });
	};

	const handlePreviewClinicalSummary = async (file) => {
		// Check if the file is a PDF
		if (file && file.name && file.name.split('.').includes('pdf')) {
			// If there's a URL, use it directly; otherwise, generate a preview from the file
			const fileUrl = file.url ? `${config.s3_url}${file.url}` : null;
			setPreviewImage(fileUrl || file.preview);
			setPdfPreviewOpen(true); // Open PDF preview modal
		} else {
			// For non-PDF files, generate a preview if necessary
			if (!file.url && !file.preview && file.originFileObj) {
				file.preview = await getBase64(file.originFileObj);
			}
			setPreviewImage(file.url || file.preview);
			setPreviewOpen(true); // Open image preview modal
			setPreviewTitle(
				file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
			);
		}
	};

	const handleClinicalDocumentChange = ({ fileList: newFileList }) => {
		const updatedFileList = newFileList.map((file) => {
			if (file.status === 'error') {
				return { ...file, status: 'done' };
			}
			return file;
		});
		setClinicalDocumentFileList(updatedFileList);
		setUpdatedClinicalDocumentFileList(updatedFileList);
		// setClinicalDocumentsToDisplay(updatedFileList);
		summaryForm.setFieldsValue({
			clinical_summary: '',
		});
		setFormData({
			...formData,
			clinical_summary: '',
		});
		setSummary('');
		summaryForm.validateFields(['clinical_document']);
	};

	const handleRemoveClinicalDocuments = async (file) => {
		Modal.confirm({
			title: 'Confirm Deletion',
			content: 'Are you sure you want to delete this file?',
			okText: 'Yes',
			cancelText: 'No',
			cancelButtonProps: {
				// Add an icon to the Cancel button
				style: {
					backgroundColor: '#fff', // Red background for Cancel
					color: '#007299', // Text color
				},
			},
			okButtonProps: {
				style: {
					backgroundColor: '#007299',
					borderColor: '#007299',
					color: '#fff',
				},
			},
			onOk: async () => {
				const updatedFileList = clinicalDocumentFileList.filter(
					(item) => item.uid !== file.uid,
				);
				const updatedFileListToDisplay =
					clinicalDocumentsToDisplay.filter(
						(item) => item.uid !== file.uid,
					);
				setClinicalDocumentFileList(updatedFileList);
				setUpdatedClinicalDocumentFileList(updatedFileList);
				setClinicalDocumentsToDisplay(updatedFileListToDisplay);
				form.validateFields(['clinical_document']);
				if (!file.originFileObj) {
					// setDeletedClinicalDocuments((prevFiles) => [
					// 	...prevFiles,
					// 	file,
					// ]);
				}
			},
			onCancel: () => {
				form.validateFields(['clinical_document']);
				console.log('Deletion cancelled');
			},
		});
	};

	// Function to generate an ID with specified format
	// const generateCustomId = () => {
	// 	const randomPart = generateRandomAlphaNumeric(9); // First 9 characters alphanumeric
	// 	const timestampPart = moment().format('YYDDMMHHMMSSMS'); // Last 15 characters formatted as YYDDMMHHMMSSMS
	// 	const customId = randomPart + timestampPart;

	// 	return customId;
	// };

	// Generate a random 24-character hexadecimal ID
	const generateCustomId = () => {
		const characters = '0123456789abcdef';
		let result = '';
		for (let i = 0; i < 24; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * characters.length),
			);
		}
		return result;
	};

	const updatePageContent = (title, id, content) => {
		if (id === null) {
			if (title === 'originalQuery') {
				setPageContent({
					...pageContent,
					originalQuery: content,
				});
			} else {
				setPageContent({
					...pageContent,
					[title]: [...pageContent[[title]], content],
				});
			}
		} else {
			const updatedPageContent = { ...pageContent };
			updatedPageContent[[title]][id] = content;
			setPageContent(updatedPageContent);
		}
		// setSummary(formData.clinical_summary);
		setClinicalSummaryTextLoading(false);
		setShowClinicalTextArea(false);
		setModalOpen([false, '', ' ']);
		setModalTextArea('');
		form.resetFields();
		document.getElementById('notification').classList.remove('d-none');
	};

	const handleAddOption = (index) => {
		setNewCheckBoxModal([true, index]);
		setNewOptionText('');
		newOptionForm.setFieldsValue({});
		newOptionForm.resetFields();
	};

	const handleNewCheckBoxModal = () => {
		// Create a new option object
		newOptionForm
			.validateFields()
			.then(() => {
				const newOption = {
					label: newOptionText,
					value: newOptionText,
				};

				if (
					newCheckBoxModal[1] !== null &&
					newCheckBoxModal[1] >= 0 &&
					newCheckBoxModal[1] < missingFields.length
				) {
					const updatedFields = [...missingFields];
					updatedFields[newCheckBoxModal[1]].options.push(newOption);
					setMissingFields(updatedFields);

					// Get the current form values
					const currentValues = missingFieldsForm.getFieldsValue();

					// Add the new option to the appropriate field and mark it as checked
					if (
						!currentValues[updatedFields[newCheckBoxModal[1]].name]
					) {
						currentValues[updatedFields[newCheckBoxModal[1]].name] =
							[];
					}
					currentValues[updatedFields[newCheckBoxModal[1]].name].push(
						newOptionText,
					);

					// Update the form with the new values
					missingFieldsForm.setFieldsValue(currentValues);
				}

				// Close the modal and reset the newOptionText state
				setNewCheckBoxModal([false, null]);
				setNewOptionText('');
				newOptionForm.setFieldsValue({});
				newOptionForm.resetFields();
			})
			.catch((info) => {
				console.log('Validate Failed:', info);
			});
	};

	const handleClinicalSummaryDoc = async () => {
		if (policyType === 'medicare') {
			let uploadPromises = [];
			let uploadedClinicalDocs = [];

			// Handle Clinical Documents Upload
			if (clinicalDocumentFileList.length > 0) {
				const clinicalDocPromises = clinicalDocumentFileList.map(
					async (fileData) => {
						let get_ext = fileData.originFileObj.name.split('.');
						let ext = get_ext[get_ext.length - 1];
						const res = await axios.get(
							`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=reclaim&file_name=${fileData.originFileObj.uid}&ext=${ext}&id=${id}`,
							{
								headers: {
									'Content-type':
										'application/json; charset=UTF-8',
									Authorization: 'Bearer ' + getUserToken(),
								},
							},
						);

						const result = await axios.put(
							res.data.data,
							fileData.originFileObj,
						);
						if (result.status === 200) {
							const documentPath = `/reclaim/${id}/${fileData.originFileObj.uid}.${ext}`;
							uploadedClinicalDocs.push({
								uid: fileData.originFileObj.uid,
								name: fileData.originFileObj.name,
								status: 'done',
								url: documentPath,
							});
						}
					},
				);
				uploadPromises = uploadPromises.concat(clinicalDocPromises);
			}
			// Wait for all uploads to complete
			await Promise.all(uploadPromises);

			// Update state with uploaded files
			setUpdatedClinicalDocumentFileList(uploadedClinicalDocs);

			// Make the API call with updated file information
			const payload = {
				analysis_id: id,
				state: state,
				provider: provider,
				isExtractedTextReturn: true,
				clinical_docs: uploadedClinicalDocs,
			};

			setIsUploadClinicalDoc(true);
			setIsClinicalDocUploadFromCommon(true);
			analyseCoverage(payload);
			setShowAppealLetterPreview(false);
		} else {
			const formData = new FormData();

			// // Append supportingDocs files
			// policyFiles.forEach((file) => {
			// 	formData.append('supporting_docs', file.url?.originFileObj); // 'supportingDocs' will be the backend key
			// });

			// Append clinicalDocs files
			clinicalDocumentFileList.forEach((file) => {
				formData.append('clinical_docs', file.originFileObj); // 'clinicalDocs' will be the backend key
			});
			formData.append('supportingDocsSummary', supportingDocsSummary);
			// formData.append('state', state);
			// formData.append('provider', provider);
			// formData.append('analysis_id', id);
			// Log to check FormData contents
			// setLoading(true);
			setIsClinicalDocUploadFromCommon(true);
			analyseCoverage(formData);
		}
	};

	const getBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const handleClinicalDocsChange = ({ fileList: newFileList }) => {
		const updatedFileList = newFileList.map((file) => {
			if (file.status === 'error') {
				return { ...file, status: 'done' };
			}
			// file.preview = await getBase64(file.originFileObj);
			return file;
		});
		setClinicalDocumentFileList(updatedFileList);
		setUpdatedClinicalDocumentFileList(updatedFileList);
		clinicalDocsForm.validateFields(['clinical_docs']);
	};

	// Function to handle form submission
	const handleShazamClick = async () => {
		try {
			setLoading(true);
			const newId = generateCustomId();
			setId(newId);

			let uploadPromises = [];
			let uploadedClinicalDocs = [];
			let uploadedPolicyDocs = [];
			let uploadedSupportingDocs = [];

			// Handle Clinical Documents Upload
			if (clinicalDocumentFileList.length > 0) {
				const clinicalDocPromises = clinicalDocumentFileList.map(
					async (fileData) => {
						let get_ext = fileData.originFileObj.name.split('.');
						let ext = get_ext[get_ext.length - 1];
						const res = await axios.get(
							`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=reclaim&file_name=${fileData.originFileObj.uid}&ext=${ext}&id=${newId}`,
							{
								headers: {
									'Content-type':
										'application/json; charset=UTF-8',
									Authorization: 'Bearer ' + getUserToken(),
								},
							},
						);

						const result = await axios.put(
							res.data.data,
							fileData.originFileObj,
						);
						if (result.status === 200) {
							const documentPath = `/reclaim/${newId}/${fileData.originFileObj.uid}.${ext}`;
							uploadedClinicalDocs.push({
								uid: fileData.originFileObj.uid,
								name: fileData.originFileObj.name,
								status: 'done',
								url: documentPath,
							});
						}
					},
				);
				uploadPromises = uploadPromises.concat(clinicalDocPromises);
			}

			if (policyType === 'commercial') {
				// Handle Policy Files Upload
				if (policyFiles.length > 0) {
					const policyDocPromises = policyFiles.map(
						async (fileData) => {
							let get_ext =
								fileData.url.originFileObj.name.split('.');
							let ext = get_ext[get_ext.length - 1];
							const res = await axios.get(
								`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=reclaim&file_name=${fileData.url.originFileObj.uid}&ext=${ext}&id=${newId}`,
								{
									headers: {
										'Content-type':
											'application/json; charset=UTF-8',
										Authorization:
											'Bearer ' + getUserToken(),
									},
								},
							);

							const result = await axios.put(
								res.data.data,
								fileData.url.originFileObj,
							);
							if (result.status === 200) {
								const documentPath = `/reclaim/${newId}/${fileData.url.originFileObj.uid}.${ext}`;
								uploadedPolicyDocs.push({
									uid: fileData.url.originFileObj.uid,
									name: fileData.url.originFileObj.name,
									status: 'done',
									url: documentPath,
								});
							}
						},
					);
					uploadPromises = uploadPromises.concat(policyDocPromises);
				}

				// Handle Supporting Files Upload
				if (supportingFiles.length > 0) {
					const supportingDocPromises = supportingFiles.map(
						async (fileData) => {
							let get_ext =
								fileData.url.originFileObj.name.split('.');
							let ext = get_ext[get_ext.length - 1];
							const res = await axios.get(
								`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=reclaim&file_name=${fileData.url.originFileObj.uid}&ext=${ext}&id=${newId}`,
								{
									headers: {
										'Content-type':
											'application/json; charset=UTF-8',
										Authorization:
											'Bearer ' + getUserToken(),
									},
								},
							);

							const result = await axios.put(
								res.data.data,
								fileData.url.originFileObj,
							);
							if (result.status === 200) {
								const documentPath = `/reclaim/${newId}/${fileData.url.originFileObj.uid}.${ext}`;
								uploadedSupportingDocs.push({
									uid: fileData.url.originFileObj.uid,
									name: fileData.url.originFileObj.name,
									status: 'done',
									url: documentPath,
								});
							}
						},
					);
					uploadPromises = uploadPromises.concat(
						supportingDocPromises,
					);
				}
			}

			// Wait for all uploads to complete
			await Promise.all(uploadPromises);

			// Update state with uploaded files
			setUpdatedClinicalDocumentFileList(uploadedClinicalDocs);
			setPolicyDoc(uploadedPolicyDocs);
			setSupportingDoc(uploadedSupportingDocs);

			// Make the API call with updated file information
			const payload = {
				analysis_id: newId,
				clinicalSummary: summary,
				state: state,
				provider: provider,
				aipa: true,
				checkCoverage: true,
				policy: true,
				policy_type: policyType,
				clinical_docs: uploadedClinicalDocs,
				...(policyType === 'commercial' && {
					policy_document: uploadedPolicyDocs,
					supporting_docs: uploadedSupportingDocs,
				}),
			};

			setIsUploadClinicalDoc(true);
			setIsClinicalDocUploadFromCommon(true);
			analyseCoverage(payload);
			setShowAppealLetterPreview(false);
		} catch (error) {
			setLoading(false);
			notification.error({
				message: 'Upload Error',
				description:
					'An error occurred while uploading files. Please try again.',
			});
			console.error('Error in handleShazamClick:', error);
		}
	};

	const forceGenerateAppealLetter = async () => {
		// Make the API call with updated file information
		const payload = {
			analysis_id: id,
			aipa: true,
			checkCoverage: true,
			policy: true,
			policy_type: policyType,
			force_generate: true,
		};
		analyseCoverage(payload);
		console.log('forceGenerateAppealLetter');
	};

	useEffect(() => {
		if (showAnalysisData) {
			document.getElementById('getQust').classList.add('bottom-100');
			document.getElementById('getAns').classList.add('top-0');
			document.getElementById('getAns').classList.remove('top-100');
			document.getElementById('getAns')?.classList.remove('d-none');
		}
	}, [showAnalysisData]);

	useEffect(() => {
		if (missingFieldsModal && missingFieldsCardRef.current) {
			// ADD
			document.getElementById('getQust').classList.add('bottom-100');
			document.getElementById('getAns').classList.add('top-0');
			document.getElementById('getAns').classList.remove('top-100');
			document.getElementById('getAns')?.classList.remove('d-none');
			// ADD
			missingFieldsCardRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [missingFieldsModal]);

	useEffect(() => {
		if (analyseCoverageState.apiState === 'success') {
			if (analyseCoverageState.data.isClinicalText) {
				setSummary(analyseCoverageState.data?.clinical_summary);
				setIsClinicalUploadModalOpen(false);
				setClinicalDocsLoading(false);
				setLoading(true);
				const payload = {
					analysis_id: id,
					state: state,
					provider: provider,
					clinicalSummary:
						analyseCoverageState.data?.clinical_summary,
					supportingDocsSummary: supportingDocsSummary,
				};
				analyseCoverage(payload);
			} else {
				setClinicalSummaryTextLoading(false);
				setClinicalDocumentsToDisplay(clinicalDocumentFileList);
				handleUploadCancel();
				setClinicalDocsLoading(false);
				setAppealLetterLoading(false);
				setShowClinicalTextArea(false);
				setFeedbackId(analyseCoverageState.data.feedback_id);
				setSupportingDocsSummary(
					analyseCoverageState.data?.supportingDocsSummary,
				);
				setLastAnalysisData(analyseCoverageState.data);
				if (isClinicalDocUploadFromCommon) {
					setSummary(
						analyseCoverageState.data['original_query'] ?? '',
					);
				}
				setPageContent({
					...pageContent,
					originalQuery:
						analyseCoverageState.data['original_query'] ?? '',
					pastHistory:
						analyseCoverageState.data['Past History'] ?? [],
					presentComplaint:
						analyseCoverageState.data['Present Complaint'] ?? [],
					recommendedProcedure:
						analyseCoverageState.data['Suggested Treatment'] ?? [],
					medicalCoverage: [
						analyseCoverageState.data['Medicare Evaluation']
							? analyseCoverageState.data['Medicare Evaluation'][
									'Coverage'
							  ]
							: 'No',
						analyseCoverageState.data['Medicare Evaluation']
							? analyseCoverageState.data['Medicare Evaluation'][
									'Rationale'
							  ]
							: '',
						analyseCoverageState.data['Medicare Evaluation']
							? analyseCoverageState.data['Medicare Evaluation'][
									'reference'
							  ]
							: null,
					],
					missing_points_for_coverage:
						analyseCoverageState.data.missing_points_for_coverage ??
						[],
				});

				setAnalysisCompleted('success');
				analyseCoverageReset();
				// setLoading(false);
				setIsUploadClinicalDoc(false);
				setIsClinicalDocUploadFromCommon(false);

				if (
					analyseCoverageState.data.hasOwnProperty(
						'is_fields_missing',
					) &&
					analyseCoverageState.data.is_fields_missing &&
					analyseCoverageState.data.hasOwnProperty('missing_fields')
				) {
					if (analyseCoverageState.data.missing_fields.length > 0) {
						setMissingFields(
							analyseCoverageState.data.missing_fields ?? [],
						);
						setMissingFieldInstructions(
							analyseCoverageState.data
								.missing_field_instructions ?? [],
						);
						setMissingFieldTitle(
							analyseCoverageState.data.missing_field_title ?? '',
						);
						setMissingFieldsModal(true);
						setIsMissingFieldsThere(true);
					}
				} else {
					setShowAnalysisData(true);
					setIsMissingFieldsThere(false);
				}
			}
		}

		if (analyseCoverageState.apiState === 'error') {
			// setLoading(false);
			setAppealLetterLoading(false);
			setIsClinicalDocUploadFromCommon(false);
			// setShowClinicalTextArea(false);
			handleUploadCancel();
			setAnalysisCompleted('error');
			setIsMissingFieldsThere(false);
			notification.error({
				message:
					'Hey, we are facing high traffic. Please check back in few minutes.',
			});
			analyseCoverageReset();
		}
	}, [analyseCoverageState]);

	useEffect(() => {
		if (getAnalysisState.apiState === 'success') {
			setFeedbackId(getAnalysisState.data.coverage_feedback_response._id);
			setLastAnalysisData(
				getAnalysisState.data?.coverage_feedback_response,
			);
			setAppealLetterLoading(false);
			setPageContent({
				...pageContent,
				originalQuery: summary ?? '',
				pastHistory:
					getAnalysisState.data.coverage_feedback_response[
						'Past History'
					] ?? [],
				presentComplaint:
					getAnalysisState.data.coverage_feedback_response[
						'Present Complaint'
					] ?? [],
				recommendedProcedure:
					getAnalysisState.data.coverage_feedback_response[
						'Suggested Treatment'
					] ?? [],
				medicalCoverage: [
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['Coverage']
						: 'No',
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['Rationale']
						: '',
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['reference']
						: null,
				],
				missing_points_for_coverage:
					getAnalysisState.data.coverage_feedback_response
						.missing_points_for_coverage ?? [],
				status: getAnalysisState.data.coverage_feedback_response.status,
			});
			setLoading(false);
			setCoverageLoading(false);
			setLooksGoodLoading(false);
			setModalOpen([false, '', '']);
			document.getElementById('getQust').classList.add('bottom-100');
			document.getElementById('getAns').classList.add('top-0');
			document.getElementById('getAns').classList.remove('top-100');
			document.getElementById('getAns')?.classList.remove('d-none');
		}

		if (getAnalysisState.apiState === 'error') {
			setLoading(false);
			setAppealLetterLoading(false);
			notification.error({
				message:
					'Hey, we are facing high traffic. Please check back in few minutes.',
			});
		}
		getAnalysisReset();
	}, [getAnalysisState]);

	useEffect(() => {
		if (reviseCoverageState.apiState === 'success') {
			setFeedbackId(reviseCoverageState.data.feedback_id);
			setLastAnalysisData(reviseCoverageState.data);
			setPageContent({
				...pageContent,
				originalQuery: reviseCoverageState.data['original_query'] ?? '',
				pastHistory: reviseCoverageState.data['Past History'] ?? [],
				presentComplaint:
					reviseCoverageState.data['Present Complaint'] ?? [],
				recommendedProcedure:
					reviseCoverageState.data['Suggested Treatment'] ?? [],
				medicalCoverage: [
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'Coverage'
						  ]
						: 'No',
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'Rationale'
						  ]
						: '',
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'reference'
						  ]
						: null,
				],
				missing_points_for_coverage:
					reviseCoverageState.data.missing_points_for_coverage ?? [],
				status: reviseCoverageState.data.status,
			});
			setAnalysisCompleted('success');
			if (
				reviseCoverageState.data.hasOwnProperty('is_fields_missing') &&
				reviseCoverageState.data.is_fields_missing &&
				reviseCoverageState.data.hasOwnProperty('missing_fields')
			) {
				if (reviseCoverageState.data.missing_fields.length > 0) {
					setMissingFields(
						reviseCoverageState.data.missing_fields ?? [],
					);
					setMissingFieldInstructions(
						reviseCoverageState.data.missing_field_instructions ??
							[],
					);
					setMissingFieldTitle(
						reviseCoverageState.data.missing_field_title ?? '',
					);
					setMissingFieldsModal(true);
					setIsMissingFieldsThere(true);
				}
			} else {
				setShowAnalysisData(true);
				setIsMissingFieldsThere(false);
			}
			reviseCoverageReset();
		}
		if (reviseCoverageState.apiState === 'error') {
			setAnalysisCompleted('error');
			reviseCoverageReset();
			notification.error({
				message:
					'Hey, we are facing high traffic. Please check back in few minutes.',
			});
			document.getElementById('notification')?.classList.remove('d-none');
			document.getElementById('getAns')?.classList.remove('d-none');
		}
	}, [reviseCoverageState]);

	useEffect(() => {
		if (sendFeedbackState.apiState === 'success') {
			sendFeedbackReset();
			setAppealLetterLoading(false);
			if (sendFeedbackState.data === 'negative')
				setThanksModal([true, 'Thanks for making us Better!']);
			else
				setThanksModal([true, 'Case has been analysed and confirmed!']);
			if (callGetAnalysisAPiBtn) {
				getAnalysis({ feedback_id: feedbackId });
				setCallGetAnalysisAPiBtn(true);
			} else {
				setCoverageLoading(false);
				setLooksGoodLoading(false);
				setModalOpen([false, '', '']);
			}
		} else if (sendFeedbackState.apiState === 'error') {
			sendFeedbackReset();
			setAppealLetterLoading(false);
			setCallGetAnalysisAPiBtn(true);
			setCoverageLoading(false);
			setLooksGoodLoading(false);
		}
	}, [sendFeedbackState]);

	useEffect(() => {
		document.getElementById('getQust').classList.remove('bottom-100');
		document.getElementById('getAns').classList.remove('top-0');
		document.getElementById('getAns').classList.add('top-100');
		document.getElementById('getAns')?.classList.add('d-none');
		getStateDropdown();
		summaryForm.setFieldsValue({
			clinical_category: 'clinical_text',
		});
	}, []);

	// Open the modal and set a timer to automatically close it after 3 seconds
	useEffect(() => {
		let timer;
		if (thanksModal) {
			timer = setTimeout(() => {
				setThanksModal([false, '']);
			}, 3000);
		}

		// Clear the timer if the component unmounts or showThanksModal changes
		return () => clearTimeout(timer);
	}, [thanksModal]);

	useEffect(() => {
		// Add the class to the body tag
		document.body.classList.add('reclaimBody');

		// Clean up to remove the class when the component unmounts
		return () => {
			document.body.classList.remove('reclaimBody');
		};
	}, []);
	//clinical column
	const clinicalComponent = () => {
		return (
			<>
				<XForm.Item
					className="addCaseLabel mb-0"
					name="clinical_category"
					rules={[
						{
							required: true,
							message: 'Please select option!',
						},
					]}
				>
					<XRadio.Group
						className="w-100"
						defaultValue={clinicalSelectedOption}
						onChange={(e) => {
							handleInputChange(e, formData, setFormData);
							if (e.target.value === 'clinical_text') {
								setClinicalSelectedOption('clinical_text');
							} else if (e.target.value === 'clinical_document') {
								setClinicalSelectedOption('clinical_document');
								// } else if (
								// 	e.target.value ===
								// 	'dictate_audio'
								// ) {
								// 	setClinicalSelectedOption(
								// 		'dictate_audio',
								// 	);
							}
						}}
					>
						<Row gutter={[16, 16]}>
							<Col xs={24} md={12}>
								<XRadio value={'clinical_text'}>
									Enter Clinical Summary
								</XRadio>
							</Col>
							<Col xs={24} md={12}>
								<XRadio value={'clinical_document'}>
									Upload Clinical Summary
								</XRadio>
							</Col>
						</Row>
						{/* <XRadio
						value={'dictate_audio'}
						>
						Dictate
						</XRadio> */}
					</XRadio.Group>
				</XForm.Item>
			</>
		);
	};
	//policy docs
	const uploadPolicyDocButton = (isDesktop) => {
		return isDesktop ? (
			<UploadPolicyContainer
				onClick={() => setIsPolicyUploadModalOpen(true)}
			>
				<PlaceholderText
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<span
						style={{
							color: 'red',
							fontSize: '24px',
							marginRight: '5px',
							marginTop: '8px',
						}}
					>
						*
					</span>
					Upload Insurance Policy Document
				</PlaceholderText>
				<SmallIcon />
			</UploadPolicyContainer>
		) : (
			<Button
				onClick={() => setIsPolicyUploadModalOpen(true)}
				className="m-0"
				style={{
					borderRadius: '6px',
					border: '1px dashed #d9d9d9',
					display: 'block',
					height: '100px',
					padding: '4px 15px',
					width: '100px',
					background: 'rgba(0, 0, 0, 0.02)',
				}}
			>
				<PlusOutlined style={{ color: '#000' }} />
				<div style={{ color: '#000' }}>Upload</div>
			</Button>
		);
	};
	// <Upload {...props}>

	/* <div style={{ width: '100%' }}>

		<PlaceholderText onClick={() => setIsPolicyUploadModalOpen(true)}>
			Click to Upload
			<UploadOutlined />
			</PlaceholderText>
</div> */

	/* /* <div style={{ width: '100%' }}>
		
		<Button  onClick={() => setIsPolicyUploadModalOpen(true)} style={{background:'rgba(0, 0, 0, 0.02)',
    color: "black",width:'100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'}} >Click to Upload <UploadOutlined /></Button>
	
		 </div> */

	const handlePolicyPreview = async (file) => {
		if (file && file.name && file.name.split('.').includes('pdf')) {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			setPreviewImage(file.url || file.preview);
			setPdfPreviewOpen(true);
		} else {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			setPreviewImage(file.url || file.preview);
			setPreviewOpen(true);
			setPreviewTitle(
				file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
			);
		}
	};

	const handleRemovePolicyFile = async (file) => {
		Modal.confirm({
			title: 'Confirm Deletion',
			content: 'Are you sure you want to delete this file?',
			okText: 'Yes',
			cancelText: 'No',
			cancelButtonProps: {
				// Add an icon to the Cancel button
				style: {
					backgroundColor: '#fff', // Red background for Cancel
					color: '#007299', // Text color
				},
			},
			okButtonProps: {
				style: {
					backgroundColor: '#007299',
					borderColor: '#007299',
					color: '#fff',
				},
			},
			onOk: async () => {
				const updatedFileList = policyFiles.filter(
					(item) => item.url.uid !== file.url.uid,
				);
				const UploadedFile = uploadedPolicyDoc.filter(
					(item) => item.url.uid !== file.url.uid,
				);
				// const updatedUploadedFile = updatedUploadedSupporingDoc.filter(
				// 	(item) => item.url.uid !== file.url.uid,
				// );
				setPolicyFiles(updatedFileList);
				// setUpdatedUploadedSupporingDoc(updatedUploadedFile);
				setUploadedPolicyDoc(UploadedFile);
				// if (!file.url.originFileObj) {
				// 	setDeletedUploadedSupportingDoc((prevFiles) => [
				// 		...prevFiles,
				// 		file,
				// 	]);
				// }
			},
			onCancel: () => {
				console.log('Deletion cancelled');
			},
		});
	};

	const handlePolicyDocSubmit = async () => {
		formUpload
			.validateFields()
			.then(async () => {
				if (policyDoc && policyDoc.length > 0) {
					let obj = {
						url: policyDoc[0],
					};
					setUploadedPolicyDoc((prevFiles) => [...prevFiles, obj]);
					obj.preview = await getBase64(policyDoc[0].originFileObj);
					setPolicyFiles((prevFiles) => [...prevFiles, obj]);
					setPolicyDoc([]);
					formUpload.resetFields();
					setIsPolicyUploadModalOpen(false);
				}
			})
			.catch((err) => {});
	};

	const handlePolicyDocChange = ({ fileList: newFileList }) => {
		const updatedFileList = newFileList.map((file) => {
			if (file.status === 'error') {
				return { ...file, status: 'done' };
			}
			return file;
		});
		setPolicyDoc(updatedFileList);
		// setPolicyFiles(updatedFileList);
		formUpload.validateFields(['policy_document']);
	};

	// Create a function to handle policy type change
	const handlePolicyTypeChange = (e) => {
		const newPolicyType = e.target.value;
		setPolicyType(newPolicyType);

		// Reset fields if changing from commercial
		if (newPolicyType === 'medicare') {
			// Reset policy files
			setPolicyFiles([]);

			// Reset supporting files
			setSupportingFiles([]);

			// Reset form fields related to commercial
			form.setFieldsValue({
				policy_document: undefined,
				supporting_docs: undefined,
			});

			// Reset other related states if needed
			setSupportingDoc([]);
			setUploadedSupporingDoc([]);
		}
	};

	//Supporting docs
	const handleSupportingDocChange = ({ fileList: newFileList }) => {
		const updatedFileList = newFileList.map((file) => {
			if (file.status === 'error') {
				return { ...file, status: 'done' };
			}
			return file;
		});
		setSupportingDoc(updatedFileList);
		formUpload.validateFields(['supporting_docs']);
	};

	const uploadSupportingDocButton = (length, isDesktop) => {
		if (
			(isDesktop && length > 0) ||
			(!isDesktop && length > 0) ||
			(!isDesktop && length <= 0)
		) {
			return (
				<Button
					onClick={() => setIsUploadModalOpen(true)}
					className="m-0"
					style={{
						borderRadius: '14px',
						border: '1px dashed #d9d9d9',
						display: isDesktop ? 'flex' : 'block',
						height: isDesktop ? '50px' : '100px',
						padding: '4px 15px',
						width: '100px',
						background: isDesktop
							? '#EFF6FE'
							: 'rgba(0, 0, 0, 0.02)',

						justifyContent: 'center',
						boxShadow:
							'0px 0px 15px rgba(0, 114, 153, 0.05) !important',
					}}
				>
					<PlusOutlined style={{ color: '#000' }} />
					<div style={{ color: '#000' }}>Upload</div>
				</Button>
			);
		} else {
			return (
				<UploadContainer onClick={() => setIsUploadModalOpen(true)}>
					<LargeIcon />
					<PlaceholderText>
						Upload Supporting Documents (Optional)
					</PlaceholderText>
				</UploadContainer>
			);
		}
	};

	// 	const uploadSupportingDocButton =(length)=> {
	// return length>0?<Button
	// onClick={() => setIsUploadModalOpen(true)}
	// className="m-0"
	// style={{
	// 	borderRadius: '6px',
	// 	border: '1px dashed #d9d9d9',
	// 	display: 'block',
	// 	height: '100px',
	// 	padding: '4px 15px',
	// 	width: '100px',
	// 	background: 'rgba(0, 0, 0, 0.02)',
	// }}
	// >
	// <PlusOutlined style={{ color: '#000' }} />
	// <div style={{ color: '#000' }}>Upload</div>
	// </Button>:
	// 		<UploadContainer onClick={() => setIsUploadModalOpen(true)}>
	// 			<LargeIcon />
	// 			<PlaceholderText>
	// 				Upload Supporting Documents (Optional)
	// 			</PlaceholderText>
	// 		</UploadContainer>
	// 		}
	// <div style={{ width: '100%' }}>
	// 	<Row style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
	// 		<UploadOutlined />
	// 	</Row>
	// 	<Row style={{fontColor:'black',opacity:'0.5'}}>
	// 		Upload Supporting Documents(Optional)
	// 	</Row>
	//       </div>
	/* <PlaceholderText onClick={() => setIsUploadModalOpen(true)}>
			Click to Upload
			<UploadOutlined />
			</PlaceholderText> */

	// <Button
	// 	onClick={() => setIsUploadModalOpen(true)}
	// 	className="m-0"
	// 	style={{
	// 		borderRadius: '6px',
	// 		border: '1px dashed #d9d9d9',
	// 		display: 'block',
	// 		height: '100px',
	// 		padding: '4px 15px',
	// 		width: '100px',
	// 		background: 'rgba(0, 0, 0, 0.02)',
	// 	}}
	// >
	// 	<PlusOutlined style={{ color: '#000' }} />
	// 	<div style={{ color: '#000' }}>Upload</div>
	// </Button>
	// );

	const handlePreview = async (file) => {
		if (file && file.name && file.name.split('.').includes('pdf')) {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			setPreviewImage(file.url || file.preview);
			setPdfPreviewOpen(true);
		} else {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			setPreviewImage(file.url || file.preview);
			setPreviewOpen(true);
			setPreviewTitle(
				file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
			);
		}
	};

	const handleSupportingDocSubmit = async () => {
		formSupportingUpload
			.validateFields()
			.then(async () => {
				if (supportingDoc && supportingDoc.length > 0 && documentName) {
					let obj = {
						url: supportingDoc[0],
						document_name: documentName,
					};
					setUploadedSupporingDoc((prevFiles) => [...prevFiles, obj]);
					obj.preview = await getBase64(
						supportingDoc[0].originFileObj,
					);
					setSupportingFiles((prevFiles) => [...prevFiles, obj]);
					setSupportingDoc([]);
					setDocumentName('');
					formSupportingUpload.resetFields();
					setIsUploadModalOpen(false);
				}
			})
			.catch((err) => {});
	};

	const handleRemoveFile = async (file) => {
		Modal.confirm({
			title: 'Confirm Deletion',
			content: 'Are you sure you want to delete this file?',
			okText: 'Yes',
			cancelText: 'No',
			cancelButtonProps: {
				// Add an icon to the Cancel button
				style: {
					backgroundColor: '#fff', // Red background for Cancel
					color: '#007299', // Text color
				},
			},
			okButtonProps: {
				style: {
					backgroundColor: '#007299',
					borderColor: '#007299',
					color: '#fff',
				},
			},
			onOk: async () => {
				const updatedFileList = supportingFiles.filter(
					(item) => item.url.uid !== file.url.uid,
				);
				const UploadedFile = uploadedSupporingDoc.filter(
					(item) => item.url.uid !== file.url.uid,
				);
				// const updatedUploadedFile = updatedUploadedSupporingDoc.filter(
				// 	(item) => item.url.uid !== file.url.uid,
				// );
				setSupportingFiles(updatedFileList);
				// setUpdatedUploadedSupporingDoc(updatedUploadedFile);
				setUploadedSupporingDoc(UploadedFile);
				// if (!file.url.originFileObj) {
				// 	setDeletedUploadedSupportingDoc((prevFiles) => [
				// 		...prevFiles,
				// 		file,
				// 	]);
				// }
			},
			onCancel: () => {
				console.log('Deletion cancelled');
			},
		});
	};

	return (
		<>
			{loading && (
				<AnalysisLoader
					analysisId={id}
					isOpenAnalysis={'true'}
					analysisCompleted={analysisCompleted}
					setAnalysisCompleted={setAnalysisCompleted}
					setLoading={setLoading}
					loading={loading}
					isMissingFieldsThere={isMissingFieldsThere}
					setIsMissingFieldsThere={setIsMissingFieldsThere}
				/>
			)}
			<>
				<Col lg={24} xl={24} xs={0} md={0}>
					<Header headerTag="Check Coverage" />
				</Col>
				<Col lg={0} xl={0} xs={24} md={24}>
					<HeaderFile activeNav="check-coverage" />
				</Col>
			</>

			<XContainer fluid>
				{/* <div className="marginTopMobile" id=""></div> */}
				<XAffix
					id="notification"
					className="d-none zIndex"
					offsetTop={10}
				>
					{/* <div className="marginBottomMobile"></div> */}
					<div
						className="mb-3"
						style={{
							backgroundColor: 'white',
							borderRadius: '20px',
							zIndex: '1111110',
						}}
					>
						<XNotificationBar style={{ zIndex: '22222' }}>
							Clinical Summary has been changed !! Click to{' '}
							<XButtonReload
								type="button"
								style={{ padding: '18px' }}
								className="refreshBtn"
								onClick={() => {
									setLoading(true);
									document
										.getElementById('notification')
										?.classList.add('d-none');

									reviseCoverageReset();
									// Update lastAnalysisData with the latest pageContent values
									const updatedLastAnalysisData = {
										...lastAnalysisData, // Keep all the existing keys and values intact
										'Past History': pageContent.pastHistory, // Update pastHistory
										'Present Complaint':
											pageContent.presentComplaint, // Update presentComplaint
										'Suggested Treatment':
											pageContent.recommendedProcedure, // Update recommendedProcedure
										'Medicare Evaluation': {
											...lastAnalysisData[
												'Medicare Evaluation'
											],
											Coverage:
												pageContent.medicalCoverage[0], // Update medicalCoverage fields
											Rationale:
												pageContent.medicalCoverage[1],
											reference:
												pageContent.medicalCoverage[2],
										},
										original_query:
											pageContent.originalQuery, // Update original_query
										past_history: pageContent.pastHistory, // Update past_history in lastAnalysisData
										present_complaint:
											pageContent.presentComplaint, // Update present_complaint in lastAnalysisData
										status: pageContent.status, // Update status in lastAnalysisData
										missing_points_for_coverage:
											pageContent.missing_points_for_coverage, // Update missing_points_for_coverage
										// Add other updates if necessary, or keep them unchanged
									};
									reviseCoverage({
										// case_id: patientData._id,
										analysis_id: id,
										feedbackId: feedbackId,
										clinicalHistory:
											pageContent.pastHistory,
										presentComplaint:
											pageContent.presentComplaint,
										recommendedProcedure:
											pageContent.recommendedProcedure,
										medicalCoverage:
											pageContent.medicalCoverage,
										state: state,
										provider: provider,
										last_analysis_data:
											updatedLastAnalysisData,
										supportingDocsSummary:
											supportingDocsSummary,
									});
								}}
							>
								Reanalyse it !!
							</XButtonReload>
							<XButtonCloseBar
								type="button"
								className="closeBar mt-3"
							>
								<CloseOutlined
									onClick={() => {
										document
											.getElementById('notification')
											?.classList.add('d-none');
									}}
									style={{ fontSize: '20px' }}
								/>
							</XButtonCloseBar>
						</XNotificationBar>
					</div>
					<div className="marginNone"></div>
				</XAffix>
			</XContainer>

			<div id="getQust" className="">
				{/* Start Desktop design */}
				<AipaDesktopBody>
					<XContainer fluid>
						<XForm
							form={summaryForm}
							name="loginForm"
							layout="vertical"
							autoComplete="off"
							onFinish={handleShazamClick}
						>
							<Row
								gutter={(15, 15)}
								className="align-items-center"
							>
								<Col xs={{ span: 3, offset: 0 }}>
									<XLottieAipaLoader>
										<XLottieAipa
											animationData={LoaderJson}
											loop={true}
										/>
									</XLottieAipaLoader>
								</Col>
								<Col xs={{ span: 19, offset: 1 }}>
									<XDecodexTitle className="text-start">
										<img
											className="img-fluid"
											src="/images/reclaim-login-logo.svg"
											alt=""
										/>
									</XDecodexTitle>
									<ParaDeocdex className="text-start mt-2">
										Reclaim provides you insights about your
										health insurance coverage.
									</ParaDeocdex>
								</Col>
							</Row>

							<Row className="mt-4" gutter={[16, 16]}>
								<Col xs={24} md={12}>
									<AddInputIcon>
										<EnvironmentOutlined />
									</AddInputIcon>
									<XForm.Item
										name="state"
										rules={[
											{
												required: true,
												message: 'Please select State',
											},
										]}
										className="mb-0"
									>
										<XSelectIcons
											size="large"
											placeholder="Select State"
											name="state"
											showSearch={true}
											// onSearch={(search) => {
											// 	if (
											// 		search.length >= 3
											// 	) {
											// 		getStateDropdown({
											// 			search,
											// 		});
											// 	} else if (
											// 		search.length === 0
											// 	) {
											// 		getStateDropdown();
											// 	}
											// }}
											filterOption={filterByLabel}
											options={
												getStateDropdownState.apiState ===
													'success' &&
												getStateDropdownState.data
													.all_states?.length > 0 &&
												getStateDropdownState.data.all_states.map(
													(itm) => {
														return {
															label: itm.state,
															value: itm.state,
															providers:
																itm.providers,
														};
													},
												)
											}
											onSelect={(value, options) => {
												setState(value);
												summaryForm.setFieldValue(
													'insurance_company',
													null,
												);
												setInsuranceCompanies(
													options.providers,
												);
											}}
										></XSelectIcons>
									</XForm.Item>
								</Col>
								<Col xs={24} md={12} className="">
									<AddInputIcon>
										<UserSwitchOutlined />
									</AddInputIcon>
									<XForm.Item
										name="insurance_company"
										className="mb-0"
										rules={[
											{
												required: true,
												message:
													'Please select provider',
											},
										]}
									>
										<XSelectIcons
											size="large"
											placeholder="Select Insurance Company"
											name="insurance_company"
											showSearch={true}
											filterOption={filterByLabel}
											options={
												insuranceCompanies.length > 0 &&
												insuranceCompanies.map(
													(itm) => {
														return {
															label: itm.name,
															value: itm.name,
														};
													},
												)
											}
											onSelect={(value) => {
												setProvider(value);
											}}
										></XSelectIcons>
									</XForm.Item>
								</Col>
							</Row>
							<Row className="mt-4" gutter={[16, 16]}>
								<Col xs={24} md={12}>
									<XForm.Item
										className="addCaseLabel mb-0"
										// name="policy_type"
										// label="what is your policy type?"
										rules={[
											{
												required: true,
												message:
													'Please select option!',
											},
										]}
									>
										<XRadio.Group
											className="w-100"
											defaultValue={policyType}
											onChange={handlePolicyTypeChange}
										>
											<Row gutter={[16, 16]}>
												<Col xs={24} md={12}>
													<XRadio value={'medicare'}>
														Medicare Policy
													</XRadio>
												</Col>
												<Col xs={24} md={12}>
													<XRadio
														value={'commercial'}
													>
														Commercial Policy
													</XRadio>
												</Col>
											</Row>
										</XRadio.Group>
									</XForm.Item>
								</Col>
								{policyType === 'commercial' ? (
									<Col xs={24} md={12}>
										<XCardDesktop
											className="bodyPadding bodyHeight h-auto "
											style={{
												width: `${
													policyFiles.length > 0
														? 'fit-content'
														: '100%'
												}`,
											}}
										>
											<XForm.Item
												className={`addCaseLabel mb-0 ${
													policyFiles.length > 0
														? 'p-2'
														: undefined
												} `}
												// label={policyFiles.length > 0 ? "Upload Insurance Policy Document" : undefined}
												// label="Upload Insurance Policy Document"
												name="policy_document"
												// rules={[
												// 	{
												// 		required: true,
												// 		validator: (_, value) => {
												// 			if (
												// 				policyFiles.length ===
												// 				0
												// 			) {
												// 				return Promise.reject(
												// 					new Error(
												// 						'Please upload insurance policy document!',
												// 					),
												// 				);
												// 			}
												// 			return Promise.resolve();
												// 		},
												// 	},
												// ]}
												rules={[
													{
														required:
															policyType ===
															'commercial',
														validator: (
															_,
															value,
														) => {
															if (
																policyType ===
																	'commercial' &&
																policyFiles.length ===
																	0
															) {
																return Promise.reject(
																	new Error(
																		'Please upload policy document!',
																	),
																);
															}
															return Promise.resolve();
														},
													},
												]}
											>
												{/* <Space
												// size={[5, 5]}

												style={{
													flexWrap: 'wrap',
													alignItems: 'start',
													border:'1px solid yellow',
													width:'100%'

												}}
											> */}
												{policyFiles.length > 0 &&
													policyFiles.map((ele) => {
														console.log(
															ele.url.name,
															'inside policy file',
														);
														return (
															<>
																{(
																	ele.url
																		? ele.url.name
																				.split(
																					'.',
																				)
																				.includes(
																					'pdf',
																				)
																		: ele.originFileObj.name
																				.split(
																					'.',
																				)
																				.includes(
																					'pdf',
																				)
																) ? (
																	<>
																		<div className="image-container_case_filing ">
																			{/* <Card
																			hoverable
																			style={{
																				// display:
																				// 	'flex',
																				justifyContent:
																					'center',
																				alignItems:
																					'center',
																				borderRadius:
																					'6px',
																				border: '1px dashed #d9d9d9',
																				display:
																					'block',
																				height: '100px',
																				padding:
																					'4px 15px',
																				width: '100px',
																				background:
																					'rgba(0, 0, 0, 0.02)',
																				// margin: '20px',
																			}}
																			onClick={() =>
																				handlePolicyPreview(
																					ele.url,
																				)
																			}
																			id="timeline-documents-hover"
																		>
																			<div
																				style={{
																					display:
																						'flex',
																					justifyContent:
																						'center',
																					alignItems:
																						'center',
																					flexDirection:
																						'column',
																					textAlign:
																						'center',
																				}}
																			>
																				<FilePdfOutlined
																					style={{
																						fontSize:
																							'30px',
																						border: 'none',
																						background:
																							'transparent',
																					}}
																				/>
																			</div>
																		</Card> */}
																			<PlaceholderText>
																				{
																					ele
																						.url
																						.name
																				}
																			</PlaceholderText>
																			<div
																				className="overlay_case_filing"
																				// style={{
																				// 	height: '100px',
																				// }}
																				style={{
																					height: 'calc(100% + 30px)',
																					top: '-14px',
																					left: '-10px',
																					width: 'calc(100% + 20px)',
																				}}
																			>
																				<EyeOutlined
																					onClick={() =>
																						handlePolicyPreview(
																							ele.url,
																						)
																					}
																					className="icon eye-icon"
																				/>
																				<DeleteOutlined
																					onClick={() =>
																						handleRemovePolicyFile(
																							ele,
																						)
																					}
																					className="icon download-icon"
																				/>
																			</div>
																			<Tooltip
																				title={
																					ele.document_name
																				}
																			>
																				<div
																					style={{
																						textAlign:
																							'center',
																						marginTop:
																							'5px',
																						fontSize:
																							'12px',
																						color: '#000',
																						whiteSpace:
																							'nowrap',
																						overflow:
																							'hidden',
																						textOverflow:
																							'ellipsis',
																						width: '100px', // or any other width as per requirement
																					}}
																				>
																					{
																						ele.document_name
																					}
																				</div>
																			</Tooltip>
																		</div>
																	</>
																) : (
																	<>
																		<div className="image-container_case_filing">
																			<Image
																				width={
																					100
																				}
																				height={
																					100
																				}
																				style={{
																					borderRadius:
																						'10px',
																				}}
																				className="supporting-docs-hover"
																				src={`${ele.preview}`}
																				onClick={() =>
																					handlePolicyPreview(
																						ele.url,
																					)
																				}
																			/>
																			<div
																				className="overlay_case_filing"
																				style={{
																					height: '100px',
																				}}
																			>
																				<EyeOutlined
																					onClick={() =>
																						handlePolicyPreview(
																							ele.url,
																						)
																					}
																					className="icon eye-icon"
																				/>
																				<DeleteOutlined
																					onClick={() =>
																						handleRemovePolicyFile(
																							ele,
																						)
																					}
																					className="icon download-icon"
																				/>
																			</div>
																			<Tooltip
																				title={
																					ele.document_name
																				}
																			>
																				<div
																					style={{
																						textAlign:
																							'center',
																						marginTop:
																							'5px',
																						fontSize:
																							'12px',
																						color: '#000',
																						whiteSpace:
																							'nowrap',
																						overflow:
																							'hidden',
																						textOverflow:
																							'ellipsis',
																						width: '100px', // or any other width as per requirement
																					}}
																				>
																					{
																						ele.document_name
																					}
																				</div>
																			</Tooltip>
																		</div>
																	</>
																)}
															</>
														);
													})}
												{policyFiles.length > 0
													? null
													: uploadPolicyDocButton(
															true,
													  )}
												{/* </Space> */}
											</XForm.Item>
										</XCardDesktop>
									</Col>
								) : (
									<Col xs={24} md={12}>
										{clinicalComponent()}
									</Col>
								)}
							</Row>
							<Row className="">
								<Col xs={24}>
									<XDivider />
								</Col>
							</Row>

							<Row gutter={[16, 16]}>
								{policyType === 'commercial' && (
									<Col xs={24} md={12}>
										{clinicalComponent()}

										<Col xs={24}>
											<XDivider />
										</Col>

										{/* Supporting Docs new column start */}

										<>
											{supportingFiles.length > 0 ? (
												<XForm.Item
													className="addCaseLabel mb-0 mt-4 "
													// label={supportingFiles.length > 0 ? "Upload Supporting Documents (Optional)" : undefined}
												>
													{/* <Space
														size={[5, 5]}
														style={{
															flexWrap: 'wrap',
															alignItems: 'start',
															border:'1px solid red'
														}}
													> */}
													{supportingFiles.length >
														0 &&
														supportingFiles.map(
															(ele) => {
																return (
																	<>
																		{(
																			ele.url
																				? ele.url.name
																						.split(
																							'.',
																						)
																						.includes(
																							'pdf',
																						)
																				: ele.originFileObj.name
																						.split(
																							'.',
																						)
																						.includes(
																							'pdf',
																						)
																		) ? (
																			<>
																				<div
																					className="image-container_case_filing"
																					style={{
																						height: '50px',
																						width: '100px',
																						padding:
																							'5px',
																						background:
																							'#EFF6FE',
																						gap: '10px',
																						border: '1px dashed #d9d9d9',
																						justifyContent:
																							'center',
																						alignItems:
																							'center',
																						display:
																							'flex',
																						borderRadius:
																							'16px',
																						boxShadow:
																							'0px 0px 15px rgba(0, 114, 153, 0.05) !important',
																					}}
																				>
																					<div>
																						{
																							ele.document_name
																						}
																					</div>
																					{/* <Card
																					hoverable
																					style={{
																						// display:
																						// 	'flex',
																						justifyContent:
																							'center',
																						alignItems:
																							'center',
																						borderRadius:
																							'6px',
																						border: '1px dashed #d9d9d9',
																						display:
																							'block',
																						height: '100px',
																						padding:
																							'4px 15px',
																						width: '100px',
																						background:
																							'rgba(0, 0, 0, 0.02)',
																						// margin: '20px',
																					}}
																					onClick={() =>
																						handlePreview(
																							ele.url,
																						)
																					}
																					id="timeline-documents-hover"
																				>
																					{/* <div
																						style={{
																							display:
																								'flex',
																							justifyContent:
																								'center',
																							alignItems:
																								'center',
																							flexDirection:
																								'column',
																							textAlign:
																								'center',
																						}}
																					>
																						<FilePdfOutlined
																							style={{
																								fontSize:
																									'30px',
																								border: 'none',
																								background:
																									'transparent',
																							}}
																						/>
																					</div> */}
																					{/* <PlaceholderText>{ele.document}</PlaceholderText>
																				</Card> */}
																					<div
																						className="overlay_case_filing"
																						style={{
																							height: '100%',
																						}}
																					>
																						<EyeOutlined
																							onClick={() =>
																								handlePreview(
																									ele.url,
																								)
																							}
																							className="icon eye-icon"
																						/>
																						<DeleteOutlined
																							onClick={() =>
																								handleRemoveFile(
																									ele,
																								)
																							}
																							className="icon download-icon"
																						/>
																					</div>
																					{/* <Tooltip
																					title={
																						ele.document_name
																					}
																				>
																					<div
																						style={{
																							textAlign:
																								'center',
																							marginTop:
																								'5px',
																							fontSize:
																								'12px',
																							color: '#000',
																							whiteSpace:
																								'nowrap',
																							overflow:
																								'hidden',
																							textOverflow:
																								'ellipsis',
																							width: '100px', // or any other width as per requirement
																						}}
																					>
																						{
																							ele.document_name
																						}
																					</div>
																				</Tooltip> */}
																				</div>
																			</>
																		) : (
																			<>
																				<div
																					className="image-container_case_filing"
																					style={{
																						backgroundColor:
																							'green',
																					}}
																				>
																					<Image
																						width={
																							100
																						}
																						height={
																							100
																						}
																						style={{
																							borderRadius:
																								'10px',
																						}}
																						className="supporting-docs-hover"
																						src={`${ele.preview}`}
																						onClick={() =>
																							handlePreview(
																								ele.url,
																							)
																						}
																					/>
																					<div
																						className="overlay_case_filing"
																						style={{
																							height: '100px',
																							backgroundColor:
																								'red',
																						}}
																					>
																						<EyeOutlined
																							onClick={() =>
																								handlePreview(
																									ele.url,
																								)
																							}
																							className="icon eye-icon"
																						/>
																						<DeleteOutlined
																							onClick={() =>
																								handleRemoveFile(
																									ele,
																								)
																							}
																							className="icon download-icon"
																						/>
																					</div>
																					<Tooltip
																						title={
																							ele.document_name
																						}
																					>
																						<div
																							style={{
																								textAlign:
																									'center',
																								marginTop:
																									'5px',
																								fontSize:
																									'12px',
																								color: '#000',
																								whiteSpace:
																									'nowrap',
																								overflow:
																									'hidden',
																								textOverflow:
																									'ellipsis',
																								width: '100px', // or any other width as per requirement
																							}}
																						>
																							{
																								ele.document_name
																							}
																						</div>
																					</Tooltip>
																				</div>
																			</>
																		)}
																	</>
																);
															},
														)}
													{supportingFiles.length > 25
														? null
														: uploadSupportingDocButton(
																supportingFiles.length,
																true,
														  )}
													{/* </Space> */}
												</XForm.Item>
											) : (
												<Col xs={24} className="mt-3">
													<XCardDesktop className="bodyPadding bodyHeight h-auto bg-red">
														<XForm.Item
															className="addCaseLabel mb-0 p-3 "
															// label={supportingFiles.length > 0 ? "Upload Supporting Documents (Optional)" : undefined}
														>
															{/* <Space
														size={[5, 5]}
														style={{
															flexWrap: 'wrap',
															alignItems: 'start',
															border:'1px solid red'
														}}
													> */}
															{supportingFiles.length >
																0 &&
																supportingFiles.map(
																	(ele) => {
																		return (
																			<>
																				{(
																					ele.url
																						? ele.url.name
																								.split(
																									'.',
																								)
																								.includes(
																									'pdf',
																								)
																						: ele.originFileObj.name
																								.split(
																									'.',
																								)
																								.includes(
																									'pdf',
																								)
																				) ? (
																					<>
																						<div
																							className="image-container_case_filing"
																							style={{
																								height: '60px',
																								width: '120px',
																								padding:
																									'5px',
																								background:
																									'rgba(0, 0, 0, 0.02)',
																								borderRadius:
																									'6px',
																								border: '1px dashed #d9d9d9',
																								justifyContent:
																									'center',
																								alignItems:
																									'center',
																								display:
																									'flex',
																							}}
																						>
																							<div>
																								{
																									ele.document_name
																								}
																							</div>
																							{/* <Card
																					hoverable
																					style={{
																						// display:
																						// 	'flex',
																						justifyContent:
																							'center',
																						alignItems:
																							'center',
																						borderRadius:
																							'6px',
																						border: '1px dashed #d9d9d9',
																						display:
																							'block',
																						height: '100px',
																						padding:
																							'4px 15px',
																						width: '100px',
																						background:
																							'rgba(0, 0, 0, 0.02)',
																						// margin: '20px',
																					}}
																					onClick={() =>
																						handlePreview(
																							ele.url,
																						)
																					}
																					id="timeline-documents-hover"
																				>
																					{/* <div
																						style={{
																							display:
																								'flex',
																							justifyContent:
																								'center',
																							alignItems:
																								'center',
																							flexDirection:
																								'column',
																							textAlign:
																								'center',
																						}}
																					>
																						<FilePdfOutlined
																							style={{
																								fontSize:
																									'30px',
																								border: 'none',
																								background:
																									'transparent',
																							}}
																						/>
																					</div> */}
																							{/* <PlaceholderText>{ele.document}</PlaceholderText>
																				</Card> */}
																							<div
																								className="overlay_case_filing"
																								style={{
																									height: '100%',
																								}}
																							>
																								<EyeOutlined
																									onClick={() =>
																										handlePreview(
																											ele.url,
																										)
																									}
																									className="icon eye-icon"
																								/>
																								<DeleteOutlined
																									onClick={() =>
																										handleRemoveFile(
																											ele,
																										)
																									}
																									className="icon download-icon"
																								/>
																							</div>
																							{/* <Tooltip
																					title={
																						ele.document_name
																					}
																				>
																					<div
																						style={{
																							textAlign:
																								'center',
																							marginTop:
																								'5px',
																							fontSize:
																								'12px',
																							color: '#000',
																							whiteSpace:
																								'nowrap',
																							overflow:
																								'hidden',
																							textOverflow:
																								'ellipsis',
																							width: '100px', // or any other width as per requirement
																						}}
																					>
																						{
																							ele.document_name
																						}
																					</div>
																				</Tooltip> */}
																						</div>
																					</>
																				) : (
																					<>
																						<div
																							className="image-container_case_filing"
																							style={{
																								backgroundColor:
																									'green',
																							}}
																						>
																							<Image
																								width={
																									100
																								}
																								height={
																									100
																								}
																								style={{
																									borderRadius:
																										'10px',
																								}}
																								className="supporting-docs-hover"
																								src={`${ele.preview}`}
																								onClick={() =>
																									handlePreview(
																										ele.url,
																									)
																								}
																							/>
																							<div
																								className="overlay_case_filing"
																								style={{
																									height: '100px',
																									backgroundColor:
																										'red',
																								}}
																							>
																								<EyeOutlined
																									onClick={() =>
																										handlePreview(
																											ele.url,
																										)
																									}
																									className="icon eye-icon"
																								/>
																								<DeleteOutlined
																									onClick={() =>
																										handleRemoveFile(
																											ele,
																										)
																									}
																									className="icon download-icon"
																								/>
																							</div>
																							<Tooltip
																								title={
																									ele.document_name
																								}
																							>
																								<div
																									style={{
																										textAlign:
																											'center',
																										marginTop:
																											'5px',
																										fontSize:
																											'12px',
																										color: '#000',
																										whiteSpace:
																											'nowrap',
																										overflow:
																											'hidden',
																										textOverflow:
																											'ellipsis',
																										width: '100px', // or any other width as per requirement
																									}}
																								>
																									{
																										ele.document_name
																									}
																								</div>
																							</Tooltip>
																						</div>
																					</>
																				)}
																			</>
																		);
																	},
																)}
															{supportingFiles.length >
															25
																? null
																: uploadSupportingDocButton(
																		supportingFiles.length,
																		true,
																  )}
															{/* </Space> */}
														</XForm.Item>
													</XCardDesktop>
												</Col>
											)}
										</>
									</Col>
								)}

								<Col md={12} xs={14}>
									{clinicalSelectedOption ===
										'clinical_text' && (
										<XForm.Item
											className="addCaseLabel mb-0"
											name="clinical_summary"
											rules={[
												{
													required: true,
													message:
														'Please enter clinical summary',
												},
											]}
										>
											<XInputTextArea.TextArea
												className="textAreaStyle2"
												name="clinical_summary"
												// showCount
												autoSize={{
													minRows: 4,
												}}
												onChange={(e) => {
													handleInputChange(
														e,
														formData,
														setFormData,
													);
													setSummary(e.target.value);
													setClinicalDocumentFileList(
														[],
													);
													setUpdatedClinicalDocumentFileList(
														[],
													);
												}}
												placeholder="Enter Detailed Clinical Summary"
											/>
										</XForm.Item>
									)}
									{clinicalSelectedOption ===
										'clinical_document' && (
										<XCardDesktop2 className="bodyPadding ">
											<XForm.Item
												className="mb-0"
												name="clinical_document"
												rules={[
													{
														required: true,
														validator: (
															_,
															value,
														) => {
															if (
																clinicalDocumentFileList.length ===
																0
															) {
																return Promise.reject(
																	new Error(
																		'Please upload Clinical Document!',
																	),
																);
															}
															return Promise.resolve();
														},
													},
												]}
											>
												<div className="uploadClinicalDocument">
													<Dragger
														style={{
															background: 'none',
														}}
														action={null}
														multiple={false}
														maxCount={1}
														showUploadList={{
															showPreviewIcon: true,
															showRemoveIcon: true,
														}}
														className="supporting_files"
														listType="picture-card"
														fileList={
															clinicalDocumentFileList
														}
														onPreview={
															handlePreviewClinicalSummary
														}
														onChange={
															handleClinicalDocumentChange
														}
														onRemove={(file) => {
															handleRemoveClinicalDocuments(
																file,
															);
															return false; // Prevent default removal behavior to avoid triggering onChange
														}}
														accept=".pdf"
														beforeUpload={(
															file,
														) => {
															const isJPEG =
																file.type ===
																'image/jpeg';
															const isJPG =
																file.type ===
																'image/jpg';
															const isPNG =
																file.type ===
																'image/png';
															const isGIF =
																file.type ===
																'image/gif';
															const isVideo =
																file.type.startsWith(
																	'video/',
																);
															const isAudio =
																file.type.startsWith(
																	'audio/',
																);

															const isLt10M =
																file.size /
																	1024 /
																	1024 <
																10;

															if (!isLt10M) {
																notification.error(
																	{
																		message:
																			'File Size Exceeded',
																		description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
																	},
																);
															}
															if (
																isGIF ||
																isVideo ||
																isAudio ||
																isJPEG ||
																isJPG ||
																isPNG
															) {
																notification.error(
																	{
																		message:
																			'File Type Error',
																		description: `GIF, video, image and audio files are not allowed.`,
																	},
																);
															}

															return (
																isLt10M &&
																!isGIF &&
																!isVideo &&
																!isAudio &&
																!isJPEG &&
																!isJPG &&
																!isPNG
															);
														}}
													>
														{clinicalDocumentFileList.length >=
														1 ? null : (
															<>
																<img
																	src="/images/aipa-clinical-document.svg"
																	alt=""
																	className="w-25"
																/>
																<p className="uploadClinicalDocumentText">
																	Click to
																	upload your
																	case file.
																</p>
															</>
														)}
													</Dragger>
												</div>
											</XForm.Item>
										</XCardDesktop2>
									)}
								</Col>
							</Row>
							<Row className="mt-4 ">
								<Col xs={{ span: 14, offset: 10 }}>
									<XShazaamBtn
										size="large"
										htmlType="submit"
										type="primary"
									>
										Shazaam !
									</XShazaamBtn>
								</Col>
							</Row>

							{/* <Row gutter={(15, 15)} className="mt-4" style={{border:'1px solid red'}}> */}
							{/* <Col xs={10} style={{border:'1px solid blue'}}>
									<Row gutter={(15, 15)}>
										{/* <Col xs={24}>
											<AddInputIcon>
												<EnvironmentOutlined />
											</AddInputIcon>
											<XForm.Item
												name="state"
												rules={[
													{
														required: true,
														message:
															'Please select State',
													},
												]}
												className="mb-0"
											>
												<XSelectIcons
													size="large"
													placeholder="Select State"
													name="state"
													showSearch={true}
													// onSearch={(search) => {
													// 	if (
													// 		search.length >= 3
													// 	) {
													// 		getStateDropdown({
													// 			search,
													// 		});
													// 	} else if (
													// 		search.length === 0
													// 	) {
													// 		getStateDropdown();
													// 	}
													// }}
													filterOption={filterByLabel}
													options={
														getStateDropdownState.apiState ===
															'success' &&
														getStateDropdownState
															.data.all_states
															?.length > 0 &&
														getStateDropdownState.data.all_states.map(
															(itm) => {
																return {
																	label: itm.state,
																	value: itm.state,
																	providers:
																		itm.providers,
																};
															},
														)
													}
													onSelect={(
														value,
														options,
													) => {
														setState(value);
														summaryForm.setFieldValue(
															'insurance_company',
															null,
														);
														setInsuranceCompanies(
															options.providers,
														);
													}}
												></XSelectIcons>
											</XForm.Item>
										</Col> */}
							{/* <Col xs={24} className="mt-2">
											<AddInputIcon>
												<UserSwitchOutlined />
											</AddInputIcon>
											<XForm.Item
												name="insurance_company"
												className="mb-0"
												rules={[
													{
														required: true,
														message:
															'Please select provider',
													},
												]}
											>
												<XSelectIcons
													size="large"
													placeholder="Select Insurance Company"
													name="insurance_company"
													showSearch={true}
													filterOption={filterByLabel}
													options={
														insuranceCompanies.length >
															0 &&
														insuranceCompanies.map(
															(itm) => {
																return {
																	label: itm.name,
																	value: itm.name,
																};
															},
														)
													}
													onSelect={(value) => {
														setProvider(value);
													}}
												></XSelectIcons>
											</XForm.Item>
										</Col> */}
							{/* <Col xs={24}>
											<XDivider />
										</Col> */}
							{/* <Col xs={24}>
											<XForm.Item
												className="addCaseLabel mb-0"
												// name="policy_type"
												label="what is your policy type?"
												rules={[
													{
														required: true,
														message:
															'Please select option!',
													},
												]}
											>
												<XRadio.Group
													className="w-100"
													defaultValue={policyType}
													onChange={
														handlePolicyTypeChange
													}
												>
													<XRadio value={'medicare'}>
														Medicare Policy
													</XRadio>
													<XRadio
														value={'commercial'}
													>
														Commercial Policy
													</XRadio>
												</XRadio.Group>
											</XForm.Item>
										</Col> */}
							{/* <Col xs={24}>
											<XDivider />
										</Col> */}
							{/* <Col xs={24}>
											<XForm.Item
												className="addCaseLabel mb-0"
												name="clinical_category"
												rules={[
													{
														required: true,
														message:
															'Please select option!',
													},
												]}
											>
												<XRadio.Group
													className="w-100"
													defaultValue={
														clinicalSelectedOption
													}
													onChange={(e) => {
														handleInputChange(
															e,
															formData,
															setFormData,
														);
														if (
															e.target.value ===
															'clinical_text'
														) {
															setClinicalSelectedOption(
																'clinical_text',
															);
														} else if (
															e.target.value ===
															'clinical_document'
														) {
															setClinicalSelectedOption(
																'clinical_document',
															);
															// } else if (
															// 	e.target.value ===
															// 	'dictate_audio'
															// ) {
															// 	setClinicalSelectedOption(
															// 		'dictate_audio',
															// 	);
														}
													}}
												>
													<XRadio
														value={'clinical_text'}
													>
														Enter Clinical Summary
													</XRadio>
													<XRadio
														value={
															'clinical_document'
														}
													>
														Upload Clinical Summary
													</XRadio>
													{/* <XRadio
														value={'dictate_audio'}
													>
														Dictate
													</XRadio> */}
							{/* </XRadio.Group>
											</XForm.Item>
										</Col> */}
							{/* </Row> */}
							{/* </Col>  */}
							{/* <Col xs={14} style={{border:'1px solid green'}}>
									{clinicalSelectedOption ===
										'clinical_text' && (
										<XForm.Item
											className="addCaseLabel mb-0"
											name="clinical_summary"
											rules={[
												{
													required: true,
													message:
														'Please enter clinical summary',
												},
											]}
										>
											<XInputTextArea.TextArea
												className="textAreaStyle"
												name="clinical_summary"
												// showCount
												autoSize={{
													minRows: 9,
												}}
												onChange={(e) => {
													handleInputChange(
														e,
														formData,
														setFormData,
													);
													setSummary(e.target.value);
													setClinicalDocumentFileList(
														[],
													);
													setUpdatedClinicalDocumentFileList(
														[],
													);
												}}
												placeholder="Enter Detailed Clinical Summary"
											/>
										</XForm.Item>
									)}
									{clinicalSelectedOption ===
										'clinical_document' && (
										<XCardDesktop className="bodyPadding h-auto">
											<XForm.Item
												className="mb-0"
												name="clinical_document"
												rules={[
													{
														required: true,
														validator: (
															_,
															value,
														) => {
															if (
																clinicalDocumentFileList.length ===
																0
															) {
																return Promise.reject(
																	new Error(
																		'Please upload Clinical Document!',
																	),
																);
															}
															return Promise.resolve();
														},
													},
												]}
											>
												<div className="uploadClinicalDocument">
													<Dragger
														style={{
															background: 'none',
														}}
														action={null}
														multiple={false}
														maxCount={1}
														showUploadList={{
															showPreviewIcon: true,
															showRemoveIcon: true,
														}}
														className="supporting_files"
														listType="picture-card"
														fileList={
															clinicalDocumentFileList
														}
														onPreview={
															handlePreviewClinicalSummary
														}
														onChange={
															handleClinicalDocumentChange
														}
														onRemove={(file) => {
															handleRemoveClinicalDocuments(
																file,
															);
															return false; // Prevent default removal behavior to avoid triggering onChange
														}}
														accept=".pdf"
														beforeUpload={(
															file,
														) => {
															const isJPEG =
																file.type ===
																'image/jpeg';
															const isJPG =
																file.type ===
																'image/jpg';
															const isPNG =
																file.type ===
																'image/png';
															const isGIF =
																file.type ===
																'image/gif';
															const isVideo =
																file.type.startsWith(
																	'video/',
																);
															const isAudio =
																file.type.startsWith(
																	'audio/',
																);

															const isLt10M =
																file.size /
																	1024 /
																	1024 <
																10;

															if (!isLt10M) {
																notification.error(
																	{
																		message:
																			'File Size Exceeded',
																		description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
																	},
																);
															}
															if (
																isGIF ||
																isVideo ||
																isAudio ||
																isJPEG ||
																isJPG ||
																isPNG
															) {
																notification.error(
																	{
																		message:
																			'File Type Error',
																		description: `GIF, video, image and audio files are not allowed.`,
																	},
																);
															}

															return (
																isLt10M &&
																!isGIF &&
																!isVideo &&
																!isAudio &&
																!isJPEG &&
																!isJPG &&
																!isPNG
															);
														}}
													>
														{clinicalDocumentFileList.length >=
														1 ? null : (
															<>
																<img
																	src="/images/aipa-clinical-document.svg"
																	alt=""
																/>
																<p className="uploadClinicalDocumentText">
																	Click or
																	drag file to
																	this area to
																	upload
																</p>
															</>
														)}
													</Dragger>
												</div>
											</XForm.Item>
										</XCardDesktop>
									)}
								</Col> */}
							{/* Policy Docs new column start */}
							{/* <Col xs={24} className="mt-3">
									<XCardDesktop className="bodyPadding bodyHeight h-auto">
										<XForm.Item
											className="addCaseLabel mb-0 p-3"
											label="Upload Insurance Policy Document"
											name="policy_document"
											// rules={[
											// 	{
											// 		required: true,
											// 		validator: (_, value) => {
											// 			if (
											// 				policyFiles.length ===
											// 				0
											// 			) {
											// 				return Promise.reject(
											// 					new Error(
											// 						'Please upload insurance policy document!',
											// 					),
											// 				);
											// 			}
											// 			return Promise.resolve();
											// 		},
											// 	},
											// ]}
											rules={[
												{
													required:
														policyType ===
														'commercial',
													validator: (_, value) => {
														if (
															policyType ===
																'commercial' &&
															policyFiles.length ===
																0
														) {
															return Promise.reject(
																new Error(
																	'Please upload policy document!',
																),
															);
														}
														return Promise.resolve();
													},
												},
											]}
										>
											<Space
												size={[5, 5]}
												style={{
													flexWrap: 'wrap',
													alignItems: 'start',
												}}
											>
												{policyFiles.length > 0 &&
													policyFiles.map((ele) => {
														return (
															<>
																{(
																	ele.url
																		? ele.url.name
																				.split(
																					'.',
																				)
																				.includes(
																					'pdf',
																				)
																		: ele.originFileObj.name
																				.split(
																					'.',
																				)
																				.includes(
																					'pdf',
																				)
																) ? (
																	<>
																		<div className="image-container_case_filing">
																			<Card
																				hoverable
																				style={{
																					// display:
																					// 	'flex',
																					justifyContent:
																						'center',
																					alignItems:
																						'center',
																					borderRadius:
																						'6px',
																					border: '1px dashed #d9d9d9',
																					display:
																						'block',
																					height: '100px',
																					padding:
																						'4px 15px',
																					width: '100px',
																					background:
																						'rgba(0, 0, 0, 0.02)',
																					// margin: '20px',
																				}}
																				onClick={() =>
																					handlePolicyPreview(
																						ele.url,
																					)
																				}
																				id="timeline-documents-hover"
																			>
																				<div
																					style={{
																						display:
																							'flex',
																						justifyContent:
																							'center',
																						alignItems:
																							'center',
																						flexDirection:
																							'column',
																						textAlign:
																							'center',
																					}}
																				>
																					<FilePdfOutlined
																						style={{
																							fontSize:
																								'30px',
																							border: 'none',
																							background:
																								'transparent',
																						}}
																					/>
																				</div>
																			</Card>
																			<div
																				className="overlay_case_filing"
																				style={{
																					height: '100px',
																				}}
																			>
																				<EyeOutlined
																					onClick={() =>
																						handlePolicyPreview(
																							ele.url,
																						)
																					}
																					className="icon eye-icon"
																				/>
																				<DeleteOutlined
																					onClick={() =>
																						handleRemovePolicyFile(
																							ele,
																						)
																					}
																					className="icon download-icon"
																				/>
																			</div>
																			<Tooltip
																				title={
																					ele.document_name
																				}
																			>
																				<div
																					style={{
																						textAlign:
																							'center',
																						marginTop:
																							'5px',
																						fontSize:
																							'12px',
																						color: '#000',
																						whiteSpace:
																							'nowrap',
																						overflow:
																							'hidden',
																						textOverflow:
																							'ellipsis',
																						width: '100px', // or any other width as per requirement
																					}}
																				>
																					{
																						ele.document_name
																					}
																				</div>
																			</Tooltip>
																		</div>
																	</>
																) : (
																	<>
																		<div className="image-container_case_filing">
																			<Image
																				width={
																					100
																				}
																				height={
																					100
																				}
																				style={{
																					borderRadius:
																						'10px',
																				}}
																				className="supporting-docs-hover"
																				src={`${ele.preview}`}
																				onClick={() =>
																					handlePolicyPreview(
																						ele.url,
																					)
																				}
																			/>
																			<div
																				className="overlay_case_filing"
																				style={{
																					height: '100px',
																				}}
																			>
																				<EyeOutlined
																					onClick={() =>
																						handlePolicyPreview(
																							ele.url,
																						)
																					}
																					className="icon eye-icon"
																				/>
																				<DeleteOutlined
																					onClick={() =>
																						handleRemovePolicyFile(
																							ele,
																						)
																					}
																					className="icon download-icon"
																				/>
																			</div>
																			<Tooltip
																				title={
																					ele.document_name
																				}
																			>
																				<div
																					style={{
																						textAlign:
																							'center',
																						marginTop:
																							'5px',
																						fontSize:
																							'12px',
																						color: '#000',
																						whiteSpace:
																							'nowrap',
																						overflow:
																							'hidden',
																						textOverflow:
																							'ellipsis',
																						width: '100px', // or any other width as per requirement
																					}}
																				>
																					{
																						ele.document_name
																					}
																				</div>
																			</Tooltip>
																		</div>
																	</>
																)}
															</>
														);
													})}
												{policyFiles.length > 0
													? null
													: uploadPolicyDocButton}
											</Space>
										</XForm.Item>
									</XCardDesktop>
								</Col> */}

							{/* Supporting Docs new column start */}
							{/* <Col xs={24} className="mt-3">
									<XCardDesktop className="bodyPadding bodyHeight h-auto">
										<XForm.Item
											className="addCaseLabel mb-0 p-3"
											label="Upload Supporting Documents (Optional)"
										>
											<Space
												size={[5, 5]}
												style={{
													flexWrap: 'wrap',
													alignItems: 'start',
												}}
											>
												{supportingFiles.length > 0 &&
													supportingFiles.map(
														(ele) => {
															return (
																<>
																	{(
																		ele.url
																			? ele.url.name
																					.split(
																						'.',
																					)
																					.includes(
																						'pdf',
																					)
																			: ele.originFileObj.name
																					.split(
																						'.',
																					)
																					.includes(
																						'pdf',
																					)
																	) ? (
																		<>
																			<div className="image-container_case_filing">
																				<Card
																					hoverable
																					style={{
																						// display:
																						// 	'flex',
																						justifyContent:
																							'center',
																						alignItems:
																							'center',
																						borderRadius:
																							'6px',
																						border: '1px dashed #d9d9d9',
																						display:
																							'block',
																						height: '100px',
																						padding:
																							'4px 15px',
																						width: '100px',
																						background:
																							'rgba(0, 0, 0, 0.02)',
																						// margin: '20px',
																					}}
																					onClick={() =>
																						handlePreview(
																							ele.url,
																						)
																					}
																					id="timeline-documents-hover"
																				>
																					<div
																						style={{
																							display:
																								'flex',
																							justifyContent:
																								'center',
																							alignItems:
																								'center',
																							flexDirection:
																								'column',
																							textAlign:
																								'center',
																						}}
																					>
																						<FilePdfOutlined
																							style={{
																								fontSize:
																									'30px',
																								border: 'none',
																								background:
																									'transparent',
																							}}
																						/>
																					</div>
																				</Card>
																				<div
																					className="overlay_case_filing"
																					style={{
																						height: '100px',
																					}}
																				>
																					<EyeOutlined
																						onClick={() =>
																							handlePreview(
																								ele.url,
																							)
																						}
																						className="icon eye-icon"
																					/>
																					<DeleteOutlined
																						onClick={() =>
																							handleRemoveFile(
																								ele,
																							)
																						}
																						className="icon download-icon"
																					/>
																				</div>
																				<Tooltip
																					title={
																						ele.document_name
																					}
																				>
																					<div
																						style={{
																							textAlign:
																								'center',
																							marginTop:
																								'5px',
																							fontSize:
																								'12px',
																							color: '#000',
																							whiteSpace:
																								'nowrap',
																							overflow:
																								'hidden',
																							textOverflow:
																								'ellipsis',
																							width: '100px', // or any other width as per requirement
																						}}
																					>
																						{
																							ele.document_name
																						}
																					</div>
																				</Tooltip>
																			</div>
																		</>
																	) : (
																		<>
																			<div className="image-container_case_filing">
																				<Image
																					width={
																						100
																					}
																					height={
																						100
																					}
																					style={{
																						borderRadius:
																							'10px',
																					}}
																					className="supporting-docs-hover"
																					src={`${ele.preview}`}
																					onClick={() =>
																						handlePreview(
																							ele.url,
																						)
																					}
																				/>
																				<div
																					className="overlay_case_filing"
																					style={{
																						height: '100px',
																					}}
																				>
																					<EyeOutlined
																						onClick={() =>
																							handlePreview(
																								ele.url,
																							)
																						}
																						className="icon eye-icon"
																					/>
																					<DeleteOutlined
																						onClick={() =>
																							handleRemoveFile(
																								ele,
																							)
																						}
																						className="icon download-icon"
																					/>
																				</div>
																				<Tooltip
																					title={
																						ele.document_name
																					}
																				>
																					<div
																						style={{
																							textAlign:
																								'center',
																							marginTop:
																								'5px',
																							fontSize:
																								'12px',
																							color: '#000',
																							whiteSpace:
																								'nowrap',
																							overflow:
																								'hidden',
																							textOverflow:
																								'ellipsis',
																							width: '100px', // or any other width as per requirement
																						}}
																					>
																						{
																							ele.document_name
																						}
																					</div>
																				</Tooltip>
																			</div>
																		</>
																	)}
																</>
															);
														},
													)}
												{supportingFiles.length > 25
													? null
													: uploadSupportingDocButton}
											</Space>
										</XForm.Item>
									</XCardDesktop>
								</Col> */}
							{/* </Row> */}
							{/* <Row gutter={(15, 15)} className="mt-5"> */}
							{/* <Col xs={{ span: 14, offset: 10 }}>
									<XShazaamBtn
										size="large"
										htmlType="submit"
										type="primary"
									>
										Shazaam !
									</XShazaamBtn>
								</Col> */}
							{/* </Row> */}
						</XForm>
					</XContainer>
				</AipaDesktopBody>
				{/* End Desktop design */}
				{/* Start Mobile design */}
				<AipaMobileBody className="paddingPage">
					<XContainer fluid className="paddingPage">
						<XForm
							form={summaryForm}
							name="loginForm"
							layout="vertical"
							autoComplete="off"
							onFinish={handleShazamClick}
						>
							<Row>
								<Col
									xs={{ span: 16, offset: 4 }}
									className="text-center"
								>
									<XLottieAipaLoader>
										<XLottieAipa
											animationData={LoaderJson}
											loop={true}
										/>
									</XLottieAipaLoader>
								</Col>

								<Col xs={24} className="mt-3">
									<ParaDeocdex>
										Reclaim provides you insights about your
										health insurance coverage.
									</ParaDeocdex>
								</Col>

								<Col xs={24} className="mt-3">
									<AddInputIcon>
										<EnvironmentOutlined />
									</AddInputIcon>
									<XForm.Item
										name="state"
										rules={[
											{
												required: true,
												message: 'Please select State',
											},
										]}
										className="mb-0"
									>
										<XSelectIcons
											style={{
												height: '50px',
											}}
											placeholder="Select State"
											name="state"
											showSearch={true}
											onSearch={(search) => {
												if (search.length >= 3) {
													getStateDropdown({
														search,
													});
												} else if (
													search.length === 0
												) {
													getStateDropdown();
												}
											}}
											filterOption={filterByLabel}
											options={
												getStateDropdownState.apiState ===
													'success' &&
												getStateDropdownState.data
													.all_states?.length > 0 &&
												getStateDropdownState.data.all_states.map(
													(itm) => {
														return {
															label: itm.state,
															value: itm.state,
															providers:
																itm.providers,
														};
													},
												)
											}
											onSelect={(value, options) => {
												setState(value);
												summaryForm.setFieldValue(
													'insurance_company',
													null,
												);
												setInsuranceCompanies(
													options.providers,
												);
											}}
										></XSelectIcons>
									</XForm.Item>
								</Col>

								<Col xs={24} className="mt-3">
									<AddInputIcon>
										<UserSwitchOutlined />
									</AddInputIcon>
									<XForm.Item
										name="insurance_company"
										className="mb-0"
										rules={[
											{
												required: true,
												message:
													'Please select Provider',
											},
										]}
									>
										<XSelectIcons
											style={{
												height: '50px',
											}}
											placeholder="Select Insurance Company"
											name="insurance_company"
											showSearch={true}
											filterOption={filterByLabel}
											options={
												insuranceCompanies.length > 0 &&
												insuranceCompanies.map(
													(itm) => {
														return {
															label: itm.name,
															value: itm.name,
														};
													},
												)
											}
											onSelect={(value) => {
												setProvider(value);
											}}
										></XSelectIcons>
									</XForm.Item>
								</Col>

								<Col xs={24} className="mt-3 ">
									<XForm.Item
										className="addCaseLabel mb-0"
										// name="policy_type"
										label="What is your policy type?"
										rules={[
											{
												required: true,
												message:
													'Please select option!',
											},
										]}
									>
										<XRadio.Group
											className="w-100"
											defaultValue={policyType}
											onChange={handlePolicyTypeChange}
										>
											<XRadio value={'medicare'}>
												Medicare Policy
											</XRadio>
											<XRadio value={'commercial'}>
												Commercial Policy
											</XRadio>
										</XRadio.Group>
									</XForm.Item>
								</Col>

								{/* Policy Docs new column start */}
								{policyType === 'commercial' && (
									<>
										<Col xs={24} className="mt-3 h-auto">
											<XCardDesktop className="bodyPadding bodyHeight h-auto">
												<XForm.Item
													className="addCaseLabel mb-0 p-3"
													label="Upload Insurance Policy Document"
													name="policy_document"
													rules={[
														{
															required: true,
															validator: (
																_,
																value,
															) => {
																if (
																	policyFiles.length ===
																	0
																) {
																	return Promise.reject(
																		new Error(
																			'Please upload insurance policy document!',
																		),
																	);
																}
																return Promise.resolve();
															},
														},
													]}
												>
													<Space
														size={[5, 5]}
														style={{
															flexWrap: 'wrap',
															alignItems: 'start',
														}}
													>
														{policyFiles.length >
															0 &&
															policyFiles.map(
																(ele) => {
																	return (
																		<>
																			{(
																				ele.url
																					? ele.url.name
																							.split(
																								'.',
																							)
																							.includes(
																								'pdf',
																							)
																					: ele.originFileObj.name
																							.split(
																								'.',
																							)
																							.includes(
																								'pdf',
																							)
																			) ? (
																				<>
																					<div className="image-container_case_filing">
																						<Card
																							hoverable
																							style={{
																								// display:
																								// 	'flex',
																								justifyContent:
																									'center',
																								alignItems:
																									'center',
																								borderRadius:
																									'6px',
																								border: '1px dashed #d9d9d9',
																								display:
																									'block',
																								height: '100px',
																								padding:
																									'4px 15px',
																								width: '100px',
																								background:
																									'rgba(0, 0, 0, 0.02)',
																								// margin: '20px',
																							}}
																							onClick={() =>
																								handlePolicyPreview(
																									ele.url,
																								)
																							}
																							id="timeline-documents-hover"
																						>
																							<div
																								style={{
																									display:
																										'flex',
																									justifyContent:
																										'center',
																									alignItems:
																										'center',
																									flexDirection:
																										'column',
																									textAlign:
																										'center',
																								}}
																							>
																								<FilePdfOutlined
																									style={{
																										fontSize:
																											'30px',
																										border: 'none',
																										background:
																											'transparent',
																									}}
																								/>
																							</div>
																						</Card>
																						<div
																							className="overlay_case_filing"
																							style={{
																								height: '100px',
																							}}
																						>
																							<EyeOutlined
																								onClick={() =>
																									handlePolicyPreview(
																										ele.url,
																									)
																								}
																								className="icon eye-icon"
																							/>
																							<DeleteOutlined
																								onClick={() =>
																									handleRemovePolicyFile(
																										ele,
																									)
																								}
																								className="icon download-icon"
																							/>
																						</div>
																						<Tooltip
																							title={
																								ele.document_name
																							}
																						>
																							<div
																								style={{
																									textAlign:
																										'center',
																									marginTop:
																										'5px',
																									fontSize:
																										'12px',
																									color: '#000',
																									whiteSpace:
																										'nowrap',
																									overflow:
																										'hidden',
																									textOverflow:
																										'ellipsis',
																									width: '100px', // or any other width as per requirement
																								}}
																							>
																								{
																									ele.document_name
																								}
																							</div>
																						</Tooltip>
																					</div>
																				</>
																			) : (
																				<>
																					<div className="image-container_case_filing">
																						<Image
																							width={
																								100
																							}
																							height={
																								100
																							}
																							style={{
																								borderRadius:
																									'10px',
																							}}
																							className="supporting-docs-hover"
																							src={`${ele.preview}`}
																							onClick={() =>
																								handlePolicyPreview(
																									ele.url,
																								)
																							}
																						/>
																						<div
																							className="overlay_case_filing"
																							style={{
																								height: '100px',
																							}}
																						>
																							<EyeOutlined
																								onClick={() =>
																									handlePolicyPreview(
																										ele.url,
																									)
																								}
																								className="icon eye-icon"
																							/>
																							<DeleteOutlined
																								onClick={() =>
																									handleRemovePolicyFile(
																										ele,
																									)
																								}
																								className="icon download-icon"
																							/>
																						</div>
																						<Tooltip
																							title={
																								ele.document_name
																							}
																						>
																							<div
																								style={{
																									textAlign:
																										'center',
																									marginTop:
																										'5px',
																									fontSize:
																										'12px',
																									color: '#000',
																									whiteSpace:
																										'nowrap',
																									overflow:
																										'hidden',
																									textOverflow:
																										'ellipsis',
																									width: '100px', // or any other width as per requirement
																								}}
																							>
																								{
																									ele.document_name
																								}
																							</div>
																						</Tooltip>
																					</div>
																				</>
																			)}
																		</>
																	);
																},
															)}
														{policyFiles.length > 0
															? null
															: uploadPolicyDocButton(
																	false,
															  )}
													</Space>
												</XForm.Item>
											</XCardDesktop>
										</Col>

										{/* Supporting Docs new column start */}
										<Col xs={24} className="mt-3">
											<XCardDesktop className="bodyPadding bodyHeight h-auto">
												<XForm.Item
													className="addCaseLabel mb-0 p-3"
													label="Upload Supporting Documents (Optional)"
												>
													<Space
														size={[5, 5]}
														style={{
															flexWrap: 'wrap',
															alignItems: 'start',
														}}
													>
														{supportingFiles.length >
															0 &&
															supportingFiles.map(
																(ele) => {
																	return (
																		<>
																			{(
																				ele.url
																					? ele.url.name
																							.split(
																								'.',
																							)
																							.includes(
																								'pdf',
																							)
																					: ele.originFileObj.name
																							.split(
																								'.',
																							)
																							.includes(
																								'pdf',
																							)
																			) ? (
																				<>
																					<div className="image-container_case_filing">
																						<Card
																							hoverable
																							style={{
																								// display:
																								// 	'flex',
																								justifyContent:
																									'center',
																								alignItems:
																									'center',
																								borderRadius:
																									'6px',
																								border: '1px dashed #d9d9d9',
																								display:
																									'block',
																								height: '100px',
																								padding:
																									'4px 15px',
																								width: '100px',
																								background:
																									'rgba(0, 0, 0, 0.02)',
																								// margin: '20px',
																							}}
																							onClick={() =>
																								handlePreview(
																									ele.url,
																								)
																							}
																							id="timeline-documents-hover"
																						>
																							<div
																								style={{
																									display:
																										'flex',
																									justifyContent:
																										'center',
																									alignItems:
																										'center',
																									flexDirection:
																										'column',
																									textAlign:
																										'center',
																								}}
																							>
																								<FilePdfOutlined
																									style={{
																										fontSize:
																											'30px',
																										border: 'none',
																										background:
																											'transparent',
																									}}
																								/>
																							</div>
																						</Card>
																						<div
																							className="overlay_case_filing"
																							style={{
																								height: '100px',
																							}}
																						>
																							<EyeOutlined
																								onClick={() =>
																									handlePreview(
																										ele.url,
																									)
																								}
																								className="icon eye-icon"
																							/>
																							<DeleteOutlined
																								onClick={() =>
																									handleRemoveFile(
																										ele,
																									)
																								}
																								className="icon download-icon"
																							/>
																						</div>
																						<Tooltip
																							title={
																								ele.document_name
																							}
																						>
																							<div
																								style={{
																									textAlign:
																										'center',
																									marginTop:
																										'5px',
																									fontSize:
																										'12px',
																									color: '#000',
																									whiteSpace:
																										'nowrap',
																									overflow:
																										'hidden',
																									textOverflow:
																										'ellipsis',
																									width: '100px', // or any other width as per requirement
																								}}
																							>
																								{
																									ele.document_name
																								}
																							</div>
																						</Tooltip>
																					</div>
																				</>
																			) : (
																				<>
																					<div className="image-container_case_filing">
																						<Image
																							width={
																								100
																							}
																							height={
																								100
																							}
																							style={{
																								borderRadius:
																									'10px',
																							}}
																							className="supporting-docs-hover"
																							src={`${ele.preview}`}
																							onClick={() =>
																								handlePreview(
																									ele.url,
																								)
																							}
																						/>
																						<div
																							className="overlay_case_filing"
																							style={{
																								height: '100px',
																							}}
																						>
																							<EyeOutlined
																								onClick={() =>
																									handlePreview(
																										ele.url,
																									)
																								}
																								className="icon eye-icon"
																							/>
																							<DeleteOutlined
																								onClick={() =>
																									handleRemoveFile(
																										ele,
																									)
																								}
																								className="icon download-icon"
																							/>
																						</div>
																						<Tooltip
																							title={
																								ele.document_name
																							}
																						>
																							<div
																								style={{
																									textAlign:
																										'center',
																									marginTop:
																										'5px',
																									fontSize:
																										'12px',
																									color: '#000',
																									whiteSpace:
																										'nowrap',
																									overflow:
																										'hidden',
																									textOverflow:
																										'ellipsis',
																									width: '100px', // or any other width as per requirement
																								}}
																							>
																								{
																									ele.document_name
																								}
																							</div>
																						</Tooltip>
																					</div>
																				</>
																			)}
																		</>
																	);
																},
															)}
														{supportingFiles.length >
														25
															? null
															: uploadSupportingDocButton(
																	supportingFiles.length,
																	false,
															  )}
													</Space>
												</XForm.Item>
											</XCardDesktop>
										</Col>
									</>
								)}

								<Col xs={24} className="mt-4">
									<XForm.Item
										className="addCaseLabel mb-0"
										name="clinical_category"
										// value={formData?.clinical_category}
										// label="Clinical Summary"
										rules={[
											{
												required: true,
												message:
													'Please select option!',
											},
										]}
									>
										<XRadio.Group
											defaultValue={
												clinicalSelectedOption
											}
											onChange={(e) => {
												handleInputChange(
													e,
													formData,
													setFormData,
												);
												if (
													e.target.value ===
													'clinical_text'
												) {
													setClinicalSelectedOption(
														'clinical_text',
													);
												} else if (
													e.target.value ===
													'clinical_document'
												) {
													setClinicalSelectedOption(
														'clinical_document',
													);
												}
											}}
											className="w-100"
										>
											<XRadio
												className="pb-2"
												value={'clinical_text'}
											>
												Enter Clinical Summary
											</XRadio>
											<XRadio
												className="pb-2"
												value={'clinical_document'}
											>
												Upload Clinical Summary
											</XRadio>
										</XRadio.Group>
									</XForm.Item>
								</Col>

								<Col xs={24} className="mt-3">
									{clinicalSelectedOption ===
										'clinical_text' && (
										<XForm.Item
											className="addCaseLabel mb-0"
											name="clinical_summary"
											rules={[
												{
													required: true,
													message:
														'Please enter clinical summary',
												},
											]}
										>
											<XInputTextArea.TextArea
												className="textAreaReclaimStyle"
												name="clinical_summary"
												// showCount
												autoSize={{
													minRows: 9,
												}}
												onChange={(e) => {
													handleInputChange(
														e,
														formData,
														setFormData,
													);
													setSummary(e.target.value);
													setClinicalDocumentFileList(
														[],
													);
													setUpdatedClinicalDocumentFileList(
														[],
													);
												}}
												placeholder="Enter Detailed Clinical Summary"
											/>
										</XForm.Item>
									)}
									{clinicalSelectedOption ===
										'clinical_document' && (
										<XCardDesktop className="bodyPadding h-auto">
											<XForm.Item
												className="mb-0"
												name="clinical_document"
												rules={[
													{
														required: true,
														validator: (
															_,
															value,
														) => {
															if (
																clinicalDocumentFileList.length ===
																0
															) {
																return Promise.reject(
																	new Error(
																		'Please upload Clinical Document!',
																	),
																);
															}
															return Promise.resolve();
														},
													},
												]}
											>
												<div className="uploadClinicalDocument">
													<Dragger
														style={{
															background: 'none',
														}}
														action={null}
														multiple={false}
														maxCount={1}
														showUploadList={{
															showPreviewIcon: true,
															showRemoveIcon: true,
														}}
														className="supporting_files"
														listType="picture-card"
														fileList={
															clinicalDocumentFileList
														}
														onPreview={
															handlePreviewClinicalSummary
														}
														onChange={
															handleClinicalDocumentChange
														}
														onRemove={(file) => {
															handleRemoveClinicalDocuments(
																file,
															);
															return false; // Prevent default removal behavior to avoid triggering onChange
														}}
														accept=".pdf"
														beforeUpload={(
															file,
														) => {
															const isJPEG =
																file.type ===
																'image/jpeg';
															const isJPG =
																file.type ===
																'image/jpg';
															const isPNG =
																file.type ===
																'image/png';
															const isGIF =
																file.type ===
																'image/gif';
															const isVideo =
																file.type.startsWith(
																	'video/',
																);
															const isAudio =
																file.type.startsWith(
																	'audio/',
																);

															const isLt10M =
																file.size /
																	1024 /
																	1024 <
																10;

															if (!isLt10M) {
																notification.error(
																	{
																		message:
																			'File Size Exceeded',
																		description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
																	},
																);
															}
															if (
																isGIF ||
																isVideo ||
																isAudio ||
																isJPEG ||
																isJPG ||
																isPNG
															) {
																notification.error(
																	{
																		message:
																			'File Type Error',
																		description: `GIF, video, image and audio files are not allowed.`,
																	},
																);
															}

															return (
																isLt10M &&
																!isGIF &&
																!isVideo &&
																!isAudio &&
																!isJPEG &&
																!isJPG &&
																!isPNG
															);
														}}
													>
														{clinicalDocumentFileList.length >=
														1 ? null : (
															<>
																<img
																	src="/images/reclaim-clinical-document2.svg"
																	alt=""
																/>
																<p className="uploadClinicalDocumentText mt-3">
																	Click file
																	to this area
																	to upload
																</p>
															</>
														)}
													</Dragger>
												</div>
											</XForm.Item>
										</XCardDesktop>
									)}
								</Col>

								<Col xs={24} className="text-center mt-4">
									<XShazaamBtn
										size="large"
										htmlType="submit"
										type="primary"
									>
										Shazaam !
									</XShazaamBtn>
								</Col>
							</Row>
						</XForm>
					</XContainer>
				</AipaMobileBody>
				{/* End Mobile design */}
			</div>

			<XContainer className="marginNagitive slideBox" fluid>
				<div
					id="getAns"
					className="slideUp top-100 d-none"
					style={{ marginTop: '100px' }}
				>
					{appealLetterLoading && (
						<XLottieOverlay>
							<XLottie
								animationData={AppealLoading}
								loop={true}
							/>
						</XLottieOverlay>
					)}
					{showAppealLetterPreview ? (
						<>
							<Row>
								<AppealLetter
									aipa={true}
									policyType={policyType}
									checkCoverage={true}
									appealLetterContent={appealLetterContent}
									appealLetterLoading={appealLetterLoading}
									showAppealLetterPreview={
										showAppealLetterPreview
									}
									setShowAppealLetterPreview={
										setShowAppealLetterPreview
									}
									setAppealLetterLoading={
										setAppealLetterLoading
									}
									previewAppealLetter={previewAppealLetter}
									id={id}
									forceGenerateAppealLetter={
										forceGenerateAppealLetter
									}
								/>
							</Row>
						</>
					) : (
						<Row gutter={(0, 30)}>
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								{/* new component start */}
								<CaseOperations
									policyType={policyType}
									aipa={true}
									checkCoverage={true}
									previewMode={policyType === 'commercial'}
									matchGender={''}
									loading={loading}
									summary={summary}
									state={state}
									provider={provider}
									setSummary={setSummary}
									handleRemoveClinicalDocuments={
										handleRemoveClinicalDocuments
									}
									handleClinicalSummaryDoc={
										handleClinicalSummaryDoc
									}
									isOpenAnalysis={'true'}
									patientData={''}
									mainDataLoading={''}
									handleClinicalDocsChange={
										handleClinicalDocsChange
									}
									clinicalDocumentFileList={
										clinicalDocumentFileList
									}
									clinicalDocsForm={clinicalDocsForm}
									handleUploadCancel={handleUploadCancel}
									clinicalDocsLoading={clinicalDocsLoading}
									setIsClinicalUploadModalOpen={
										setIsClinicalUploadModalOpen
									}
									isClinicalUploadModalOpen={
										isClinicalUploadModalOpen
									}
									setPreviewImage={setPreviewImage}
									previewImage={previewImage}
									setPreviewTitle={setPreviewTitle}
									previewTitle={previewTitle}
									setPdfPreviewOpen={setPdfPreviewOpen}
									pdfPreviewOpen={pdfPreviewOpen}
									documentsToDisplay={supportingFiles}
									supportingDocsSummary={
										supportingDocsSummary
									}
									clinicalSummaryTextLoading={
										clinicalSummaryTextLoading
									}
									setClinicalSummaryTextLoading={
										setClinicalSummaryTextLoading
									}
									setShowClinicalTextArea={
										setShowClinicalTextArea
									}
									showClinicalTextArea={showClinicalTextArea}
									setFormData={setFormData}
									formData={formData}
									id={id}
									setPageContent={setPageContent}
									pageContent={pageContent}
									clinicalDocumentsToDisplay={
										clinicalDocumentsToDisplay
									}
									feedbackId={feedbackId}
									reviseCoverage={reviseCoverage}
									lastAnalysisData={lastAnalysisData}
									setLoading={setLoading}
									getBase64={getBase64}
									setClinicalDocsLoading={
										setClinicalDocsLoading
									}
									setSubmitClinicalDocs={
										setSubmitClinicalDocs
									}
									setUpdatedClinicalDocumentFileList={''}
									form={form}
									setModalTextArea={setModalTextArea}
									setModalOpen={setModalOpen}
									modalOpen={modalOpen}
									sendFeedback={sendFeedback}
									sendFeedbackState={sendFeedbackState}
									modalTextArea={modalTextArea}
									updatePageContent={updatePageContent}
									missingFieldsForm={missingFieldsForm}
									missingFieldsCardRef={missingFieldsCardRef}
									handleAddOption={handleAddOption}
									missingFields={missingFields}
									missingFieldInstructions={
										missingFieldInstructions
									}
									setMissingFieldsModal={
										setMissingFieldsModal
									}
									setMissingFieldInstructions={
										setMissingFieldInstructions
									}
									setMissingFields={setMissingFields}
									missingFieldTitle={missingFieldTitle}
									missingFieldsModal={missingFieldsModal}
									setAppealLetterLoading={
										setAppealLetterLoading
									}
									forceGenerateAppealLetter={
										forceGenerateAppealLetter
									}
								/>
								{/* new component end */}

								{!missingFieldsModal ? (
									<>
										<div className="showMobile">
											<Row
												gutter={(15, 15)}
												className="mt-4"
											>
												<Col xs={12}>
													<XButtonConfirm
														className="btnRefine w-100"
														type="primary"
														size="large"
														shape="round"
														style={{
															background: '#fff',
														}}
														icon={
															<img
																src={
																	isCheckCoveragePage
																		? '/images/reclaim-like-icon.svg'
																		: '/images/like-icon.svg'
																}
																className="rotateButton"
																alt=""
															/>
														}
														onClick={() => {
															form.setFieldsValue(
																{
																	modal_text_area:
																		pageContent.medicalCoverage
																			? pageContent
																					.medicalCoverage[1]
																			: '',
																	medicare_flag:
																		pageContent.medicalCoverage
																			? pageContent
																					.medicalCoverage[0]
																			: '',
																},
															);
															setModalTextArea(
																pageContent
																	.medicalCoverage[1],
															);
															setModalOpen([
																true,
																`Edit Medicare Coverage`,
																pageContent
																	.medicalCoverage[1],
																1,
																'feedbackSummary',
															]);
															setCallGetAnalysisAPiBtn(
																true,
															);
														}}
													>
														Refine
													</XButtonConfirm>
												</Col>
												<Col xs={12}>
													<XButtonConfirm
														className="btnRefine w-100"
														type="primary"
														size="large"
														shape="round"
														style={{
															background: '#fff',
														}}
														icon={
															<img
																src={
																	isCheckCoveragePage
																		? '/images/reclaim-like-icon.svg'
																		: '/images/like-icon.svg'
																}
																alt=""
															/>
														}
														loading={
															LooksGoodLoading
														}
														onClick={() => {
															setLooksGoodLoading(
																true,
															);
															setCallGetAnalysisAPiBtn(
																true,
															);
															sendFeedback({
																feedback_id:
																	feedbackId,
																feedback:
																	'positive',
																status: 'approved',
																response:
																	pageContent.medicalCoverage,
															});
														}}
													>
														Looks Good
													</XButtonConfirm>
												</Col>
												{pageContent
													.medicalCoverage[0] ===
													'Yes' && (
													<>
														<Col
															xs={24}
															className="mt-4"
														>
															<XShazaamBtn
																className="w-100"
																onClick={() => {
																	setAppealLetterLoading(
																		true,
																	);
																	// setLoading(true);
																	previewAppealLetter(
																		'pre',
																		lastAnalysisData.appeal_letter_generated,
																	);
																}}
																type="primary"
																shape="round"
																size="large"
															>
																Generate Appeal
																Letter
															</XShazaamBtn>
														</Col>
													</>
												)}
											</Row>
										</div>
										<div className="showDesktop">
											<Row className="text-end">
												<Col
													xs={24}
													lg={12}
													className="mt-4"
												>
													<Space
														style={{
															width: '100%',
														}}
													>
														<XButtonConfirm
															className="mb-2 mb-md-0 mb-sm-0"
															type="primary"
															size="large"
															shape="round"
															style={{
																background:
																	'#fff',
															}}
															icon={
																<img
																	src={
																		isCheckCoveragePage
																			? '/images/reclaim-like-icon.svg'
																			: '/images/like-icon.svg'
																	}
																	className="rotateButton"
																	alt=""
																/>
															}
															onClick={() => {
																form.setFieldsValue(
																	{
																		modal_text_area:
																			pageContent.medicalCoverage
																				? pageContent
																						.medicalCoverage[1]
																				: '',
																		medicare_flag:
																			pageContent.medicalCoverage
																				? pageContent
																						.medicalCoverage[0]
																				: '',
																	},
																);
																setModalTextArea(
																	pageContent
																		.medicalCoverage[1],
																);
																setModalOpen([
																	true,
																	`Edit Medicare Coverage`,
																	pageContent
																		.medicalCoverage[1],
																	1,
																	'feedbackSummary',
																]);
																setCallGetAnalysisAPiBtn(
																	true,
																);
															}}
														>
															Refine
														</XButtonConfirm>
														<XButtonConfirm
															className="mb-2 mb-md-0 mb-sm-0"
															type="primary"
															size="large"
															shape="round"
															style={{
																background:
																	'#fff',
															}}
															icon={
																<img
																	src={
																		isCheckCoveragePage
																			? '/images/reclaim-like-icon.svg'
																			: '/images/like-icon.svg'
																	}
																	alt=""
																/>
															}
															loading={
																LooksGoodLoading
															}
															onClick={() => {
																setLooksGoodLoading(
																	true,
																);
																setCallGetAnalysisAPiBtn(
																	true,
																);
																sendFeedback({
																	feedback_id:
																		feedbackId,
																	feedback:
																		'positive',
																	status: 'approved',
																	response:
																		pageContent.medicalCoverage,
																});
															}}
														>
															Looks Good
														</XButtonConfirm>
													</Space>
												</Col>
												{pageContent
													.medicalCoverage[0] ===
													'Yes' && (
													<>
														<Col
															xs={24}
															lg={12}
															className="mt-4 text-md-end"
														>
															<XButtonConfirm
																onClick={() => {
																	setAppealLetterLoading(
																		true,
																	);
																	if (
																		policyType ===
																			'commercial' &&
																		!lastAnalysisData.appeal_letter_generated
																	) {
																		previewAppealLetter(
																			'pre',
																			false,
																			true,
																		);
																	} else {
																		previewAppealLetter(
																			'pre',
																			lastAnalysisData.appeal_letter_generated,
																		);
																	}
																}}
																type="primary"
																shape="round"
																size="large"
																className="btnActive"
															>
																Generate Appeal
																Letter
															</XButtonConfirm>
														</Col>
													</>
												)}
											</Row>
										</div>
									</>
								) : (
									<></>
								)}
							</Col>
						</Row>
					)}
				</div>
			</XContainer>

			<XModal
				centered
				open={thanksModal[0]}
				closable={false} // Hide the close icon
				// onOk={() => setThanksModal([false, ''])}
				// onCancel={() => {
				// 	setThanksModal([false, '']);
				// }}
				className="modalButton"
				okText="Submit"
				footer={null}
			>
				<Row
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}
					gutter={(15, 0)}
					className="mt-2"
				>
					<Col xs={24} lg={18}>
						<XLottieMargin>
							<XLottie animationData={Thanks} loop={true} />
						</XLottieMargin>

						<XPara1
							style={{ textAlign: 'center' }}
							className="mt-0 mb-4 "
						>
							{thanksModal[1]}
						</XPara1>
					</Col>
				</Row>
			</XModal>

			<Modal
				title="Upload Insurance Policy Document"
				open={isPolicyUploadModalOpen}
				onOk={handlePolicyDocSubmit}
				onCancel={handleUploadCancel}
				okButtonProps={{
					style: {
						backgroundColor: '#007299',
						borderColor: '#007299',
						color: '#fff',
						borderRadius: '20px',
					},
				}}
				cancelButtonProps={{
					style: { color: '#007299', borderRadius: '20px' },
				}}
			>
				<XForm form={formUpload} layout="vertical" autoComplete="off">
					<XForm.Item
						name="policy_document"
						rules={[
							{
								required: true,
								validator: (_, value) => {
									if (policyDoc.length === 0) {
										return Promise.reject(
											new Error('Please Select file!'),
										);
									}
									return Promise.resolve();
								},
							},
						]}
					>
						<Dragger
							fileList={policyDoc}
							maxCount={1}
							beforeUpload={(file) => {
								const isGIF = file.type === 'image/gif';
								const isVideo = file.type.startsWith('video/');
								const isAudio = file.type.startsWith('audio/');
								const isJPEG = file.type === 'image/jpeg';
								const isJPG = file.type === 'image/jpg';
								const isPNG = file.type === 'image/png';
								const isLt10M = file.size / 1024 / 1024 < 10;

								if (!isLt10M) {
									notification.error({
										message: 'File Size Exceeded',
										description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
									});
								}
								if (
									isGIF ||
									isVideo ||
									isAudio ||
									isJPEG ||
									isJPG ||
									isPNG
								) {
									notification.error({
										message: 'File Type Error',
										description: `GIF, video, image and audio files are not allowed.`,
									});
								}

								return (
									isLt10M &&
									!isGIF &&
									!isVideo &&
									!isAudio &&
									!isJPEG &&
									!isJPG &&
									!isPNG
								);
							}}
							// accept=".pdf,.jpeg,.jpg,.png"
							accept=".pdf"
							onChange={handlePolicyDocChange}
						>
							<p className="ant-upload-drag-icon">
								<InboxOutlined style={{ color: '#007299' }} />
							</p>
							<p className="ant-upload-text">
								Click to upload your case file.
							</p>
						</Dragger>
					</XForm.Item>
				</XForm>
			</Modal>

			<Modal
				title="Upload Supporting Documents"
				open={isUploadModalOpen}
				onOk={handleSupportingDocSubmit}
				onCancel={handleUploadCancel}
				okButtonProps={{
					style: {
						backgroundColor: '#007299',
						borderColor: '#007299',
						color: '#fff',
						borderRadius: '20px',
					},
				}}
				cancelButtonProps={{
					style: { color: '#007299', borderRadius: '20px' },
				}}
			>
				<XForm
					form={formSupportingUpload}
					layout="vertical"
					autoComplete="off"
				>
					<XForm.Item
						name="Supporting_docs"
						rules={[
							{
								required: true,
								validator: (_, value) => {
									if (supportingDoc.length === 0) {
										return Promise.reject(
											new Error('Please Select file!'),
										);
									}
									return Promise.resolve();
								},
							},
						]}
					>
						<Dragger
							fileList={supportingDoc}
							maxCount={1}
							beforeUpload={(file) => {
								const isGIF = file.type === 'image/gif';
								const isVideo = file.type.startsWith('video/');
								const isAudio = file.type.startsWith('audio/');
								const isJPEG = file.type === 'image/jpeg';
								const isJPG = file.type === 'image/jpg';
								const isPNG = file.type === 'image/png';
								const isLt10M = file.size / 1024 / 1024 < 10;

								if (!isLt10M) {
									notification.error({
										message: 'File Size Exceeded',
										description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
									});
								}
								if (
									isGIF ||
									isVideo ||
									isAudio ||
									isJPEG ||
									isJPG ||
									isPNG
								) {
									notification.error({
										message: 'File Type Error',
										description: `GIF, video, image and audio files are not allowed.`,
									});
								}

								return (
									isLt10M &&
									!isGIF &&
									!isVideo &&
									!isAudio &&
									!isJPEG &&
									!isJPG &&
									!isPNG
								);
							}}
							// accept=".pdf,.jpeg,.jpg,.png"
							accept=".pdf"
							onChange={handleSupportingDocChange}
						>
							<p className="ant-upload-drag-icon">
								<InboxOutlined style={{ color: '#007299' }} />
							</p>
							<p className="ant-upload-text">
								Click or drag file to this area to upload
							</p>
						</Dragger>
					</XForm.Item>
					<XForm.Item
						name="document_name"
						label="Document Name"
						className="mt-3"
						rules={[
							{
								required: true,
								message: 'Document Name is required',
							},
						]}
					>
						<Input
							placeholder="Enter Document Name"
							// value={documentName}
							name="document_name"
							onChange={(e) => {
								setDocumentName(e.target.value);
							}}
						/>
					</XForm.Item>
				</XForm>
			</Modal>

			{/* Modal for adding new option */}
			<XModal
				title="Add New Option"
				open={newCheckBoxModal[0]}
				onOk={handleNewCheckBoxModal}
				okText="Submit"
				okButtonProps={{
					style: {
						backgroundColor: '#007299',
						borderColor: '#007299',
						color: '#fff',
						borderRadius: '20px',
					},
				}}
				onCancel={() => {
					setNewCheckBoxModal([false, null]);
					setNewOptionText('');
					newOptionForm.setFieldsValue({});
					newOptionForm.resetFields();
				}}
			>
				<XForm
					form={newOptionForm}
					name="groupForm"
					layout="vertical"
					autoComplete="off"
				>
					<XForm.Item
						name={'new-option'}
						rules={[
							{
								required: true,
								message: 'Please input option',
							},
						]}
					>
						<Input
							size="large"
							className="mt-3 mb-3"
							value={newOptionText}
							onChange={(e) => setNewOptionText(e.target.value)}
							placeholder="Enter new option text"
						/>
					</XForm.Item>
				</XForm>
			</XModal>
		</>
	);
};

const mapStateToProps = (state) => ({
	analyseCoverageState: state.analyseCoverage,
	reviseCoverageState: state.reviseCoverage,
	sendFeedbackState: state.sendFeedback,
	getStateDropdownState: state.getStateDropdown,
	getAnalysisState: state.getAnalysis,
	getAppealLetterState: state.getAppealLetter,
});

const mapDispatchToProps = (dispatch) => ({
	analyseCoverage: (params) => dispatch(analyseCoverageApi(params)),
	analyseCoverageReset: () => dispatch(analyseCoverageReset()),
	reviseCoverage: (params) => dispatch(reviseCoverageApi(params)),
	reviseCoverageReset: () => dispatch(reviseCoverageReset()),
	sendFeedback: (params) => dispatch(sendFeedbackApi(params)),
	sendFeedbackReset: () => dispatch(sendFeedbackReset()),
	getStateDropdown: () => dispatch(getStateDropdownApi()),
	getStateDropdownReset: () => dispatch(getStateDropdownReset()),
	getAnalysis: (params) => dispatch(getAnalysisApi(params)),
	getAnalysisReset: () => dispatch(getAnalysisReset()),
	getAppealLetter: (params) => dispatch(getAppealLetterApi(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Policy);
