import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, notification } from 'antd';
import { Logo, Title, XForm, XLoginBTN, XLottie } from '../Login/LoginStyle';
import { CenterWrapper, XPara1 } from './ForgotEmailPasswordStyle';
import { Button, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import LoginAnimation from '../../json/Login.json';
import { XContainer } from '../../styles/GlobalStyle';
import Navbar from 'react-bootstrap/Navbar';
import { handleInputChange } from '../../utils/formFunctions';
import animationData from '../../json/new-reclaim-login.json';

// Actions
import {
	sendPasswordResetEmailApi,
	sendPasswordResetEmailReset,
} from '../../actions/sendPasswordResetEmailAction';

const Forgot = (props) => {
	const {
		sendPasswordResetEmail,
		sendPasswordResetEmailReset,
		sendPasswordResetEmailState,
	} = props;
	const [form] = XForm.useForm();
	const [formData, setFormData] = useState({});
	const navigate = useNavigate();

	useEffect(() => {
		if (sendPasswordResetEmailState.apiState === 'success') {
			localStorage.setItem('email', formData.email);
			sendPasswordResetEmailReset();
			navigate('/verify-otp');
		} else if (sendPasswordResetEmailState.apiState === 'error') {
			notification.error({
				message: sendPasswordResetEmailState.message,
			});
			sendPasswordResetEmailReset();
		}
	}, [sendPasswordResetEmailState]);

	useEffect(() => {
		// Add the class to the body tag
		document.body.classList.add('reclaimBody');

		// Clean up to remove the class when the component unmounts
		return () => {
			document.body.classList.remove('reclaimBody');
		};
	}, []);

	return (
		<XContainer fluid>
			<Row className="showDesktop">
				<Col xs={24}>
					<Navbar.Brand href="/">
						<Logo>
							<img
								className="img-fluid"
								src="./images/reclaim-login-logo.svg"
								alt="logo"
							/>
						</Logo>
					</Navbar.Brand>
				</Col>
			</Row>
			<CenterWrapper>
				<Row className="showMobile mb-5">
					<Col xs={24}>
						<Logo>
							<img
								className="img-fluid"
								src="./images/reclaim-login-logo.svg"
								alt="logo"
							/>
						</Logo>
					</Col>
				</Row>
				<Row className="align-items-center">
					<Col
						xs={{ span: 24, order: 2 }}
						sm={{ span: 24, order: 2 }}
						lg={{ span: 8, order: 1 }}
					>
						{/* <Title className="mb-2">
							Forgot password
						</Title> */}
						<h1 className="mb-2" style={{ color: '#0e4c88' }}>
							Forgot password
						</h1>
						<XPara1 className="mb-4 mb-lg-5 text-left">
							Enter the email address associated with your account
							and we'll send you a link to reset your password
						</XPara1>

						<XForm
							form={form}
							name="loginForm"
							layout="vertical"
							autoComplete="off"
							onFinish={() => sendPasswordResetEmail(formData)}
						>
							<XForm.Item
								className="loginInputsLbl"
								name="email"
								label="Email Address"
								rules={[
									{
										required: true,
										message: 'Please enter email address',
									},
								]}
							>
								<Input
									size="large"
									name="email"
									placeholder="Enter Email Address"
									onChange={(e) =>
										handleInputChange(
											e,
											formData,
											setFormData,
										)
									}
								/>
							</XForm.Item>

							<XForm.Item className="mt-4">
								<XLoginBTN htmlType="submit">
									Send OTP on Email
								</XLoginBTN>
							</XForm.Item>
						</XForm>
					</Col>
					<Col
						xs={{ span: 24, order: 1 }}
						sm={{ span: 24, order: 1 }}
						lg={{ span: 10, offset: 6, order: 2 }}
						className="text-center showDesktop"
					>
						{/* <XLottie animationData={LoginAnimation} loop={true} /> */}
						<XLottie
							animationData={animationData}
							loop={true}
							style={{ width: '40vw', height: 'auto' }}
						/>
					</Col>
				</Row>
			</CenterWrapper>
		</XContainer>
	);
};

const mapStateToProps = (state) => ({
	sendPasswordResetEmailState: state.sendPasswordResetEmail,
});

const mapDispatchToProps = (dispatch) => ({
	sendPasswordResetEmail: (params) =>
		dispatch(sendPasswordResetEmailApi(params)),
	sendPasswordResetEmailReset: () => dispatch(sendPasswordResetEmailReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
