import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment';
import {
	Row,
	Col,
	Input,
	notification,
	Upload,
	// Timeline,
	Result,
	Tooltip,
	Tag,
	Select,
	Modal,
	InputNumber,
	Card,
	DatePicker,
	Avatar,
	Spin,
	Image,
	Affix,
	Checkbox,
	Radio,
	List,
	Skeleton,
	FloatButton,
	Divider,
} from 'antd';
import axios from 'axios';
import AppealLoading from '../../json/reloading.json';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
	XContainer,
	XForm,
	XModal,
	XAlertBar,
	XButton,
	XTag,
	XCardsCriteriaBg,
	XCardsTransparent,
	XAffix,
	XSelect,
	XRoundButton,
	XList,
	XCircle,
	XLabel,
	XModalTitle,
	XModalSubtitle,
	ChatButton,
	ChatBoxWrap,
	XShareButton,
} from '../../styles/GlobalStyle';
import {
	ArrowLeftOutlined,
	WarningOutlined,
	PlusOutlined,
	EditOutlined,
	FileTextOutlined,
	FileImageOutlined,
	UserOutlined,
	AntDesignOutlined,
	DeleteOutlined,
	CloseOutlined,
	FilePdfOutlined,
	InboxOutlined,
	EyeOutlined,
	LoadingOutlined,
	FileSyncOutlined,
} from '@ant-design/icons';
import HeaderFile from '../../components/Header/HeaderFile';
import Header from '../../components/Header/Header';
import ChatBox from '../../components/Chat/Chat';
import {
	XCardPreview,
	XCardInner,
	XTabs,
	XRadio,
} from '../../pages/CaseFilings/AppealLetterPreviewStyle';
import {
	XButtonConfirm,
	XButtonLike,
	XDivider,
	XLottieOverlay,
	XPara1,
	XRecommendedCard,
} from '../../pages/AnalyseCoverage/AnalyseCoverageStyle';
import {
	ApprovHeading,
	CaseTimelineTitle,
	ResponseHeading,
	XAddCodeSticky,
	XButtonBack,
	XCardBackBg,
	XEditTitle,
} from '../../pages/CaseForm/CaseFormStyle';
import {
	getAppealLetterApi,
	getAppealLetterReset,
} from '../../actions/getAppealLetterAction';
import { editCaseApi, editCaseReset } from '../../actions/editCaseAction';
import {
	getVerifyApproveAppealLetterUserListReset,
	getVerifyApproveAppealLetterUserListApi,
} from '../../actions/getVerifyApproveAppealLetterUserListAction';
import {
	saveAppealLetterApi,
	saveAppealLetterReset,
} from '../../actions/saveAppealLetterAction';
import { handleInputChange } from '../../utils/formFunctions';
import {
	getAnalysisApi,
	getAnalysisReset,
} from '../../actions/getAnalysisAction';
import {
	checkModulePermissions,
	getUserToken,
	toTitleCase,
} from '../../utils/helper';
import config from '../../config';
import DownloadAnimation from '../../json/download.json';
import DrawerCodingTools from '../../components/CodingTools/DrawerCodingTools';
import { XButtonImg } from '../../pages/Manage/ManageStyle';
import {
	generateAppealLetterApi,
	generateAppealLetterReset,
} from '../../actions/generateAppealLetterAction';
import { XLottie } from '../../pages/Login/LoginStyle';
const { TextArea } = Input;
const { Dragger } = Upload;

const AppealLetter = (props) => {
	const location = useLocation();
	// Get the id from the URL
	const {
		getAppealLetter,
		getAppealLetterState,
		getAppealLetterReset,
		editCase,
		editCaseState,
		editCaseReset,
		getVerifyApproveAppealLetterUserList,
		getVerifyApproveAppealLetterUserListState,
		getVerifyApproveAppealLetterUserListReset,
		getAnalysis,
		getAnalysisState,
		getAnalysisReset,
		saveAppealLetter,
		saveAppealLetterState,
		saveAppealLetterReset,
		aipa,
		previewAppealLetter,
		appealLetterContent,
		patientDetails,
		setAppealLetterLoading,
		setShowAppealLetterPreview,
		showAppealLetterPreview,
		appealLetterLoading,
		generateAppealLetter,
		generateAppealLetterState,
		generateAppealLetterReset,
		id,
		checkCoverage,
		forceGenerateAppealLetter,
		policyType
	} = props;
	const navigate = useNavigate();
	const [downloadLoading, setDownloadLoading] = useState(false);
	const [patientData, setPatientData] = useState(patientDetails || {});
	// for send appeal letter
	const [displayFaxUpload, setDisplayFaxUpload] = useState(false);
	const [displayEmailContent, setDisplayEmailContent] = useState(false);
	const [letterDownloaded, setLetterDownloaded] = useState(false);
	const [successModal, setSuccessModal] = useState(false);
	const [sendLetterLoading, setSendLetterLoading] = useState(false);

	const handleEmailSend = () => {
		const subject = `Case Details: ${patientData.patient_first_name} ${patientData.patient_last_name} (MRN: ${patientData.mrn})`;
		const body = `Hi,\n\nI hope this email finds you well. Below are the case details regarding denials made by ${
			patientData.insurance_company?.name || ''
		}.\n\nMRN: ${patientData.mrn}\nName: ${
			patientData.patient_first_name
		} ${patientData.patient_last_name}\nAge: ${moment(
			patientData.date_of_birth,
		).fromNow(true)},\nGender: ${
			patientData.gender ? toTitleCase(patientData.gender) : ''
		}\nDOB: ${patientData.date_of_birth ? '' : ''}\nState: ${
			patientData.state
		}\nInsurance Company: ${
			patientData.insurance_company?.name || '-'
		}\n\nClinical Summary: ${patientData.clinical_summary}.`;
		const mailtoLink = `mailto:?subject=${encodeURIComponent(
			subject,
		)}&body=${encodeURIComponent(body)}`;
		// Open the email client
		window.open(mailtoLink);
		setDownloadLoading(false);
		setLetterDownloaded(false);
		setDisplayEmailContent(false);
		setDisplayFaxUpload(false);
		setAppealLetterLoading(false);
		setSendLetterLoading(false);
		// navigate(-1);
		generateAppealLetterReset();
	};

	useEffect(() => {
		if (generateAppealLetterState.apiState === 'success') {
			notification.success({
				message: 'Appeal Letter downloaded',
			});
			if (displayEmailContent) {
				setDisplayEmailContent(true);
				setLetterDownloaded(true);
				setDownloadLoading(false);
				setAppealLetterLoading(false);
				setSendLetterLoading(false);
			} else {
				setDownloadLoading(false);
				setLetterDownloaded(false);
				setDisplayEmailContent(false);
				setDisplayFaxUpload(false);
				setAppealLetterLoading(false);
				setSendLetterLoading(false);
			}
			setDownloadLoading(false);
		} else if (generateAppealLetterState.apiState === 'error') {
			setDownloadLoading(false);
			setLetterDownloaded(false);
			setDisplayEmailContent(false);
			setDisplayFaxUpload(false);
			notification.error({
				message: 'Unable to downlaod Appeal Letter',
			});
		}
		generateAppealLetterReset();
	}, [generateAppealLetterState.apiState]);

	return (
		<>
			{downloadLoading && (
				<XLottieOverlay>
					<XLottie animationData={DownloadAnimation} loop={true} />
				</XLottieOverlay>
			)}
			<XCardBackBg className="mb-2 w-100" bodyStyle={{ padding: 10 }}>
				<Row>
					<Col xs={20}>
						<XButtonBack
							onClick={() => setShowAppealLetterPreview(false)}
							icon={<ArrowLeftOutlined />}
							size="midium"
						/>
						<XEditTitle>Back</XEditTitle>
					</Col>
				</Row>
			</XCardBackBg>
			<Row gutter={(0, 30)}>
				<Col xs={24} sm={24} md={24} lg={14} xl={16}>
					<XCardPreview>
						<div
							style={{
								// height: '700px',
								overflowX: 'hidden',
								// overflowY: 'auto',
								textAlign: 'justify',
								padding: '0 5px',
							}}
							className="appeal-letter-modal"
						>
							<div
								dangerouslySetInnerHTML={{
									__html: appealLetterContent,
								}}
							/>
							<br />
						</div>
						{/* footer start */}
					</XCardPreview>
				</Col>
				<Col
					xs={24}
					sm={24}
					md={24}
					lg={10}
					xl={8}
					className="mt-4 mt-lg-0"
				>
					<XAffix offsetTop={20}>
						<XCardBackBg>
							{/* new code start */}
								<Row gutter={[15, 15]}>
									<Col xs={24}>
									<XRecommendedCard
										role="button"
										className="shareModal mt-2"
										onClick={() => {
											setAppealLetterLoading(true);
											// setLoading(true);
											if(policyType === 'commercial'){
												previewAppealLetter('pre', false,true);
											}else{
												previewAppealLetter('pre', false);
											}
										}}
									>
										<Row className="align-items-center">
											<Col className="text-center" xs={4}>
												<FileSyncOutlined
													style={{
														fontSize: '25px',
													}}
												/>
											</Col>
											<Col xs={20}>
												<p className="m-0">
													Re-Generate Appeal Letter
												</p>
											</Col>
										</Row>
									</XRecommendedCard>
								</Col>
								</Row>

							<Row gutter={[15, 15]}>
								<Col xs={24}>
									{displayEmailContent ? (
										<XRecommendedCard
											role="button"
											className="shareModalActive"
											onClick={() => {
												setDisplayFaxUpload(false);
												if (!letterDownloaded) {
													generateAppealLetter({
														aipa: true,
														html: appealLetterContent,
													});
													setDownloadLoading(true);
													setDisplayEmailContent(
														true,
													);
													// setLetterDownloaded(true);
												}
											}}
										>
											<Row className="align-items-center">
												<Col
													className="text-center"
													xs={4}
												>
													<img
														src="/images/envelope 1 copy.svg"
														alt="envelope"
													/>
												</Col>
												<Col xs={20}>
													<p className="m-0">
														Send Via Email
													</p>
												</Col>
											</Row>
										</XRecommendedCard>
									) : (
										<XRecommendedCard
											role="button"
											className="shareModal"
											onClick={() => {
												setDisplayFaxUpload(false);
												if (!letterDownloaded)
													generateAppealLetter({
														aipa: true,
														html: appealLetterContent,
													});
												setDownloadLoading(true);
												setDisplayEmailContent(true);
												// setLetterDownloaded(true);
											}}
										>
											<Row className="align-items-center">
												<Col
													className="text-center"
													xs={4}
												>
													<img
														src="/images/envelope 1.svg"
														alt="emvelope"
													/>
												</Col>
												<Col xs={20}>
													<p className="m-0">
														Send Via Email
													</p>
												</Col>
											</Row>
										</XRecommendedCard>
									)}
								</Col>
							</Row>
							<Row gutter={[15, 15]}>
								<Col xs={24}>
									{displayFaxUpload ? (
										<XRecommendedCard
											role="button"
											className="shareModalActive mt-2"
											onClick={() => {
												setDisplayEmailContent(false);
												setDisplayFaxUpload(true);
												setLetterDownloaded(false);
											}}
										>
											<Row className="align-items-center">
												<Col
													className="text-center"
													xs={4}
												>
													<img
														src="/images/fax-active.svg"
														alt="fax"
													/>
												</Col>
												<Col xs={20}>
													<p className="m-0">
														Share Via Fax
													</p>
												</Col>
											</Row>
										</XRecommendedCard>
									) : (
										<XRecommendedCard
											role="button"
											className="shareModal mt-2"
											onClick={() => {
												setDisplayEmailContent(false);
												setDisplayFaxUpload(true);
												setLetterDownloaded(false);
											}}
										>
											<Row className="align-items-center">
												<Col
													className="text-center"
													xs={4}
												>
													<img
														src="/images/fax 1.svg"
														alt="fax"
													/>
												</Col>
												<Col xs={20}>
													<p className="m-0">
														Share Via Fax
													</p>
												</Col>
											</Row>
										</XRecommendedCard>
									)}
								</Col>
							</Row>
							<Row gutter={[15, 15]}>
								<Col xs={24}>
									<XRecommendedCard
										role="button"
										className="shareModal mt-2"
										onClick={() => {
											setDisplayFaxUpload(false);
											setDisplayEmailContent(false);
											generateAppealLetter({
												aipa: true,
												html: appealLetterContent,
											});
											setDownloadLoading(true);
										}}
									>
										<Row className="align-items-center">
											<Col className="text-center" xs={4}>
												{sendLetterLoading &&
												!displayFaxUpload &&
												!displayEmailContent ? (
													<Spin
														indicator={
															<LoadingOutlined
																spin
															/>
														}
														size="small"
													/>
												) : (
													<img
														src="/images/download 1.svg"
														alt="download"
													/>
												)}
											</Col>
											<Col xs={20}>
												<p className="m-0">
													Download PDF
												</p>
											</Col>
										</Row>
									</XRecommendedCard>
								</Col>
							</Row>
							{displayFaxUpload ? (
								<div className="mt-3">
									<Dragger showUploadList={false}>
										<img
											src="/images/file-upload 1.svg"
											style={{
												marginBottom: '8px',
											}}
											alt="file upload"
										/>
										<p
											style={{
												color: '#ACACAC',
												fontFamily: 'Poppins',
												fontSize: '14px',
												fontStyle: 'normal',
												fontWeight: '500',
												lineHeight: '20px',
												letterSpacing: '0.28px',
											}}
										>
											Upload Acknowledgement
										</p>
									</Dragger>
									<XForm.Item className="text-right mt-3">
										<XShareButton
											loading={sendLetterLoading}
											style={{
												background: '#212226',
											}}
											onClick={() => {
												setLetterDownloaded(false);
												setDisplayEmailContent(false);
												setDisplayFaxUpload(false);
											}}
										>
											Send
										</XShareButton>
									</XForm.Item>
								</div>
							) : (
								''
							)}
							{displayEmailContent && letterDownloaded ? (
								<div className="mt-3">
									<p>
										The letter has been downloaded. Please
										ensure that you do not overlook
										attaching the document in the email you
										are about to send.
									</p>
									<XForm.Item className="text-right mt-3">
										<XShareButton
											loading={sendLetterLoading}
											style={{
												background: '#212226',
											}}
											onClick={() => {
												setSendLetterLoading(true);
												handleEmailSend();
											}}
										>
											Send Email
										</XShareButton>
									</XForm.Item>
								</div>
							) : (
								''
							)}
							{/* new code end */}
						</XCardBackBg>
					</XAffix>
				</Col>
			</Row>

			{/* edit appeal letter modal */}
			{/* <Modal
				title="Edit Appeal Letter"
				open={showEditAppealLetter}
				okText="Save and Preview"
				// loading={appealLetterLoading}
				onOk={saveAndPreviewAppealLetter}
				width={900}
				okButtonProps={{
					style: {
						background: 'black',
					},
					loading: editAppealLetterLoading, // Show loading state on the button
				}}
				onCancel={closeEditAppealLetter}
			>
				<Editor
					apiKey={config.tiny_url_key}
					onInit={(evt, editor) => (editorRef.current = editor)}
					init={{
						menubar: false,
						plugins: ['code', 'image', 'link', 'visualblocks'],
						branding: false,
						toolbar:
							'undo redo | formatselect | ' +
							'bold italic backcolor | alignleft aligncenter alignright alignjustify | blocks fontfamily fontsize | ' +
							'bullist numlist outdent indent | image link |' +
							'removeformat | visualblocks code | help ',
						content_style:
							'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
						font_size_formats: '8px 10px 12px 14px 18px 24px 36px',
					}}
					initialValue={appealLetterContent}
				/>
			</Modal> */}
			{/* fax success modal */}
			<XModal
				centered
				open={successModal}
				onOk={() => setSuccessModal(false)}
				onCancel={() => {
					navigate(-1);
					setSuccessModal(false);
				}}
				className="modalButton"
				okText="Submit"
				footer={null}
				width={400}
			>
				<Row gutter={(15, 0)} className="mt-4">
					<Col
						xs={24}
						lg={{ span: 18, offset: 3 }}
						className="text-center"
					>
						<img
							src="/images/fax-success.svg"
							style={{ marginBottom: '8px' }}
							alt="fax"
						/>

						<XPara1 className="mt-5 mb-4">
							The letter has been successfully sent via fax.
						</XPara1>
					</Col>
				</Row>
			</XModal>
		</>
	);
};

const mapStateToProps = (state) => ({
	getAppealLetterState: state.getAppealLetter,
	editCaseState: state.editCase,
	getVerifyApproveAppealLetterUserListState:
		state.getVerifyApproveAppealLetterUserList,
	getAnalysisState: state.getAnalysis,
	saveAppealLetterState: state.saveAppealLetter,
	generateAppealLetterState: state.generateAppealLetter,
});

const mapDispatchToProps = (dispatch) => ({
	getAppealLetter: (params) => dispatch(getAppealLetterApi(params)),
	getAppealLetterReset: (params) => dispatch(getAppealLetterReset(params)),
	editCase: (params) => dispatch(editCaseApi(params)),
	editCaseReset: (params) => dispatch(editCaseReset(params)),
	getVerifyApproveAppealLetterUserList: (params) =>
		dispatch(getVerifyApproveAppealLetterUserListApi(params)),
	getVerifyApproveAppealLetterUserListReset: () =>
		dispatch(getVerifyApproveAppealLetterUserListReset()),
	getAnalysis: (params) => dispatch(getAnalysisApi(params)),
	getAnalysisReset: (params) => dispatch(getAnalysisReset(params)),
	saveAppealLetter: (params) => dispatch(saveAppealLetterApi(params)),
	saveAppealLetterReset: (params) => dispatch(saveAppealLetterReset(params)),
	generateAppealLetter: (params) => dispatch(generateAppealLetterApi(params)),
	generateAppealLetterReset: () => dispatch(generateAppealLetterReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppealLetter);
