import { Button, Card, Pagination, Affix, List, Tabs, Steps } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import Container from 'react-bootstrap/Container';
import {
	Layout,
	Table,
	Tag,
	Dropdown,
	Modal,
	Form,
	Select,
	Switch,
	AutoComplete,
	Input,
	Upload,
} from 'antd';
const { Search } = Input;

export const Theme = {
	colors: {
		white: '#fff',
		menu1: '#a7a7a7',
		menu2: '#212226',
		color1: '#757575',
		color2: '#081D16',
		color3: '#d0e0df',
		color4: '#7f8e89',
		color5: '#f8f8f8',
		color6: '#dadee3',
		color7: '#11263c',
		color8: '#868FA0',
		color9: '#687182',
		color10: '#9B68BF',
		color11: '#212226',
		color12: '#57636F',
		fontPoppins: "'Poppins', sans-serif !important ",
		fontDM: "'DM Sans', sans-serif !important ",
		fontInter: "'Inter', sans-serif !important ",
	},
	media: { mobile: '768px', tab: '998px' },
};
export const XLayout = styled(Layout)`
	background: none !important;
	margin-bottom: 15px;
`;
export const XPagination = styled(Pagination)`
	display: block !important;
	.ant-pagination-prev,
	.ant-pagination-next {
		background: #fff;
	}
	.ant-pagination-prev,
	.ant-pagination-next,
	.ant-pagination-disabled {
		border: 1px solid #bebebe;
		min-width: 20px;
		height: 20px;
		line-height: 10px;
	}

	.ant-pagination-disabled,
	.ant-pagination-prev,
	.ant-pagination-next {
		span svg {
			font-size: 10px;
		}
	}

	.ant-pagination-item {
		min-width: 20px;
		border: 1px solid #bebebe;
		height: 20px;
		line-height: 20px;
		background: #fff;
		margin-inline-end: 15px;
	}
	.ant-pagination-item a {
		padding: 0px;
		font-size: 11px;
	}
	.ant-pagination-item-active {
		border-color: #007299 !important;
		// color: ${({ theme }) => theme.colors.bgButton1} !important;
		// color: #007299 !important;
	}
`;
export const XContainer = styled(Container)`
	// margin-left: 10px;
	padding: 0px 60px;
	@media only screen and (max-width: 768.99px) {
		padding: 0px 15px;
	}
	@media only screen and (min-width: 1920.99px) {
		width: 1800px;
	}
	@media only screen and (min-width: 2000.99px) {
		width: 100%;
	}
`;
export const XButton = styled(Button)`
	width: 100%;
	border-radius: 50px !important;

	background: #007299 !important;
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.colors.fontDM};
	padding: 10px 0px;
	height: auto;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.28px;
	&:hover {
		background: #007299 !important;
		color: ${({ theme }) => theme.colors.white} !important;
	}
`;
export const XButton2 = styled(Button)`
	width: 100%;
	font-family: ${({ theme }) => theme.colors.fontInter} !important;
	padding: 12px 0px;
	height: auto !important;
	border-radius: 20px;
	border: 1px solid ${({ theme }) => theme.colors.color6};
	background: #007299;
	color: ${({ theme }) => theme.colors.white};
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 14px;
	letter-spacing: 0.28px;

	&:hover {
		// background: ${({ theme }) => theme.colors.menu2} !important;
		background: #007299 !important;
		color: ${({ theme }) => theme.colors.white} !important;
	}
`;

export const XShareButton = styled(Button)`
	width: 100%;
	border-radius: 10px #007299 !important;
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.colors.fontDM};
	padding: 10px 0px;
	height: auto;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.28px;
	background: #007299 !important;
	&:hover {
		// background: ${({ theme }) => theme.colors.menu2} !important;
		background: #fff !important;
		color: #007299 !important;
	}
`;

export const FilterButton = styled(Button)`
	width: 100%;
	font-family: ${({ theme }) => theme.colors.fontInter} !important;
	padding: 12px 0px;
	height: auto !important;
	border-radius: 20px;
	// border: 1px solid #007299;
	// color: ${({ theme }) => theme.colors.menu2};
	color: #007299;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 14px;
	letter-spacing: 0.28px;

	&:hover {
		// border: 0.2px solid #007299 !important;
		color: #007299 !important;
	}
`;
export const XCardsTransparent = styled(Card)`
	border-radius: 20px !important;
	border: 1px solid ${({ theme }) => theme.colors.color3};
	background: rgba(255, 255, 255, 0.7) !important;
	.ant-card-body {
		padding: 30px !important;
		@media only screen and (max-width: 768.99px) {
			padding: 10px !important;
		}
	}
`;
export const XTable = styled(Table)`
	.ant-table {
		background: transparent;
	}
	// .ant-table-cell-row-hover {
	// 	background: none !important;
	// }
	.ant-table-thead > tr > th {
		color: ${({ theme }) => theme.colors.color4};
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 16px;
		text-transform: uppercase;
		border-top: 2px solid rgba(0, 0, 0, 0.05);
		border-radius: 0px !important;
		&::before {
			display: none;
		}
	}
	.ant-table-tbody > tr > td {
		border-top: 1px solid rgba(0, 0, 0, 0.05);
		border-bottom: 0px;
		color: ${({ theme }) => theme.colors.color7};
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: 0.42px;
	}
	.showDate img {
		margin-right: 10px;
		margin-top: -3px;
	}

	td.custom-border-left {
		// border-left: 10px solid green !important;
		// border-radius: 20px !important;
		padding: 0 !important;
	}

	td > .claim_category_line {
		height: 70px !important;
		display: grid;
		place-items: center;
	}
	td > .full {
		border-left: 5px solid #00a954 !important;
	}
	td > .neutral {
		border-left: 5px solid #e9ecef !important;
	}
	td > .rejected {
		border-left: 5px solid #ff1c1c !important;
	}
	td > .partial {
		border-left: 5px solid #ffd17b !important;
	}

	td > .triangle {
		// width: 0;
		// height: 0;
		display: grid;
		place-items: center;
		position: absolute;
		top: 0;
	}
	td > .in_process {
		border-right: 20px solid transparent;
		border-top: 20px solid #1f9254 !important;
	}
	td > .untimely {
		border-right: 20px solid transparent;
		border-top: 20px solid #db3a17 !important;
	}
	td > .escalation {
		border-right: 20px solid transparent;
		border-top: 20px solid #e57711 !important;
	}
	td > .at_risk {
		border-right: 20px solid transparent;
		border-top: 20px solid #e5aa11 !important;
	}

	.ant-pagination-prev,
	.ant-pagination-next {
		background: #fff;
	}
	.ant-pagination-prev,
	.ant-pagination-next,
	.ant-pagination-disabled {
		@media only screen and (max-width: 768.99px) {
			border: 1px solid #bebebe;
			min-width: 20px;
			height: 20px;
			line-height: 10px;
		}
	}

	.ant-pagination-disabled,
	.ant-pagination-prev,
	.ant-pagination-next {
		span svg {
			font-size: 10px;
		}
	}

	.ant-pagination-item {
		@media only screen and (max-width: 768.99px) {
			min-width: 20px;
			border: 1px solid #bebebe;
			height: 20px;
			line-height: 20px;
			background: #fff;
			margin-inline-end: 15px;
		}
	}
	.ant-pagination-item a {
		@media only screen and (max-width: 768.99px) {
			padding: 0px;
			font-size: 11px;
		}
	}
	.ant-pagination-item-active {
		border-color: #007299 !important;
		color: ${({ theme }) => theme.colors.bgButton1} !important;
	}
	.ant-pagination-options-size-changer {
		@media only screen and (max-width: 768.99px) {
			height: 20px;
		}
	}
	// .ant-pagination-item-active a {
	// 	color: #007299 !important; /* Set the text color for the active item */
	// }
	.ant-pagination .ant-pagination-item-active > a {
		color: #007299 !important;
	}
`;
export const XTag = styled(Tag)`
	border-radius: 4px;
	font-family: ${({ theme }) => theme.colors.fontInter};
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 0.36px;
	padding-left: 20px;
	border: none;
	&:before {
		content: '■';
		position: absolute;
		top: -1px;
		left: 5px;
		font-size: 10px;
	}
`;

export const XTag2 = styled(Tag)`
	border-radius: 4px;
	font-family: ${({ theme }) => theme.colors.fontInter};
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 0.36px;
	padding-left: 20px;
	&:before {
		content: '■';
		position: absolute;
		top: -1px;
		left: 5px;
		font-size: 10px;
	}
`;
export const XDropdown = styled(Dropdown)`
	text-align: center;
	color: ${({ theme }) => theme.colors.color8};
	font-size: 26px;
	font-weight: 600;
`;
export const XConfirmModal = styled(Modal)``;

export const XForm = styled(Form)`
	label {
		color: ${({ theme }) => theme.colors.color7} !important;
		font-size: 14px !important;
		font-style: normal;
		font-weight: 500 !important;
		line-height: normal;
	}
	input {
		border: 1px solid ${({ theme }) => theme.colors.color3};
		background: transparent;
		height: auto;
		padding-top: 10px;
		padding-bottom: 10px;
		@media only screen and (max-width: 768.99px) {
		}
	}
	button {
		display: inline-block;
		margin-left: 15px;
		border-radius: 20px;
		background: ${({ theme }) => theme.colors.menu2};
		color: #fff;
		font-size: 14px !important;
		padding: 10px 30px;
		height: auto;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		border: 1px solid ${({ theme }) => theme.colors.color6};
		&:hover {
			background: ${({ theme }) => theme.colors.menu2};
			color: #fff;
		}
		@media only screen and (max-width: 768.99px) {
		}
	}
	.ant-upload-list-item-action.ant-btn {
		background: none;
	}
	button.cancel {
		background: ${({ theme }) => theme.colors.white};
		color: #171c26;
	}
	.customLabel {
		color: ${({ theme }) => theme.colors.menu2};
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 28px;
	}
	.customHeader {
		color: ${({ theme }) => theme.colors.menu2};
		background: #f3f6f9;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 28px;
		padding: 5px 15px;
	}
	.ant-switch {
		padding: 0px 5px;
		background: transparent;
		border: 1px solid ${({ theme }) => theme.colors.menu2};

		.ant-switch-handle {
			top: 1px;
			&::before {
				background: ${({ theme }) => theme.colors.menu2};
			}
		}
	}
	button.ant-switch {
		&:hover {
			background: ${({ theme }) => theme.colors.menu2};
			.ant-switch-handle {
				&::before {
					background: ${({ theme }) => theme.colors.white};
				}
			}
		}
	}
	button.ant-switch-checked {
		background: ${({ theme }) => theme.colors.menu2};
		.ant-switch-handle {
			top: 1px;
			&::before {
				background: ${({ theme }) => theme.colors.white};
			}
		}
	}
	.ant-picker-large .ant-picker-input > input {
		font-size: 14px !important;
	}
`;

export const XSelect = styled(Select)`
	.ant-select-selector {
		border: 1px solid rgba(6, 36, 66, 0.15) !important;
		background: transparent !important;
		box-shadow: none;
		min-height: 45px !important;
		color: ${({ theme }) => theme.colors.menu2};
		font-size: 14px !important;
		font-style: normal;
		font-weight: 400 !important;
		line-height: 45px;
	}
`;
export const X2Select = styled(Select)`
	.ant-select-selector {
		border: 1px solid rgba(6, 36, 66, 0.15) !important;
		border-radius: 50px !important;
		background: transparent !important;
		box-shadow: none;
		min-height: 45px !important;
		color: ${({ theme }) => theme.colors.menu2};
		font-size: 14px !important;
		font-style: normal;
		font-weight: 400 !important;
		line-height: 45px;
	}
`;

export const XModal = styled(Modal)`
	.ant-modal-title {
		color: ${({ theme }) => theme.colors.menu2};
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: 0.36px;
	}
	label {
		color: ${({ theme }) => theme.colors.menu2};
		font-size: 14px !important;
		font-weight: 500 !important;
	}
	.modalButton {
		width: 100%;
		position: relative;
		margin: 0px;
		display: block;
		color: ${({ theme }) => theme.colors.white};
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: 0.36px;
	}
	.ant-modal-content {
		border-radius: 20px !important;
	}
	.ant-modal-footer {
		.ant-btn {
			border-radius: 20px;
			height: 40px;
			padding: 0px 30px;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: 0.28px;
		}
		.ant-btn-primary {
			border: 1px solid ${({ theme }) => theme.colors.color6};
			background: ${({ theme }) => theme.colors.menu2};
			&:hover {
				border: 1px solid ${({ theme }) => theme.colors.color6};
				background: ${({ theme }) => theme.colors.menu2};
			}
		}
		.ant-btn-default {
			border-color: ${({ theme }) => theme.colors.color6};
			background: ${({ theme }) => theme.colors.white};
			box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.32),
				0px 5px 5px 0px rgba(0, 0, 0, 0.1);
			color: ${({ theme }) => theme.colors.menu2};
			&:hover {
				border-color: ${({ theme }) => theme.colors.menu2};
				background: ${({ theme }) => theme.colors.menu2};
				color: ${({ theme }) => theme.colors.white};
			}
		}
	}
`;
export const XSwitch = styled(Switch)`
	&.ant-switch-checked {
		background-color: #007299 !important;
		border: none !important;
	}
	&:not(.ant-switch-checked) {
		background-color: #d9d9d9 !important; /* Set your desired color for unchecked state */
		border: none !important;

		.ant-switch-handle::before {
			background-color: #fff !important; /* Unchecked handle color */
		}
	}
	.ant-switch-inner .ant-switch-inner-unchecked {
		color: black !important;
	}
`;
export const XCardsCaseBg = styled(Card)`
	background: ${({ theme }) => theme.colors.white};
	border: 1px solid rgba(87, 99, 111, 0.15);
	border-radius: 10px;
	.ant-card-body {
		border-radius: 10px;
		border: none;
		@media only screen and (max-width: 768.99px) {
			padding: 10px !important;
		}
	}
`;
export const XCardsCriteriaBg = styled(Card)`
	border-radius: 20px;
	border: none;
	.ant-card-body {
		border-radius: 20px;
		background: ${({ theme }) => theme.colors.white};
		box-shadow: 0px 2px 10px 0px rgba(25, 15, 64, 0.05);
		padding: 30px !important;
		padding-left: 0 !important;
		@media only screen and (max-width: 768.99px) {
			padding: 15px !important;
			padding-left: 0 !important;
		}
	}
`;

export const XCardsData = styled(Card)`
	border-radius: 20px;
	border: none;
	padding: 10px;
	.ant-card-body {
		border-radius: 20px;
		background: ${({ theme }) => theme.colors.white};
		box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.05);
		padding: 30px !important;
		@media only screen and (max-width: 768.99px) {
			padding: 0 !important;
		}
	}
`;
export const XSelectRounded = styled(Select)`
	.ant-select-selector {
		border-radius: 25px !important;
		border: 1px solid rgba(6, 36, 66, 0.15) !important;
		background: transparent !important;
		box-shadow: none;
		height: 40px !important;
		color: ${({ theme }) => theme.colors.menu2};
		font-size: 14px !important;
		font-style: normal;
		font-weight: 400 !important;
		line-height: 16px;
	}
	@media only screen and (min-width: 768.99px) {
	}
`;

export const XAutoComplete = styled(AutoComplete)`
	width: 100%;
	.ant-input {
		border-radius: 25px;
		border: 1px solid rgba(6, 36, 66, 0.15);
		box-shadow: none;
		height: 40px;
	}
	background: ${({ theme }) => theme.colors.white};
	@media only screen and (min-width: 768.99px) {
	}
`;

export const XSearch = styled(Search)`
	width: 100%;
	.ant-input {
		border-radius: 25px;
		border: 1px solid rgba(6, 36, 66, 0.15);
		box-shadow: none;
		font-size: 14px !important;
		font-style: normal;
		font-weight: 400 !important;
		line-height: 16px;
	}
	.ant-input-wrapper {
		border-radius: 25px !important;
		border: 1px solid rgba(6, 36, 66, 0.15);
		box-shadow: none;
	}
	.ant-input-affix-wrapper {
		border: none;
	}

	.ant-input-affix-wrapper:hover,
	.ant-input-affix-wrapper:focus,
	.ant-input-affix-wrapper:focus-within {
		border: none !important;
		box-shadow: none !important;
		border-color: none !important;
	}

	.ant-input-search-button {
		border-radius: 25px !important;
		border: 1px solid rgba(6, 36, 66, 0.15);
		box-shadow: none;
		// background: ${({ theme }) => theme.colors.menu2};
		background: #007299;
	}

	.ant-input-search-button:hover {
		border-radius: 25px !important;
		border: 1px solid rgba(6, 36, 66, 0.15);
		box-shadow: none;
		// background: ${({ theme }) => theme.colors.menu2} !important;
		background: #007299 !important;
	}
	.ant-input-search-button:focus-visible {
		border-radius: 25px !important;
		border: 1px solid rgba(6, 36, 66, 0.15);
		box-shadow: none;
		// background: ${({ theme }) => theme.colors.menu2} !important;
		background: #007299 !important;
	}
	// .ant-input-group-addon:last-child {
	// 	inset-inline-start: 95px !important;
	// }

	background: ${({ theme }) => theme.colors.white};
`;

export const XAlertBar = styled.div(`

	position: fixed;
	border-radius: 5px;
	box-sizing: border-box;
	color: white;
	font-size: 1em;
	margin: 0 auto;
	margin-bottom: 1em;
	padding: 0.5em;
	width: 100%;
	top: 0;
	z-index: 1;
	display: none;
    
	.alert-success {
	  background-color: #e67e22;
	  text-align: center;
	}`);
export const GlobalStyle = createGlobalStyle`
	*{
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		font-family: 'Poppins', sans-serif !important;
		${'' /* font-family: 'DM Sans', sans-serif; */}
	}
		.custom-crop-modal .ant-btn-default {
    color: #007299; /* Reset button text color */
	background:#fff;
    border-radius: 20px; /* Reset button rounded corners */
    padding: 6px 16px; /* Reset button padding */
    margin-right: 8px; /* Optional: spacing */
}
	body{
		background: #f7f8fa url(../images/body-bg.svg) no-repeat center center;
		background-attachment: fixed;
		background-size: cover;
		font-family: 'Poppins', sans-serif !important;
	}
	.ant-layout-has-sider, .ant-layout{
		background:transparent !important;
	}
	a, p{
		font-family: 'Poppins', sans-serif !important;
		font-weight:normal;
		text-decoration:none;
	}
	.offcanvas-header{
		border-bottom:1px solid #CCC;
	}
	.offcanvas.offcanvas-top{
		height:100vh;
		transition:transform 0.3s ease-in-out !important;
		z-index:1050;
		@media only screen and (max-width: 1199.99px) {
			padding-top:60px;
		}
		
		${
			'' /* height:100vh;
		transition: mobileNavEffect 1s normal forwards ease-in-out !important;
		animation: mobileNavEffect .3s ease-in-out;		
		@keyframes mobileNavEffect {
			100% {
				opacity: 1;
			}

			50% {
				opacity: 0.6;
				transform: scale3d(.1, .1, .1);
			}
			25% {
				opacity: 0.3;
				transform: scale3d(.1, .1, .1);
			}
			0% {
				opacity: 0;
			}
		} */
		}
	}
	
	.marginTopMobile {
		@media only screen and (max-width: 991.99px) {
			margin-bottom: 100px;
		}
	}

	.marginTopMobileNoHeader {
		@media only screen and (max-width: 991.99px) {
			margin-top: -90px !important;
		}
	}
	.marginBottomMobile {
		@media only screen and (max-width: 991.99px) {
			margin-bottom: 70px !important;
		}
	}
	.marginNone {
		@media only screen and (max-width: 991.99px) {
			margin-top: -70px !important;
		}
	}
	.nav-link {
		color: ${({ theme }) => theme.colors.menu1};
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		&:hover {
			color: ${({ theme }) => theme.colors.menu2} !important;
			font-weight: 600;
		}
	}
	.nav-link.active {
		color: ${({ theme }) => theme.colors.menu2} !important;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
	}
	.ant-modal-confirm {
		.ant-btn{
			&:hover{
				border-color: ${({ theme }) => theme.colors.menu2} !important;
			}
		}
		.ant-btn-dangerous{
			background: ${({ theme }) => theme.colors.menu2};
			color: ${({ theme }) => theme.colors.white};
			border-color: ${({ theme }) => theme.colors.menu2};
			&:hover{
				background: ${({ theme }) => theme.colors.menu2} !important;
				color: ${({ theme }) => theme.colors.white} !important;
			}
		}
		.ant-btn-default{
			background: ${({ theme }) => theme.colors.white};
			color: ${({ theme }) => theme.colors.menu2};
			border-color: ${({ theme }) => theme.colors.color3};
			&:hover{
				background: ${({ theme }) => theme.colors.menu2} !important;
				color: ${({ theme }) => theme.colors.white} !important;
			}
		}
	}
	.ant-select.ant-select-in-form-item,
	.ant-input,
	.ant-picker,
	.ant-select-selector,
	.ant-input-affix-wrapper {
		background: transparent !important;
	}
	span.successful, .approved{
		background: #e1fcef !important;
		color: #14804a !important;
	}
	span.rejected {
		background: #fff1f0 !important;
		color: #cf1322 !important;
	}
	span.pending, .pending_for_analysis{
		background: #FDE9CB !important;
		color: #E78900 !important;
	}

	span.allocated {
		background: #FFE9DA !important;
		color: #7A0E27 !important;
		border-color: #b7eb8f;
	}
	
	span.analysed {
		background: #FFFCDA !important;
		color: #7A670E !important;
	}

	span.letter_created {
		background: #FFFCDA !important;
		color: #7A670E !important;
	}

	span.letter_sent {
		border-radius: 5px;
		border: 0.5px solid rgba(56, 160, 108, 0.30);
		background: #E1FCEF;
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
		color: #38A06C;
	}

	span.No {
		background: #fff1f0 !important;
		color: #cf1322 !important;
		border:1px solid;
		display:flex;
		justify-content:center;
		align-items:center;
	}
	
	span.Yes {
		background: #e1fcef !important;
		color: #38A06C !important;
		border:1px solid;
		display:flex;
		justify-content:center;
		align-items:center;
	}

	.textArea{
		border-radius: 20px;
		border: 1px solid rgba(0, 0, 0, 0.10);
		background: ${({ theme }) => theme.colors.white} !important;
		box-shadow: 0px 2px 10px 0px rgba(25, 15, 64, 0.05);
		padding:10px 15px !important;
	}
	.iconResp{
		width:35px;
		@media only screen and (max-width: 769px) {
			width:36px;
		}
	}
	p.modalPara1{
		color: rgba(33, 34, 38, 0.60);
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 22px; /* 157.143% */
	}
	.confirmModal {
		.ant-btn{
			border-radius: 25px !important;	
		}
		.ant-btn-default{
			background: ${({ theme }) => theme.colors.white};
			border: 1px solid ${({ theme }) => theme.colors.menu2};
			color: ${({ theme }) => theme.colors.menu2};
		};
		.ant-btn-dangerous{
			background: ${({ theme }) => theme.colors.menu2};
			color: ${({ theme }) => theme.colors.white};
		};

	}
	.slideBox {
		overflow: hidden;
		@media only screen and (min-width: 2000.99px) {
			margin-top:5%;
		}
		@media (max-width: 768.99px) {
			padding-top:7%;
		}
		@media (max-width: 992.99px) {
			padding-top:3%;
		}
		@media (max-width: 1024.99px) {
			padding-top:3%;
		}
	}
	.slideUp {
		
		position: relative;
		transition: all .5s ease-out;
	}
	.slideDown {
		position: relative;
		transition: all .5s ease-out;
		height: 100%;
	}
	.top-0{
		top:0;
	}
	.bottom-0{
		position: relative;
		bottom:0 !important;
	}
	.top-100{
		top:100%!important;
	}
	.bottom-100{
		position: absolute;
		bottom:100% !important;
		z-index: -1;
	}
	.ant-layout-sider-children{
		overflow-y: scroll;
	}
	.ant-layout-sider-children::-webkit-scrollbar {
		width: .2em; 
	}	
	.ant-layout-sider-children::-webkit-scrollbar,
	.ant-layout-sider-children::-webkit-scrollbar-thumb {
		overflow:visible;
		border-radius: 4px;
	}
	.ant-layout-sider-children::-webkit-scrollbar-thumb {
		background: rgba(0,0,0,.2); 
	}
	.cover-bar {
		position: absolute;
		background: #fff;;
		height: 100%;  
		top: 0;
		right: 0;
		width: .2em;
		-webkit-transition: all .5s;
		opacity: 1;
	}
	.customScroll:hover .cover-bar {
		opacity: 0;
		-webkit-transition: all .5s;
	}
	
	
	${
		'' /* .customScroll::-webkit-scrollbar {
		width: 2px !important;
	}
	.customScroll:hover {
		overflow-y:auto !important;
	}
	.customScroll::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
		border-radius: 10px;
		background-color: ${Theme.colors.color3} !important;
	}
	.customScroll::-webkit-scrollbar-thumb {
		height: 5px;
		background-color: ${Theme.colors.color4};
		border-radius:5px;
	}
	.customScroll::-webkit-scrollbar-thumb:hover {
		background-color: ${Theme.colors.color2};
	} */
	}

	.customHorizontalScroll ::-webkit-scrollbar {
		height: 5px !important;
	}
	.customHorizontalScroll ::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
		border-radius: 10px;
		background-color: ${Theme.colors.color3} !important;
	}
	.customHorizontalScroll ::-webkit-scrollbar-thumb {
		width: 5px;
		background-color: ${Theme.colors.color4};
		border-radius:5px;
	}
	.customHorizontalScroll ::-webkit-scrollbar-thumb:hover {
		background-color: ${Theme.colors.color2};
	}

	.headerStyle {
		color: #000;
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: -0.36px;
	}
	.labelStyle{
		color: #171C26;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		padding-bottom: 0 !important;
	}
	.labelStyle .ant-form-item-label{
		padding:0px !important;
	}

	.labelHeading{
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: ${({ theme }) => theme.colors.color11};
		font-family: ${({ theme }) => theme.colors.fontPoppins};
	}
	.subLabelStyle{
		color: #171C26;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
	}

	.feedbackText{
		color: #000;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 26px; /* 185.714% */
		letter-spacing: -0.28px;
		
	}

	.feedbackCardHeader{
		margin-left: 8px;
		color: #212226;
		font-family: Poppins;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 16px; /* 100% */
		letter-spacing: -0.32px;
	}
	.feedbackTextArea{
		border-radius: 20px;
		border: 1px solid rgba(0, 0, 0, 0.10);
		background: #FFF;
		box-shadow: 0px 2px 10px 0px rgba(25, 15, 64, 0.05);
		color: rgba(0, 0, 0, 0.40);
		font-family: Inter;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 30px; /* 166.667% */
		letter-spacing: -0.36px;
		height:200px;
		padding:20px !important;
	}
	// 

	:where(.css-dev-only-do-not-override-3mqfnx).ant-input-affix-wrapper:focus, :where(.css-dev-only-do-not-override-3mqfnx).ant-input-affix-wrapper:focus-within{
		border: 0.1px grey !important;
		box-shadow: 0.5px 0.5px 2px 0.2px #888888;
	}

	:where(.css-dev-only-do-not-override-3mqfnx).ant-input-affix-wrapper:focus, :where(.css-dev-only-do-not-override-3mqfnx).ant-input-affix-wrapper:hover{
		border: 0.1px grey !important;
		box-shadow: 0.5px 0.5px 2px 0.2px #888888;
	}
	.ant-form-item-control-input-content .ant-switch-checked{
		background: ${({ theme }) => theme.colors.menu2};
		.ant-switch-handle {
			top: 1px;
			&::before {
				background: ${({ theme }) => theme.colors.white};
			}
		}
	}
	
	:where(.css-dev-only-do-not-override-3mqfnx).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled){
		background: ${({ theme }) => theme.colors.menu2};
		.ant-switch-handle {
			top: 1px;
			&::before {
				background: ${({ theme }) => theme.colors.white};
			}
		}
	}
	
	.remove_important > .ant-form-item-required{
		color:green !important;
	}
	canvas{
		width:10%;
	}
	.logo{
		display:block;
		width: 100%;
		text-align: center;
		margin: 30px 0px;
		@media (max-width: 992px) {
			margin:0px;
		}
	}
	.logo a img{
		vertical-align: middle;
		border-style: none;
	}
	.overlayDrawerCloseBtn{
		display:none;
	}
	@media only screen and (max-width: 992px) {
		/********** Mobile overlay menu */    
		.overlayDrawerClose{
			position: fixed;
			display: block;
			width:100%;
			min-height: 100vh;
			top:0;
			right:0%;
			background-color: rgba(0, 0, 0, 0.7);
			animation-direction: normal;
			animation-name: cssAnimation;
			animation-duration:0.15s;
			z-index: 10;
		}
		.overlayDrawerCloseBtn{
			position: absolute;
			top:15px;
			right:20px;
			font-size:3rem !important;
			line-height:2rem;
			background:none !important;
			color: #FFF !important;
			font-weight: 200 !important;
			box-shadow:0px;
			padding:0px;
			display:block;
			border:none;
		}
		#root  .ant-layout-sider-collapsed + .overlayDrawerClose{
			display: block;
		}
		#root .ant-layout-sider-zero-width + .overlayDrawerClose{
			display: none;
		}
		.ant-layout-header{
			background:  ${({ theme }) => theme.colors.white};
		}
		/*
		.ant-layout .ant-layout-sider{
			position:fixed;
			width:80px;
			height:100vh;
			background: ${({ theme }) => theme.colors.white} !important;
		}
		*/
	}
	.ant-layout-sider-children {
		background: #fff;
	}
	.ant-menu-item {
		margin: 15px auto !important;
		padding-left: 16px !important; 
	}
	.nav-link-item {
		display: block;
		text-align: center;
	}
	.nav-link-item: hover{
		background: #f0f0f0f0;
		border-radius: 10px;

	}
	.nav-link-item-active{
		display: block;
		text-align: center;
		background: #f0f0f0f0;
		border-radius: 10px;

	}
	.headerTagStyle{
		color: #212226;
		font-family: Poppins;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}	
	.headerTagDashboard{
		color: #212226;
		font-family: Poppins;
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 32px; /* 177.778% */
		letter-spacing: -0.36px;
	}

	.donut-amount {
		position: absolute;
		top: 45%;
		left: 0;
		right: 0;
		text-align: center;
		z-index: 99;
		font-weight: bold;
		@media only screen and (min-width: 1024px) {
			margin-left:0px;	
			top: 30% !important;		
		}
		@media only screen and (max-width: 1440.99px) {
			left:2%;
			top: 32% !important;
		}

		@media only screen and (max-width: 1024.99px) {
			left: -5% !important;
			font-size: 8px;			
			top:28% !important;
			margin-left:20px;
			
		}
		@media only screen and (max-width: 767.99px) {
			left: -5% !important;
			font-size: 13px;			
			margin-left:20px;
			top: 30% !important;
		}
	}

	.donut-chart{
		height: 250px !important;
		width: 100% !important;			
		display: auto !important;

		margin: 0 auto;
		@media only screen and (min-width: 2000.99px) {
			height: 220px !important;
			width: 220px !important;
			display: auto !important;
		}
		@media only screen and (max-width: 1470.99px) {
			height: 230px !important;
			width: 230px !important;
		}
		@media only screen and (max-width: 1290.99px) {
			height: 190px !important;
			width: 190px !important;
			display: auto !important;
		}
		@media only screen and (max-width: 1440.99px) {
			height: 220px !important;
			width: 220px !important;
			display: auto !important;
		}
		@media only screen and (min-width: 1175px) {
			height: 200px !important;
			width: 120px !important;
			display: auto !important;
		}
		@media only screen and (min-width: 1044px) {
			height: 230px !important;
			width: 230px !important;
			
		}
		@media only screen and (max-width: 1024.99px) {
			height: 180px !important;
			width: 180px !important;
			display: auto !important;
		}
		@media only screen and (max-width: 991.99px) {
			height: 220px !important;
			width: 220px !important;
			display: auto !important;
		}
	}

	.shareModal .ant-card-body{
		padding:15px !important;
	}
	.shareModalActive .ant-card-body{
		padding:15px !important;
		// background: ${({ theme }) => theme.colors.menu2} !important;
		border-radius: 8px;
		color: #fff;
		background: #007299 !important;
	}
	.tableHeader{
		color: #11263C;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 1.2; /* 114.286% */
	}
	.ant-form-item-no-colon{
		color: #11263C  !important;
		font-size: 14px  !important;
		font-style: normal  !important;
		font-weight: 500 !important;
		line-height: 16px  !important; /* 114.286% */
	}
	.filter-sub-item{
		color: #11263C !important;
		font-family: Poppins;
		font-size: 12px !important;
		font-style: normal !important;
		font-weight: 500 !important;
		line-height: 18px !important; /* 150% */
		letter-spacing: 0.36px !important;
		margin: 5px auto;
	}
	.removeFormMargin{
		margin-bottom: 14px !important;
	}
	.ant-drawer-body{
		padding-top: 14px !important;
	}
	.ant-upload-select {
		width: 100% !important;
	}


	.confirmCoverage:hover {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		background: #000 !important;
	}

	ul.listCircule{
        margin: 0px;
        padding: 0px;
    }
    ul.listCircule li{
        list-style-type: disc;
		color: #000;
		font-family: Inter;
		font-size: 15px;
		font-weight: 400;
		line-height: 28px !important;
		margin-bottom: 5px !important;
    } 
    ${
		'' /* ul.listCircule li::before {
        content: "";
        border:2px solid #5E5E5E;
        border-radius: 50%;
        font-weight: bold;
        display: inline-block; 
        width: 0.7em;
        height:0.7em;
        margin-right:.5em;
        } */
	}
	.dashboard-img {
		height: 240px !important;
	}
	.config-text {
		color: #212226;
		font-family: Inter;
		font-size: 18px;
		font-style: normal;
		font-weight: 600 !important;
		line-height: 28px; /* 155.556% */
	}
	.criteria_list {
		border-left: 5px solid #9B68BF;
		padding-left: 25px;
		height: 50px;
		width: 375px;
		display: flex;
		align-items: center;
		background:
			linear-gradient(90deg, rgba(207, 174, 223, 0.15) 0.1%, rgba(207, 174, 223, 0.00) 99.86%);
		@media only screen and (max-width: 768.99px) {
			padding: 15px !important;
		}
	}

	.criteria_sub_list {
		padding-left: 25px !important;
		@media only screen and (max-width: 768.99px) {
			padding:0px 15px !important;
		}
	}

	.criteria_list_title {
		color: #9B68BF;
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 28px;
	}

	.supporting_files .ant-upload-select {
		width: 102px !important;
		margin:0 !important;
	}

	.supporting_files .ant-upload-list-item-container {
		margin-right:12px !important;
		margin-bottom: 0 !important;
	}

	.ant-upload-list-item-container {
		margin-right: 19px !important;
	}

	:where(.css-dev-only-do-not-override-3mqfnx).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: #212226 !important;
	}

	.no-padding-card .ant-card-body{
		padding:0 !important;
	}
	#revnueModalUploadButton:hover{
		color: black !important;
	}
	.timeline-documents .ant-card-body{
		padding:15px;
	}
	#timeline-documents-hover .ant-card-body{
		padding:5px !important;
		display: flex;
		justify-content: center;
		height: 100%;
		background: none;
		box-shadow: none;
	}
	.timeline-upload .ant-upload-list{
		display: flex;
		flex-wrap: wrap;
		row-gap: 10px;
	}

	//Flip Card Timeline
	.front,.back{
		backface-visibility: hidden;
		transition: transform .6s linear;
	}
	.front {
		transform: perspective(600px) rotateY(0deg);
	}
	.back{
		 transform: perspective(600px) rotateY(-180deg); 
	}
	.toggle.rotated > .front{
		transform: perspective(600px) rotateY(180deg);
	}
	.toggle.rotated > .back{ 
		transform: perspective(600px) rotateY(0deg);
	}

	.supporting-docs-hover, .ant-image-mask{
		border-radius: 10px !important;
	}

	.image-container, .image-container_case_filing {
		position: relative;
	  }
	.imgBoxButton{
		background: #ffffff;
		border: 1px solid #d1d7e5;
		box-shadow: inset 0px -5px 2px #eeeff7 !important;
		border-radius: 10px;
		padding-bottom:5px;
	}
	  .image-container, .uploadText {
		position: relative;
		text-align:center;
		font-size: 12px;
		line-height:28px;
		color: rgb(0, 0, 0);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 75px;
	  }
	  .image-container {
		width: 100px;
		height: 100px;
	  }
	  
	  .supporting-docs-hover, .overlay, .overlay_case_filing {
		border-radius: 10px;
	  }
	  
	  .overlay, .overlay_case_filing {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5); /* Mask the image */
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0;
		transition: opacity 0.3s ease;
	  }
	  
	  .image-container:hover .overlay, .image-container_case_filing:hover .overlay_case_filing {
		opacity: 1;
	  }
	  
	  .icon {
		color: white;
		font-size: 24px;
		margin: 0 5px;
		cursor: pointer;
	  }
	  
	.dashboard-picker.ant-picker{
		background: #FFFFFF !important;
		width: 240px !important;
		border-radius: 25px !important;
		margin: 0 5px !important;
		border: 1px solid rgba(6, 36, 66, 0.1) !important;
		color: #212226 !important;
	}
	.dashboard-picker.ant-picker-input{
		color: #212226 !important;
	}
		.dashboard-picker .ant-picker-input > input {
		font-size: 14px !important; /* Adjust this size as needed */
	}
	#tooltip-card .ant-card-body{
		padding:5px !important;
		display: flex;
		justify-content: center;
		height: 100%;
		background: none;
		box-shadow: none;
	}
	ul.markData {
		margin: 0px;
		padding: 0px;
	}
	ul.markData li {
		display: list-item;
		list-style-type: none;
		padding-left: 10px;
		font-weight: 500;
		font-size: 14px;
		line-height: 32px;
		letter-spacing: -0.02em;
		color: #FFFF;
		text-align: left;
	}
	ul.markData li em {
		position: absolute;
		left: 0;
		margin-top: 10px;
		width: 10px;
		height: 10px;
		border-radius: 20px;
	}
	#tooltip-card ul.markData li em.color1 {
		background: #1f9254;
	}
	#tooltip-card ul.markData li em.color2 {
		background: #e5aa11;
	}
	#tooltip-card ul.markData li em.color3 {
		background: #e53711;
	}
	#tooltip-card ul.markData li em.color4 {
		background: #2F8DFA;
	}
	#tooltip-card ul.markData li em.color5 {
		background: #1FD0BF;
	}
	#tooltip-card ul.markData li em.color6 {
		background: #D5001A;
	}
	#tooltip-card.ant-tooltip-inner{
		padding:5px 20px
	}
	.half-donut-chart{
		height: 250px !important;
		width: 100% !important;			
		display: auto !important;
		padding: 15px auto;
		@media only screen and (max-width: 380.99px) {
			height:170px !important;
		}
	}
	.case-filing:hover {
		cursor:pointer !important;
	}
	.normal-header .ant-list-header {
		font-size: 10px !important;
		padding: 10px !important;
		font-family: 'Inter', sans-serif !important;
		background: none !important;
	}
	// .custom-crop-modal .ant-modal-body .reactEasyCrop_CropArea {
	// 	width: 40px !important;
	// 	height: 40px !important;
	// }

	// .custom-crop-modal .ant-modal-body .reactEasyCrop_CropArea {
	// 	width: 40px !important;
	// 	height: 40px !important;
	// 	aspect-ratio: 1 / 1;
	// }

	.rpv-core__inner-pages{
		scrollbar-width:none !important;
	}
	.rpv-core__page-layer--single{
		margin:0 !important;
		width:100% !important;
	}
	.btnActive {
		background: #007299 !important;
		color:${({ theme }) => theme.colors.white} !important;
		
	}
	.btnActive img{
		filter: invert(0) !important;	
	}
	:where(.css-dev-only-do-not-override-98ntnt).ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector {
		background: rgba(0, 0, 0, 0.04) !important;
	}
	:where(.css-dev-only-do-not-override-98ntnt).ant-picker-outlined.ant-picker-disabled {
		background: rgba(0, 0, 0, 0.04) !important;
	}
	:where(.css-dev-only-do-not-override-98ntnt).ant-input-outlined.ant-input-disabled, :where(.css-dev-only-do-not-override-98ntnt).ant-input-outlined[disabled] {
		background: rgba(0, 0, 0, 0.04) !important;
	}
	@media only screen and (max-width: 992.99px) {
        #getQust{
            width:100% !important; 
        }
    }
`;
export const XRoundButton = styled(Button)`
	background: ${({ theme }) => theme.colors.white} !important;
	border: 1px solid ${({ theme }) => theme.colors.menu2} !important;
	color: ${({ theme }) => theme.colors.menu2} !important;
	width: 32px !important;
	height: 32px !important;
	padding: 4px 0px !important;
	svg {
		margin-top: 3px !important;
	}

	&:hover,
	svg {
		border-color: ${({ theme }) => theme.colors.menu2} !important;
		color: ${({ theme }) => theme.colors.menu2} !important;
	}
`;
export const XList = styled(List)`
	.ant-list-item {
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: start;
		padding: 15px;
		font-family: 'Inter', sans-serif !important;
	}
	@media only screen and (max-width: 768.99px) {
		.ant-list-item {
			padding-left: 50px !important;
		}
		.ant-list-item label {
			margin-left: -40px !important;
		}
	}
	.ant-list-header {
		font-size: 14px;
		font-weight: 600;
		padding: 20px !important;
		font-family: 'Inter', sans-serif !important;
		background: var(
			--Linear,
			linear-gradient(
				90deg,
				rgba(105, 234, 203, 0.15) 0%,
				rgba(234, 204, 248, 0.15) 48%,
				rgba(102, 84, 241, 0.15) 100%
			)
		);
	}
`;
export const XCircle = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 10px;
	height: 30px;
	line-height: 20px;
	padding: inherit;
	border-radius: 50%;
	border: 1px solid #2122261a;
	text-align: center;
	margin-right: 12px;
	@media only screen and (max-width: 768.99px) {
		position: absolute;
		left: 10px;
		width: 30px;
		height: 30px;
		padding: 1px !important;
	}
`;
export const XLabel = styled.span`
	font-size: 14px !important;
	font-weight: 600 !important;
	font-family: 'Inter', sans-serif !important;
`;
export const XModalTitle = styled.div`
	font-size: 18px !important;
	font-weight: 600 !important;
	font-family: 'Inter', sans-serif !important;
`;
export const XModalSubtitle = styled.div`
	font-size: 13px !important;
	font-weight: 500 !important;
	font-family: 'Inter', sans-serif !important;
`;
export const XAffix = styled(Affix)`
	&.zIndex .ant-affix {
		z-index: 11111;
	}
`;
// export const XAffix = styled.div``;

export const ChatButton = styled(Button)`
	position: fixed;
	right: 10px;
	bottom: 30px;
	cursor: pointer;
	border: 0px;
	.ant-btn-text {
		background: none !important;
		border: 0px;
	}
	&:active,
	&:focus,
	&:hover {
		outline: none;
		box-shadow: 0px;
		border: 0px;
		background: none !important;
	}
	${
		'' /* @media only screen and (max-width: 768.99px) {
		top: 20px;
		right: 15px;
	} */
	}
	img {
		box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
		border-radius: 30px;
	}
	.mobileClose {
		display: none;
		@media only screen and (max-width: 768.99px) {
			display: block;
		}
	}
	@media only screen and (max-width: 768.99px) {
		.hideMobileClose {
			display: none;
		}
		.mobileClose {
			position: fixed;
			top: 25px;
			right: 25px;
		}
	}
`;
export const ChatBoxWrap = styled.div`
	position: fixed;
	width: 350px;
	top: 20px;
	right: 10px;
	z-index: 9999;
	@media only screen and (max-width: 768.99px) {
		width: 100%;
		right: 0;
		top: 0;
		bottom: 0;
		height: 70px;
	}
`;
export const XCardSummaryEdit = styled(Card)`
	background: #f7f8fa;
	border-radius: 10px;
	font-family: ${({ theme }) => theme.colors.fontInter};
	color: ${({ theme }) => theme.colors.menu2};
	font-weight: 400;
	font-size: 14px;
	line-height: 30px;
	letter-spacing: -0.02em;
	@media only screen and (max-width: 768.99px) {
		.ant-card-body {
			padding: 0px 0px !important;
		}
	}
`;
export const XCardsCase = styled(Card)`
	border-radius: 0px !important;
	border: none;
	background: transparent !important;
	.ant-card-body {
		padding: 0px !important;
		@media only screen and (max-width: 768.99px) {
		}
	}
	.title {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #212226;
	}
`;
export const XSelectAddCase = styled(Select)`
	.ant-select-selector {
		border: 1px solid rgba(6, 36, 66, 0.15) !important;
		background: #fff !important;
		box-shadow: none;
		color: ${({ theme }) => theme.colors.menu2};
		font-size: 14px !important;
		font-style: normal;
		font-weight: 400 !important;
	}
`;
export const XCardsAddCaseInner = styled(Card)`
	width: 100%;
	position: relative;
	border-radius: 10px !important;
	border: 1px solid rgba(18, 18, 73, 0.15);
	background: #ffffff !important;
	.ant-card-body {
		padding: 30px !important;
		@media only screen and (max-width: 768.99px) {
			padding: 10px !important;
		}
	}
	.customHr {
		border-top: 1px dashed #11263c;
		opacity: 0.2;
	}
`;
export const XCustomHeader = styled.section`
	width: 100%;
	position: relative;
	background: #fff;
	margin-top: -20px;
	padding: 15px 60px;
	z-index: 0;
	@media only screen and (max-width: 991.99px) {
		margin-top: 70px;
		padding: 15px;
	}
`;

export const XTabs1 = styled(Tabs)`
	/* Style the tab list (labels of each tab) */
	&.child-tabs {
		.ant-tabs-nav {
			color: #007299 !important;
		}
		/* Style each tab item (the individual tab) */
		.ant-tabs-tab {
			/* Change the background color on hover */
			background-color: transparent !important;
			&:hover {
				background-color: transparent !important;
				.ant-tabs-tab-btn {
					color: #007299 !important;
				}
			}

			/* Style active tab */
			&:active {
				color: #007299 !important;
			}
		}
		.ant-tabs-tab-active {
			color: #007299 !important;
			// background-color: ${({ theme }) =>
				theme.colors.white} !important;
			background-color: unset !important; /* Resets background to default */
		}
		.ant-tabs-tab-active .ant-tabs-tab-btn {
			color: #007299 !important;
			transition: none !important;
		}
		/* Style the ink bar (line below active tab) */
		.ant-tabs-ink-bar {
			background: #007299 !important; /* Change the underline color */
			// height: 4px; /* Adjust the height of the underline */
		}
		.ant-tabs-nav .ant-tabs-nav-list {
			transition: none !important;
			// color: #007299 !important;
		}
		.ant-tabs-tab-btn {
			color: #000000 !important;
		}
	}
`;

export const XSteps = styled(Steps)`
	// .ant-steps-item .ant-steps-item-icon {
	// 	background-color: #e6f1f5 !important; /* Color for inactive steps */
	// }

	/* Active step */
	.ant-steps-item-active .ant-steps-item-icon {
		background-color: #007299 !important; /* Color for active steps */
		color: #fff !important;
	}
	// .ant-steps-item .ant-steps-icon {
	// 	color: #007299 !important;
	// }
	.ant-steps-item-active .ant-steps-icon {
		color: #fff !important;
	}

	// .ant-steps-item-finish
	// 	.ant-steps-item-content
	// 	.ant-steps-item-title::after {
	// 	background-color: #007299 !important;
	// }
`;

export const XNewUpload = styled(Upload)`
	.ant-upload-list .ant-upload-list-picture-card {
		.ant-upload .ant-upload-select {
			background: red !important;
			border: none !important;
		}
	}
`;

export const XUploadCustom = styled(Upload)`
	.ant-upload-list-item-container {
		position: absolute;
		width: 100% !important;
		display: block !important;
	}
	.ant-upload-list-item-container {
		background: #fff !important;
	}
`;

export const CustomUpload = styled.div`
	           width: 150px,
				height: 150px,
				border: 2px solid #007299, // Border color and width
				border-radius: 8px, // Optional: make the corners rounded
				display: flex,
				align-items: center,
				justify-content: center,
`;

export const OutlineCircle = styled.div`
	position: relative;
	border: 1px solid lightgrey;
	border-radius: 50%;
	width: 25px;
	height: 25px;
`;
export const XNextPrevBtn = styled(Button)`
	border-radius: 50px !important;
	background: #007299 !important;
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.colors.fontDM};
	padding: 10px 0px;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 30px;
	letter-spacing: -0.28px;
	span {
		display: masonry !important;
	}
	&:hover {
		background: #007299 !important;
		color: ${({ theme }) => theme.colors.white} !important;
		border-color: #007299 !important;
	}
	svg {
		margin-top: -4px;
	}
	@media only screen and (max-width: 576.99px) {
		margin: 5px !important;
	}
`;
